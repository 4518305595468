import { Component,OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() totalpages: number = 0;        
  @Input() pageno: number = 0;    
  @Input() selectedindex: number = 0;   
  @Input() maxPagesVisible: number = 10; 

  @Output() pageChanged = new EventEmitter<number>();

  pagelinks: number[] = [];       
  visiblePages: number[] = [];    

  pageIndex = 0;       

  ngOnChanges() {
    this.pagelinks = Array.from({ length: this.totalpages }, (_, i) => i + 1);
    this.updateVisiblePages();
  }

  updateVisiblePages() {
    console.log(this.pageIndex,this.selectedindex,'selectedindextest')
    this.visiblePages = this.pagelinks.slice(this.pageIndex, this.pageIndex + 10);
  }

  selectPage(page: number) {
    this.pageChanged.emit(page);
  }

  getNextSet() {
    if (this.pageIndex + this.maxPagesVisible < this.totalpages) {
      this.pageIndex += this.maxPagesVisible;
      this.updateVisiblePages();
    }
  }

  getPrevSet() {
    if (this.pageIndex >= this.maxPagesVisible) {
      this.pageIndex -= this.maxPagesVisible;
      this.updateVisiblePages();
    }
  }

  resetPageIndex() {
    this.pageIndex = 0;
  }

}
