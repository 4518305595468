import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  createteams(teamsdata: any) {
    throw new Error('Method not implemented.');
  }
  baseurl = environment.apiurl;
  

  constructor(private http: HttpClient) { }
   mshteam(): Observable<any> {
    return this.http.get<any>(this.baseurl+ '/mshteam')  
}

uploadFile(formData: FormData): Observable<any> {
  return this.http.post<any>(this.baseurl + 'import_mshteam', formData);
}


// changeStatus(member: number, status: string): Observable<any> {
//   return this.http.patch(`${this.baseurl}/mshteam/${member}`, {
//     status: status,
//   }).pipe(
//     catchError((err) => throwError(err))
//   );
// }

// changeStatus(member: number, status: string): Observable<any> {   ``
       
//   const url = `${this.baseurl}mshteam/${member}`;
//   return this.http.patch(url, data).pipe(
//     catchError((error) => {
//       console.error('Error in changeStatus:', error);
//       return throwError(() => new Error('Failed to change status. Please try again.'));
//     })
//   );
// }

changeStatus(memberId: number, data: { status: string }): Observable<any> {
  const url = `${this.baseurl}mshteam/${memberId}`; 
  return this.http.patch(url, data).pipe(
    catchError((error) => {
      console.error('Error in changeStatus:', error);
      return throwError(() => new Error('Failed to change status. Please try again.'));
    })
  );
}
updateMember(memberId: number, data:any): Observable<any> {
  const url = `${this.baseurl}mshteam/${memberId}`; 
  return this.http.patch(url, data).pipe(
    catchError((error) => {
      console.error('Error in changeStatus:', error);
      return throwError(() => new Error('Failed to edit member. Please try again.'));
    })
  );
}

}
