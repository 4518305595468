import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import axios, { AxiosInstance } from 'axios';
@Injectable({
  providedIn: 'root'
})
export class CityService {

  private baseUrl = environment.apiurl;

  constructor() {}

  // Default method with state ID 101
  async getDefaultState(countryid:number) {
    try {
      const response = await axios.get(`${this.baseUrl}states/${countryid}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching default state:', error);
      throw error;
    }
  }

  // Method to get cities based on selected state ID
  async getCitiesByState(stateId: number) {
    try {
      const response = await axios.get(`${this.baseUrl}states/cities/${stateId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching cities for state ID ${stateId}:`, error);
      throw error;
    }
  }
}
