import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
// import { environment } from '../../environments/environment';

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: 'root'
})
export class InvestorService {
// baseUrl = "http://10.0.53.134:8080";
baseUrl = environment.apiurl 
private apinewUrl = `${this.baseUrl}investor/list`;
private newidUrl =`${this.baseUrl}investor/`;
private updateUrl=`${this.baseUrl}investor/`;
// private deleteurl='http://localhost:8095/api/investor/'
constructor(
  private http: HttpClient,
) {}

getInvestors(): Observable<any> {
  return this.http.get(this.apinewUrl);
}
getInvestorbyId(id:any):Observable<any>{
  const url = this.newidUrl + `${id}`;
return this.http.get(url)
}
UpdateInvestorData(id:any,data):Observable<any>{
  // const url=this.updateUrl + `${id}`;
  return this.http.put(`${this.updateUrl}${id}`, data);
}
deleteInvestorById(id: any): Observable<any> {
  console.log('ID:', id); // Add this line to check the value of id
  const url = this.newidUrl + `${id}`;
  console.log(url)
  return this.http.delete(url);
}


getIncubatorsList() {
 
  const url = this.baseUrl + "investors/list";
  let result: any = this.http
    .get(url)
    .pipe(catchError(this.handleError));
  return result;
}
filterIncubator(data) {
  const url = this.baseUrl + "search";
  let result: any = this.http
    .post(url, data, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
}
getIncubatorsByID(id) {
  const url = this.baseUrl + `investors/${id}`;
  let result: any = this.http
    .get(url, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
}
getschemeList(){
  const url=this.baseUrl +"schemes/list";
  let result: any = this.http
    .get(url, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
}
// Get Country
getCountryList(){
  const url = this.baseUrl + "countries";
  let result: any = this.http.get(url, {headers: headers}).pipe(catchError(this.handleError));
  return result;
}
// Get City
getCityList(id: any){
  const url = this.baseUrl + `cities/${id}`;
  let result: any = this.http.get(url, {headers: headers}).pipe(catchError(this.handleError));
  return result;
}
getAllCityList(){
  const url = this.baseUrl + `cities`;
  let result: any = this.http.get(url, {headers: headers}).pipe(catchError(this.handleError));
  return result;
}
actionIncubator(currentData, id) {
  let url = this.baseUrl + "investors/" + id;

  let result: any = this.http
    .put(url, currentData, { headers: headers })
    .pipe(catchError(this.handleError));

    console.log("data:results:", currentData);
  return result;
}
editIncubatorModal(currentData, id) {
  let url = this.baseUrl + "incubators/" +id;
  // console.log("update::", currentData);
    let result = this.http
    .put(url, currentData, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
}
updateScheme(data, id) {
  let url = this.baseUrl + `incubators/${id}/schemes`;
    let result = this.http
    .put(url, data, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
}
assignStarup(data, id){
  let url = this.baseUrl + `incubators/${id}/startups`;
    let result = this.http
    .put(url, data, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
}
handleError(error: HttpErrorResponse) {
  return throwError(error);
}
}
