
<!-- <div class="container-fluid bg-white mb-3">
  <div class="row bg-purple border-5 p-3 cursor-pointer" (click)="challenges()">
    <div class="col-3 d-flex justify-content-center align-items-center pos_rel">
      <i class="fa fa-arrow-left mr-3 text-white font-weight-bold pos_abs leftarr" aria-hidden="true"></i>
      <div>
        
        <p class="text-white font-weight-bold">
          Challenge Name :
        </p>
        <p class="text-white font-weight-bold mb-0">
          {{challengename}}
        </p>
      </div>
    </div>
    <div class="col-3">
      <div>
        <p class="text-white font-weight-bold">
          Edition Name :
        </p>
        <p class="text-white font-weight-bold mb-0">
          {{edition_name}}
        </p>
      </div>
    </div>
    <div class="col-3">
      <div>
        <p class="text-white font-weight-bold">
          Description :
        </p>
        <p class="text-white font-weight-bold mb-0">
          {{edition_desc}}
        </p>
      </div>
    </div>
    <div class="col-3">
      <div>
        <p class="text-white font-weight-bold">
          Duration :
        </p>
        <p class="text-white font-weight-bold mb-0">
          {{start_date}} to {{end_date}}
        </p>
      </div>
 
    </div>
  </div>
</div> -->

 <div class="container-fluid">
  <div class="d-flex justify-content-end mb-2">
    <button type="button"  class="btn btn-primary" (click)="handleCancel()">
      Go back
    </button>
  </div>
    <div class="row">
      
      <div class="col-12 col-sm-12 bg-white shadow p-5">
      
          <nb-stepper #stepper orientation="horizontal" class="p-3">


            <!--personal details-->

            <nb-step [stepControl]="companyDetailsForm" label="Personal Details">
            
              <div id="step1">
                <div class="w-100">
                  <h2 class="text-center">
                    Personal Details
                  </h2>
                 </div>
                  <div class="mt-4 w-100">
                    
                    <form id="step1form">
                      <div class="row">
                        <div class="col">
                          <input type="text" class="form-control" readonly name = "first_name" placeholder="First name">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" readonly name= "last_name" placeholder="Last name">
                        </div>
                      </div>
                      <div class="row my-3">
                        <div class="col">
                          <input type="text" class="form-control" readonly name="email" placeholder="Email">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" readonly name="contact_no" placeholder="Phone Number">
                        </div>
                      </div>
                      <div class="row my-3">
                        <div class="col">
                          <select name="gender" class="form-control">
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="OTHERS">Others</option>
                          </select>
                          <!-- <input type="text" class="form-control" readonly name="gender" placeholder="Gender"> -->
                        </div>
                        <div class="col">
                          <!-- <input type="text" class="form-control" readonly name="city" placeholder="city"> -->
                          <select name="country" id="country" class="form-control">
                            <option value="">Select Country</option>
                          </select>
                        </div>
                      </div>
                      <div class="row my-3">
                        <div class="col">
                          <!-- <input type="text" class="form-control" readonly name="state" placeholder="State"> -->
                          <select name="state" id="state" class="form-control">
                            <option value="">Select State</option>
                          </select>
                        </div>
                        <div class="col">
                          <!-- <input type="text" class="form-control" readonly name="city" placeholder="City"> -->
                          <select name="city" id="city" class="form-control">
                            <option value="">Select City</option>
                          </select>
                        </div>
                      </div>
                      <div class="row my-3">
                        <div class="col">
                          <input type="text" class="form-control" readonly name="linked_in" placeholder="LinkedIn Profile">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" readonly name="company_name" placeholder="Organisation Name">
                        </div>
                      </div>
                      <div class="row my-3">
                        <div class="col">
                          <input type="text" class="form-control" readonly name="company_website" placeholder="Website">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" readonly name="designation" placeholder="Designation">
                        </div>
                      </div>
                      <div class="row my-3">
                        <div class="col">
                          <!-- <input type="text" class="form-control" readonly name="languages" placeholder="Languages"> -->
                          <select name="languages" class="form-control">
                            <option value="">Select Language</option>
                            <option value="ENGLISH">English</option>
                            <option value="HINDI">Hindi</option>
                            <option value="OTHERS">Others</option>
                          </select>
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" readonly name="awards" placeholder="Awards & Recognitions">
                        </div>
                      </div>

                      <div class="row my-3">
                        <div class="col">
                          <label for="">Select Domains</label>
                          <select name="domain" class="form-control" id="multiselect_domain" multiple>
                            <option value="{{a.name}}" disabled *ngFor = "let a of alldomains">{{a.name}}</option>
                          </select>
                        </div>

                        <div class="col">
                          <label for="">Select Sectors</label>
                          <select name="sector" class="form-control" id="multiselect_sector" multiple>
                            <option value="{{s.name}}" *ngFor = "let s of sectors">{{s.name}}</option>
                          </select>
                        </div>
                      </div>

                      <div class="d-flex justify-content-center w-100">
                       <button nbButton nbStepperNext (click)="personalJson()" id="next_step_1" type="button" class="btn custombtn">Next</button>
                      </div>
                    </form>
                
                  </div>
              </div>
            </nb-step> 
            <!--personal details-->

            <nb-step [stepControl]="companyDetailsForm" label="Company Details">
            
              <div id="step2">
                <div class="w-100">
                  <h2 class="text-center">
                    Expertise and Experience
                  </h2>
                 </div>
                  <div class="mt-4 w-100">
                    <div class="d-flex justify-content-between mt-3 alignitemscenter w-100 mb-3">
                      <a class="font-weight-bold text-white bg-purple p-2 border-5 add_question" (click)="addEditor('step2')">
                        <i class="icon fa fa-plus text-white font-weight-bold mr-2"></i>
                        Add Question
                      </a>
      
                    </div>
                
               <form [formGroup]="editorsForm" id="step2form">
             
                <div class="form-row row align-items-center justify-content-between mb-2">
                      <div class="col-12">
                        <div formArrayName="editors">
                          <div *ngFor="let editor of editors.controls ; let i=index">
                            <div class="form" id="form{{i}}">
                            <div class="row w-100 border border-5 border-secondary p-3 mb-4 removeinui">
                              <div class="col-10">
                                <input type="text" formControlName="{{i}}" class="form-control hideonpreview" placeholder="Please Enter Question" value="" (keyup)="getlabel($event,i)">
                                <label for="" class="addlabel" id="label{{i}}"></label>
                                <input type="checkbox" class="scorable" disabled id="scorable{{i}}" value="scorable" (change)="addInput_scorable(i,$event)" name=""> <span class="scorable_text">Scorable</span>
                                <div id="answer{{i}}" class="answer">
                                  
                                </div>
                                <a (click)="removeEditor(i,'step2')" class="ml-auto text-white bg-orange font-weight-bold float-right mb-3 removequestion p-2 border-5">
                                  <i class="icon fa fa-minus text-white font-weight-bold mr-2"></i>
                                  Remove Question
                                </a>
                              </div>
                              <div class="col-2">
                                <div formArrayName="menus">
                                  <div *ngFor="let menu of menus.controls | unique;">
                                    <select (change)="addInput_m(i,$event)" class="btn bg-transparent border-info selectfield"> 
                                        <option value="">Select Answer Type</option>
                                        <option value="textarea">Long Answer</option>
                                        <option value="text">Short Answer</option>
                                        <option value="radio">Radio</option>
                                        <option value="checkbox">Checkbox</option>
                                        <option value="file">File Upload</option>
                                        <option value="Number">Enter Number</option>
                                        <option value="date">Select Date</option>
                                        <option value="select">Dropdown</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            
                         
                            </div>
                    
                          </div>
                          </div>
                      </div>

                    </div>
               
                  
                
      
                  <div  class="d-flex justify-content-center alignitemscenter addhere">
                        
                  </div>
         
                </div>
 
                  <div class="d-flex justify-content-center w-100">
                    <button nbButton nbStepperPrevious class="btn custombtnprev mr-3" id="prev_step_2" type="button">Prev</button>
                   <button nbButton nbStepperNext (click)="companyJson()" id="next_step_2" type="button" class="btn custombtn">Next</button>
                  </div>
                    
              </form>
                
                  </div>
              </div>
            </nb-step> 
      
      
            
             <nb-step [stepControl]="applicationSectionForm" label="Application Section" >
              
              <div id="step3">
                <div class="w-100">
                  <h2 class="text-center">
                    Availability and Preferences
                  </h2>
                 </div>
                  <div class="mt-4 w-100">
                    <div class="d-flex justify-content-between mt-3 alignitemscenter w-100 mb-3">
                      <a class="font-weight-bold text-white bg-purple p-2 border-5 add_question" (click)="addEditor('step3')">
                        <i class="icon fa fa-plus text-white font-weight-bold mr-2"></i>
                        Add Question
                      </a>
      
                    </div>
                
               <form [formGroup]="editors3Form" id="step3form">
             
                <div class="form-row row align-items-center justify-content-between mb-2">
                  
                      <div class="col-12">
                        <div formArrayName="editors3">
                          <div *ngFor="let editor of editors3.controls ; let i=index">
                            <div class="form" id="form{{i}}">
                            <div class="row w-100 border border-5 border-secondary p-3 mb-4 removeinui">
                              <div class="col-10">
                                <input type="text" formControlName="{{i}}" class="form-control hideonpreview" placeholder="Please Enter Question" value="" (keyup)="getlabel($event,i)">
                                <label for="" class="addlabel" id="label{{i}}"></label>
                                <input type="checkbox" id="scorable{{i}}" disabled class="scorable" value="scorable" (change)="addInput_scorable(i,$event)" name=""> <span class="scorable_text">Scorable</span>
                                <div id="answer{{i}}" class="answer">
                                  
                                </div>
                                <a (click)="removeEditor(i,'step3')" class="ml-auto text-white bg-orange font-weight-bold float-right mb-3 removequestion p-2 border-5">
                                  <i class="icon fa fa-minus text-white font-weight-bold mr-2"></i>
                                  Remove Question
                                </a>
                              </div>
                              <div class="col-2">
                                <div formArrayName="menus">
                                  <div *ngFor="let menu of menus.controls | unique;">
                                    <select (change)="addInput_m(i,$event)" class="btn bg-transparent border-info selectfield"> 
                                        <option value="">Select Answer Type</option>
                                        <option value="textarea">Long Answer</option>
                                        <option value="text">Short Answer</option>
                                        <option value="radio">Radio</option>
                                        <option value="checkbox">Checkbox</option>
                                        <option value="file">File Upload</option>
                                        <option value="Number">Enter Number</option>
                                        <option value="date">Select Date</option>
                                        <option value="select">Dropdown</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            
                         
                            </div>
               
                          </div>
                          </div>
                      </div>

                    </div>
               
                  
                
      
                  <div class="d-flex justify-content-center alignitemscenter addhere">
                        
                  </div>
                  </div>
         
                  <div class="d-flex justify-content-center w-100">
                    <button nbButton nbStepperPrevious class="btn custombtnprev mr-3" id="prev_step_3" type="button">Prev</button>
                   <button nbButton nbStepperNext  (click)="sectionJson()"  id="next_step_3" type="button" class="btn custombtn">Next</button>
                  </div>
                    
              </form>
                
                  </div>
              </div>
         
           </nb-step> 
      
           <nb-step [stepControl]="additionalQuestionForm" label="Additional Question" >
            <div id="step4">
              <div class="w-100">
                <h2 class="text-center">
                  Additional Information
                </h2>
               </div>
                <div class="mt-4 w-100">
                  <div class="d-flex justify-content-between mt-3 alignitemscenter w-100 mb-3">
                    <a class="font-weight-bold text-white bg-purple p-2 border-5 add_question" (click)="addEditor('step4')">
                      <i class="icon fa fa-plus text-white font-weight-bold mr-2"></i>
                      Add Question
                    </a>
    
                  </div>
              
             <form [formGroup]="editors4Form" id="step4form">
           
              <div class="form-row row align-items-center justify-content-between mb-2">
           
                    <div class="col-12">
                      <div formArrayName="editors">
                        <div *ngFor="let editor of editors4.controls ; let i=index">
                          <div class="form" id="form{{i}}">
                          <div class="row w-100 border border-5 border-secondary p-3 mb-4 removeinui">
                            <div class="col-10">
                              <input type="text" formControlName="{{i}}" class="form-control hideonpreview" placeholder="Please Enter Question" value="" (keyup)="getlabel($event,i)">
                              <label for="" class="addlabel" id="label{{i}}"></label>
                              <input type="checkbox" id="scorable{{i}}" disabled class="scorable" value="scorable" (change)="addInput_scorable(i,$event)" name=""> <span class="scorable_text">Scorable</span>
                              <div id="answer{{i}}" class="answer">
                                
                              </div>
                              <a (click)="removeEditor(i,'step4')" class="ml-auto text-white bg-orange font-weight-bold float-right mb-3 removequestion p-2 border-5">
                                <i class="icon fa fa-minus text-white font-weight-bold mr-2"></i>
                                Remove Question
                              </a>
                            </div>
                            <div class="col-2">
                              <div formArrayName="menus">
                                <div *ngFor="let menu of menus.controls | unique;">
                                  <select (change)="addInput_m(i,$event)" class="btn bg-transparent border-info selectfield"> 
                                      <option value="">Select Answer Type</option>
                                      <option value="textarea">Long Answer</option>
                                      <option value="text">Short Answer</option>
                                      <option value="radio">Radio</option>
                                      <option value="checkbox">Checkbox</option>
                                      <option value="file">File Upload</option>
                                      <option value="Number">Enter Number</option>
                                      <option value="date">Select Date</option>
                                      <option value="select">Dropdown</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          
                       
                          </div>
    
                        </div>
                        </div>
                    </div>
           
                  </div>

                <div class="d-flex justify-content-center alignitemscenter addhere">
                      
                </div>
            
              </div>
      
                <div class="d-flex justify-content-center w-100">
                  <button nbButton nbStepperPrevious class="btn custombtnprev mr-3" id="prev_step_4" type="button">Prev</button>
                 <button nbButton (click)="createApplicationForm()" id="next_step_4" type="button" class="btn custombtn">Submit</button>
                </div>
                  
            </form>
              
                </div>
            </div>
       
       </nb-step> 
      
       <!-- <nb-step [stepControl]="reviewPublishForm" label="Review" class="step4width">
       
        <div class="grid-container col-sm-12">
        <form [formGroup]="reviewPublishForm"  class="step-container">
   
      <div class="w-100 d-flex justify-content-center align-items-center">
     
      <button nbButton nbStepperNext type="button" id="next_step_5" class="btn btn-primary custombtn" (click)="publish()">confirm</button>
    </div>
      </form> 
      </div>
      </nb-step>  -->
    
           </nb-stepper>
        
      </div>
    </div>
  </div>
  


