<!-- Labs form-->
 <div>
<div *ngIf="isDownloading" class="loader-overlay">
  <div class="loader"></div>
  <!-- <p>Downloading...</p> -->
</div>
<div class="mb-3 ml-1 mr-1">
  <div>
    <h2 class="h2 bg-white mx-auto text-center">Labs Details</h2>
  </div>
  <div class="d-flex justify-content-end">
    <a
      class="btn btn-primary float-right mb-2 text-white mr-4 ml-5"
      (click)="gotosection(section)"
      >Lab Listing</a
    >
    <a
      class="btn btn-primary float-right mb-2 text-white"
      (click)="gotoBooking()"
      >Bookings Listing</a
    >
  </div>
</div>
<div class="container-grid" id="lab_form">
  <form [formGroup]="labFacilityForm" #section1>
    <div class="row">
      <div class="col-md-6 ml-3 mt-3">
        <div class="form-group">
          <label for="labFacility" class="form-label">Lab Title</label>
          <span class="text-danger">*</span>
          <input
            type="text"
            class="form-control"
            placeholder="Please enter Lab Title"
            formControlName="labTitle"
          />
          <div
            *ngIf="
              labFacilityForm.get('labTitle').invalid &&
              labFacilityForm.get('labTitle').touched
            "
          >
            <div
              *ngIf="labFacilityForm.get('labTitle').errors.required"
              class="text-danger"
            >
              Lab Title is required.
            </div>

            <div
              *ngIf="labFacilityForm.get('labTitle').errors.pattern"
              class="text-danger"
            >
              Valid Lab Title is required.
            </div>

          </div>
        </div>
        <div class="form-group">
          <label for="labFacility" class="form-label">Lab Information</label>
          <span class="text-danger">*</span>
          <input
            type="text"
            class="form-control"
            placeholder="Please enter Lab Information"
            formControlName="labInformation"
          />
          <div
            *ngIf="
              labFacilityForm.get('labInformation').invalid &&
              labFacilityForm.get('labInformation').touched
            "
          >
            <div
              *ngIf="labFacilityForm.get('labInformation').errors.required"
              class="text-danger"
            >
              Lab Information is required.
            </div>

            <div
            *ngIf="labFacilityForm.get('labInformation').errors.pattern"
            class="text-danger"
          >
            Valid Lab Information is required.
          </div>
          </div>
        </div>

        <div class="form-group">
          <label for="">Website URL</label>
          <span class="text-danger">*</span>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">@</span>
            </div>
            <input
              type="url"
              class="form-control"
              formControlName="labWebsiteUrl"
              placeholder="Please enter Website URL"
              aria-label=""
              aria-describedby="basic-addon1"
            />
          </div>
          <div
            *ngIf="
              labFacilityForm.get('labWebsiteUrl').invalid &&
              labFacilityForm.get('labWebsiteUrl').touched
            "
          >
            <div
              *ngIf="labFacilityForm.get('labWebsiteUrl').errors.required"
              class="text-danger"
            >
              URL is required.
            </div>

            <div
              *ngIf="labFacilityForm.get('labWebsiteUrl').errors.pattern"
              class="text-danger"
            >
              Valid URL is required.
            </div>  
          </div>
        </div>
      </div>     
      <div class="col-sm-5 mt-3">
        <div>
          <label>Lab Image 1</label><span class="text-danger">*</span>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              (change)="onFileSelect($event, 0)"
              accept=".jpg,.png,.jpeg"
              multiple
            />
          
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[0]" for="customFile"><a href="{{previewimageurl[0]}}" target="_blank">View Image</a></button>

           
            <label class="custom-file-label" for="customFile">{{
              images[0] || "Choose File"
            }}</label>
          
            <div
              *ngIf="
                labFacilityForm.get('_img1').invalid &&
                labFacilityForm.get('_img1').touched
              "
            >
              <div
                *ngIf="labFacilityForm.get('_img1').errors.required"
                class="text-danger"
              >
                File is required.
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <label>Lab Image 2</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              (change)="onFileSelect($event, 1)"
              accept=".jpg,.png,.jpeg"
              multiple
            />
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[1]" for="customFile"><a href="{{previewimageurl[1]}}" target="_blank">View Image</a></button>
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[1]" (click)="clearImage(1)">Remove Image</button>
            <label class="custom-file-label" for="customFile">{{
              images[1] || "Choose File"
            }}</label>
            <div
              *ngIf="
                labFacilityForm.get('_img2').invalid &&
                labFacilityForm.get('_img2').touched
              "
            >
              <div
                *ngIf="labFacilityForm.get('_img2').errors.required"
                class="text-danger"
              >
                File is required.
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <label>Lab Image 3</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              (change)="onFileSelect($event, 2)"
              accept=".jpg,.png,.jpeg"
              multiple
            />
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[2]" for="customFile"><a href="{{previewimageurl[2]}}" target="_blank">View Image</a></button>
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[2]" (click)="clearImage(2)">Remove Image</button>
            <label class="custom-file-label" for="customFile">{{
              images[2] || "Choose File"
            }}</label>
            <div
              *ngIf="
                labFacilityForm.get('_img3').invalid &&
                labFacilityForm.get('_img3').touched
              "
            >
              <div
                *ngIf="labFacilityForm.get('_img3').errors.required"
                class="text-danger"
              >
                File is required.
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <label>Lab Image 4</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              (change)="onFileSelect($event, 3)"
              accept=".jpg,.png,.jpeg"
              multiple
            />
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[3]" for="customFile"><a href="{{previewimageurl[3]}}" target="_blank">View Image</a></button>
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[3]" (click)="clearImage(3)">Remove Image</button>
            <label class="custom-file-label" for="customFile">{{
              images[3] || "Choose File"
            }}</label>
            <div
              *ngIf="
                labFacilityForm.get('_img4').invalid &&
                labFacilityForm.get('_img4').touched
              "
            >
              <div
                *ngIf="labFacilityForm.get('_img4').errors.required"
                class="text-danger"
              >
                File is required.
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <p class="text-danger">
            <span style="color: #444444">Note:</span> Please upload
            PNG/JPEG/JPG. Maximum size of the file is 2MB.
          </p>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="form-group col-md-6 p-2">
          <fieldset class="border p-2">
            <legend class="float-none w-auto p-2">Incharge Details</legend>

            <div class="form-group">
              <label for="labFacility" class="form-label">Name </label>
              <span class="text-danger">*</span>
              <input
                type="text"
                class="form-control"
                formControlName="inchargeName"
                placeholder="Please enter Name"
              />
              <div
                *ngIf="
                  labFacilityForm.get('inchargeName').invalid &&
                  labFacilityForm.get('inchargeName').touched
                "
              >
                <div
                  *ngIf="labFacilityForm.get('inchargeName').errors.required"
                  class="text-danger"
                >
                  Name is required.
                </div>

          <div
            *ngIf="labFacilityForm.get('inchargeName').errors.pattern"
            class="text-danger"
          >
            Valid Name is required.
          </div>


              </div>
            </div>

            <div class="form-group">
              <label for="labFacility" class="form-label">Contact Number </label
              ><span class="text-danger">*</span>
              <input
                type="number"
                class="form-control"
                formControlName="contactNumber"
                placeholder="Please enter Contact Number"
              />
              <div
                *ngIf="
                  labFacilityForm.get('contactNumber').invalid &&
                  labFacilityForm.get('contactNumber').touched
                "
              >
                <div
                  *ngIf="labFacilityForm.get('contactNumber').errors.required"
                  class="text-danger"
                >
                  Contact is required.
                </div>

                <div
                  *ngIf="labFacilityForm.get('contactNumber').errors.pattern"
                  class="text-danger"
                >
                  Contact number is invalid
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="labFacility" class="form-label">Designation </label>
              <span class="text-danger">*</span>
              <input
                type="text"
                class="form-control"
                formControlName="designation"
                placeholder="Please enter Designation"
              />
              <div
                *ngIf="
                  labFacilityForm.get('designation').invalid &&
                  labFacilityForm.get('designation').touched
                "
              >
                <div
                  *ngIf="labFacilityForm.get('designation').errors.required"
                  class="text-danger"
                >
                  Designation is required.
                </div>

           <div
            *ngIf="labFacilityForm.get('designation').errors.pattern"
            class="text-danger"
          >
            Valid Designation is required.
          </div>


              </div>
            </div>

            <div class="form-group">
              <label for="labFacility" class="form-label">Email Address </label>
              <span class="text-danger">*</span>
              <input
                type="email"
                class="form-control"
                formControlName="emailAddress"
                placeholder="Please enter Email Address"
              />
              <div
                *ngIf="
                  labFacilityForm.get('emailAddress').invalid &&
                  labFacilityForm.get('emailAddress').touched
                "
              >
                <div
                  *ngIf="labFacilityForm.get('emailAddress').errors.required"
                  class="text-danger"
                >
                  Email is required.
                </div>
                <div
                  *ngIf="labFacilityForm.get('emailAddress').errors.pattern"
                  class="text-danger"
                >
                  Valid Email is required.
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="form-group col-md-6 p-2">
          <fieldset class="border p-2">
            <legend class="float-none w-auto p-2">Location</legend>

            <div class="form-group">
              <label for="labFacility" class="form-label">
                Address</label
              ><span class="text-danger">*</span>
              <input
                type="text"
                class="form-control"
                placeholder="Please enter Address"
                formControlName="addressLine1"
              />
              <div
                *ngIf="
                  labFacilityForm.get('addressLine1').invalid &&
                  labFacilityForm.get('addressLine1').touched
                "
              >
                <div
                  *ngIf="labFacilityForm.get('addressLine1').errors.required"
                  class="text-danger"
                >
                  Address is required.
                </div>

                <div
                *ngIf="labFacilityForm.get('addressLine1').errors.pattern"
                class="text-danger"
              >
                Valid Address is required.
              </div>

              </div>
            </div>
            <div class="form-group">
              <label for="labFacility" class="form-label">State </label>
              <span class="text-danger">*</span>
              <!-- <select class="custom-select" formControlName="stateName">
                <option selected value="">Please select State</option>
                <option *ngFor="let states of arrstates" [ngValue]="states">
                  {{ states }}
                </option>
              </select> -->
              <ng-select 
              [items]="dropdownstates"
              bindLabel="label"  
    bindValue="value"  
              [ngClass]="{
                'is-invalid':
                  (
                    labFacilityForm.get('stateName')?.invalid) ||
                  (labFacilityForm.get('stateName')?.invalid &&
                    labFacilityForm.get('stateName')?.touched)
              }"            theme="default"
              placeholder="Select state"
              (change)="onStateSelect($event)"
              formControlName="stateName"
              [dropdownPosition]="'top'"
            ></ng-select>

              <div
                *ngIf="
                  labFacilityForm.get('stateName').invalid &&
                  labFacilityForm.get('stateName').touched
                "
              >
                <div
                  *ngIf="labFacilityForm.get('stateName').errors.required"
                  class="text-danger"
                >
                  State is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="labFacility" class="form-label">City </label>
              <span class="text-danger">*</span>
              <!-- <input
                type="text"
                class="form-control"
                formControlName="cityName"
                placeholder="Please enter City"
              /> -->
              <ng-select 
              [items]="selected_cities"
              [ngClass]="{
                'is-invalid':
                  (
                    labFacilityForm.get('cityName')?.invalid) ||
                  (labFacilityForm.get('cityName')?.invalid &&
                    labFacilityForm.get('cityName')?.touched)
              }"            theme="default"
              placeholder="Select City"
              bindLabel="city"
              bindValue="city"
             
              formControlName="cityName"
              [dropdownPosition]="'top'"
            ></ng-select> 
              <div
                *ngIf="
                  labFacilityForm.get('cityName').invalid &&
                  labFacilityForm.get('cityName').touched
                "
              >
                <div
                  *ngIf="labFacilityForm.get('cityName').errors.required"
                  class="text-danger"
                >
                  City is required.
                </div>

                <div
                *ngIf="labFacilityForm.get('cityName').errors.pattern"
                class="text-danger"
              >
                Valid City is required.
              </div>

              </div>
            </div>
          

            <div class="form-group">
              <label for="labFacility" class="form-label">Zip Code </label>
              <span class="text-danger">*</span>
              <input
                type="number"
                class="form-control"
                formControlName="zipCode"
                placeholder="Please enter Zip Code"
                maxlength="6"
              />
              <div
                *ngIf="
                  labFacilityForm.get('zipCode').invalid &&
                  labFacilityForm.get('zipCode').touched
                "
              >
                <div
                  *ngIf="labFacilityForm.get('zipCode').errors.required"
                  class="text-danger"
                >
                  Zip Code is required.
                </div>
                <div
                  *ngIf="labFacilityForm.get('zipCode').errors.pattern"
                  class="text-danger"
                >
                  Valid Zip Code is required.
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="form-group mr-3 ml-3">
      <label for="labFacility" class="form-label"
        >Lab Benefits & Highlights</label
      ><span class="text-danger">*</span>     
      <ckeditor
        [editor]="Editor"
        [config]="editorConfig"
        [config]="{ placeholder: 'Please enter Lab Benefits & Highlights' }"
        formControlName="labBenefits"
        placeholder="Please enter Lab Benefits & Highlights"
        #myEditor
      ></ckeditor>
      <div
        *ngIf="
          labFacilityForm.get('labBenefits').invalid &&
          labFacilityForm.get('labBenefits').touched
        "
      >
        <div
          *ngIf="labFacilityForm.get('labBenefits').errors.required"
          class="text-danger"
        >
          Benefits & Highlights is required.
        </div>

        <div  *ngIf="labFacilityForm.get('labBenefits').errors.pattern"  class="text-danger">
                Valid Benefits & Highlights is required.
        </div>


      </div>
    </div>

    <div class="form-group mr-3 ml-3">
      <label for="labFacility" class="form-label">Lab Eligibility</label>
      <span class="text-danger">*</span>     
      <ckeditor
        [editor]="Editor"
        [config]="editorConfig"
        [config]="{ placeholder: 'Please enter Lab Eligibility' }"
        placeholder="Please enter Lab Eligibility"
        formControlName="labEligibility"
        #myEditor
      ></ckeditor>
      <div
        *ngIf="
          labFacilityForm.get('labEligibility').invalid &&
          labFacilityForm.get('labEligibility').touched
        "
      >
        <div
          *ngIf="labFacilityForm.get('labEligibility').errors.required"
          class="text-danger"
        >
          Eligibility is required.
        </div>

        <div  *ngIf="labFacilityForm.get('labEligibility').errors.pattern"  class="text-danger">
          Valid Eligibility is required.
        </div>


      </div>
    </div>

    <div class="d-flex w-100 justify-content-center">
     
      <button
        class="btn custombtn mr-3 mb-3 mt-3"
        (click)="submitLabData()"
        *ngIf="createMode"  
      >
        Create
      </button>
     
      <button
        class="btn custombtn mr-3 mb-3 mt-3"
        (click)="updateLabs()"
        *ngIf="editMode"
      >
        Update
      </button>

     

      <!-- <button class="btn custombtn mr-3 mb-3 mt-3" (click)="updateForms()" *ngIf="editMode">Update</button> -->
      <button class="btn custombtn mb-3 mt-3" (click)="cancel()">Cancel</button>
    </div>
    <div class="row">
      
    </div>
  </form>


  <form [formGroup]="labFilterForm">
<div class="p-2">
  <div class="row d-flex"> 
    <div class="col-sm-4">
      <label class="mx-3">User Type</label>    
      <select  id="selectedUserType"   class="form-select usertypeselect"  formControlName="selectedUserType" (change)="onUserSelectionChange($event)"  > 
        <option value="ALL">Select User Type</option>
        <option value="INCUBATOR" selected>INCUBATOR</option>
         <option value="COE" >COE</option>
         <option value="ACCELERATOR">ACCELERATOR</option>
         <option value="SUPERADMIN">SUPER ADMIN</option>
     </select>     
     
  
  </div>
  <div class="col-sm-4">
    <label class="mx-3">User</label>    
  <ng-multiselect-dropdown 
              [placeholder]="''"
              [settings]="dropdownSettings"
              [data]="dropdownuser"
              [(ngModel)]="selectedUser"
              [ngModelOptions]="{standalone: true}"
              
            >
    </ng-multiselect-dropdown>
   

</div>
<div class="col-sm-4">
  <label class="mx-3">Status</label>    
<ng-multiselect-dropdown 
            [placeholder]="''"
            [settings]="dropdownSettingst"
            [data]="dropdownstatus"
            [(ngModel)]="selectedStatus"
            [ngModelOptions]="{standalone: true}"
            
          >
  </ng-multiselect-dropdown>
</div>
      </div>
      <div class="row d-flex">
        <div class="col-sm-8">
          <div class="input_row">
            <div class="input_box">
              <label  class="mx-3">From Date</label>
              <input type="date" formControlName="start_date" id="start_date"
                class="form-control date"
               
              />
            </div>
           
          <div class="input_box">
              <label class="mx-3">To Date</label>
              <input type="date" formControlName="end_date" id="end_date"
                class="form-control date"/>
            </div>
          </div>
         
          
        </div>
        <div class="col-4 d-flex justify-content-end align-self-end ms-auto">
       
            <button class="btn btn-primary mr-1" (click)="filtersItem()" *ngIf="filterMode" id="filter"> Filter </button> 
            <button class="btn btn-secondary mr-1" (click)="resetFilters()" >Reset</button>
            <button class="btn btn-success mr-1" (click)="downloadButton == true ? downloadLabList() : (downloadButton = false)" [ngClass]="{ 'disabled': downloadButton == false }"><i class="fas fa-download"></i> Download</button>
         
        </div>
      </div>

    </div>
    </form>
 
   <!-- <div class="filter-container">
     
    <div class="filter-row justify-content-center mt-3">
      <p (click)="countFilter('ALL')" [class.active]="activeFilter == null">ALL  <span> {{+approvedCount + +rejectedCount + +pendingCount}}</span></p>
      <p (click)="countFilter('APPROVED')" [class.active]="activeFilter == 'APPROVED'">APPROVED  <span> {{approvedCount}}</span></p>
      <p (click)="countFilter('DECLINED')" [class.active]="activeFilter == 'DECLINED'">DECLINED  <span> {{rejectedCount}}</span></p>
      <p (click)="countFilter('PENDING')" [class.active]="activeFilter == 'PENDING'">PENDING  <span> {{pendingCount}}</span></p>
    </div>
    

 
</div> -->
<!-- <div class="filter-container">
  <div class="filter-row justify-content-center mt-3">
    <p (click)="filtersItem('ALL')" [class.active]="activeFilter == null">
      ALL <span> {{ +approvedCount + +rejectedCount + +pendingCount }}</span>
    </p>
    <p (click)="filtersItem('APPROVED')" [class.active]="activeFilter == 'APPROVED'">
      APPROVED <span>{{ approvedCount }}</span>
    </p>
    <p (click)="filtersItem('DECLINED')" [class.active]="activeFilter == 'DECLINED'">
      DECLINED <span>{{ rejectedCount }}</span>
    </p>
    <p (click)="filtersItem('PENDING')" [class.active]="activeFilter == 'PENDING'">
      PENDING <span>{{ pendingCount }}</span>
    </p>
  </div>
</div> -->
<div class="filter-container">
  <div class="filter-row justify-content-center mt-3">

  <!-- "View All" should be active only when all three statuses are selected -->
  <p (click)="filtersItem('All')"
     [class.active]="activeFilter?.length === 3">
    View All <span>{{ +approvedCount + +rejectedCount + +pendingCount }}</span>
  </p>

  <p (click)="filtersItem('APPROVED')" 
     [class.active]="activeFilter?.length < 3 && activeFilter?.includes('APPROVED')">
    APPROVED <span>{{approvedCount}}</span>
  </p>

  <p (click)="filtersItem('DECLINED')" 
     [class.active]="activeFilter?.length < 3 && activeFilter?.includes('DECLINED')">
     DECLINED <span>{{rejectedCount}}</span>
  </p>

  <p (click)="filtersItem('PENDING')" 
     [class.active]="activeFilter?.length < 3 && activeFilter?.includes('PENDING')">
    PENDING <span>{{pendingCount}}</span>
  </p>
</div>
</div>
<!-- filter count row -->

    <!-- download button -->
  <div class="table-wrapper table-responsive" #section>
    <table class="table table-striped">
      <thead class="container-lg ">
        <tr>
          <th scope="col" class="headerfix">Sl No</th>
          <th scope="col" class="headerfix">Updated Time</th>
          <th class="headerfix">Lab Title</th>
          <th class="headerfix">Incharge Name</th>
          <th class="headerfix">Incharge Contact</th>
          <th class="headerfix">Email Address</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody *ngIf="list?.length > 0; else noRecords">
        <tr *ngFor="let data of list ; let i = index">
          <!-- | paginate: { itemsPerPage: pageSize, currentPage: p } -->
          <th scope="row" class="text-center">{{getSerialNo(i+1)}}</th>
                    <td>{{data.updated_at |  date}}</td>
          <td >{{ data?.name }}</td>
          <td>{{ data.incharge_details?.name }}</td>
          <td>{{ data.incharge_details?.phone }}</td>
          <td class="text-break">{{ data.incharge_details?.email }}</td>
          <td>{{ data?.status }}</td>
          <td class="d-flex">
            <div *ngIf="data.status === 'PENDING'" class="d-flex">
              <a
                class="dropbtn fa fa-check mr-2"
                style="color: blue"
                (click)="
                  changestatus(data.id, 'APPROVED', 'labresource', data.creator)
                "
              ></a>
              <a
                class="dropbtn fa fa-close mr-2"
                style="color: red"
                (click)="
                  changestatus(data.id, 'DECLINED', 'labresource', data.creator)
                "
              ></a>
            </div>
            <div *ngIf="data.status === 'APPROVED'">
              <a
                class="dropbtn fa fa-close mr-2 ml-4"
                style="color: red"
                (click)="
                  changestatus(data.id, 'DECLINED', 'labresource', data.creator)
                "
              ></a>
            </div>
            <div *ngIf="data.status === 'DECLINED'">
              <a
                class="dropbtn fa fa-check mr-4"
                style="color: blue"
                (click)="
                  changestatus(data.id, 'APPROVED', 'labresource', data.creator)
                "
              ></a>
            </div>

            <span
              class="fa fa-edit cursor-pointer"
              style="color: blue"
              alt="Edit" title="Edit" 
              (click)="editData(data, section1)"
            ></span>
            <!-- <span
              class="fa fa-trash ml-2 cursor-pointer"
              style="color: red"
              alt="Delete" title="Delete" 
              (click)="deleteData(data.id)"
            ></span> -->
            <a
              class="fa fa-eye ml-2 cursor-pointer"
              style="color: green"
              data-toggle="modal"
              alt="View" title="View" 
              data-target="#exampleModal"
              (click)="preview(data)"
            ></a>
          </td>
        </tr>
      </tbody>
      <ng-template #noRecords>
        <tbody>
          <tr>
            <td colspan="12" class="no-records-found">No Data found.</td>
          </tr>
        </tbody>
      </ng-template>
    </table>
    <!-- <p class="text-center" *ngIf="list.length < 1">No data found</p> -->
    <!-- <pagination-controls *ngIf="paginationEnabled && list?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls> -->
  </div>

  <app-pagination 
  [totalpages]="totalpages" 
  [pageno]="pageno"
  [selectedindex]="selectedindex"
  (pageChanged)="paginate_startup($event)">
  </app-pagination>
  <!-- Labs Table ends-->
</div>
<!-- Labs form Ends-->

<!-- Modal -->
<div
  class="modal custom-modal"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content d-flex">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Preview</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <label for="basic-url">Lab Title</label>
          <div class="input-group">
            <div class="input-group-prepend"></div>
            <input
              type="text"
              class="form-control mb-2"
              placeholder=""
              aria-label="Username"
              value="{{ modaldata.name }}"
              aria-describedby="basic-addon1"
              readonly
            />
          </div>
          <label for="basic-url">Lab Images</label>
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div  class="col" *ngFor="let img of modaldata.img1">
              <img [src]="img" alt="image" class="rounded" width="150" height="150"  />
            </div>
          
            
            
          </div>

          <div class="mb-2">
            <label for="basic-url">Lab Information </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.info }}"
              id="fileInput"
              readonly/>
            <label for="basic-url">Website URL </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.websiteurl }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Lab Benefits </label>
          
            <textarea class="form-control" [value]="stripHtmlTags(modaldata.benefits)"  readonly></textarea>
            <label for="basic-url">Lab Eligibility</label>
            <!-- <input
              class="form-control mb-2"
              value="{{ modaldata.eligibility }}"
              id="fileInput"
              readonly
            /> -->
            <textarea class="form-control" [value]="stripHtmlTags(modaldata.eligibility)" readonly></textarea>
            <div>
              <h2>Incharge Details:</h2>
            </div>

            <label for="basic-url">Name </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.name }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Contact Number</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.phone }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Designation </label>
            
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.designation }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Email Address</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.email }}"
              id="fileInput"
              readonly
            />

            <label for="basic-url">User Type</label>
            <input
              class="form-control mb-2"
              value="{{ userType }}"
              id="fileInput"
              readonly
            /> 
            <label for="basic-url">User Name</label>
            <input
              class="form-control mb-2"
              value="{{ userName }}"
              id="fileInput"
              readonly
            />

            <div>
              <h2>Location:</h2>
            </div>
            <label for="basic-url">Address </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.address_line1 }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">City</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.city }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">State </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.state }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">ZipCode</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.zip_code }}"
              id="fileInput"
              readonly
            />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
