import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FaqsService } from "../../services/faqs.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { toJSON, toDOM } from "dom-to-json";
import * as _ from "lodash";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ShowcaseDialogComponent } from "../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ExcelService } from '../../services/excel.service';
import { ActivatedRoute,Router,Route } from "@angular/router";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
@Component({
  selector: "ngx-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"],
})
export class FaqsComponent implements OnInit {
  public Editor = ClassicEditor;
  filterStatus: '';
  filterArray = [
    {
      "name": "status",
      "value": []
    },
  ];
  public editorConfig = {
    placeholder: 'Enter Answer',
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
  };
  faqsForm: FormGroup;
  categoryList = "General";
  dropLists = [
    "list1",
    "list2",
    "list3",
    "list4",
   
  ];
  page_index:number = 0;
  pageno:any = 0;
  totalpages: number;
  totalItems: number;
  selectedindex: number=0;
  
  data: any;
  dataaa: any;
  list: any[] = [];
  list2: any[] = [];
  id_byList:any;
  editMode: boolean;
  createMode: boolean = true;
  currentData: any;
  faqsEditdata: any;
  inValidMessage: boolean;
  formSubmitted = false;
  paginationEnabled: boolean = true;
  value: any;
  index: any;
  modaldata = { categories: "", question: "", answer: "", user_Id:"" };
  user_Id:any=[];
  searchText = '';
  p: number = 1;
  pageSize: number = 25;
  id = "section";
  // dialog: any;
  mat_data: any;
  filter_list: any;
  newsFilterForm: FormGroup;
  selectedStatus: any = [];
  filterEnable: boolean = false; 
  isDownloading:boolean;
  dropdownSettingst: IDropdownSettings = {};
  dropdownstatus: any[] = ["active", "inactive"]
  filterValue: any;
  myArray: any[] = [];
  filterMode: boolean = true;
  activeFilter: string [] =[];
  filterResponseData: any;
  selectstatus: any = [];
  // filterStatus: any[] = []
  selectedStatuses: any[];
  constructor(
    private faqsService: FaqsService,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private route:ActivatedRoute,
    private router:Router,
    private datePipe: DatePipe,

  ) {
    this.route.queryParams.subscribe(params => { 
        this.filterStatus = params['status'];
    });
    this.filterValue = this.route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);
  }

  ngOnInit(): void {
    this.faqsForm = this.fb.group({
      categories: [""],
      question: ["", Validators.required],
      answer: ["", Validators.required],
    });
    this.newsFilterForm = this.fb.group({

      status:['']    
    });
    this.dropdownSettingst={
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    }
    this.setFilters()
    this.getCategory();
    this.getAllFaqsQuestion();
    this.getactivefaq()
    
  }
 
  getCategory() {
    this.faqsService.getallcategory().subscribe((res: any) => {
     
    });
  }
  getactivefaq(){
    this.faqsService.activeFaqList().subscribe((res: any) => {
      this.list2 = res.results;    
      console.log("list", this.list2);
     
    });

  }
  getAllFaqsQuestion() {
   let data= {
    search_entity: "FAQS",
    "page": 0, "page_size": 10}
    this.faqsService.filterPress(data).subscribe((res: any) => {
      this.list = res.results;    
      console.log("list", this.list);
      this.totalItems=+res.total_search_results_count;  
      var totalPagesss = Math.ceil(this.totalItems/10);
      this.totalpages = totalPagesss;
     
    });
  
  }
  get filteredData() {
    // let filteredDataByStatus;
    // if(this.filterStatus) {
    //     filteredDataByStatus = this.list?.filter(d => d.faq_status == this.filterStatus.toLowerCase())
    // } else { filteredDataByStatus = this.list }
    // return filteredDataByStatus?.filter(d => {

    //   if (d && d.faq_question || d && d.faq_answer || d && d.faq_status ) {
    //       return  d.faq_question.toLowerCase().includes(this.searchText.toLowerCase()) 
    //       || d.faq_answer.toLowerCase().includes(this.searchText.toLowerCase())
    //       || d.faq_status.toLowerCase().includes(this.searchText.toLowerCase());
    //   }
    
    //   return false;
    // });
    return this.list
  }
  

  submitFaqsQuestion() {
    this.formSubmitted = true;
    if (this.faqsForm.valid) {
      this.editMode = false;
      this.createMode = true;
      this.data = {
        faqs_category_id: 1,
        faq_question: this.faqsForm.controls.question.value,
        faq_answer: this.faqsForm.controls.answer.value,
        user_id: sessionStorage.getItem('user_id')
      };
      this.faqsService.createFaqs(this.data).subscribe(
        (res: any) => {
          this.data = res.results;
          this.faqsForm.reset();
          this.getAllFaqsQuestion();
          this.toastrService.success("Data Added Successfully!");
        },
        (error) => {
          this.inValidMessage = true;
          this.toastrService.warning("Record already Exist!");
        }
      );
    } else {
      this.faqsForm.markAllAsTouched();
    }
  }
  formatDate(date){
    console.log('date_formatted',date);
    let myDate=new Date(date);
    let formattedDate=this.datePipe.transform(myDate,'dd-MM-yyyy hh:mm');
    return formattedDate;
   }
  editData(data, el:any) {
    el.scrollIntoView({behaviour:'smooth'});

    this.data = data;
    console.log("editdata", this.data);
    this.faqsForm.controls["categories"].setValue(data.faqs_categoryname);
    this.faqsForm.controls["question"].setValue(data.faq_question);
    this.faqsForm.controls["answer"].setValue(data.faq_answer);
    this.editMode = true; //change btn to update
    this.createMode = false;
  }

  updateForms() {
    if (this.faqsForm.valid) {
      if (this.editMode && _.faqsForm == _.data) {
     
        this.editMode = true;
        this.currentData = this.faqsForm.value;
        this.faqsService
          .editfaqsQuestion(this.currentData, this.data.faqs_id)
          .subscribe(
            (res) => {
              this.faqsForm.reset();
              this.getAllFaqsQuestion();
              this.editMode = false;
              this.createMode = true;
              this.toastrService.success("Data Updated Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Update records!");
            }
          ); /////put api function call ends here!
      }
    }
  }

  deleteFaqs(id) {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this item?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          this.faqsService.deleteFaqsRow(id).subscribe(
            (data) => {
              this.getAllFaqsQuestion();
              this.toastrService.success("Data Deleted Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.danger("Unable to Delete records!");
            }
          );
        }
      });
      var htmlElement = document.documentElement;
        if (htmlElement) {
          htmlElement.style.top = '0'; 
          htmlElement.style.position = 'sticky';
        } 
  }
 
  changestatus(data:any, status:any){
    // localStorage.setItem('myData', 'Faqs');
    console.log('data', data);
    this.mat_data = status;

    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Faqs' }
        })
    dialogRef.afterClosed().subscribe(result => {
     var result=result;
      if(result==true){
        var value = {
          faqs_id: data.faqs_id,
          faqs_category_id: data.faqs_category_id,
          faq_question: data.faq_question,
          faq_answer: data.faq_answer,
          user_id: data.user_id,
          faq_status: status
        }
        this.faqsService.actionFaqs(value).subscribe((res:any)=>{
            console.log('change_status',res);
            this.getAllFaqsQuestion();
            localStorage.removeItem('myData');
            this.toastrService.success("Successfully updated Status!");
          },
          (error) => {
          this.toastrService.warning("Unable to Update Status!");
        })
        
      }
      });
  }

  cancel() {
    this.faqsForm.reset();
    this.editMode = false;
    this.createMode = true;
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.list2, event.previousIndex, event.currentIndex);
    // this.resetIndexes();    
    console.log(this.list2);
    const data = this.list2.map((item, index) => ({
      "faqId": item.faqs_id,
      "orderId": index + 1 
    }));
    console.log("modified data is ==> ", data)
    // this.faqsService.dragUpdate(this.id).subscribe((res:any)=>{
    //   this.id = this.id

    // })
    this.faqsService.changeOrder(data).subscribe((res: any) => {      
      console.log('FAQ order updated successfully:', res);
      this.toastrService.success("Successfully updated FAQ!");
    },
    (error) => {
      this.inValidMessage = true;
      this.toastrService.warning("Unable to Update FAQ!");
    }
    );
    
    

  }

  preview(data: any) {
    console.log("dataPreview:", data);
    this.modaldata.question = data.faq_question;
    this.modaldata.answer = data.faq_answer;
 
  }


  exportData(dataArray: any[]) {
    const data = dataArray.map((d) => ({
      Question: d.faq_question ? d.faq_question : '-',
      Answer: d.faq_answer ? d.faq_answer.replace(/<\/?[^>]+(>|$)/g, "") : '-',
      Status: d.faq_status ? d.faq_status :'-',
      'Updated Time':new Date(d?.updated_at),
      'Created at':this.formatDate(d?.created_at),

    }));
    this.exportAsXLSX(data)
 
    console.log("Export:", data);
  }

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Faqs','Faqs_data');
  }

  // downloadFaqList() {
  //   var exportTableData
  //   if(this.filterStatus) {
  //       exportTableData = this.list?.filter(d => d.faq_status == this.filterStatus.toLowerCase())
  //   } else { exportTableData = this.list }
  //   this.exportData(exportTableData);
  // }


  async listAllFaqsDownload(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const request = {
      query:this.searchText,

        filters: this.myArray,
        search_entity: "FAQS",
        
        page: 0,
        page_size: 10000,
      };
  
      this.faqsService.filterPress(request).subscribe(
        (res: any) => {
          resolve(res.results);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  
 async downloadFaqList(){
    this.isDownloading = true;

  try {
    const FaqsData = await this.listAllFaqsDownload();
    this.exportData(FaqsData);
  } catch (err) {
    console.error('Download failed', err);
  }

  this.isDownloading = false;
  };
  async filtersItem() {
    
    this.p = 1;
    // var dateFilter = [];
    // let startDate = this.prFilterForm.get('start_date').value;
    // let endDate = this.pressFilterForm.get('end_date').value;
    // if(startDate && endDate){
    //   dateFilter.push({ "start_date": startDate, "end_date": endDate });
    // }
   
  this.myArray = await this.setFilters(this.selectedStatus);
    console.log('myarray',this.myArray)
    console.log("Filters Applied:", this.myArray);
  
    let requestData = {
      query:this.searchText,
      filters: this.myArray,
      search_entity: "FAQS",
      
      page: 0,
      page_size: 10,
    };
  
    this.faqsService.filterPress(requestData).subscribe((res: any) => {
      console.log("API Response:", res);
    
      this.list = res.results;

      
      this.totalItems=+res.total_search_results_count;  
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages; // Replace with your dynamic value
      
    });
    this.filterEnable = true;



  }
  
  async setFilters(...filters: any[][]) {
    //const filterNames = ["state", "city", "domain", "schemes", "status"];
  
   
    this.filterArray = this.filterArray.map((filter, index) => ({
        ...filter,
        value: filters[index] || [] // Assign values if present, else keep empty
    }));
    console.log('filterArray ----',this.filterArray);
    return this.filterArray;
  }
  resetFilter() {
    this.selectedStatus = []; // Reset selected filters in dropdown
    this.myArray = []; // Reset filter array
    this.filterEnable = false; // Disable filter applied flag if needed
    this.p = 1; // Reset pagination to first page
    setTimeout(() => {
      this.searchText = ''; // ✅ Ensure UI updates properly
    });
    // Call existing method to load initial data
    this.getAllFaqsQuestion();
  }


paginate_startup(pageno) {
  this.pageno = pageno;

  console.log('pageno----',this.pageno);
  this.selectedindex = pageno-1;
  console.log('selectedindex-----',this.selectedindex)
  console.log('myarray ----',this.myArray);
  let data={
"query":this.searchText,
    "filters": this.myArray,
    "search_entity": "FAQS",
    "page": pageno- 1,
    "page_size": 10,
      
    }
    // this.isDownloading = true;

  
  console.log('data_payload',data);
  // this.loading = true;
  this.faqsService.filterPress(data).subscribe((res)=>{
    this.data = res.results;
    this.list = res.results;
    console.log('data----');
    console.log(this.data);
    // this.loading = false;
    // this.isDownloading = true;

  })
}

getSerialNo(i:number) {
  return i+(this.selectedindex*10);
}


  
}
