<div>
    <div *ngIf="isDownloading" class="loader-overlay">
        <div class="loader"></div>
        
      </div>
    <div class="row">
    <div class="col-sm-4">
        <label>State</label>
        <ng-multiselect-dropdown [(ngModel)]="selectstate" [placeholder]="''" [settings]="dropdownSettings_s"
        (onSelect)="onStateSelect($event)"
        (onDeSelect)="onStateDeSelect($event)"
        [data]="dropdownstatesapi"
        [ngModelOptions]="{standalone: true}">
        </ng-multiselect-dropdown>
    </div>
  
    <div class="col-4">
          <label class="mx-3">City</label>
        
        <ng-multiselect-dropdown
        [(ngModel)]="selectcity"
        [placeholder]="''"
        [settings]="dropdownSettings_city"
        [data]= "selected_citiesapi"
        [ngModelOptions]="{ standalone: true }"
        ></ng-multiselect-dropdown>
        </div>
    <div class="col-sm-4">
        <label>Domain</label>
        <ng-multiselect-dropdown [(ngModel)]="selectdomain" [placeholder]="''" [settings]="dropdownSettings"
            [data]="dropdownList"  [ngModelOptions]="{standalone: true}"
            (onSelect)="onItemSelectDomain($event)" (onDeSelect)="onItemDeSelectDomain($event)">
        </ng-multiselect-dropdown>
    </div>
</div>

<div class="row  d-flex startups-status-filter-reset">
    <div class="col-sm-4">
        <label>Status</label>
        <ng-multiselect-dropdown [(ngModel)]="selectstatus" [placeholder]="''" [settings]="dropdownSettings"
            [data]="dropdownstatus"  [ngModelOptions]="{standalone: true}">
        </ng-multiselect-dropdown>
    </div>
    <div class=" col-sm-4 mt-4">
        <input class="form-control " type="text" [(ngModel)]="searchText" placeholder="Search by Name..." [ngModelOptions]="{standalone: true}">
      </div>
    <!-- <div class="col-sm-4">
        <label>Status</label>
        <input class="form-control " type="text" [(ngModel)]="searchText" [placeholder]="'Search by name'" 
             [ngModelOptions]="{standalone: true}"/>
    
    </div> -->
    
        
        <div class="col-4  d-flex justify-content-end align-self-end ms-auto my-1">
           
            <button [disabled]="selectstate =='' &&  selectcity == '' && selectdomain.length==0  && selectstatus == '' && selectedScheme == '' && searchText == ''   "  class="btn btn-primary  mr-1" (click)="filtersItem()" *ngIf="filterMode" id="filter">
                Filter
              </button>
         
            <button class="btn btn-secondary mx-3" (click)="resetFilters()">Reset</button>
            <button class="btn btn-success " (click)="downloadStartupList()">
                <i class="fas fa-download"></i>
                Download
            </button>
        </div>

    
</div>



<div class="row startups-schemes-download">
    <div class="col-sm-6 ">
        <label>Schemes</label>
        <ng-multiselect-dropdown placeholder="select scheme" [settings]="dropdownSettings_scheme"
            [data]="dropdownschemes" [(ngModel)]="selectedScheme" (onSelect)="schemeChanged($event)"
            [ngModelOptions]="{standalone: true}"></ng-multiselect-dropdown>
    </div>
    <div class="col-sm-6">
      
        <div class=" d-flex justify-content-end align-self-end ms-auto pt-4">
            <button class="btn btn-success" (click)="downloadStartupList()">
                <i class="fas fa-download"></i>
                Download
            </button>
        </div>
    </div>
</div>

<br>
<!-- filter count row -->
<!-- <div class="filter-container">
    <div class="filter-row justify-content-center">
        <p (click)="filtersItem('All')" [class.active]="activeFilter == null">
            View All <span>{{ +approvedCount + +rejectedCount + +pendingCount }}</span>
        </p>
        <p (click)="filtersItem('APPROVED')" [class.active]="activeFilter == 'APPROVED'" [class.disabled]="!dsApproved">
            APPROVED <span>{{ approvedCount }} </span>
        </p>
        <p (click)="filtersItem('REJECTED')" [class.active]="activeFilter == 'REJECTED'" [class.disabled]="!dsRejected">
            REJECTED <span>{{ rejectedCount }}</span>
        </p>
        <p (click)="filtersItem('PENDING')" [class.active]="activeFilter == 'PENDING'" [class.disabled]="!dsPending">
            PENDING <span>{{ pendingCount }}</span>
        </p>
    </div>
</div> -->
<div class="filter-container p-2">
     
    <div class="filter-row">
      <!-- "View All" should be active only when all three statuses are selected -->
      <p (click)="filtersItem('All')"
         [class.active]="activeFilter?.length === 3">
        View All <span>{{ +approvedCount + +rejectedCount + +pendingCount }}</span>
      </p>
    
      <p (click)="filtersItem('APPROVED')" 
         [class.active]="activeFilter?.length < 3 && activeFilter?.includes('APPROVED')">
        APPROVED <span>{{approvedCount}}</span>
      </p>
    
      <p (click)="filtersItem('REJECTED')" 
         [class.active]="activeFilter?.length < 3 && activeFilter?.includes('REJECTED')">
        REJECTED <span>{{rejectedCount}}</span>
      </p>
    
      <p (click)="filtersItem('PENDING')" 
         [class.active]="activeFilter?.length < 3 && activeFilter?.includes('PENDING')">
        PENDING <span>{{pendingCount}}</span>
      </p>
    </div>
    

 
</div>
<!-- filter count row -->


<div class="container-grid col-12 col-sm-12">
    
    <div class="p-2">
        <strong class="d-flex justify-content-center" style="font-size: 27px;">Startup Detail</strong>
    </div>

    <div class="container-lg">

        <div class="table-wrapper table-responsive">
            <div *ngIf="loading" class="loader-container" >
                <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <table class="table table-striped" *ngIf="!loading">
                <thead>
                    <tr>
                        <th scope="col" class="text-center headerfix d-lg-table-cell">SI No</th>
                        <th class="headerfix d-lg-table-cell">Updated Time</th>
                        <th class="headerfix d-lg-table-cell">Startup Name</th>
                        <th>Schemes</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Domain</th>
                        <!-- <th>Stages</th> -->
                        <th>Status</th>
                        <th *ngIf="activeFilter !== 'PENDING'" class="headerfix d-lg-table-cell">{{ activeFilter || 'Approved/Rejected' }} By</th>
                        <th class="headerfix d-lg-table-cell">Last Logged In</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody *ngIf="data?.length > 0; else noRecords">
                    <tr *ngFor="let d of data let i = index">
                        <th scope="row" class="text-center">{{getSerialNo(i+1)}}</th>
                        <td>{{d.updated_at | date}}</td>
                        <td>{{d.name | titlecase}}</td>
                        <td>{{d.scheme_names}}
                            {{d.entitytype}}
                        </td>
                        <td>{{d.state | titlecase}}</td> 
                        <td>{{d.city | titlecase}}</td>
                        <td class="textstructure">{{d.domain}}</td>
                        <!-- <td>{{d.startup_stage | titlecase}}</td> -->
                        <td>{{d.status}}</td>
                        <td *ngIf="activeFilter !== 'PENDING'">{{d.approved_by}}</td>
                        <td class="text-center">{{ d.login_info != null ? (d.login_info.last_login_time | date) : '-' }}</td>
                        <td class="d-flex justify-content-center">
                            <div class="d-flex">
                                <div *ngIf="d.status === 'PENDING'" class="d-flex">
                                    <a class="dropbtn fa fa-check mr-2" style="color:blue"
                                        (click)="changestatus(d.id, 'APPROVED')"></a>
                                    <a class="dropbtn fa fa-close mr-2 " style="color:red"
                                        (click)="changestatus(d.id, 'REJECTED')"></a>
                                </div>
                                <div *ngIf="d.status === 'APPROVED'">
                                    <a class="dropbtn fa fa-close mr-2 ml-4" style="color:red"
                                        (click)="changestatus(d.id, 'REJECTED')"></a>
                                </div>
                                <div *ngIf="d.status === 'REJECTED'">
                                    <a class="dropbtn fa fa-check mr-4" style="color:blue"
                                        (click)="changestatus(d.id, 'APPROVED')"></a>
                                </div>
                                <div>
                                    <a class="fa fa-eye arrow" (click)="preview(d.id)" style="color:green"></a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ng-template #noRecords>
                    <tbody>
                        <tr> <td colspan="12" class="no-records-found">No Data found.</td> </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>

        <div class="w-100 mt-3">
            <!-- <ul class="d-flex list-unstyled float-right">
                <li class="mr-2 p-3"><a (click)="getPrevSet()"><<</a></li>
                <li class="mr-2 p-3" [ngClass]="{'bg-primary text-white':selectedindex==i-1}" *ngFor = "let i of initial_pages;let i1 = index"><a class="paginationlink text-decoration-underline" (click)="paginate_startup(i)"> {{i}} </a></li>
                <li class="mr-2 p-3"><a (click)="getNextSet()">>></a></li>
            </ul> -->
            <app-pagination 
                [totalpages]="totalpages" 
                [pageno]="pageno"
                [selectedindex]="selectedindex"
                (pageChanged)="paginate_startup($event)">
            </app-pagination>
        </div>
    </div>
</div>
</div>
