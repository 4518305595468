<!-- <section class="challengeform">
    <div class="my-2" style="text-align: end">
        <button type="button" class="btn btn-primary" (click)="handleRoute()" >Go back</button>
    </div>
    <div class="container bg-white p-3 customcontainer">
      <div class="row">
        <div class="col-12">
            <table class="table">
                <thead>
                    <th>
                        Field
                    </th>
                    <th>
                        Value
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor = "let key of Object.keys(mentordata)">
                        <td>
                            <ng-container *ngIf = "key !== 'undefined'">
                                {{key}}
                            </ng-container>

                            <ng-container *ngIf = "key === 'undefined'">
                                Please choose your preferred mentoring format
                            </ng-container>
                            </td>
       
                        <td>
                            <ng-container *ngIf="key !== 'profile_img' && key !== 'linked_in' && key !== 'company_website'">
                              <ng-container *ngIf="!isEmptyValue(mentordata[key]); else emptyValue">
                                {{mentordata[key]}}
                              </ng-container>
                              <ng-template #emptyValue>--</ng-template>
                            </ng-container>
                          
                            <ng-container *ngIf="key === 'profile_img'">
                              <ng-container *ngIf="mentordata[key] && mentordata[key][0]?.url; else emptyValue">
                                <img [src]="mentordata[key][0].url" class="h-auto" alt="" width="100">
                              </ng-container>
                            </ng-container>
                          
                            <ng-container *ngIf="key === 'linked_in'">
                              <ng-container *ngIf="!isEmptyValue(mentordata[key]); else emptyValue">
                                <a [href]="mentorlink(mentordata[key])" target="_blank">Click Here</a>
                              </ng-container>
                            </ng-container>
                          
                            <ng-container *ngIf="key === 'company_website'">
                              <ng-container *ngIf="mentordata[key] && formatUrl(mentordata[key]); else emptyValue">
                                <a [href]="formatUrl(mentordata[key])" target="_blank">Click Here</a>
                              </ng-container>
                              <ng-container *ngIf="mentordata[key] && !formatUrl(mentordata[key])">
                                --
                              </ng-container>
                            </ng-container>
                          
                            <ng-template #emptyValue>--</ng-template>
                          </td>
                          
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
    </div>
  </section> -->

  <div class="container">
    <div class="row mb-4 ">
      <div class="col-md-4 newdownload d-flex">
        <button class="btn btn-primary" style="color: white" (click)="handleRoute()">Go to Mentor</button>
        <button class="btn btn-primary ml-3" (click)="downloadPdf()" style="color: white;">
          <i class="fa fa-download"></i> Click to Download</button>
      </div>
    </div>
  </div>


  <div class="container container-align " id="pdfSample">
    <div class="row mb-4">
      <div class="row w-100 d-flex justify-content-between align-items-center py-5 mt-2">
        <div class="col-md-12 col-lg-3 col-4 img-padding">
          <img
          *ngIf="profile_image; else showStaticImage"
          [src]="profile_image"
          class="img-fluid img-rounded-align"
          alt="Profile Image"
          style="filter: drop-shadow(0 2px 4px black);"
        />
        
        <!-- Fallback static image -->
        <ng-template #showStaticImage>
          <img
            src="/assets/images/profilebannerimg.jpeg"
            class="img-fluid img-rounded-align"
            alt="Default Profile Image"
            style="filter: drop-shadow(0 2px 4px black);"
          />
        </ng-template>
        </div>
        <div class="col-md-12 col-lg-9  p-4 newbg-data view-border">
          <div class="col-12 d-flex flex-wrap">
          <div class="col-4">
            <p class="mb-1">
              <span class="fw-bold text-dark">Mentor Name : </span>  
              <span style="font-weight: normal;">{{ mentordata?.first_name }} {{ mentordata?.last_name }}</span>
            </p>
          </div>
          <div class="col-4">
            <p class="mb-1">
              <span class="fw-bold text-dark">Gender : </span>
              <span style="font-weight: normal;">
                {{ mentordata?.gender ? mentordata.gender : '-' }}
              </span>
            </p>
        </div>
          <div class="col-4">
            <p class="mb-1">
              <span class="fw-bold text-dark">Contact No : </span>
              <span style="font-weight: normal; ">{{ mentordata?.contact_no }}</span>
            </p>
          </div>
        
          <!-- Gender -->
          <!-- < -->
          <div class="col-4">
            <p class="mb-1">
              <span class="fw-bold text-dark">Email : </span>
              <span style="font-weight: normal; word-break: break-all;">{{ mentordata?.email }}</span>
            </p>
          </div>
          <div class="col-4">
            <p class="mb-1">
              <span class="fw-bold text-dark">Organization Name : </span>
              <span style="font-weight: normal; word-break: break-word;">
                {{ mentordata?.company_name ? mentordata.company_name : '-' }}
              </span>
            </p>
          </div>
          <div class="col-4">
            <p class="mb-1">
              <span class="fw-bold text-dark">Website : </span>
              <!-- <span style="font-weight: normal;">{{ mentordata?.gender }}</span> -->
              <a [href]="mentordata?.company_website" target="_blank" style="text-decoration: none; color: blue;">Click Here </a>
            </p>
          </div>
          <!-- Address (City, State, Country) -->
          <div class="col-4">
            <p class="mb-1">
              <span class="fw-bold text-dark">Address : </span>
              <span style="font-weight: normal;">
                <ng-container *ngIf="mentordata?.city || mentordata?.state || mentordata?.country; else noAddress">
                  {{ mentordata?.city ? mentordata.city + ', ' : '' }} 
                  {{ mentordata?.state ? mentordata.state + ', ' : '' }} 
                  {{ mentordata?.country }}
                </ng-container>
                <ng-template #noAddress>-</ng-template>
              </span>
            </p>
            </div>
          </div>
          </div>
      </div>
    </div>
    <!-- basic completed -->
    <!-- <div class="row m-3 pb-3">
      <div class="col-12 d-flex flex-wrap  justify-content-center  newbg-data p-3 view-border ">
        <!-- <div class="col-12 row-border "> -->
          <!-- <div class="m-2"> -->
          <!-- <p class="my-2 col-4">
            <span class="fw-bold text-dark">  Created Date : </span>
            <span style="font-weight: normal;">
            {{mentordata?.created_at|date}}
            </span>
          </p> -->
<!--          
          <p class="my-2 col-4">
            <span class="fw-bold text-dark"> Designation : </span>
            <span style="font-weight: normal;">
            {{mentordata?.designation}}
            </span>
          </p> -->
         
          <!-- <p class="my-2 col-4">
            <span class="fw-bold text-dark">awards : </span>
            <!-- <span style="font-weight: normal;">
            {{mentordata?.awards}}
            </span> -->
            <!-- <span *ngFor="let award of mentordata?.awards; let last = last" style="font-weight: normal;">
              {{ award }}<span *ngIf="!last">, </span>
            </span>
          </p> --> 
          <!-- </div> -->
        <!-- </div> -->
        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark">Languages:</span>
          <span style="font-weight: normal;">
            {{ mentordata?.languages?.length > 0 ? mentordata?.languages.join(', ') : 'Not Specified' }}
          </span>
        </p> -->
        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Profile Score : </span> -->
          <!-- <span *ngFor="let languages of mentordata?.languages; let last = last" style="font-weight: normal;">
            {{ languages }}<span *ngIf="!last">, </span>
          </span> -->
          <!-- <span style="font-weight: normal;">
            {{mentordata?.score}}
            </span>
        </p> -->
        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Domain : </span>
          <span *ngFor="let domain of mentordata?.domain; let last = last" style="font-weight: normal;">
            {{ domain }}<span *ngIf="!last">, </span>
          </span>
        </p> -->
        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Sector : </span>
          <span *ngFor="let sector of mentordata?.sector; let last = last" style="font-weight: normal;">
            {{ sector }}<span *ngIf="!last">, </span>
          </span>
        </p> -->
        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Mentor Experience : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['Mentorship Experience']}}
          </span>
        </p> -->
        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Mentor Commitment Level : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['Mentorship commitment level']}}
          </span>
        </p> -->

        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Mentor's Best Description  : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['What describes you the best?']}}
          </span>
        </p> -->

        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark"> International Exposure : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['Do you have any international exposure?']}}
          </span>
        </p> -->

        <!-- <p class="my-2 col-4">
          <span class="fw-bold text-dark"> stage of start-up you are willing to mentor : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['Please specify the startup stages you can mentor.']}}
          </span>
        </p>

        <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Brief : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['Please Provide your brief Bio in less than 200 words.']}}
          </span>
        </p>

        <p class="my-2 col-4">
          <span class="fw-bold text-dark"> No. of start-ups mentored  : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['Please specify the number of startups you have mentored to date?']}}
          </span>
        </p>

        <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Associated with any startup/Incubator Agency : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['Associated with any Incubator/Startup Agency']}}
          </span>
        </p>

        <p class="my-2 col-4">
          <span class="fw-bold text-dark">Specialized Sector : </span>
          <span style="font-weight: normal;">
            <!-- {{mentordata?.['Any specialized functional area of mentoring? (Sector Specific)']}} -->
            <!-- {{ mentordata?.["Any specialized functional area of mentoring? (Sector Specific) "] }}
          </span>
        </p>
        <p class="my-2 col-4">
          <span class="fw-bold text-dark"> Approval for usage of promotion by official msh platform : </span>
          <span style="font-weight: normal;">
            {{mentordata?.['Do you consent your profile visibility and promotion by MSH to its Portal?']}}
          </span>
        </p>

        <p class="my-2 col-4 ">
          <span class="fw-bold text-dark">LinkedIn Profile: </span>
          <a *ngIf="mentordata?.linked_in" [href]="mentordata?.linked_in" target="_blank" style="font-weight: normal; text-decoration: none; color: blue;">
            {{ mentordata?.linked_in }}
          </a>
        </p>
      </div> --> 
        <!-- </div>  -->
        <div class="container">
          <div class="row m-3 pb-3">
            <div class="col-12 d-flex flex-wrap justify-content-center newbg-data p-3 view-border">
        
              <!-- Row 1 -->
              <div class="row w-100 border-bottom">
                <p *ngIf="mentordata?.created_at" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Created Date : </span>
                  <span style="font-weight: normal;"> {{ mentordata?.created_at | date }}</span>
                </p>
                <p class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Designation : </span>
                  <span style="font-weight: normal;">
                    {{ mentordata?.designation ? mentordata.designation : '-' }}
                  </span>
                </p>
                <!-- <p *ngIf="mentordata?.awards?.length && mentordata?.awards?.length" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Awards:</span>
                  <span *ngFor="let award of mentordata?.awards; let last = last" style="font-weight: normal;">{{ award }}<span *ngIf="!last">, </span></span>
                </p> -->
                <p class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Awards : </span>
                  <span *ngIf="filteredAwards.length; else noAwards">
                    <span *ngFor="let award of filteredAwards; let last = last" style="font-weight: normal;">
                      {{ award }}<span *ngIf="!last">, </span>
                    </span>
                  </span>
                  <ng-template #noAwards>-</ng-template>
                </p>
              </div>
        
              <!-- Row 2 -->
              <div class="row w-100 border-bottom">
                <!-- <p *ngIf="mentordata?.languages?.some(lang => lang.trim() !== '')" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Languages:</span>
                  <span style="font-weight: normal;">{{ mentordata?.languages.join(', ') }}</span>
                </p> -->
                <p class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Languages: </span>
                  <span style="font-weight: normal;">
                    {{ cleanedLanguages?.length ? cleanedLanguages.join(', ') : '-' }}
                  </span>
                </p>
                <p *ngIf="mentordata?.score" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Score : </span>
                  <span style="font-weight: normal;">{{ mentordata?.score }}</span>
                </p>
                <!-- <p *ngIf="mentordata?.domain?.length" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">  </span>
                  <span *ngFor="let domain of mentordata?.domain; let last = last" style="font-weight: normal;">{{ domain }}<span *ngIf="!last">, </span></span>
                </p> -->
                <p *ngIf="mentordata?.domain?.length" class="my-2 col-md-4">
                  <span class="fw-bold text-dark"> Domain : </span>
                
                  <!-- Show first few sectors -->
                  <span *ngFor="let domain of mentordata?.domain.slice(0, 5); let last = last" style="font-weight: normal;">
                    {{ domain }} <span *ngIf="!last">, </span>
                  </span>
                
                  <!-- Show "Read More" only if there are more than 3 sectors -->
                  <span *ngIf="mentordata?.domain.length > 5"
                        data-toggle="modal" data-target="#domainModal"
                        (click)="previewdomain(mentordata)" 
                        class="text-primary readMore" style="cursor: pointer;">
                    Read More
                  </span>
                </p>
              </div>
        
              <!-- Row 3 -->
              <div class="row w-100 border-bottom">
                <!-- <p *ngIf="mentordata?.sector?.length" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Sector:</span>
                  <span *ngFor="let sector of mentordata?.sector; let last = last" style="font-weight: normal;">{{ sector }}
                    <span *ngIf="!last">, </span></span>
                </p> -->
                <p *ngIf="mentordata?.sector?.length" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Sector : </span>
                
                  <!-- Show first few sectors -->
                  <span *ngFor="let sector of mentordata?.sector.slice(0, 5); let last = last" style="font-weight: normal;">
                    {{ sector }} <span *ngIf="!last">, </span>
                  </span>
                
                  <!-- Show "Read More" only if there are more than 3 sectors -->
                  <span *ngIf="mentordata?.sector.length > 6"
                        data-toggle="modal" data-target="#sectorModal"
                        (click)="previewsector(mentordata)" 
                        class="text-primary readMore" style="cursor: pointer;">
                    Read More
                  </span>
                </p>
                
                <!-- Modal for displaying full sector list -->
            
                
                
                <p *ngIf="mentordata?.['Mentorship Experience']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Mentor Experience : </span>
                  <span style="font-weight: normal;">{{ mentordata?.['Mentorship Experience'] }}</span>
                </p>
                <p *ngIf="mentordata?.['Mentorship commitment level']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Mentor Commitment Level : </span>
                  <span style="font-weight: normal;"> {{ mentordata?.['Mentorship commitment level'] }}</span>
                </p>
              </div>
        
              <!-- Row 4 -->
              <div class="row w-100 border-bottom">
                <p *ngIf="mentordata?.['What describes you the best?']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Mentor's Best Description : </span>
                  <span style="font-weight: normal;">{{ mentordata?.['What describes you the best?'] }}</span>
                </p>
                <p *ngIf="mentordata?.['Do you have any international exposure?']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">International Exposure : </span>
                  <span style="font-weight: normal;">{{ mentordata?.['Do you have any international exposure?'] }}</span>
                </p>
                <p *ngIf="mentordata?.['Please specify the startup stages you can mentor.']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Startup Stages Willing to Mentor : </span>
                  <span style="font-weight: normal;">{{ mentordata?.['Please specify the startup stages you can mentor.'] }}</span>
                </p>
              </div>
        
              <!-- Row 5 -->
               <div class="row w-100 border-bottom"> 
                 <!-- <p *ngIf="mentordata?.['Please Provide your brief Bio in less than 200 words.']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Brief:</span>
                  <span style="font-weight: normal;">{{ mentordata?.['Please Provide your brief Bio in less than 200 words.'] }}</span>
                </p>  -->
                <p *ngIf="mentordata?.['Please Provide your brief Bio in less than 200 words.']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Brief : </span>
                  <span style="font-weight: normal;">
                    {{ truncateText(mentordata?.['Please Provide your brief Bio in less than 200 words.'], 40) }}
                  </span>
                
                  <span *ngIf="readMore"
                        data-toggle="modal" data-target="#briefModal"
                        (click)="preview(mentordata)" 
                        class="text-primary readMore" style="cursor: pointer;">
                    Read More
                  </span>
                </p>
                 
                 <p *ngIf="mentordata?.['Please specify the number of startups you have mentored to date?']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">No. of Startups Mentored : </span>
                  <span style="font-weight: normal;">{{ mentordata?.['Please specify the number of startups you have mentored to date?'] }}</span>
                </p>
                <p *ngIf="mentordata?.['Associated with any Incubator/Startup Agency']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Associated with Incubator/Startup Agency : </span>
                  <span style="font-weight: normal;">{{ mentordata?.['Associated with any Incubator/Startup Agency'] }}</span>
                </p>
              </div>
         
              <!-- Row 6 -->
              <div class="row w-100 border-bottom">
                <p *ngIf="mentordata?.['Any specialized functional area of mentoring? (Sector Specific) ']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Specialized Sector : </span>
                  <span style="font-weight: normal;">
                    {{  truncateText(mentordata?.['Any specialized functional area of mentoring? (Sector Specific) '], 40) }}
                  </span>
                  <span *ngIf="readMore"
                  data-toggle="modal" data-target="#SpecializedsectorModal"
                  (click)="previewspecializedsector(mentordata)" 
                  class="text-primary readMore" style="cursor: pointer;">
              Read More
            </span>
                </p>
             
                <p *ngIf="mentordata?.['Do you consent your profile visibility and promotion by MSH to its Portal?']" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">Approval for MSH Promotion : </span>
                  <span style="font-weight: normal;">{{ mentordata?.['Do you consent your profile visibility and promotion by MSH to its Portal?'] }}</span>
                </p>
                <p *ngIf="mentordata?.linked_in" class="my-2 col-md-4">
                  <span class="fw-bold text-dark">LinkedIn Profile : </span>
                  <a [href]="mentordata?.linked_in" target="_blank" style="text-decoration: none; color: blue;">Click Here </a>
                </p>
              </div>
             
            </div>
          </div>
          <!-- docs and links -->
          <div class="m-0 p-0">
          <p class="  content_heading m-0"> Knowledge Base </p>
        </div>
          <div class="my-2 d-flex ">
           
           
         
            <button class="btn btn-primary mx-2 fw-normal" (click)="showDocs()">Show Documents</button>
            <button class="btn btn-secondary fw-normal " (click)="showLinks()">Show Links</button>
          </div>
          <div class="row d-flex justify-content-between" *ngIf="showDocuments">
          <div class="col-md-6" *ngIf="mentordata?.knowledge_base_docs?.length > 0">
            <div class="p-1 mt-1 ml-3 mr-5">
              <p class="content_heading my-2">  Knowledge Base Documents</p>
              <div class="content_bg">
                <table class="table table-bordered center_content view-border">
                <thead>
                  <tr>
                    <th><p class="increased_width">Document Name</p></th>
                    <th><p class="increased_width">Document link</p></th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngFor="let obj of mentordata?.knowledge_base_docs">
                    <td>
                      <span class="mx-2 dark">{{ obj.title }}</span>
                    </td>
                    <td>
                      <a href="{{ obj.url ? obj.url : obj.link  }}" target="_blank" style="font-size: 18px; color: blue">Click here</a>
                    </td>
                  </tr>
                </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          <!-- base links -->
          <div class="row" *ngIf="showLinksFlag">
          <div class="col-md-6" *ngIf="mentordata?.knowledge_base_links?.length > 0">
            <div class="p-1 mt-1 ml-3 mr-5">
              <strong class="content_heading py-2"> Knowledge Base Links</strong>
              <div class="content_bg">
                <table class="table table-bordered center_content view-border">
                <thead>
                  <tr>
                    <th><p class="increased_width">Link Name</p></th>
                    <th><p class="increased_width"> link</p></th>
                  </tr>
                </thead>
                <tbody >
                  <tr *ngFor="let obj of mentordata?.knowledge_base_links">
                    <td>
                      <span class="mx-2">{{ obj.title }}</span>
                    </td>
                    <td style="font-size: 18px; color: blue">
                      <a href="{{ obj.url ? obj.url : obj.link  }}" target="_blank">Click here</a>
                    </td>
                  </tr>
                </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>
      <!-- </div> -->
        <!-- details completed -->
        <!-- <div class="row">
          <div class="col-md-6" *ngIf="facebook_link || twitter_link || linkedin_link || instagram_link || koo_link || youtube_link">
            <div class="p-1 mt-1 ml-5 mr-3">
              <strong class="content_heading">Social Account</strong>
              <div class="content_bg mt-3 table-wrapper-horizontal">
                <div class="table-wrapper">
                 <table class="table table-bordered center_content">
                <thead>
                  <tr>
                    <th><p class="increased_width">Profile Name</p></th>
                    <th><p class="increased_width">Social link</p></th>
                  </tr>
                </thead>
                <tbody style="font-size: 14px">
                  <tr>
                    <td class="w-50">
                      <button
                        class="btn btn-primary btn-lg m-2 fb_color networkButtons"
                      >
                        <i class="fab fa-facebook fa-lg"></i> Facebook
                      </button>
                    </td>
                    <td class="center_content">
                      <ng-container *ngIf="facebook_link">
                        <a class="link_color" (click)="view_website(facebook_link)">Click here</a>
                      </ng-container>
                      <ng-container *ngIf="!facebook_link">
                        -
                      </ng-container>
                    </td>
                    
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="btn btn-info btn-lg m-2 twitter_color networkButtons"
                      >
                      <img src="assets/images/twitter.png" alt="Twitter" style="width: 30px; height: auto;">
                      <!-- <i class="fab fa-twitter fa-lg"></i> -->
                         <!-- Twitter
                      </button>
                    </td>
                    <td class="center_content">
                      <ng-container *ngIf="twitter_link">
                        <a class="link_color" (click)="view_website(twitter_link)">Click here</a>
                      </ng-container>
                      <ng-container *ngIf="!twitter_link">
                        -
                      </ng-container>
                      
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="btn btn-secondary btn-lg m-2 linkedIn_color networkButtons"
                      >
                        <i class="fab fa-linkedin fa-lg"></i> LinkedIn
                      </button>
                    </td>
                    <td class="center_content">
                      
                      <ng-container *ngIf="linkedin_link">
                        <a class="link_color" (click)="view_website(linkedin_link)">Click here</a>
                      </ng-container>
                      <ng-container *ngIf="!linkedin_link">
                        -
                      </ng-container>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="btn btn-danger btn-lg m-2 instagram_color networkButtons"
                      >
                        <i class="fab fa-instagram fa-lg"></i> Instagram
                      </button>
                    </td>
                    <td class="center_content">
                     
                      <ng-container *ngIf="instagram_link">
                        <a class="link_color" (click)="view_website(instagram_link)">Click here</a>
                      </ng-container>
                      <ng-container *ngIf="!instagram_link">
                        -
                      </ng-container>
                    </td>
                  </tr>
      
               
                  <tr>
                    <td>
                      <button
                        class="btn btn-youtube btn-lg m-2 youtube_color text-white networkButtons"
                      >
                        <i class="fab fa-youtube fa-lg"></i> Youtube
                      </button>
                    </td>
                    <td class="center_content">
                      <ng-container *ngIf="youtube_link">
                        <a class="link_color" (click)="view_website(youtube_link)">Click here</a>
                      </ng-container>
                      <ng-container *ngIf="!youtube_link">
                        -
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
                 </table>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-md-6" *ngIf="documentList?.length > 0">
            <div class="p-1 mt-1 ml-3 mr-5">
              <strong class="content_heading">Documents</strong>
              <div class="content_bg mt-3 table-wrapper-horizontal">
                <div class="table-wrapper">
                <table class="table table-bordered center_content">
                <thead>
                  <tr>
                    <th><p class="increased_width">Document Name</p></th>
                    <th><p class="increased_width">Document link</p></th>
                  </tr>
                </thead>
                <tbody style="font-size: 14px; color: blue">
                  <tr *ngFor="let obj of documentList">
                    <td>
                      <span class="mx-2">{{ obj.file_name }}</span>
                    </td>
                    <td>
                      <a href="{{ obj.link }}" target="_blank">Click here</a>
                    </td>
                  </tr>
                </tbody>
                </table>
              </div>
              </div>
            </div>
          </div> -->
        <!-- </div>  -->
        <!-- <div class="row w-100 border-bottom "> -->
          <!-- <p *ngIf="mentordata?.['Please Provide your brief Bio in less than 200 words.']" class="my-2 col-md-4">
            <span class="fw-bold text-dark">Brief:</span>
            <span style="font-weight: normal;">{{ mentordata?.['Please Provide your brief Bio in less than 200 words.'] }}</span>
          </p> -->
          <!-- <div class="col-12 "> -->
            <!-- <div class=" p-4 content_heading" *ngIf="mentordata?.['Please Provide your brief Bio in less than 200 words.']"> -->
              <!-- <strong>Brief Information</strong> -->
           
            <!-- <div class="mt-1 ml-5 mr-5" *ngIf="mentordata?.['Please Provide your brief Bio in less than 200 words.']"> -->
              <!-- <p style="font-style: normal;">{{ mentordata?.['Please Provide your brief Bio in less than 200 words.'] }}</p> -->
            <!-- </div> -->
          <!-- </div> -->
          <!-- <p *ngIf="" class="my-2 col-md-4">
            <span class="fw-bold text-dark">Brief Information : </span>
            <span style="font-weight: normal;">
              <!-- {{ truncateText(mentordata?.['Please Provide your brief Bio in less than 200 words.'], 40) }} -->
          <!-- {{mentordata?.['Please Provide your brief Bio in less than 200 words.']}} -->
            <!-- </span> -->
            <!-- </p>  -->
            <!-- </div> -->
            <!-- </div> -->
 
 

  <!-- modal -->
  <div class="modal fade" id="briefModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
         
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
        <div class="modal-body">
          <h5 class="modal-title"  style="font-weight: bold;">{{ modaldata.title }}</h5>
        <p  style="font-weight: normal;">  {{ modaldata.description }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- modal for sector -->
  <div class="modal fade" id="SpecializedsectorModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
         
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
        <div class="modal-body">
          <h5 class="modal-title"  style="font-weight: bold;">{{ modaldata.title }}</h5>
         <p  style="font-weight: normal;"> {{ modaldata.description }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- sector -->
  <div class="modal fade" id="sectorModal" tabindex="-1" role="dialog" aria-labelledby="sectorModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sectorModalLabel">Sectors</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5 class="modal-title" style="font-weight: bold;">{{ modaldata.title }}</h5>
          <p style="font-weight: normal;"> {{ modaldata.description }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- domain -->
  <div class="modal fade" id="domainModal" tabindex="-1" role="dialog" aria-labelledby="sectorModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sectorModalLabel">Domain</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5 class="modal-title" style="font-weight: bold;">{{ modaldata.title }}</h5>
          <p style="font-weight: normal;"> {{ modaldata.description }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>