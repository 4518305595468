import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { LabService } from "../../../services/lab.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { states } from "../../../../assets/js/states";
import { ChallengeService } from "../../../services/challenge.service";
import * as _ from "lodash";
import { ShowcaseDialogComponent } from "../../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { DialogNamePromptComponent } from "../../../@core/miscellaneous/dialog/dialog-name-prompt/dialog-name-prompt.component";
import { ExcelService } from "../../../services/excel.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import * as XLSX from 'xlsx';
import { CityService } from "../../../services/statecity/city.service";
@Component({
  selector: "ngx-labs",
  templateUrl: "./labs.component.html",
  styleUrls: ["./labs.component.scss"],
})
export class LabsComponent implements OnInit {
  filterArray = [
    {
      "name": "usertype",
      "value": []
    },
    {
      "name": "userids",
      "value": []
    },
    {
      "name": "created_at",
      "value": [
          {
              "start_date": "",
              "end_date": ""
          }
      ]
  },
    {
        "name": "status",
        "value": []
    }
]
  @Input() newid: any;
  @Input() entity_type: any;
  id = "section";
  // tab?: any;
  numericPattern = "^[0-9]*$";
  // url ='src/assets/images/view_profive_nav_icon.png';
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
  };
  urls = [];
  data: any;
  inValidMessage!: boolean;
  dataTable: any;
  list: any = [];
  byId: any;
  dataByID: any;
  msg = "";

  selected_cities:any = [];
  labFacilityForm: FormGroup;
  formSubmitted: boolean=false;
  labListing: boolean = false;
  fileInputLabel: string;
  imageUrl: string | ArrayBuffer | null = null;
  public arrstates = states;
  filesizeerr = false;
  files: any = [];
  countryid=101;
  createMode: boolean = true;
  editMode: boolean;
  isDownloading:boolean;
  lab_id: any;
  currentData: any;
  images: any = [];
  selectedindex:number = 0;

  modaldata = {
    name: "",
    info: "",
    websiteurl: "",
    benefits: "",
    eligibility: "",
    img1: "",
    img2: "",
    img3: "",
    img4: "",
    incharge_details: {
      name: "",
      phone: "",
      designation: "",
      email: "",
    },
    address_line1: "",
    city: "",
    state: "",
    zip_code: "",
  };
  uploadMode: boolean = false;
  indexes = [];
  myArray: any[] = [];
  apidata: any;
  paginationEnabled: boolean = true;
  p: number = 1;
  pageSize : number = 25;
  selectedUserType: any;
  selectedUser: any[];
  selectedStatus: any = [];
  totalItems: number;
  dropdownSettings_s: IDropdownSettings = {};
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingst: IDropdownSettings = {};
  dropdownSettingsS: IDropdownSettings = {};
  filterValue: any;
  originalData: any[];
  dropdownuserType: any[] = ["INCUBATOR", "COE", "ACCELERATOR","SUPERADMIN"];
  dropdownuser: any[] = [];
  dropdownstatus: any[] = ["APPROVED", "PENDING", "DECLINED"]
  filterMode: boolean = true;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  labFilterForm: FormGroup;
  previewimageurl: any = [0,0,0,0];
  filterEnable: boolean = false; 
  // previewimageurl: any[];
  activeFilter: string[] = [];
  filterValue1 = null;
  viewback: any;
  downloadButton: boolean = false;
  resetMode: boolean;
  totalApproved: any;
  totalRejected: any;
  totalPending: any;
  userName: any;
  userType: any;
  
  page_index:number = 0;
  pageno:any = 0;
  totalpages: number;
  dropdownstates: any[] = []; // To store state names
  dropdownCity: any[] = []; 

  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private router: Router,
    private labService: LabService,
    private toastrService: NbToastrService,
    private challengeService: ChallengeService,
    private dialogService: NbDialogService,
    private excelService:ExcelService,
    private _route:ActivatedRoute,
    private cityservice:CityService,
    
    
  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);
    // this.activeFilter = this.filterValue
    this.activeFilter = ['APPROVED','PENDING','DECLINED'];
console.log('active', this.activeFilter)
  }

  ngOnInit(): void {
    const urlRegex =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.labFacilityForm = this.fb.group({
      labTitle: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      labInformation: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      labWebsiteUrl: ["", [Validators.required, Validators.pattern(urlRegex)]],
      // labCenters: ["", Validators.required],
      labBenefits: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      labEligibility: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      addressLine1: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      cityName: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      stateName: ["", Validators.required,],
      zipCode: ["", [Validators.required,Validators.pattern(/^\d{6}$/)]],
      inchargeName: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      contactNumber: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      designation: ["", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      emailAddress: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],

      uploadedImage: [""],
      _img1: ["", [Validators.required]],
      _img2: [""],
      _img3: [""],
      _img4: [""],
    });

    this.labFilterForm = this.fb.group({
      selectedUserType: ['ALL'],
      start_date: [''], 
      end_date: [''],  

      
    });

    
    if(this.filterValue != null) {
      this.filtersItem(this.filterValue);
    }
    else{       
      this.getAllData();
    }
    this.loadStates();
    // this.selectedUserType = {};
    this.selectedUser = [];
    this.selectedStatus = []   
   
    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettingst={
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    }

    this.dropdownSettingsS = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',     
      allowSearchFilter: true
    };



    
    // if(this.filterValue != null){
    //   this.filterItem(this.filterValue);
    // }
    // else{
    //   this.getAllData()
    // }
    // else{
    //   this.getAllData()
    // }
    
    // if (this.id && this.entity_type) {

 
  }
  async loadStates() {
    try {
      const response = await this.cityservice.getDefaultState(this.countryid); // API call
      console.log('Fetched Response:', response);
  
      // Ensure we extract `result` array
      const states = response.result || []; 
  
      
      this.dropdownstates = states.map((state: { name: any; id: any; }) => ({ 
        label: state.name,  // Assuming API returns `name` field
        value: state.id      // Assuming API returns `id` field
      }));
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  }
  async onStateSelect(event: any) {
    console.log('Selected State:', event);
    
    const selectedStateId = event.value; // State ID
  
    try {
      const response = await this.cityservice.getCitiesByState(selectedStateId);
      console.log('Fetched Cities Response:', response);
  
      // Extract `result` array safely
      const cities = response.result || []; 
  
      this.selected_cities = cities.map((city: { name: any; }) => ({ city: city.name })) // Assuming `name` field
        .sort((a: { city: string; }, b: { city: any; }) => a.city.localeCompare(b.city));
  
      // Reset selected city
      this.labFacilityForm.get('cityName')?.patchValue(null);
    } catch (error) {
      console.error(`Error fetching cities for state ID ${selectedStateId}:`, error);
    }
  }
  submitLabData() {
    this.formSubmitted = true;
    this.uploadMode = false;
    console.log(this.labFacilityForm);
    if (this.labFacilityForm.valid) {
      console.log(this.labFacilityForm.value);
      const formData = new FormData();
      this.labFacilityForm.get("uploadedImage").setValue(this.files);
      console.log(
        "onfile select",
        this.labFacilityForm.get("uploadedImage").value
      );
      for (let i = 0; i < this.files.length; i++) {
        const file: File = this.files[i];
        formData.append("file", file);
      }
      console.log("formDATA", formData);
      this.challengeService
        .uploadManyFiles(formData, "lab-docs")
        .subscribe((response: any) => {
          console.log("image response", response.results);
          this.labFacilityForm.get("uploadedImage").setValue(response.results);
          this.fileInputLabel = undefined;
          let selectedState = this.dropdownstates.find(state => state.value === this.labFacilityForm.controls.stateName.value);

          let data = {
            name: this.labFacilityForm.controls.labTitle.value,
            information: this.labFacilityForm.controls.labInformation.value,
            address_line1: this.labFacilityForm.controls.addressLine1.value,
            city: this.labFacilityForm.controls.cityName.value,
            state: selectedState ? selectedState.label : null,
            zip_code: this.labFacilityForm.controls.zipCode.value,
            incharge_details: {
              name: this.labFacilityForm.controls.inchargeName.value,
              phone: this.labFacilityForm.controls.contactNumber.value,
              email: this.labFacilityForm.controls.emailAddress.value,
              designation: this.labFacilityForm.controls.designation.value,
            },
            website_url: this.labFacilityForm.controls.labWebsiteUrl.value,
            // centers: this.labFacilityForm.controls.labCenters.value,
            benefits: this.labFacilityForm.controls.labBenefits.value,
            eligibility: this.labFacilityForm.controls.labEligibility.value,
            documents: this.labFacilityForm.controls.uploadedImage.value,
          };
          this.labService.createLabData(data).subscribe(
            (res: any) => {
              this.dataTable = res.results;
              console.log("Lab dataTable::", this.dataTable);
              this.labFacilityForm.reset();
              this.toastrService.success("Data Added Successfully!");
              this.getAllData();
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Add records!");
            }
          );
          this.files = [];
          this.images = [];
        });
    } else {
      this.labFacilityForm.markAllAsTouched();
    }
  }

  getAllData() {
    let data = {    
      search_entity: "LAB",
      "page": 0, "page_size": 10
      };
    this.labService.getAllLabData(data).subscribe((res: any) => {
      this.list = res.results;
      this.data = res.results;
      this.downloadButton = true;
      console.log("list", this.list);
      this.totalItems = +res.total_search_results_count;
      var totalPagesss = Math.ceil(this.totalItems/10);
      this.totalpages = totalPagesss;
      // this.initial_pages = this.pagelinks.slice(this.page_index, this.page_index + 10);

      // this.activeFilter = null;
      console.log("334___________", this.totalItems);
      this.originalData = this.list;
      // this.filtersItem(res);
         // Store overall counts once so they are not reset when filtering
    // this.totalApproved = res.approved_count;
    // this.totalRejected = res.declined_count;
    // this.totalPending = res.pending_count;
      this.approvedCount = res.approved_count;
      this.rejectedCount = res.declined_count;
      this.pendingCount = res.pending_count;
  
  
      console.log('fsdsds',this.newid,this.entity_type)
    if(this.newid && this.entity_type){
      this.applyInitialFilters() 
  }
    });
  }

  editData(data: any, el: any) {
    try {
      console.log("data__edit", data);
      el.scrollIntoView({ behaviour: "smooth" });
      console.log("edit data", data);
      this.editMode = true; //change btn to update
      this.createMode = false;
      let selectedState = this.dropdownstates.find(state => state.label === data.state);

      // this.labFacilityForm.controls["status"].setValue(data.status);
      this.lab_id = data.id;
      this.labFacilityForm.controls["labTitle"].setValue(data.name);
      this.labFacilityForm.controls["labInformation"].setValue(
        data.information
      );
      this.labFacilityForm.controls["labWebsiteUrl"].setValue(data.website_url);
      this.labFacilityForm.controls["labBenefits"].setValue(data.benefits);
      this.labFacilityForm.controls["labEligibility"].setValue(
        data.eligibility
      );
      this.labFacilityForm.controls["addressLine1"].setValue(
        data.address_line1
      );
      this.labFacilityForm.controls["cityName"].setValue(data.city);
      this.labFacilityForm.controls["stateName"].setValue(selectedState ? selectedState.value : null);
      this.labFacilityForm.controls["zipCode"].setValue(data.zip_code);
      this.labFacilityForm.controls["inchargeName"].setValue(
        data.incharge_details["name"]
      );
      this.labFacilityForm.controls["contactNumber"].setValue(
        data.incharge_details["phone"]
      );
      this.labFacilityForm.controls["designation"].setValue(
        data.incharge_details["designation"]
      );
      this.labFacilityForm.controls["emailAddress"].setValue(
        data.incharge_details["email"]
      );
      this.labFacilityForm.controls["uploadedImage"].setValue(data.documents);
      this.labFacilityForm.get("_img1").setValue(data.documents[0]?.url ? data.documents[0]?.url : data.documents[0]);
      this.labFacilityForm.get("_img2").setValue(data.documents[1]?.url ? data.documents[1]?.url : data.documents[1]);
      this.labFacilityForm.get("_img3").setValue(data.documents[2]?.url ? data.documents[2]?.url : data.documents[2]);
      this.labFacilityForm.get("_img4").setValue(data.documents[3]?.url ? data.documents[3]?.url : data.documents[3]);

      console.log('553', this.files)
      if(data.documents[0]){
        const file = data.documents[0]?.url ? data.documents[0]?.url : data.documents[0]; 
        this.files.splice(0, 1, file);
      }
      if(data.documents[1]){
        const file = data.documents[1]?.url ? data.documents[1]?.url : data.documents[1];
        this.files.splice(1, 1, file);
      }
      if(data.documents[2]){
        const file = data.documents[2]?.url ? data.documents[2]?.url : data.documents[2];
        this.files.splice(2, 1, file);
      }
      if(data.documents[3]){
        const file = data.documents[3]?.url ? data.documents[3]?.url : data.documents[3];
        this.files.splice(3, 1, file);
      }
      console.log('570', this.files)

      this.previewimageurl=[];   
      // for (let i = 0; i < data.documents.length; i++) {
      //   const last_part = data.documents[i].url.split("/");
      //   this.images[i] = last_part[last_part.length - 1];
      //   this.previewimageurl.push(data.documents[i]?.url);
      // }
      if (data.documents && data.documents.length > 0) {
        for (let i = 0; i < data.documents.length; i++) {
            const last_part = data.documents[i].url ? data.documents[i].url.split("/") : data.documents[i].split("/");
            if (data.documents[i]?.url ? data.documents[i]?.url : data.documents[i]) {
                this.images[i] = last_part[last_part.length - 1];
                this.previewimageurl.push(data.documents[i]?.url ? data.documents[i]?.url : data.documents[i]);
            }
            console.log("previewimageurl +++++++++" + i, this.previewimageurl[i]);
        }
    }
      console.log("images", this.images);
      console.log("files", this.files);
      console.log("image url", this.previewimageurl);
      console.log("uploded images => ",this.labFacilityForm.controls["uploadedImage"].value)
      
     
      
    } catch (error) {
      console.log(error);
    }
  }

  updateLabs() {
    if (this.editMode) {
      this.editMode = true;
      this.formSubmitted = true;

      console.log(this.previewimageurl);
      if(this.previewimageurl){
        var filesUpdated = this.previewimageurl.filter(item => item != '');
        console.log(filesUpdated);
      }
      this.previewimageurl=[];
      this.previewimageurl = filesUpdated
      let selectedState = this.dropdownstates.find(state => state.value === this.labFacilityForm.controls.stateName.value);

      if (this.labFacilityForm.valid) {
        console.log(this.labFacilityForm.value);
        let data = {
          id: this.lab_id,
          name: this.labFacilityForm.controls.labTitle.value,
          information: this.labFacilityForm.controls.labInformation.value,
          address_line1: this.labFacilityForm.controls.addressLine1.value,
          city: this.labFacilityForm.controls.cityName.value,
          state: selectedState ? selectedState.label : null,
          zip_code: this.labFacilityForm.controls.zipCode.value,
          incharge_details: {
            name: this.labFacilityForm.controls.inchargeName.value,
            phone: this.labFacilityForm.controls.contactNumber.value,
            email: this.labFacilityForm.controls.emailAddress.value,
            designation: this.labFacilityForm.controls.designation.value,
          },
          website_url: this.labFacilityForm.controls.labWebsiteUrl.value,
          benefits: this.labFacilityForm.controls.labBenefits.value,
          eligibility: this.labFacilityForm.controls.labEligibility.value,
          // documents: this.labFacilityForm.controls.uploadedImage.value,
          documents: this.previewimageurl
        };

        const formData = new FormData();
        if (this.uploadMode == true) {
          console.log(" upload mode files", this.previewimageurl);
          let previousFiles = this.previewimageurl;
          for (let i = 0; i < this.files.length; i++) {
            if ((this.files[i])) {
              const file: File = this.files[i];
              formData.append("file", file);
            }
          }
          console.log("formDATA", formData);
          console.log("after append", previousFiles, this.previewimageurl);
          this.challengeService
            .uploadManyFiles(formData, "lab-docs")
            .subscribe((response: any) => {
              console.log("image response", response.results);
              // let docs = this.labFacilityForm.controls.uploadedImage.value;
              console.log('this preview image url', this.previewimageurl)
              let docs: any[] = previousFiles.filter(img => img.length > 1); // Here work is needed
              let newdocs = response.results;

              console.log('files to combineAll', docs, newdocs);

              console.log("upload mode docs", docs);
              console.log("indexes", this.indexes);
              // let j = 0;
              // for (let i = 0; i < 4; i++) {
              //   if (response.results[j]) {
              //     if(response.results[j] != docs){
              //       console.log('not equal', response.results[j])
              //       docs.splice(i, 1, response.results[j]);
              //     }
              //     else{
              //       docs.push(response.results[j]);
              //       console.log('equals', response.results[j])
              //     }
              //     j++;
              //   }
              // }
              this.previewimageurl = [];
              this.previewimageurl = this.previewimageurl.concat(docs);
              this.previewimageurl = this.previewimageurl.concat(response.results);
              console.log('before put call', this.previewimageurl)
              this.fileInputLabel = undefined;
              data.documents = this.previewimageurl;
              console.log("upload mode data", data);
              this.labService.editLabData(data).subscribe(
                (res: any) => {
                  this.dataTable = res.results;
                  console.log("Lab dataTable::", this.dataTable);
                  this.labFacilityForm.reset();
                  this.previewimageurl=[];
                  this.toastrService.success("Data Updated Successfully!");
                  this.getAllData();
                  this.editMode = false;
                  this.createMode = true;
                },
                (error) => {
                  this.inValidMessage = true;
                  this.toastrService.warning("Unable to Update records!");
                }
              );

              this.uploadMode = false;
            });
        } else {
          console.log("normal mode data", data);
          // return;
          this.labService.editLabData(data).subscribe(
            (res: any) => {
              this.dataTable = res.results;
              console.log("Lab dataTable::", this.dataTable);
              this.labFacilityForm.reset();
              this.previewimageurl= '';
              this.toastrService.success("Data Updated Successfully!");
              this.getAllData();
              this.editMode = false;
              this.createMode = true;
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Update records!");
            }
          );
        }
      } else {
        console.log("edit form is invalid");
        this.labFacilityForm.markAllAsTouched();
      }
      this.previewimageurl = [];
      this.images = [];
      this.indexes = [];
    }
  }

  removeImage1(item: any) 
  {


    const updatedValue = this.labFacilityForm.controls["uploadedImage"].value.slice();
  
    // Set the value at index 1 to an empty string
    updatedValue[1] = '';
  
   
    this.labFacilityForm.controls["uploadedImage"].patchValue(updatedValue);     
    this.labFacilityForm.controls["uploadedImage"].setValue(updatedValue.filter(Boolean));
    this.previewimageurl[1] = '';

  }
  removeImage2(item: any) 
  {
  
    const updatedValue = this.labFacilityForm.controls["uploadedImage"].value.slice();
  
    // Set the value at index 1 to an empty string
    updatedValue[2] = '';
  
   
    this.labFacilityForm.controls["uploadedImage"].patchValue(updatedValue);     
    this.labFacilityForm.controls["uploadedImage"].setValue(updatedValue.filter(Boolean));
    this.previewimageurl[2] = '';
  }
  removeImage3(item: any) 
  {
    
   
    const updatedValue = this.labFacilityForm.controls["uploadedImage"].value.slice();
  
    // Set the value at index 1 to an empty string
    updatedValue[3] = '';
  
   
    this.labFacilityForm.controls["uploadedImage"].patchValue(updatedValue);     
    this.labFacilityForm.controls["uploadedImage"].setValue(updatedValue.filter(Boolean));
    this.previewimageurl[3] = '';
  }


  onFileSelect(event, index) {
    this.uploadMode = true;
    this.indexes.push(index);
    console.log("event.target.files", event.target.files);
    const file = event.target.files[0];
    this.files[index] = file; // use spread operator to push all File objects into array
    console.log("on file select files", this.files);
    const filesize = this.files.size;
    const filesizemb = filesize / 1024 ** 2;
    console.log("filesize in mb", filesizemb);
    console.log("file", this.files);

    console.log('551', this.files)

    if (filesizemb > 2) {
      this.filesizeerr = true;
    } else {
      this.filesizeerr = false;
    }
    if (index == 0) {
      this.images[0] = this.files[0]?.name;
      this.files.splice(0, 1, file)
      this.previewimageurl.splice(0, 1, '')
      this.labFacilityForm.get("_img1").setValue(this.files[0]);
    }
    if (index == 1) {
      this.images[1] = this.files[1]?.name;
      this.files.splice(1, 1, file)
      this.previewimageurl.splice(1, 1, '')
      this.labFacilityForm.get("_img2").setValue(this.files[1]);
    }
    if (index == 2) {
      this.images[2] = this.files[2]?.name;
      this.files.splice(2, 1, file)
      this.previewimageurl.splice(2, 1, '')
      this.labFacilityForm.get("_img3").setValue(this.files[2]);
    }
    if (index == 3) {
      this.images[3] = this.files[3]?.name;
      this.files.splice(3, 1, file)
      this.previewimageurl.splice(3, 1, '')
      this.labFacilityForm.get("_img4").setValue(this.files[3]);
    }

    console.log('579', this.files)

    // this.fileInputLabel = file.name;
  }

  cancel() {
    this.labFacilityForm.reset();
    this.editMode = false;
    this.createMode = true;
    this.labFacilityForm.get("stateName").setValue("")
  }

  gotosection(el: any) {
    el.scrollIntoView({ behaviour: "smooth" });
  }

  gotoBooking() {
    this.router.navigate(["/admin/resource-booking"], {
      queryParams: { facility: "lab" },
    });
  }

  deleteData(id: any) {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this News?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          console.log("deletedata:", id);
          this.labService.deleteLabData(id).subscribe(
            (data) => {
              this.getAllData();
              this.toastrService.success("Lab Deleted Successfully!");

            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Delete Lab!");
            }
          );
        }
      });
  }

  preview(item: any) {
    console.log("item", item);
    this.modaldata.name = item.name;
    this.modaldata.info = item.information;
    this.modaldata.websiteurl = item.website_url;
    this.modaldata.benefits = item.benefits;
    this.modaldata.eligibility = item.eligibility;
    this.modaldata.img1 = item.documents.map(doc=>{
      return typeof doc ==='string'? doc: doc.url;
    })

    // this.modaldata.img2 = item.documents[1]?.url ? item.documents[1]?.url : item.documents[1];
    // this.modaldata.img3 = item.documents[2]?.url ? item.documents[2]?.url : item.documents[2];
    // this.modaldata.img4 = item.documents[3]?.url ? item.documents[3]?.url : item.documents[3];
    this.modaldata.incharge_details.name = item.incharge_details.name;				
    
    this.modaldata.incharge_details.designation =
      item.incharge_details.designation;
    this.modaldata.incharge_details.phone = item.incharge_details.phone;
    this.modaldata.incharge_details.email = item.incharge_details.email;
    this.modaldata.address_line1 = item.address_line1;
    this.modaldata.city = item.city;
    this.modaldata.state = item.state;
    this.modaldata.zip_code = item.zip_code;
    this.userType = item.type;
    this.userName = item.userName
    console.log('userName', this.userName)
  }

// multi select change function
onUserSelectionChange(event:any){ 
  let userType = "INCUBATOR";
  if(event.target.value){
    userType = event.target.value;
  }
  let data = {    
    search_entity: userType,
    };
  this.labService.filterLab(data).subscribe((res: any) => {
    console.log("pendinggggg.........:", res);
    this.data = res.results;
    let data = [];
      for (var i = 0; i < this.data.length; i++) {
        data.push({
          item_id: this.data[i].user_id,
          item_text: this.data[i].name,
        });
      }
      this.dropdownuser = [...data];
      // console.log('data_domain',data);
    console.log("this is user data", this.dropdownuser)
    
  });
  if(event.target.value === "ALL"){
    this.dropdownuser = [];
  }
}
// multi select change function
  changestatus(
    id: string,
    status: string,
    resource_type: string,
    entity: string
  ) {
   
   
      this.dialogService
        .open(ShowcaseDialogComponent, {
          context: {
            message: "Are you sure do you want to change the status this Lab?",
          },
        })
        .onClose.subscribe((confirmed) => {
          if (confirmed) {            
            var data = {
              status: status,
            };
            this.labService
              .actionFacility(data, id, this.getResourceName(resource_type))
              .subscribe(
                (res: any) => {
                  console.log("change_status", res);    
                  this.toastrService.success("Successfully updated Status!");
                  // this.getAllData();
                  if(this.filterEnable == false)
                  {
                    console.log("not enable filter")
                    this.getAllData();
                  }
                  this.editMode = false;
                  this.createMode = true;
                  const updatedIncubatorIndex = this.data.findIndex(incubator => incubator.id === id);
                  if (updatedIncubatorIndex !== -1) {
                    this.data[updatedIncubatorIndex].status = status;
                  }
                  

                 
                  
                  
                },
                (error) => {                  
                  this.inValidMessage = true;
                  this.toastrService.warning("Unable to Update Status!");
                  
                }
              );
          }
        });
        var htmlElement = document.documentElement;
        if (htmlElement) {
          htmlElement.style.top = '0'; 
          htmlElement.style.position = 'sticky';
        }

  }


  getResourceName(facility: string): string {
    if (facility === "labresource") {
      return "lab";
    } else if (facility === "guesthouseresource") {
      return "guesthouse";
    } else if (facility === "coworkingresource") {
      return "coworking";
    } else if (facility === "equipmentresource") {
      return "equipment";
    }
  }


  
 
  public stripHtmlTags(html: string): string {
    if (!html) return ''; // Return empty string if input is undefined or null

    // Regular expression to match HTML tags and replace with an empty string
    return html.replace(/(<([^>]+)>)/gi, '');
  }
  // xls download file 
  //  filter count data
  getCountsByStatus(startups) {
    const statusCounts = {};
    startups.forEach((startup) => {
      const status = startup.status;
      if (status) {
        if (statusCounts.hasOwnProperty(status)) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      }
    });   

    return statusCounts;
  }
  // updateStatusCounts(res) {
  //   console.log(res,'res')
  //   this.approvedCount = res.approved_count;
  //   this.rejectedCount = res.declined_count; 
  //   this.pendingCount = res.pending_count; 
  // }
  //  filter count data
  // filter
//   filterItem(filterValue) {
//     this.downloadButton = false;
//     // console.log('filter',this.entityId,this.entity_type)
//     this.filterEnable = true
//     // this.filterMode = false;
//     this.resetMode = true;  
//     this.myArray = [];
//     this.p = 1;
//     var userList = [];
//     var statusList =[];  
//     var selectedUserType = this.labFilterForm.get('selectedUserType').value || 'ALL';
//     var startDate = this.labFilterForm.get('start_date').value;
//     var endDate = this.labFilterForm.get('end_date').value;
  

//           var userList = this.selectedUser.map(user => user.item_id);
//   if (userList.length > 0) {
//     this.myArray.push({
//       name: "userids",
//       value: userList,
//     });
//   }
  
//     for (var i = 0; i < this.selectedUser.length; i++) {
//       userList.push(this.selectedUser[i].item_id);
//     }

//     for (var i = 0; i < this.selectedStatus.length; i++) {
//       statusList.push(this.selectedStatus[i]);
//     }    
   
//     if (this.selectedUser.length != 0) {
//       this.myArray.push({
//         name: "userids",
//         value: userList,
//       });
//     }

//     // if (this.selectedStatus.length != 0) {
//     //   this.myArray.push({
//     //     name: "status",
//     //     value: statusList,
//     //   });
//     // }  
//       // Add the date filter here
//       if (startDate && endDate) {
//         this.myArray.push({
//           name: "created_at",
//           value: [
//             {
//               "start_date": startDate,
//               "end_date": endDate,
//             }
//           ]
//         });
//       }
//       // Add filters based on navigation state
//       // if (this.entity_type) {
//       //   this.myArray.push({
//       //     name: "entity_type",
//       //     value: this.entity_type,
//       //   });
//       // }
  
//       // if (this.id) {
//       //   this.myArray.push({
//       //     name: "id",
//       //     value: this.id,
//       //   });
//       // }
//   // if (entity_type) {
//   //   this.myArray.push({
//   //     name: "entity_type",
//   //     value: entity_type,
//   //   });
//   // }

//   // if (tab) {
//   //   this.myArray.push({
//   //     name: "tab",
//   //     value: tab,
//   //   });
//   // }

//   console.log("Filters Applied:", this.myArray);


//       this.activeFilter = this.selectedStatus.length == 3 ? null : (this.selectedStatus.length > 0 && this.selectedStatus.length < 3 ? this.selectedStatus : null)
//       this.filterValue1 = this.selectedStatus      
//       console.log("selectcity1223:", this.myArray);
   
//     console.log("+++++++++myArray",this.myArray);            
//     let data1 = {
//      filters: this.myArray, 
//      "search_entity":"LAB", 
//      "search_usertype_entity":selectedUserType,    
//       "page": 0, "page_size": this.totalItems
//     };
//    console.log('945', this.filterValue1)
//     if(this.filterValue == null){      
//       this.labService.getAllLabData(data1).subscribe((res: any) => {
//         // console.log("res myarray___0603:", res);
//         this.data = res.results; 
//         this.list = []
//         if(this.selectedStatus.length > 0){
//           console.log('this selectedStatus', this.selectedStatus);
//           this.selectedStatus.forEach(status => {
//             const filterStatus = this.data.filter(item => item.status == status);
//             console.log(filterStatus);
//             console.log(this.list)
//             this.list.push(...filterStatus);   
//             this.downloadButton = true;         
//           })
//           console.log('955', this.list)
//         }
//         else{
//           this.list = this.data;
//           this.downloadButton = true;
//         }
//         // this.updateStatusCounts(this.list);   
//         if(data1.filters.length > 0 && data1.filters[0]['name'] === 'status' ){
//           // this.updateStatusCounts(this.data);
//             // this.updateStatusCounts(this.list);   
// // && data1.search_usertype_entity === 'ALL'

//         // if(data1.filters.length === 1 && data1.filters[0]['name'] === 'status' && data1.search_usertype_entity === 'ALL'){
//         //   // this.updateStatusCounts(this.data);
//         }else{
//           this.updateStatusCounts(res);
//         }
//       });   
     
//     }

//     else{   
//     let data = {    
//       "page": 0, "page_size": 2984
//   }
 
//     console.log("data____filterdata", data);
//     this.labService.getAllLabData(data).subscribe((res: any) => {
//       console.log("pendinggggg.........:", res);
//       this.data = res.results; 
   
//       this.updateStatusCounts(res);      
//     });
//   }
//   }

  resetFilters() {
    // this.resetMode = false;
    // this.filterMode = true;
    this.filterEnable = false;
    // clear the filter criteria object
    this.data = {};
  
       this.selectedUser = [];
       this.selectedStatus = [];
       this.labFilterForm.get('selectedUserType').reset();
       this.labFilterForm.get('selectedUserType').setValue('ALL');
       this.labFilterForm.get('start_date').reset();
       this.labFilterForm.get('end_date').reset();
    // reset the filtered data to the original data
    // this.list = this.originalData;
    this.selectedindex = 0;

    this.getAllData();
    // this.updateStatusCounts(this.list);
    this.myArray=[];
    this.removeFilterQueryParam();
    this.activeFilter = ['APPROVED','PENDING','DECLINED'];
    this.newid = "";
    this.entity_type = ""
  }
  //  // filter
  //  countFilter(data)
  // {
  //   this.activeFilter = data;
  //   this.filterEnable = true
  //   this.myArray = [];
  //   // var domains = [];
  //   var userList = [];
  //   var statusList =[];  
  //   var startDate = this.labFilterForm.get('start_date').value;
  //   var endDate = this.labFilterForm.get('end_date').value;

  //   for (var i = 0; i < this.selectedUser.length; i++) {
  //     userList.push(this.selectedUser[i].item_id);
  //   }

  //   for (var i = 0; i < this.selectedStatus.length; i++) {
  //     statusList.push(this.selectedStatus[i]);
  //   }    
   
  //   if (this.selectedUser.length != 0) {
  //     this.myArray.push({
  //       name: "userids",
  //       value: userList,
  //     });
  //   }

  //   if (this.selectedStatus.length != 0) {
  //     this.myArray.push({
  //       name: "status",
  //       value: statusList,
  //     });
  //   }  
  //     // Add the date filter here
  //     if (startDate && endDate) {
  //       this.myArray.push({
  //         name: "created_at",
  //         value: [
  //           {
  //             "start_date": startDate,
  //             "end_date": endDate,
  //           }
  //         ]
  //       });
  //     }

  //   // 
    
  //   // let fitlere_data = data;
  //   // this.activeFilter = data;
  //   // this.filterValue1 = data; 
    
  //   // localStorage.removeItem('status')
  // //   if(this.viewback == 'true')
  // //  {
  // //    const getArray = localStorage.getItem('filterArray');
  // //   const parsedArray = JSON.parse(getArray);
  // //   this.myArray = parsedArray;
  // //    this.myArray = this.myArray.filter(item => item.name !== 'status');
  // //  }
  //   // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //   if(data == 'ALL')
  //   {
  //     // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //     this.myArray.push({
  //       name: "status",
  //       value: ["PENDING", "DECLINED", "APPROVED"]
  //     });
  //     this.activeFilter = null;
  //     this.selectedStatus = ["PENDING", "DECLINED", "APPROVED"];
  //     // const jsonString = JSON.stringify(this.myArray);
  //     // localStorage.setItem('filterArray', jsonString); 
     
  //   }
  //   else {
      
  //       // this.myArray = this.myArray.filter(item => item.name !== 'status');
     
     
  //     this.myArray.push({
  //       name: "status",
  //       value: [data],
  //     });
  //     this.selectedStatus = [data];
  //     // const jsonString = JSON.stringify(this.myArray);
  //     // localStorage.setItem('filterArray', jsonString); 
  //   }
  
   
    
  //   const jsonString = JSON.stringify(this.myArray);
  //   localStorage.setItem('filterArray', jsonString); 
   
  //   // let searchUserType = this.selectedUserType;
  //   // if (!searchUserType) {
  //   //   // Provide a default value if selectedUserType is not set
  //   //   searchUserType = 'INCUBATOR';  // You can change this to 'COE' or 'ACCELERATOR' as needed
  //   // }    //   this.applyInitialFilters();
    // } else if (this.filterValue != null) {
    //   this.filterItem(this.filterValue);
    // } else if (!this.id && this.entity_type) {
    //   console.log("Showing total data for entity type:", this.entity_type);
    //   this.getAllData();
    // } else {
    //   console.log("No Data");
    //   this.list = []; // Setting list to an empty array
    // }
  //   console.log('090909', this.labFilterForm.get("selectedUserType").value)
  //   const selectedEntity = this.labFilterForm.get("selectedUserType").value;
  
  //   let data1 = {
  //     filters: this.myArray,
  //     "search_entity": "LAB",
  //     "search_usertype_entity":selectedEntity,
  //     "page": 0, "page_size": 5000

  // }
  //   this.labService.getAllLabData(data1).subscribe((res: any) => {     
     
  //     this.list = res.results;
  //     console.log('count status data is ', this.list)
  //     this.updateStatusCounts(this.list);
  //     // this.pageSize(1) 
  //   //   const statusCount = {
  //   //     approvedData: this.approvedCount,
  //   //     rejectedData: this.rejectedCount,
  //   //     pendingData: this.pendingCount
  //   //   };
  //   //   const countstring = JSON.stringify(statusCount);
  //   //   localStorage.setItem('countdata', countstring);
  //   // });
  // })
  // }
  
  // countFilter(data){
  //   this.p = 1;
  //   console.log('data', data);
  //   if(data === "ALL"){
  //     console.log('this list', this.data)
  //     const filterData = ["APPROVED", "PENDING", "DECLINED"]
  //     const newFilter = this.data.filter((item: any) => item.status == 'APPROVED' || 'REJECTED' || 'PENDING');
  //     console.log('new filter', newFilter);
  //     this.selectedStatus = filterData
  //     this.activeFilter = null;
  //     this.list = newFilter;
  //   }
  //   else{
  //     console.log('this list', this.data)
  //     const newFilter = this.data.filter((item: any) => item.status == data);
  //     console.log('new filter', newFilter);
  //     this.selectedStatus = [data]
  //     this.activeFilter = data;
  //     this.list = newFilter;
  //   }
  // }

  clearImage(data){
    console.log(data);
    this.previewimageurl.splice(data, 1, '')
    this.images.splice(data, 1, '');

    console.log(this.previewimageurl)
    // this.files = this.previewimageurl
  }

//   applyInitialFilters(){
//   console.log(this.id, this.entity_type);
//   this.filterEnable = true

//   this.myArray=[];
//   this.p = 1;
//   this.labFilterForm.get('selectedUserType').setValue(this.entity_type)
//   // var selectedUserType = this.entity_type;
  
//   let userType = this.entity_type;
//   let data = {
//     search_entity: userType,
//   };
//   this.labService.filterLab(data).subscribe((res: any) => {
//     console.log('response from entity type in redirect', res);
//     this.data = res.results;
//     let data = [];
//       for (var i = 0; i < this.data.length; i++) {
//         data.push({
//           item_id: this.data[i].user_id,
//           item_text: this.data[i].name,
//         });
//       }
//       this.dropdownuser = [...data];
//       console.log("this is user data", this.dropdownuser);

//       const redirectedSelectedUser = this.dropdownuser.filter(item => item.item_text == this.id);
//       console.log('redirectedSelectedUser', redirectedSelectedUser);
//       this.selectedUser = redirectedSelectedUser

//       var userList = this.selectedUser.map(user => user.item_id);
//       if (userList.length > 0) {
//         this.myArray.push({
//           name: "userids",
//           value: userList,
//         });
//       }

//       console.log(this.myArray);

//       this.activeFilter = null;
//       let payload = {
//         filters: this.myArray,
//         "search_entity":"LAB", 
//         "search_usertype_entity":this.entity_type,
//         "page": 0,
//         "page_size": this.totalItems
//       };

//       // this.list = []

//       this.labService.getAllLabData(payload).subscribe((res: any) => {
//         this.data = res.results;
//         console.log('this data', this.data)
//         this.list = [];
//         if(this.selectedStatus.length > 0){
//           console.log('this selectedStatus', this.selectedStatus);
//           this.selectedStatus.forEach(status => {
//             const filterStatus = this.data.filter(item => item.status == status);
//             console.log(filterStatus);
//             console.log(this.list)
//             this.list.push(...filterStatus);            
//           })
//           console.log('955', this.list)
//         }
//         else{
//           this.list.push(this.data);
//         }
//         if(payload.filters.length > 0 && payload.filters[0]['name'] === 'status'){
//           this.updateStatusCounts(this.data);
//         }
//         else{
//           this.updateStatusCounts(this.data);
//         }
//       } )

//       console.log('selected entity data', this.list)
//   })
// //   let section = document.querySelector('section');
// //     section.scrollIntoView();
  
// // }
// }
applyInitialFilters() {
  console.log(this.newid, this.entity_type);
  this.filterEnable = true;
  this.myArray = [];
  this.p = 1;
  this.labFilterForm.get('selectedUserType').setValue(this.entity_type);

  let userType = this.entity_type;
  let data = {
    search_entity: userType,
  };

  this.labService.filterLab(data).subscribe((res: any) => {
    console.log('response from entity type in redirect', res);
    this.data = res.results;
    let data = [];
    for (let i = 0; i < this.data.length; i++) {
      data.push({
        item_id: this.data[i].user_id,
        item_text: this.data[i].name,
      });
    }
    this.dropdownuser = [...data];
    console.log("this is user data", this.dropdownuser);

    const redirectedSelectedUser = this.dropdownuser.filter(item => item.item_text == this.newid);
    console.log('redirectedSelectedUser', redirectedSelectedUser);
    this.selectedUser = redirectedSelectedUser;
    console.log('myaray',this.myArray)
    let userList = this.selectedUser.map(user => user.item_id);
    if (userList && userList.length > 0) {
      this.myArray.push({
        name: "userids",
        value: userList,
      });
    }

    console.log('hghgh',this.myArray);

    // this.activeFilter = ["APPROVED"];
    this.activeFilter = ["APPROVED","PENDING","DECLINED"];

    let payload = {
      filters: this.myArray,
      search_entity: "LAB",
      search_usertype_entity: this.entity_type,
      page: 0,
      page_size: 10
    };
    console.log('wyecwbiecwiuebciwe')

    // if (this.selectedUser.length > 0) {
      // this.list = []; // Clear the list here before fetching new data
      this.labService.getAllLabData(payload).subscribe((res: any) => {
        this.data = res.results;
        this.list = [];
        this.totalItems = +res.total_search_results_count;

        // Example dynamic pagination generation
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages; // Replace with your dynamic value
    
        console.log('this data after api hit', res.results);  
        // return
        if (this.selectedStatus.length > 0) {
          console.log('this selectedStatus', this.selectedStatus);
          this.selectedStatus.forEach(status => {
            const filterStatus = this.data.filter(item => item.status == status);
            console.log(filterStatus,"&**********");
            console.log(this.list);
            this.list.push(...filterStatus);
            this.downloadButton = true;
          });
          console.log('Filtered list:', this.list);
        } else {
          this.list.push(...this.data);
          this.downloadButton = true;
        }

        
          // this.updateStatusCounts(res);
          this.approvedCount = res.approved_count;
          this.rejectedCount = res.declined_count;
          this.pendingCount = res.pending_count;
        
      });
    // } else {
      // this.getAllData(); // Call getAllData() when selectedUser.length === 0
    // }

    // console.log('selected entity data', this.list);
  });

  let section = document.querySelector('section');
  if (section) {
    section.scrollIntoView();
  }
}


removeFilterQueryParam(): void {
 
  // Clone the current query parameters
  const updatedQueryParams = { ...this._route.snapshot.queryParams };

  // Remove 'filter' from the cloned object
  delete updatedQueryParams['filter'];

  // Navigate to the updated query parameters
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: updatedQueryParams, // Set updated params explicitly
    queryParamsHandling: undefined, // Ensure Angular fully replaces the params
  });

  console.log('Updated Query Params:', updatedQueryParams);
}
// filtersItem(filterValue) {
//   console.log('filterValue', filterValue)
//   this.downloadButton = false;
//   this.filterEnable = true;
//   this.resetMode = true;
//   this.myArray = [];
//   this.p = 1;

//   let userList = this.selectedUser?.map(user => user.item_id) || [];
//   let statusList = this.selectedStatus.length ? this.selectedStatus : [];

//   let selectedUserType = this.labFilterForm.get('selectedUserType').value || 'ALL';
//   let startDate = this.labFilterForm.get('start_date').value;
//   let endDate = this.labFilterForm.get('end_date').value;

 
//   // Add user filter
//   if (userList.length > 0) {
//     this.myArray.push({ name: "userids", value: userList });
//   }
//   if (filterValue) {//whatever i select
//     this.activeFilter = filterValue === "ALL" ? null : filterValue;
//     this.selectedStatus = filterValue === "ALL" ? ["APPROVED", "PENDING", "DECLINED"] : [filterValue];
//   }

//   // ✅ Use `this.selectedStatus` directly
//   if (this.selectedStatus.length > 0) {
//     this.myArray.push({ name: "status", value: this.selectedStatus });
//     this.activeFilter = this.selectedStatus
//   }
 
//   if (selectedUserType && selectedUserType !== "ALL") {
//     this.myArray.push({ name: "usertype", value: selectedUserType });
//   }
//   // Add date filter
//   if (startDate && endDate) {
//     this.myArray.push({
//       name: "created_at",
//       value: [{ "start_date": startDate, "end_date": endDate }],
//     });
//   }

//   console.log("Filters Applied:", this.myArray);

//   let requestData = {
//     "filters": this.myArray,
//     "search_entity": "LAB",
//     "search_usertype_entity": selectedUserType,
//     "page": 0,
//     "page_size": this.totalItems,
//   };
 
// if(filterValue == null){
//   this.labService.getAllLabData(requestData).subscribe((res: any) => {
//     this.data = res.results;
//     this.list=[];
//     // this.approvedCount = this.totalApproved;
//     // this.rejectedCount = this.totalRejected;
//     // this.pendingCount = this.totalPending;
  
//     // Update status counts
//     this.approvedCount = res.approved_count;
//     this.rejectedCount = res.declined_count;
//     this.pendingCount = res.pending_count;
//     this.list = this.data;

//     this.downloadButton = true;
//   });
// }
// else{
  
//      if (!this.myArray.some(item => item.name === 'status')) {
//     this.myArray.push({ name: "status", value: Array.isArray(this.filterValue) ? this.filterValue : [this.filterValue] });
//   }
//   let requestData = {
//     "filters": this.myArray,
//     "search_entity": "LAB",
//     "search_usertype_entity": selectedUserType,
//     "page": 0,
//     "page_size": 10000,
//   };
//   this.labService.getAllLabData(requestData).subscribe((res: any) => {
//     this.data = res.results;

//     this.list=[];

//     // Update status counts
//     this.approvedCount = res.approved_count;
//     this.rejectedCount = res.declined_count;
//     this.pendingCount = res.pending_count;
//     this.list = this.data;


//     this.downloadButton = true;

// })
// }
// }
async filtersItem(filterValue:any) {
  this.selectedindex = 0;
  console.log('filtervalue ----',filterValue);
  if(filterValue != undefined){
    if (filterValue === 'All') {
      this.activeFilter = ['APPROVED', 'DECLINED', 'PENDING']; // Select all
      this.selectedStatus = ['APPROVED', 'DECLINED', 'PENDING']
    } else if(filterValue === 'APPROVED' || filterValue === 'PENDING' || filterValue === 'DECLINED') {
      this.selectedStatus = [filterValue];
      if (this.activeFilter?.includes(filterValue)) {
        this.activeFilter = this.activeFilter?.filter(f => f !== filterValue); // Remove if already selected
      } else {
        this.activeFilter?.push(filterValue); // Add to selection
      }
    }
  }
  else {
    this.activeFilter = ['APPROVED', 'DECLINED', 'PENDING'];
    console.log('af',this.activeFilter);
  }
  // If all three are selected, keep only "View All" active
  if (this.activeFilter?.length === 3) {
    this.activeFilter = ['APPROVED', 'DECLINED', 'PENDING']; // Keep all three for View All
  }
  //console.log("Filter Value:", filterValue);
  this.resetMode = true;
  this.p = 1;
  let userList = this.selectedUser?.map(user => user.item_id) || [];
console.log('userList,', userList)
  let selectedUserType = this.labFilterForm.get('selectedUserType').value || 'ALL';
  let startDate = this.labFilterForm.get('start_date').value;
  let endDate = this.labFilterForm.get('end_date').value;

  let dateFilter = []
  if(startDate && endDate){
    dateFilter.push({ "start_date": startDate, "end_date": endDate });
  }
  this.myArray = await this.setFilters(selectedUserType,userList,dateFilter,this.selectedStatus);

    this.activeFilter = this.myArray[3]?.value.length !=0 ? this.myArray[3]?.value : ['APPROVED','PENDING','DECLINED'];
    console.log('activefilter',this.activeFilter);

  console.log("Filters Applied:", this.myArray);
  // const pageno = this.selectedindex

  let requestData = {
    "filters": this.myArray,
    "search_entity": "LAB",
   "search_usertype_entity": selectedUserType,
    "page": 0,
    "page_size": 10,
  };

  this.labService.getAllLabData(requestData).subscribe((res: any) => {
    console.log("API Response:", res);
  
    this.data = res.results;
    this.list=[];
    // Update status counts
    this.approvedCount = res.approved_count ;
    this.rejectedCount = res.declined_count ;
    this.pendingCount = res.pending_count;
    // this.loading = false;
    this.list = this.data;
    this.totalItems = +res.total_search_results_count;

    // Example dynamic pagination generation
  var totalPages = Math.ceil(this.totalItems/10);
  this.totalpages = totalPages; // Replace with your dynamic value


     this.downloadButton = true;
  });

}

async setFilters(...filters: any[][]) {
  //const filterNames = ["state", "city", "domain", "schemes", "status"];

  // Map through filterArray and update values based on corresponding position in filters array
  this.filterArray = this.filterArray.map((filter, index) => ({
      ...filter,
      value: filters[index] || [] // Assign values if present, else keep empty
  }));
  console.log('filterArray ----',this.filterArray);
  return this.filterArray;
}

paginate_startup(pageno) {
  this.pageno = pageno;
  let selectedUserType = this.labFilterForm.get('selectedUserType').value || 'ALL';

  console.log('pageno----',this.pageno);
  this.selectedindex = pageno-1;
  console.log('selectedindex-----',this.selectedindex)
  console.log('myarray ----',this.myArray);
  let data={
    // query:this.searchText,
    "filters": this.myArray,
    "search_entity": "LAB",
   "search_usertype_entity": selectedUserType,
    "page": pageno-1,
    "page_size": 10,
      
    }
    // this.isDownloading = true;

  
  console.log('data_payload',data);
  // this.loading = true;
  this.labService.getAllLabData(data).subscribe((res)=>{
    this.data = res.results;
    this.list = res.results;
    console.log('data----');
    console.log(this.data);
    // this.loading = false;
    // this.isDownloading = true;

  })
}

getSerialNo(i:number) {
  return i+(this.selectedindex*10);
}

async listAllStartupsforDownload() {
  let selectedUserType = this.labFilterForm.get('selectedUserType').value || 'ALL';

  return new Promise((resolve,reject)=>{
    let data = {
      "filters": this.myArray,
      "search_entity": "LAB",
     "search_usertype_entity": selectedUserType,
      "page": 0,
      "page_size": 10000,
    };
    // this.isDownloading = true;

    // this.loading = true;
    this.labService.getAllLabData(data).subscribe((res: any) => {
      // this.datafordownload = res.results;
      resolve(res.results);
    },
    (err) => {
      reject(err);
    }
  );
  })
}
 


async downloadLabList(){
  this.isDownloading = true;

try {
  const FaqsData = await this.listAllStartupsforDownload();
  this.downloadTableData(FaqsData);
} catch (err) {
  console.error('Download failed', err);
}

this.isDownloading = false;
};

downloadTableData(dataList:any) {
  // Prepare the data for Excel export
  const header = ['Sl No','Center Name','Lab Title', 'Incharge Name', 'Incharge Contact', 'Incharge Email ','Lab Information','Website URL', 'Incharge Designation','Address','City','State','Zip Code','Lab Benifits','Lab Eligibility','Status'];
  
  const data = dataList.map((item, index) => ({
    'Sl No': index + 1,
    'Center Name': item.userName,

    'Lab Title': item?.name || '',
    'Lab Information':item.information,
    'Website URL':item.website_url,
    'Incharge Name': item.incharge_details?.name || '',
    'Incharge Contact': item.incharge_details?.phone || '',
    'Incharge Designation':item.incharge_details?.designation,
    'Incharge Email ': item.incharge_details?.email || '',
    'Address':item.address_line1,
    'City':item.city,
    'State':item.state,
    'Zip Code':item.zip_code,
    'Lab Benifits': this.stripHtmlTags(item.benefits),
    'Lab Eligibility':this.stripHtmlTags(item.eligibility),
    'Status': item?.status || '',
    // 'Updated Time': new Date(item?.updated_at),
  }));
this.exportAsXLSX(data)
  // Create a new workbook
  // const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  
  // // Convert data to worksheet
  // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { header })


  // // Add the worksheet to the workbook
  // XLSX.utils.book_append_sheet(workbook, worksheet, 'Labs Data');
  // const currentDate = new Date();
  // const day = String(currentDate.getDate()).padStart(2, '0');
  // const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  // const year = currentDate.getFullYear();
  // const formattedDate = `${day}-${month}-${year}`;
  // // Export the workbook as an Excel file
  // XLSX.writeFile(workbook, `Labs_export_${formattedDate}.xlsx`);
}
exportAsXLSX(data):void {
  this.excelService.exportAsExcelFile(data, 'Startup Report','Startup-Data');
}




}