import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AxiosServicesService } from './axios/axios-services.service';
const headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Authorization', `Bearer ${sessionStorage.getItem('admintoken')}`);
@Injectable({
  providedIn: 'root'
})
export class EditionService {

  //baseUrl = "http://43.205.193.210:8090";
  //baseUrl = "http://43.205.193.210:8090";
  baseUrl = environment.apiurl;

  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) { }

  getAllEdition() {
    let url = this.baseUrl + "challenges/c5178ee0-7fd4-4223-960b-0362f06311db/editions";
    let result: any = this.http.get(url).pipe(catchError(this.handleError));
    return result;
  }
  createEditions(challengeid:any,data: any) {
    console.log("data :: ", data);
    const url = this.baseUrl + `challenges/editions?challenge_id=${challengeid}`;
      let result: any = this.http
      .post(url, data , {'headers':headers})
      .pipe(catchError(this.handleError));
    return result;
  }

  createEditions_ext(data: any) {
    console.log("data :: ", data);
    const url = this.baseUrl + `challenges/editions?type=EXTERNAL`;
      let result: any = this.http
      .post(url, data , {'headers':headers})
      .pipe(catchError(this.handleError));
    return result;
  }

  getEditionsByChallenge(challengeid:any){
    const url = this.baseUrl + `challenges/${challengeid}/editions`;
    let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  getExternalEditions(data){
    // const url = this.baseUrl + `challenges/editions/external`;
    // let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    // return result;
    const url = this.baseUrl + "search";
    let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  getIndividualEdition(id:any){
    const url = this.baseUrl + `challenges/editions/${id}`;
    let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
    return result;
  }

  deletechallenge(id:any){
    const url = this.baseUrl + `challenges/editions/${id}`;
    let result = this.http.delete(url,{headers:headers}).pipe(catchError(this.handleError));
    return result;
  }

  updateExternalEdition(id:any,data:any){
      const url = this.baseUrl + `challenges/editions/${id}`;
      let result = this.http.put(url, data, { headers: headers }).pipe(catchError(this.handleError));
      return result;
    
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
