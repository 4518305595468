<div *ngIf="isDownloading" class="loader-overlay">
  <div class="loader"></div>
  <!-- <p>Downloading...</p> -->
</div>

<div class="container-grid col-sm-12" #section>
      <h2 class="">News</h2>
      <form
        [formGroup]="newsForm"
        class="form-control-group col-12"
       enctype="multipart/form-data"
      >
      <div class="grid-container">
        <div class="grid-item">
          <label for=""  class="label-required">Title</label>
          <input
            type="text"
            formControlName="newsTitle"
            class="form-control"
          />
          <div *ngIf="newsForm.get('newsTitle').invalid && newsForm.get('newsTitle').touched">
            <div *ngIf="newsForm.get('newsTitle').errors.required" class="text-danger">Title is required.</div>
          </div>
        </div>
        <div class="grid-item">
          <label for=""  class="label-required">Link</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">@</span>
            </div>
            <input type="text" class="form-control" formControlName="newsLink"
            placeholder="" aria-label="" aria-describedby="basic-addon1">
          </div>
          <div *ngIf="newsForm.get('newsLink').invalid && newsForm.get('newsLink').touched">
              <div *ngIf="newsForm.get('newsLink').errors.required" class="text-danger">URL is required.</div>
              <div *ngIf="newsForm.get('newsLink').errors.pattern" class="text-danger">Valid URL is required.</div>
          </div>   
        </div>
        <div class="grid-item"><label  class="label-required">Select File</label>
          <div class="custom-file">
          
              <input type="file" class="custom-file-input" id="customFile" (change)="onFileSelect($event)">
            <label class="custom-file-label" for="customFile">{{fileInputLabel || 'Choose File'}}</label>
            <span style="color:black">(File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)</span>
            <div *ngIf="newsForm.get('uploadedImage').invalid && newsForm.get('uploadedImage').touched" style="color:red">
              <div *ngIf="newsForm.get('uploadedImage').errors.required" class="text-danger">File is required.</div>
              <p class="text-danger" *ngIf="filetypeerr"> 
                File format is incorrect
              </p>
              <p class="text-danger" *ngIf="filesizeerr"> 
                File size should not exceed 2 MB
              </p>
              <!-- <div *ngIf="newsForm.get('uploadedImage').errors?.fileSizeExceeded">File size exceeded 2 MB</div>
             <div *ngIf="newsForm.get('uploadedImage').errors?.invalidFileType">Invalid file type</div> -->
            </div>
          </div>
        </div>
        <div class="grid-item">
          <div class="my-div">
          <div class=""  *ngIf="imageUrl">
            <img [src]="imageUrl" alt="Image preview" height="auto" width="200" alt="">
          </div>
        </div>
      </div>
      </div>

      <div class="form-control-group col-sm-12 mt-2">
        <label  class="label-required">Desription</label>
           <ckeditor [editor]="Editor" formControlName="newsDescription" [config]="editorConfig"></ckeditor>
           <div *ngIf="newsForm.get('newsDescription').invalid && newsForm.get('newsDescription').touched">
            <div *ngIf="newsForm.get('newsDescription').errors.required" class="text-danger">Desription is required.</div>
          </div>
       </div>
  
  </form>
      <div class="d-flex w-100 justify-content-center">
        <button class="btn custombtn mr-3 mb-3 mt-3" (click)="publishNews()" *ngIf="createMode">Create</button>
        <button class="btn custombtn mr-3 mb-3 mt-3" (click)="updateForms()" *ngIf="editMode">Update</button>
        <button class="btn custombtn mb-3 mt-3" (click)="cancel()">Cancel</button>
      </div>
      

      <!-- <div class="row float-right">
        <div class=" d-flex col-md-6 my-2 ">
          <input class="form-control" type="text" [(ngModel)]="searchText" placeholder="Search by Title...">
          <div class="download-bt col">
            <button class="btn btn-success" (click)="downloadlabList()"><i class="fas fa-download"></i> Download</button>
          </div>
        </div>
      </div> -->

      <div class="row my-2 gap-2">
        <div class="d-flex flex-wrap justify-content-between w-100">
          
          <!-- Search Input -->
          <div class="col-md-4 mt-4">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="searchText"
              placeholder="Search by Title..."
            />
          </div>
      
          <!-- Status Filter -->
          <div class="col-md-4 mb-3">
            <form [formGroup]="newsFilterForm">
              <label>Status:</label>
              <ng-multiselect-dropdown
                [placeholder]="''"
                [settings]="dropdownSettingst"
                [data]="dropdownstatus"
                [(ngModel)]="selectedStatus"
                [ngModelOptions]="{standalone: true}"
              >
              </ng-multiselect-dropdown>
            </form>
          </div>
      
          <!-- Action Buttons -->
          <div class="col-md-4 mb-3 d-flex align-items-end justify-content-start">
            <button
              type="button"
              class="btn btn-primary mx-2"
              (click)="filtersItem()"
            >
              Filter
            </button>
            <button
              type="button"
              class="btn btn-secondary mx-2"
             
              (click)="resetfilter()"
            >
              Reset
            </button>
            <button
              type="button"
              class="btn btn-success mx-2"
              (click)="downloadlabList()"
            >
              <i class="fas fa-download"></i> Download
            </button>
          </div>
          
        </div>
      </div>
      <div class="table-wrapper table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" class="headerfix" >Sl No</th>
            <th class="headerfix">Updated Time</th>
            <th scope="col " class="text-center">Title</th>
            <th scope="col">Link</th>
            <th scope="col">Image</th>
            <th scope="col">Description</th>
            <th scope="col">Status</th> 
            <th scope="col" width="200px">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="filteredData?.length > 0; else noRecords">
          <tr  *ngFor="let d of filteredData; let i = index" >
            <!-- <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th> -->
            <th scope="row" class="text-center">{{getSerialNo(i+1)}}</th>
            <td>{{d.updated_at |  date}}</td>

            <td class="m_col">{{ truncateText(d.news_subject, 70) }} <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore"  class="readMore">Read more</span></td>


            <td class="m_col"><a href="{{d.news_link}}" target="_blank">Click Here</a></td>
            
            <td class="m_col"><a href="{{d.news_image}}" target="_blank">Click Here</a></td>
            <td class="m_col">
              <div style="display: inline-block;">
                  <span [innerHtml]="truncateTextt(d.news_description, 70)"></span>
                  <span *ngIf="readMore" data-toggle="modal" data-target="#exampleModal" 
                        (click)="preview(d)" class="readMore ml-1">Read more</span>
              </div>
          </td>
          
            
          
          
            <td>{{d.news_status}} </td>

            <td >
               
              <div class="d-flex align-items-center ">
                <div *ngIf="d.news_status === 'active'">
                  <a class="fa fa-close mr-3 arrow" style="color:red"  (click)="changestatus(d,'inactive')"></a>
                </div>
                <div *ngIf="d.news_status === 'inactive'">
                    <a class="fa fa-check mr-3 arrow" style="color:blue"  (click)="changestatus(d,'active')"></a>
                </div>
                <a class="fa fa-edit mr-3 arrow" style = "color:blue" (click)="editData(d, section)" ></a>
                <a class="fa fa-trash mr-3 arrow" style = "color:red" (click)="deleteNewsData(d.news_id)" ></a>
                <a class="fa fa-eye mr-3 arrow" style = "color:green" data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" ></a>
                </div>

            </td>
          </tr>
        
        </tbody>
        <ng-template #noRecords>
          <tbody>
            <tr>
              <td colspan="6" class="no-records-found">No Data found.</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
      </div>

      <!-- <pagination-controls *ngIf="filteredData?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls> -->
    </div>

    <app-pagination 
          [totalpages]="totalpages" 
          [pageno]="pageno"
          [selectedindex]="selectedindex"
          (pageChanged)="paginate_startup($event)">
          </app-pagination>

<!-- Modal -->
<div class="modal custom-modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content ">
    <div class="modal-header ">
      <h3 class="modal-title " id="exampleModalLabel">Preview</h3>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="container-fluid ">
        <label for="basic-url">News Title</label>
        <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <input type="text" class="form-control mb-2" placeholder="" aria-label="Username" value="{{modaldata.newsTitle}}" aria-describedby="basic-addon1" readonly>
        </div>
        <label for="basic-url">News Image</label>
        <div class="input-group">
        <div class="input-group-prepend">
        </div>
        <div class="d-flex justify-content-center align-items-center mb-2">
        <img [src]="modaldata.image" alt="image" width="735" height="300">
      </div>
      </div>

        <label for="basic-url">News Description  </label>
    
     <div class="container">
     
      <div class="text_contianer form-control" [innerHtml]="modaldata.description" style="word-break: break-all; overflow-y: auto;">
             
      </div>
    </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
      
    </div>
  </div>
  
</div>
</div>