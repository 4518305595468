import { AfterViewInit, Component, OnInit,ViewChild } from "@angular/core";
import * as XLSX from "xlsx";
import { StartupService } from "../../services/startup.service";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ExportToCsv } from "export-to-csv";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChallengeService } from "../../services/challenge.service";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { citiesOnly, states } from "../../../assets/js/states";
import { MatDialog } from "@angular/material/dialog";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { ExcelService } from "../../services/excel.service";
import { StateService } from "../../services/state.service";
import { forkJoin } from 'rxjs';
import { PaginationComponent } from '../pagination/pagination.component';
import { CityService } from "../../services/statecity/city.service";
import { query } from "@angular/animations";

const styles = {
  header: {
    fontSize: 18,
    bold: true,
  },
  subheader: {
    fontSize: 14,
    bold: true,
  },
  body: {
    fontSize: 12,
  },
};

@Component({
  selector: "ngx-startups",
  templateUrl: "./startups.component.html",
  styleUrls: ["./startups.component.scss"],
})
export class StartupsComponent implements OnInit , AfterViewInit{
  @ViewChild(PaginationComponent) paginationComponent!: PaginationComponent;

  filterArray = [
    {
      "name": "state",
      "value": []
    },
    {
      "name": "city",
      "value": []
    },
    {
        "name": "domain",
        "value": []
    },
    {
        "name": "scheme_ids",
        "value": []
    },
    {
        "name": "status",
        "value": []
    }
]

searchText = '';
countryid=101;
selected_citiesapi:any[] = [];
dropdownstatesapi: any;
dropdowncitiesapi:any = [];
// searchText = '';
  data: any;
  items = [{ title: "PDF" }, { title: "EXCEL" }];
  list: any = [];
  modalUpdateForm: FormGroup;
  action: { status: string };
  inValidMessage: boolean;
  alldomains: any;
  groupbydomains: any;
  isDownloading: boolean;

  dropdownList = [];
  sectors: any = [];
  dropdownList1 = [];
  tempdata = [];
  selectedItems1 = [];
  dropdowncities:any = [];

  dropdownSettings_city: IDropdownSettings={};

  dropdownSettings: IDropdownSettings = {};
  dropdownSettings_t: IDropdownSettings = {};
  dropdownSettings_s: IDropdownSettings = {};
  dropdownSettings_scheme: IDropdownSettings = {};
  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectcity: any=[];
  selectstate: any = [];
  selectedScheme:any = [];

  selectdomain: any = [];
  selectsector: any = [];
  selectstatus: any = [];
  selectedItems: any[];
  selectedStates: any[];
  selectedindex:number = 0;
  dsApproved = true;
  schemId:any;
  dsRejected = true;
  dsPending = true;
  viewallcount:any;
  // dropdownstates: any[] = [
  //   'Karnataka','UP','Telengana', 'Andhra Pradesh'
  // ];
  dropdownstates: any = [];
  selectedStatus: any[];
  dropdownstatus: any[] = [ "APPROVED","PENDING", "REJECTED"];
  dropdownschemes:any[] = [
    { id: 1, name: "TIDE 2.0" }, 
    { id: 2, name: "SAMRIDH" },
    { id: 3, name: "OTHER MEITY SCHEME" },
    { id: 4, name: "GENESIS" },
    { id: 5, name: "NON MEITY SCHEME" },
    { id: 6, name: "NGIS" }
  ];
  originalData: any[];
  public domain = [];
  public sector = [];
  filteredlist = [];
  filterMode: boolean = true;
  resetMode: boolean = false;
  p: number = 1;
  filterValue: any;
  mat_data: string;
  totalItems: number;
  StartupList:any;
  allStates: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  pageSize = 10; 
  loading: boolean = true; 
  activeFilter: string[]=[] ; 
  filterValue1:any;
  viewback: any;
  // dsFiltervalue: string;
  // dsPending:boolean = true;
  // dsApproved: boolean = true;
  // dsRejected: boolean = true;
  // dsAll: boolean = true;
  schemeSelected:boolean = false;
  schemesData:any;
  count: any;
  test:number = 4000;
  pagelinks:any = [];
  initial_pages: any = [];
  dots: string;
  remaining_pages: any = [];
  approved_count:any;
  rejected_count:any;
  pending_count:any;
  page_index:number = 0;
  pageno:any = 0;
  totalpages: number;
  datafordownload: any;
  section:any;
  // stateid: any;

  constructor(
    private startupService: StartupService,
    private toastrService: NbToastrService,
    private router: Router,
    private fb: FormBuilder,
    private challengeService: ChallengeService,
    private _route:ActivatedRoute,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private excelService:ExcelService,
    private statesservice : StateService,
    private cityservice : CityService



  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);

    this.activeFilter = ['APPROVED','PENDING','REJECTED'];
    if(localStorage.getItem('viewback'))
    {
      this.viewback = localStorage.getItem('viewback');
    }
    

  }

  ngOnInit(): void {
    this.selectcity = "";
    this.selectstate = "";
    //this.selectdomain = "";
    this.selectstatus = "";
 
    this.selectedScheme=""
    this.searchText="";
    console.log('selectstate:', this.selectstate);
    console.log('selectcity:', this.selectcity);
    console.log('selectdomain:', this.selectdomain);
    console.log('selectstatus:', this.selectstatus);
    console.log('selectscheme:', this.selectedScheme);
    console.log('searchText:', this.searchText);

    this.loadStates();
    this.schemId = sessionStorage.getItem('startupSchemeId');  
    

    if (this.schemId) {
      // Pre-select the scheme in dropdown
      this.selectedScheme = this.dropdownschemes.filter(scheme => scheme.id == this.schemId);
      console.log('selectschme',this.selectedScheme);
    }
    if (!this.schemId) {
      sessionStorage.removeItem('startupSchemeId');  // Clear schemeId if not from Dashboard
    }
    // Check if the page has been reloaded
if (!sessionStorage.getItem("pageReloaded")) {
  // This code will only run once after the page is reloaded
  console.log("Page loaded for the first time in this session.");

  // Set a flag in sessionStorage
  sessionStorage.setItem("pageReloaded", "true");
} else {
  console.log("Page reloaded.");
  localStorage.removeItem("viewback");
}

    this.selectedItems = [];
    this.selectedStates = [];
    this.selectedStatus = [];
    
 
    this.getStates();
    this.citiesData();

    this.onItemSelectDomain(0);
    this.getDomains();
    console.log('viewback',this.viewback);
    if (this.viewback == 'true') {   
      console.log('here1234');       
      this.backfilter();   
      if(localStorage.getItem('countdata'))
      {
        const storedJsonString = localStorage.getItem('countdata');
        const storedObject = JSON.parse(storedJsonString);
        console.log("count data is==>", storedObject);
        console.log(storedObject.approvedData)
        this.approvedCount = storedObject.approvedData;
        this.rejectedCount = storedObject.rejectedData;
        this.pendingCount = storedObject.pendingData;
       
  
      }  
      // localStorage.removeItem('viewback');
    }
    // console.dir(this.filterValue,{merge:true});
    else if (this.filterValue != null) {
      console.log('here1234'); 
      console.log('here is the filtered set',this.filterValue);
       this.filtersItem(this.filterValue);
      // localStorage.removeItem('viewback');
      // localStorage.removeItem('filterArray');
      // this.selectedStates = [];
      // this.selectedStatus = this.filterValue;
      // this.selectcity = '';
      // this.selectedItems = [];
      // this.activeFilter = this.filterValue;
      // this.viewback = 'true'
      
      

    } 
    else {
      console.log('in else 1234 ---');
      this.listAllStartups();
    }
    // if(this.filterValue != null) {
   

    this.modalUpdateForm = this.fb.group({
      modalStartUpName: [""],
      modalDocumentName: [""],
    });

    
    this.dropdownList = [];
    // this.dropdownstates = states;
  
    this.selectedItems1 = [];
    this.filteredlist = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_t = {
      singleSelection: true,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };

    this.dropdownSettings_city = {

      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true

    };
    this.dropdownSettings_scheme = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    // this.exportData(this.startupdata)
    // const startupDataArray = this.startupdata1; // Array containing multiple startupData objects
    // this.exportData(startupDataArray);
    // pagination page redirection code
    this._route.queryParams.subscribe((params) => {
      // Use the 'page' parameter from the URL or default to 1
      this.p = +params['page'] || 1;
      // Load data for the current page
      this.loadData(this.p);
    });
     // pagination page redirection code

  }
   citiesData(){
    this.dropdowncities = Object.values(citiesOnly);
    console.log('dropdown_____', this.dropdowncities)
  
   }

   async loadStates() {
    try {
      const response = await this.cityservice.getDefaultState(this.countryid); // API call
      console.log('Fetched Response:', response);
   
     
      const states = response.result || [];
      // console.log('states 1234 ----',states);
   
      
      this.dropdownstatesapi = states.map((state: { name: any; id: any; }) => ({
        item_text: state.name,  
        item_id: state.id    
      }));
      console.log('hfhyfgh',this.dropdownstatesapi)
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  }

  async onStateSelect(event: any) {
    console.log('Selected State:', event);
    const selectedStateId = event.item_id; // Get state ID
  localStorage.setItem('selectedStateId', selectedStateId);

    try {
      const response = await this.cityservice.getCitiesByState(selectedStateId);
      console.log('Fetched Cities Response:', response);
  
      // Extract `result` array safely
      const cities = response.result || [];
  
      // Convert cities to expected format
      const newCities = cities.map((city: { id: any; name: any }) => ({
        item_id: city.id,
        item_text: city.name
      }));
  
      // Merge with existing cities (avoiding duplicates)
      this.selected_citiesapi = [...this.selected_citiesapi, ...newCities].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.item_id === value.item_id)
      );
  
      // Sort the updated list
      this.selected_citiesapi.sort((a, b) => a.item_text.localeCompare(b.item_text));
  
    } catch (error) {
      console.error(`Error fetching cities for state ID ${selectedStateId}:`, error);
    }
  }

  async onpageloadstateselect(val:any){

    try {
      console.log('val',val);
      const response = await this.cityservice.getCitiesByState(val);
      console.log('Fetched Cities Response:', response);
  
      // Extract `result` array safely
      const cities = response.result || [];
  
      // Convert cities to expected format
      const newCities = cities.map((city: { id: any; name: any }) => ({
        item_id: city.id,
        item_text: city.name
      }));
  
      // Merge with existing cities (avoiding duplicates)
      this.selected_citiesapi = [...this.selected_citiesapi, ...newCities].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.item_id === value.item_id)
      );
  
      // Sort the updated list
      // this.selected_citiesapi.sort((a, b) => a.item_text.localeCompare(b.item_text));
  
    } catch (error) {
      console.error(`Error fetching cities for state`, error);
    }
  }
  async backdeselect(val:any){
    this.cityservice.getCitiesByState(val).then(response => {
      const citiesToRemove = response.result.map(city => city.id);
  
      // Remove cities belonging to the deselected state
      this.selected_citiesapi = this.selected_citiesapi.filter(
        city => !citiesToRemove.includes(city.item_id)
      );
      this.selectcity = [];
  
    }).catch(error => {
      console.error(`Error fetching cities for deselected state ID ${val}:`, error);
    });
  
  }

  async onStateDeSelect(event: any) {
  

    if (this.viewback == 'true') {
      console.log('viewback3234')
          const backdeselectstateid=localStorage.getItem('selectedStateId')
          this.backdeselect(backdeselectstateid)
      
        } 
  else{
   
    console.log('Deselected State:', event);
    const deselectedStateId = event.item_id;
   
    // Fetch cities of the deselected state
    this.cityservice.getCitiesByState(deselectedStateId).then(response => {
      const citiesToRemove = response.result.map(city => city.id);
   
      // Remove cities belonging to the deselected state
      this.selected_citiesapi = this.selected_citiesapi.filter(
        city => !citiesToRemove.includes(city.item_id)
      );
      this.selectcity = [];
    }).catch(error => {
      console.error(`Error fetching cities for deselected state ID ${deselectedStateId}:`, error);
    });
  }}
   
  


  removeFilterQueryParam(): void {
 
    // Clone the current query parameters
    const updatedQueryParams = { ...this._route.snapshot.queryParams };
  
    // Remove 'filter' from the cloned object
    delete updatedQueryParams['filter'];
  
    // Navigate to the updated query parameters
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: updatedQueryParams, // Set updated params explicitly
      queryParamsHandling: undefined, // Ensure Angular fully replaces the params
    });
  
    console.log('Updated Query Params:', updatedQueryParams);
  }
  
  // schemeChanged(event) {
  //   this.loading = true;
  //   //this.filtersItem();
  //   console.log('event ----',event);
  //   console.log('myarray',this.myArray);
  //   this.myArray.push(
  //     {
  //       "name": "scheme_ids",
  //       "value": [
  //         event.id
  //       ]
  //     }
  //   )
  //   let payload = {
  //     "filters": this.myArray,
  //     "search_entity": "STARTUP",
  //     "page": 0,
  //     "page_size": 10
  //   }

  //   this.startupService.filterStartup(payload).subscribe((res: any) => {
  //     console.log("API Response:", res);
    
  //     this.data = res.results;
  //     // Update status counts
  //     this.approvedCount = res.approved_count ;
  //     this.rejectedCount = res.rejected_count ;
  //     this.pendingCount = res.pending_count;
  //             this.totalItems = +res.total_search_results_count;
  //       console.log('totalItems ----');
  //       console.log(this.totalItems);
  //       this.pagelinks = [];
  //     for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
  //       this.pagelinks.push(i);
  //   }
  //   console.log('filter_pages');
  //   console.log(this.pagelinks);
  //   this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
  //       this.onPageChange(1);
  //       this.loading = false;
  //   });

  //   this.startupService.getSchemesList(this.selectedScheme.map((scheme:any) => scheme.id)).subscribe(res => {
  //       this.loading = false;
  //       this.schemesData = res.results;
  //       //this.data = this.schemesData;
  //   });
  //   this.schemeSelected = true
  // }

schemeChanged(event) {
  this.loading = true;
  this.selectedindex = 0;
  if (this.paginationComponent) {
    this.paginationComponent.resetPageIndex();
  }
  console.log('event ----', event);
  console.log('myarray', this.myArray);
  this.myArray = this.myArray.filter(filter => filter.name !== "scheme_ids");

  this.myArray.push({
    "name": "scheme_ids",
    "value": [event.id]
  });

  let payload = {
    "filters": this.myArray,
    "search_entity": "STARTUP",
    "page": 0,
    "page_size": 10
  };

  // Parallel API calls using forkJoin
  forkJoin({
    startupData: this.startupService.filterStartup(payload),
    schemesList: this.startupService.getSchemesList(this.selectedScheme.map((scheme: any) => scheme.id))
  }).subscribe({
    next: (response: { startupData: any; schemesList: any }) => {  // Explicit typing
      console.log("API Response:", response.startupData);

      this.data = response.startupData.results;
      this.approvedCount = response.startupData.approved_count;
      this.rejectedCount = response.startupData.rejected_count;
      this.pendingCount = response.startupData.pending_count;
      this.totalItems = +response.startupData.total_search_results_count;

      console.log('totalItems ----', this.totalItems);

      this.pagelinks = [];
      for (let i = 1; i <= Math.ceil(this.totalItems / 10); i++) {
        this.pagelinks.push(i);
      }
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages;
      console.log('filter_pages', this.pagelinks);
      this.initial_pages = this.pagelinks.slice(this.page_index, this.page_index + 10);
      this.onPageChange(1);

      // Assign schemes data
      this.schemesData = response.schemesList.results;

      this.loading = false;
      this.schemeSelected = true;
    },
    error: (error) => {
      console.error("Error in API calls:", error);
      this.loading = false;
    }
  });
}

  ngOnDestroy(): void{
    // remove style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = ''; 
      htmlElement.style.position = '';
    }
    localStorage.removeItem('viewback');
    // sessionStorage.removeItem('schemeId')

    // this.dsFiltervalue = '';
    // this.dsAll = false;
    // this.dsApproved = false;
    // this.dsPending = false;
    // this.dsRejected = false
    
    
   
      // remove style for ui break on scroll
 }

//  pagination page redirection code
onPageChange(page: number): void {
  // Update the current page and navigate to the new URL
  this.p = page;
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: { page: page },
    queryParamsHandling: 'merge',
  });
 
  this.loadData(page);
}
private loadData(page: number): void {
  // Implement your logic to load data based on the page
  console.log('Loading data for page', page);
}
//  pagination page redirection code

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Startup Report','Startup-Data');
  }
  // state
  getStates() {
    this.statesservice.getallstates().subscribe((res: any) => {
      this.allStates = res.results;
      console.log("all states =>", this.allStates);
      this.dropdownstates = this.allStates
     
   
    });
  }
  // state

 

  listAllStartups() {
    console.log('hello from startups 122345');
    // this.activeFilter = null;

    var data;
    if(this.schemId ==null){
    // let data={
    //   search_entity: "STARTUP",
    //   "page": 0, "page_size": 10,
    // }
    data={
      search_entity: "STARTUP",
      "page": 0, "page_size": 10,
    }
  }
  else{
    this.activeFilter = ["APPROVED"]
    data={
      search_entity: "STARTUP",
      "page": 0, "page_size": 10,
      "filters": [
      {
          "name": "scheme_ids",
          "value": [
              this.schemId
          ]
      },
      {
        name: "status",
        value: ["APPROVED"] //  Adding the APPROVED filter
      }
      
  ],
    }
    this.filterValue = data.filters;
    this.myArray = data.filters;
    console.log('filterValue406',this.myArray);
  }
    this.loading = true;
    // this.isDownloading = true;
    this.startupService.getStartupsList(data).subscribe((res: any) => {
      this.data = res.results;
      this.StartupList=res.results;
      this.count = +res.total_search_results_count;
      // this.approved_count = res.approved_count;
      // this.rejected_count = res.rejected_count;
      // this.pending_count = res.pending_count;
      this.originalData = this.data;
      // this.updateStatusCounts(res)
      this.approvedCount = res.approved_count ;
      this.rejectedCount = res.rejected_count ;
      this.pendingCount = res.pending_count;
      this.totalItems = +res.total_search_results_count;
      console.log('totalItems1111 ----',this.totalItems);
      this.pagelinks = [];
      for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
        this.pagelinks.push(i);
    }

      // Example dynamic pagination generation
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages; // Replace with your dynamic value
    this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
    // var paginationList = document.getElementById('pagination-list');
    // var paginationHTML = '';

    // Display first 10 pages
    // for (var i = 1; i <= Math.min(totalPages, 10); i++) {
    //     this.initial_pages.push(i);
    //     //paginationHTML += `<li class="mr-2"><a class="paginationlink" id = "paginate_${i}"> ${i} </a></li>`;
    // }
    // Add ellipsis if there are more pages
    if (totalPages > 10) {
        this.dots = '...'
        //paginationHTML += '<li class="mr-2"><span>...</span></li>';
    }

    // Display next set of pages after ellipsis
    for (var i = Math.max(11, totalPages - 10 + 1); i <= totalPages; i++) {
        this.remaining_pages.push(i);
        //paginationHTML += `<li class="mr-2"><a > ${i} </a></li>`;
    }

    // paginationList.innerHTML = paginationHTML;
        // Update status counts
        //this.updateStatusCounts(this.originalData);
        // this.isDownloading = false;
        this.loading = false;
        this.onPageChange(1) 

    });
  }

  async listAllStartupsforDownload() {

    return new Promise((resolve,reject)=>{
      let data= {
        query:this.searchText,
        filters: this.myArray,
        search_entity: "STARTUP",
        "page": 0, "page_size": 10000
  
      }
      // this.isDownloading = true;

      // this.loading = true;
      this.startupService.getStartupsList(data).subscribe((res: any) => {
        this.datafordownload = res.results;
        resolve(this.datafordownload);
      });
    })
  }
  ngAfterViewInit(){
    document.querySelectorAll('.paginationlink').forEach((element , i)=>{
    })
  }

  paginate_startup(pageno) {
    this.pageno = pageno
    console.log('pageno----',this.pageno);
    this.selectedindex = pageno-1;
    console.log('selectedindex-----',this.selectedindex)
    console.log('myarray ----',this.myArray);
    let data={
      query:this.searchText,
        filters: this.myArray,
        search_entity: "STARTUP", 
        "page": pageno-1, "page_size": 10,
      }
      // this.isDownloading = true;

    
    console.log('data_payload',data);
    this.loading = true;
    this.startupService.getStartupsList(data).subscribe((res)=>{
      this.data = res.results
      console.log('data----');
      console.log(this.data);
      this.loading = false;
      // this.isDownloading = true;

    })
  }
  getCountsByStatus(startups) {
    const statusCounts = {};

    startups.forEach((startup) => {
      const status = startup.status;

      if (status) {
        if (statusCounts.hasOwnProperty(status)) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      }
    });
   

    return statusCounts;
  }
  // updateStatusCounts(data) {
  //   const statusCounts = this.getCountsByStatus(data);  

  //   // Separate the counts
  //   this.approvedCount = statusCounts["APPROVED"] || 0;
  //   this.rejectedCount = statusCounts["REJECTED"] || 0;
  //   this.pendingCount = statusCounts["PENDING"] || 0;
  // }
  // updateStatusCounts(res) {
  //   this.approvedCount = res.approved_count || 0;
  //   this.rejectedCount = res.rejected_count || 0;
  //   this.pendingCount = res.pending_count || 0;
  
  //   const statusCount = {
  //     approvedData: this.approvedCount,
  //     rejectedData: this.rejectedCount,
  //     pendingData: this.pendingCount
  //   };
  
  //   localStorage.setItem('countdata', JSON.stringify(statusCount));
  // }

  async downloadStartupList(){
    this.isDownloading = true;  // Start loader

    if(this.schemeSelected) {
        console.log('in if download');
        this.exportScheme(this.schemesData);
    } else {
        console.log('in else download');
        // const startupDataArray = this.originalData; // Array containing multiple startupData objects
        let downloaddata = await this.listAllStartupsforDownload();
        this.exportData(downloaddata);
        // this.exportAsXLSX(this.data)
    }
    this.isDownloading = false;  // Stop loader after process

  }
    exportScheme(dataArray:any[]) {
    const invalid = [undefined,null,'null',''];
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };

  
  const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
 
  const filename = `StartupReport_${formattedDate}.xlsx`;

    const dataToExport = dataArray.map((d) => ({    
        'Startup name': d.name,
         'Schemes': d.entitytype,
        'Founder name' :d.foundername,
               'Phone': d.mobile,
        'Founder email': d.email,
        'Login email':d.loginemail,
        'Supported agency': d.supportedagency,
        'Supported agency website': d.supportedagencywebsite,
        entitytype: d.entitytype,
        'Entity Type': d.entityname,
        Domain: d.domain ? (Array.isArray(d.domain) ? d.domain.join(', ') : d.domain) : '-',
      City: d.city ? d.city : '-',
      State:d.state ? d.state : '-',
      Country:d.country ? d.country : '-',
      Status: d.status ? d.status : '-',
      Stage : d?.startup_stage ? d?.startup_stage : '-',
       'Suppoted Agency website': d.supportedagencywebsite,
        'Availed MSH Scheme':d.is_availed_scheme ? 'yes' : 'No',
        Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
        'Company Logo': d.profile_image && d.profile_image[0]?.url ? d.profile_image[0].url : '-',
    
     'First Name':d.first_name ?d.first_name:'-' ,
    'Last Name':d.last_name ? d.last_name :'-',
    
       'Zip code' :d.zipcode? d.zipcode :'-',
      'Mobile 1':d.mobile? d.mobile :'-',
      'Mobile 2':d.contact_info.mobile2? d.contact_info.mobile2 :'-',
   

      'Website Url':d.website_url ? d.website_url : '-',
      Description: d.short_description ? d.short_description.slice(0, 1000) : '-',
      
    
   
       'Part of which scheme': d.entitytype ? d.entitytype: '-',
       'Name of the Agenecy': d.supportedagency ? d.supportedagency:'-',
       
      'CIN No':d.cin_no ? d.cin_no : '-',
      'Funding Status':d.funding_status ? d.funding_status:'-',

      'Funding Agency Name':invalid.includes(d.funding_organization_name)? '-' : d.funding_organization_name,
      'Funding Amount(₹)(in Lakhs)':d.funding_amount ? d.funding_amount : '-',
      'Documents': d.documents ? (Array.isArray(d.documents) ? d.documents.map(info => `${info.file_name} URL: ${info.url}`).join('\n') : '-') : '-',
    
      'Market Product Name': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.productName}`).join('\n') : '-',
      'Market Product Detail': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.productDetail}`).join('\n') : '-',
      'Market Product CIN': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.cinNumber}`).join('\n') : '-',
     
    'Opportunity Name': Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `Opportunity Name: ${info.opportunity_Name}`).join('\n') : '-',
'Opportunity Details': Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `Opportunity Name: ${info.opportunity_Detail.slice(0, 1000)}`).join('\n') : '-',
      

     
      'Round Date': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Date}`).join('\n') : '-',
      'Round Amount': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Amount}`).join('\n') : '-',
      'Round Details': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Details.slice(0, 1000)}`).join('\n') : '-',
      'Round Valuation': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Valuation.slice(0, 1000)}`).join('\n') : '-',
      
      'Patents': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.patents}`).join('\n') : '-',
      'Copyrights': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.copyRights}`).join('\n') : '-',
      'Trademarks': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.tradeMarks}`).join('\n') : '-',
      'Trade Secrets': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.tradeSecrets}`).join('\n') : '-',

      'CEO Name': d.ceorname ? d.ceorname:'-',
      'CEO Mobile':d.ceophoneno ? d.ceophoneno :'-' , 
      'CEO Email': d.ceoemail? d.ceoemail: '-' ,
      'CEO Experience': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_exp : '-') : '-',
      'CEO LinkedIn': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).linkedinProfile : '-') : '-',
      
      'Team Size': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.filter(info => info.team_meb_designation !== 'CEO').length : '-',
      'Team Details': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.filter(info => info.team_meb_designation !== 'CEO').map(info => `Name: ${info.team_meb_name}, Mobile: ${info.team_meb_mob}, Email: ${info.team_meb_email}, Experience: ${info.team_meb_exp},Designation: ${info.team_meb_designation},Linkedin_Profile: ${info.linkedinProfile}`).join('\n') : '-', //
 
      Facebook: d?.social_info?.facebook ? d.social_info.facebook : '-',
      Instagram: d?.social_info?.instagram ? d.social_info.instagram : '-',
      Twitter: d?.social_info?.twitter ? d.social_info.twitter : '-',
      LinkedIn: d?.social_info?.linkedIn ? d.social_info.linkedIn : '-',
      Youtube: d?.social_info?.youtube ? d.social_info.youtube : '-',
      
      
      'Created At': d?.created_at ? new Date(d?.created_at) : '-',
      'Updated At ':d?.updated_at ?new Date(d.updated_at) : '-',
      'Last Login Date':d?.updated_at ?new Date(d.updated_at) : '-',

      'Last Login Time':d?.updated_at? formatTime(new Date(d.updated_at)):'-',
      'Approved By':d.approved_by ? d.approved_by : '-',
   

   
     
    })).reduce(function(r,a) {
        r[a.entitytype] = r[a.entitytype] || [];
        r[a.entitytype].push(a);
        r[a.entitytype].map(item => {
            delete item.entitytype;
            return item;
        })
        return r;
    }, Object.create(null));

    this.excelService.exportExcelFileWithSheet(dataToExport,filename);
  }

  
  
//   exportData(dataArray: any[]) {    
//     const data = dataArray.map((d) => ({
//       Name: d.name,
//       Domain: d.domain,
//       Sector: d.sector,
//       City: d.city,
//       State:d.state,
//       Country:d.country,
//       websiteUrl:d.website_url,
//       Description: d.short_description,
//       Status: d.status,
//       ApprovedBy:d.approved_by,
//       CinNo:d.cin_no,
//       FundinAgencyName:d.funding_organization_name,
//       FundingAmount:d.funding_amount,
//       GoToMarket:Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `Product Name= ${info.productName},Product Detail= ${info.productDetail},Cin Number=${info.cinNumber}`).join(', ') : '',
//       OpportunityDetails:Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `OpportunityName=${info.opportunity_Name},OpportunityDetail= ${info.opportunity_Detail}`).join(', ') : '',
//       FunddingRounds:Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `Round Date=${info.round_Date},Round Detail= ${info.round_Details},Round Amount=${info.round_Amount},Round Valuation= ${info.round_Valuation}`).join(', ') : '',
//       IntellectualProperty:Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `Patents=${info.patents},Copyrights= ${info.copyRights},TradeMarks=${info.tradeMarks},Trade Secreats= ${info.tradeSecrets}`).join(', ') : '',
//       TeamDetails:Array.isArray(d?.team_info?.team_info) ? d?.team_info?.team_info.map(info => `Name=${info.team_meb_name},Mobile= ${info.team_meb_mob},Email=${info.team_meb_email},Experience= ${info.team_meb_exp},Description=${info.team_meb_designation},Linkedin Profile= ${info.linkedinProfile}`).join(', ') : '',      
// //       SocialInfo: [
// //   d?.social_info?.facebook ? `Facebook=${d.social_info.facebook}` : '',
// //   d?.social_info?.instagram ? `Instagram=${d.social_info.instagram}` : '',
// //   d?.social_info?.twitter ? `Twitter=${d.social_info.twitter}` : '',
// //   d?.social_info?.linkedIn ? `LinkedIn=${d.social_info.linkedIn}` : '',
// //   d?.social_info?.youtube ? `Youtube=${d.social_info.youtube}` : '',
// //   d?.social_info?.koo ? `Koo=${d.social_info.koo}` : ''
// // ]
// //   .filter(item => item !== '') // Exclude empty items
// //   .join(', '),
//       SocialInfo: d?.social_info ? `Facebook=${d.social_info.facebook}, Instagram=${d.social_info.instagram},Twitter=${d.social_info.twitter},LinkedIn=${d.social_info.linkedIn},Youtube=${d.social_info.youtube},Koo=${d.social_info.koo}` : '',
//       'Profile Email': d?.contact_info?.email,
//       'Login Email':d?.login_email,
//       'Updated_at':new Date(d?.updated_at),
      
     
//     }));
//     this.exportAsXLSX(data)
//     // this.generateExcelFile(data, "myData.xlsx");
//     console.log("Export:", data);
//   }
  
exportData(dataArray: any) { 
  const invalid = [undefined,null,'null',''];
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };
  const data = dataArray.map((d) => {
    // for(let i = 0; i < dataArray.length; i++){
    //   console.log( i, 'CEODetail', d?.team_info.teamInfo.filter(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ));
    // }
    // var CEODetails = d?.team_info?.teamInfo.filter(item => item?.team_meb_designation == "CEO" || item?.team_meb_designation == "ceo");
    
    const result: any = {
      // link: 'https://google.com',
      Name: d.name ? d.name : '-',
      Domain: d.domain ? (Array.isArray(d.domain) ? d.domain.join(', ') : d.domain) : '-',
      Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
      'Company Logo': d.profile_image && d.profile_image[0]?.url ? d.profile_image[0].url : '-',
    
     'First Name':d.contact_info.name ?d.contact_info.name:'-' ,
     'Last Name':d.contact_info.lastName ? d.contact_info.lastName :'-',
      City: d.city ? d.city : '-',
      State:d.state ? d.state : '-',
      Country:d.country ? d.country : '-',
      'Zip code' :d.contact_info.zipCode? d.contact_info.zipCode :'-',
      'Mobile 1':d.contact_info.mobile? d.contact_info.mobile :'-',
      'Mobile 2':d.contact_info.mobile2? d.contact_info.mobile2 :'-',

      'Website Url':d.website_url ? d.website_url : '-',
      Description: d.short_description ? d.short_description.slice(0, 1000) : '-',
      Status: d.status ? d.status : '-',
      // 'Approved By':d.approved_by ? d.approved_by : '-',
      Stage : d?.startup_stage ? d?.startup_stage : '-',
       'Part of which scheme': d.scheme_names ? d.scheme_names.join(',') : '-',
       'Name of the Agenecy': d.agency_names ? d.agency_names.join(','):'-',
        'Availed MSH Scheme':d.is_availed_scheme ? 'yes' : 'No',
      'CIN No':d.cin_no ? d.cin_no : '-',
      'Funding Status':d.funding_status ? d.funding_status:'-',

      'Funding Agency Name':invalid.includes(d.funding_organization_name)? '-' : d.funding_organization_name,
      'Funding Amount(₹)(in Lakhs)':d.funding_amount ? d.funding_amount : '-',
      'Documents': d.documents ? (Array.isArray(d.documents) ? d.documents.map(info => `${info.file_name} URL: ${info.url}`).join('\n') : '-') : '-',
      // GoToMarket:Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `Product Name= ${info.productName},Product Detail= ${info.productDetail},Cin Number=${info.cinNumber}`).join(', ') : '-',
      'Market Product Name': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.productName}`).join('\n') : '-',
      'Market Product Detail': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.productDetail}`).join('\n') : '-',
      'Market Product CIN': Array.isArray(d?.go_to_market_info?.go_to_market_info) ? d?.go_to_market_info?.go_to_market_info.map(info => `${info.cinNumber}`).join('\n') : '-',
      // 'Opportunity Details':Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `OpportunityName=${info.opportunity_Name},OpportunityDetail= ${info.opportunity_Detail}`).join('\n') : '-',
      'Opportunity Name': Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `Opportunity Name: ${info.opportunity_Name}`).join('\n') : '-',
      'Opportunity Details': Array.isArray(d?.opportunity_info?.opportunity_info) ? d?.opportunity_info?.opportunity_info.map(info => `Opportunity Name: ${info.opportunity_Detail.slice(0, 1000)}`).join('\n') : '-',
      // 'Funding Rounds':Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `Round Date=${info.round_Date},Round Detail= ${info.round_Details.slice(0, 1000)},Round Amount=${info.round_Amount},Round Valuation= ${info.round_Valuation}`).join('\n') : '-',
      'Round Date': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Date}`).join('\n') : '-',
      'Round Amount': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Amount}`).join('\n') : '-',
      'Round Details': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Details.slice(0, 1000)}`).join('\n') : '-',
      'Round Valuation': Array.isArray(d?.funding_info?.funding_info) ? d?.funding_info?.funding_info.map(info => `${info.round_Valuation.slice(0, 1000)}`).join('\n') : '-',
      // 'Intellectual Property':Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `Patents=${info.patents},Copyrights= ${info.copyRights},TradeMarks=${info.tradeMarks},Trade Secreats= ${info.tradeSecrets}`).join('\n') : '-',
      'Patents': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.patents}`).join('\n') : '-',
      'Copyrights': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.copyRights}`).join('\n') : '-',
      'Trademarks': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.tradeMarks}`).join('\n') : '-',
      'Trade Secrets': Array.isArray(d?.intellectual_property_info?.intellectual_property_info) ? d?.intellectual_property_info?.intellectual_property_info.map(info => `${info.tradeSecrets}`).join('\n') : '-',

      'CEO Name': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_name : '-') : '-',
      'CEO Mobile': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_mob : '-') : '-',
      'CEO Email': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_email : '-') : '-',
      'CEO Experience': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).team_meb_exp : '-') : '-',
      'CEO LinkedIn': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.find(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ).linkedinProfile : '-') : '-',
      // 'CEO Name': d?.team_info?.teamInfo ? (d?.team_info.teamInfo.filter(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) ? d?.team_info.teamInfo.filter(item => item.team_meb_designation === "CEO" || item.team_meb_designation === "ceo" ) : '-') : '-',
      'Team Size': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.filter(info => info.team_meb_designation !== 'CEO').length : '-',
      'Team Details': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.filter(info => info.team_meb_designation !== 'CEO').map(info => `Name: ${info.team_meb_name}, Mobile: ${info.team_meb_mob}, Email: ${info.team_meb_email}, Experience: ${info.team_meb_exp},Designation: ${info.team_meb_designation},Linkedin_Profile: ${info.linkedinProfile}`).join('\n') : '-', //
      // 'Team Member Name': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Name: ${info.team_meb_name}`).join('\n') : '-',
      // 'Team Member Mobile': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Mobile: ${info.team_meb_mob}`).join('\n') : '-',
      // 'Team Member Email': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Email: ${info.team_meb_email}`).join('\n') : '-',
      // 'Team Member Experience': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Experience: ${info.team_meb_exp}`).join('\n') : '-',
      // 'Team Member Designation': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `Designation: ${info.team_meb_designation}`).join('\n') : '-',
      // 'Team Member LinkedIn': Array.isArray(d?.team_info?.teamInfo) ? d?.team_info?.teamInfo.map(info => `LinkedIn: ${info.linkedinProfile}`).join('\n') : '-',
//       SocialInfo: [
//   d?.social_info?.facebook ? `Facebook=${d.social_info.facebook}` : '',
//   d?.social_info?.instagram ? `Instagram=${d.social_info.instagram}` : '',
//   d?.social_info?.twitter ? `Twitter=${d.social_info.twitter}` : '',
//   d?.social_info?.linkedIn ? `LinkedIn=${d.social_info.linkedIn}` : '',
//   d?.social_info?.youtube ? `Youtube=${d.social_info.youtube}` : '',
//   d?.social_info?.koo ? `Koo=${d.social_info.koo}` : ''
// ]
//   .filter(item => item !== '') // Exclude empty items
//   .join(', '),
      // 'Social Info': d?.social_info ? `Facebook=${d.social_info.facebook ? d.social_info.facebook : '- '}\n Instagram=${d.social_info.instagram ? d.social_info.instagram : '-'}\n Twitter=${d.social_info.twitter ? d.social_info.twitter : '-'}\n LinkedIn=${d.social_info.linkedIn ? d.social_info.linkedIn : '-'}\n Youtube=${d.social_info.youtube ? d.social_info.youtube : '-'}\nKoo=${d.social_info.koo ? d.social_info.koo : '-'}` : '-',
      Facebook: d?.social_info?.facebook ? d.social_info.facebook : '-',
      Instagram: d?.social_info?.instagram ? d.social_info.instagram : '-',
      Twitter: d?.social_info?.twitter ? d.social_info.twitter : '-',
      LinkedIn: d?.social_info?.linkedIn ? d.social_info.linkedIn : '-',
      Youtube: d?.social_info?.youtube ? d.social_info.youtube : '-',
      // Koo: d?.social_info?.koo ? d.social_info.koo : '-',
      // 'Profile Email': d?.contact_info?.email,
      'Login Email':d?.login_email ?  d?.login_email : '-',
      'Created At': d?.created_at ? new Date(d?.created_at) : '-',
      // 'Updated At':new Date(d?.updated_at),
      'Last Login Date':d?.login_info ? formatDate(new Date(d.login_info.last_login_time) ) : '-',
      'Last Login Time':d?.login_info ? formatTime(new Date(d.login_info.last_login_time)):'-'
    };
    if (this.activeFilter === ['APPROVED']) {
      result.ApprovedBy = d.approved_by ? d.approved_by : '-';
    }else if(this.activeFilter === ['REJECTED']){
      result.RejectedBy = d.approved_by ? d.approved_by : '-';
    }else if(this.activeFilter !== ['PENDING']){
      result['Approved/Rejected By'] = d.approved_by ? d.approved_by : '-';
    }
    return result;
  });
  this.exportAsXLSX(data);
  // this.generateExcelFile(data, "myData.xlsx");
  console.log("Export:", data);
  
}


 
  downloadFile(d) {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Applications",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "applications",
      headers: ["Name", "Domain", "Sector", "Location", "Description", "Stage"],
    };
    const csvdata = [
      {
        Name: d.name,
        Domain: d.domain,
        City: d.city,
        Sector: d.sector,
        Description: d.short_description,
        Stage: d.startup_stage,
      },
    ];
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvdata);
  }

  editData(data) {
    this.data = data;
    console.log("edit statupDATA:", this.data);
    this.modalUpdateForm.controls["modalStartUpName"].setValue(data.name);
  }
  updatestartupModel(id) {
    let data = {};
    this.startupService.editStartup(data, id).subscribe(
      (res: any) => {
        console.log("statSucces:", res);
        this.toastrService.success("Data Updated Successfully!");
      },
      (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update records!");
      }
    );
  }


  
  changestatus(id: string, value: string) {
    // localStorage.setItem('myData', 'Startup');
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = '0'; 
      htmlElement.style.position = 'sticky';
    }
    this.mat_data = value;
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Startup' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              status: value,
            };
            this.startupService.actionStartup(data, id).subscribe(
              (res: any) => {
                console.log("change_status", res);
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
                const updatedStartupIndex = this.data.findIndex(startup => startup.id === id);
              if (updatedStartupIndex !== -1) {
                this.data[updatedStartupIndex].status = value;
                 this.data[updatedStartupIndex].approved_by = res?.results[0]?.approved_by || ''
              }
              },
              (error) => {
                this.inValidMessage = true;
                this.toastrService.warning("Unable to Update Status!");
              }
            );
          }
        })
  }

  getDomains(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.challengeService.getalldomains().subscribe(
        (res: any) => {
          this.alldomains = res.results;
          let data = [];
  
          for (let i = 0; i < this.alldomains.length; i++) {
            data.push({
              item_id: i + 1,
              item_text: this.alldomains[i].name,
            });
          }
  
          this.dropdownList = [...data];
          this.groupbydomains = _.groupBy(this.alldomains, "name");
  
          console.log("group domains", this.groupbydomains);
          resolve(); // Resolves once `groupbydomains` is set
        },
        (error) => {
          console.error("Error fetching domains", error);
          reject(error);
        }
      );
    });
  }
  
  async onItemSelectDomain(event) {
    await this.groupbysectors(event);
    console.log("sectors", this.sectors);
    for (var i = 0; i < this.sectors.length; i++) {
      this.tempdata.push({
        item_id: i + 1,
        item_text: this.sectors[i].name,
      });
    }
    this.dropdownList1 = [...this.tempdata];
    console.log("sectors_dropdown", this.dropdownList1);
  }

  async onItemDeSelectDomain(event) {
    console.log("event", event);
  }
  async groupbysectors(event) {
    if (!this.groupbydomains) {
      console.log("groupbydomains is undefined, fetching data...");
      await this.getDomains(); // Wait until data is loaded
    }
  
    console.log("groupbydomains after fetching ----", this.groupbydomains);
  
    const sectors = this.groupbydomains?.[event.item_text]?.[0]?.sectors;
    if (!sectors) {
      console.error("Sectors data not found for", event.item_text);
      return Promise.reject("Sectors data not found");
    }
  
    this.challengeService.setSectorData(sectors);
    this.sectors.push(...sectors);
  
    return this.sectors; // No need for explicit Promise wrapping
  }
  

  
  countAllFilter()
  {
    this.activeFilter = null;
    localStorage.removeItem('status');
    this.onPageChange(1) 
    this.resetFilters()
    this.listAllStartups();
    //  this.updateStatusCounts(this.data);
  }

  

  
  preview(d) {
    console.log("pCheck::", d,this.selectedindex);
    // if(this.filterValue1?.length > 0)
    // {
    //   localStorage.setItem('status',this.filterValue1)
    //   console.log("filte value is ==> ", this.filterValue1)
    // }
  
      this.filterValue = d;
      if(this.searchText&&this.searchText!==null){
        localStorage.setItem('query',JSON.stringify(this.searchText));
      }else{
        localStorage.removeItem('query');
      }
      if (this.filterValue1 && this.filterValue1.length > 0) {
          localStorage.setItem('status', JSON.stringify(this.filterValue1));
          this.activeFilter = this.filterValue;
  
          const statusFilter = { name: "status", value: this.filterValue1 };
          this.myArray = this.myArray.filter(item => item.name !== "status"); // Remove existing status filter
          this.myArray.push(statusFilter);
      } else {
          localStorage.removeItem('status');
      }
      if (this.myArray?.length > 0) {
        localStorage.setItem('filterArray', JSON.stringify(this.myArray));
    } else {
        localStorage.removeItem('filterArray');  // ✅ Remove completely if empty
    }
    if (this.selectedScheme && this.selectedScheme.length > 0) {
      localStorage.setItem('selectedSchemes', JSON.stringify(this.selectedScheme));

      const schemeFilter = { name: "schemes", value: this.selectedScheme };
      this.myArray = this.myArray.filter(item => item.name !== "schemes"); // Remove duplicates
      this.myArray.push(schemeFilter);
  } else {
      localStorage.removeItem('selectedSchemes');
  }

  
    // const jsonString = JSON.stringify(this.myArray);
    // localStorage.setItem('filterArray', jsonString); 
    // if (this.selectedScheme && this.selectedScheme.length > 0) {
    //   localStorage.setItem('selectedSchemes', JSON.stringify(this.selectedScheme));
    // }
    localStorage.setItem('selectedindex',this.selectedindex.toString())
    this.router.navigateByUrl(`/admin/pdftemplate/${d}`);

  }
  async backfilter(){
    if(this.viewback == 'true')
    {
      // if(localStorage.getItem('filterArray')!=null || localStorage.getItem('filterArray')!=undefined){
        const storedValue = localStorage.getItem('query');
        // this.searchText= storedValue;
        this.searchText = storedValue ? JSON.parse(storedValue) : ''; 
      const getArray = localStorage.getItem('filterArray');
        console.log(getArray,'getArray000')
        this.myArray = getArray ? JSON.parse(getArray) : [];
        // this.myArray = parsedArray;
        const stateValue = this.myArray.find(item => item.name === 'state')?.value;
        const statusValue = this.myArray.find(item => item.name === 'status')?.value;
        const cityvalue = this.myArray.find(item => item.name === 'city')?.value;
        const domainvalue = this.myArray.find(item => item.name === 'domain')?.value;
        console.log('domain_back ----',domainvalue);
        const storedSchemes = localStorage.getItem('selectedSchemes');
        this.selectedScheme = storedSchemes ? JSON.parse(storedSchemes) : [];

        this.selectstate = stateValue;
        this.selectstatus = statusValue;
        this.selectcity = cityvalue
        // this.selectdomain = domainvalue;
        for(var i = 0 ; i < domainvalue?.length ; i++){
          this.selectdomain.push({
            'item_text':domainvalue[i]
          })
        }
        console.log('selected_domain_back',this.selectdomain);
      // }
     
    }
   
    const storedFilters = localStorage.getItem('filterArray');
    const stateId=localStorage.getItem('selectedStateId')
    console.log('stored',storedFilters);
    if (storedFilters) {
        console.log('✅ Stored filters found');
        this.myArray = JSON.parse(storedFilters);
    
        console.log('⚡ Calling onpageloadstateselect()');
        await this.onpageloadstateselect(stateId);
      }
    if (localStorage.getItem('status')) {
      this.activeFilter = JSON.parse(localStorage.getItem('status'));
  } else if (this.schemId) {
      this.activeFilter = ["APPROVED"];  // If scheme is selected, default to APPROVED
  } else {
      this.activeFilter = ['APPROVED','PENDING','REJECTED']; // Reset if no filters
  }

  if (this.selectstatus && this.selectstatus.length > 0) {
    // this.activeFilter = this.selectstatus[0]; // Set active filter based on restored status
    this.activeFilter = this.myArray.find(item => item.name === 'status')?.value || ['APPROVED', 'PENDING', 'REJECTED'];

  }
      // if(localStorage.getItem('status'))
      // {
      //   this.activeFilter = localStorage.getItem('status');
      // }
      if (this.selectedScheme.length > 0) {
        const schemeFilter = { name: "scheme_ids", value: this.selectedScheme.map(s => s.id) };
        this.myArray = this.myArray.filter(item => item.name !== "scheme_ids"); // Remove old scheme filters
        this.myArray.push(schemeFilter);
    }
    if (this.schemId) {
      const schemeFilter = { name: "scheme_ids", value: [this.schemId] };
      const statusFilter = { name: "status", value: ["APPROVED"] };
      this.activeFilter = ["APPROVED"]
      this.myArray = this.myArray.filter(item => item.name !== "scheme_ids" && item.name !== "status");
      this.myArray.push(schemeFilter,statusFilter);
  
      localStorage.setItem('filterArray', JSON.stringify(this.myArray));
  }
     
  console.log("Restored Filters:", this.myArray);
  this.selectedindex  = Number(localStorage.getItem('selectedindex'))
const pageno = this.selectedindex
    let data = {
      query:this.searchText,
      filters: this.myArray,
      search_entity: "STARTUP",
      "page": pageno, "page_size": 10

    };

   
    console.log("data____0603", data,this.selectedindex);
   
   
      this.startupService.filterStartup(data).subscribe((res: any) => {
        // console.log("res myarray___0603:", res);
        this.data = res.results;       
        this.approvedCount = res.approved_count ?? this.approvedCount;
        this.rejectedCount = res.rejected_count ?? this.rejectedCount;
        this.pendingCount = res.pending_count ?? this.pendingCount;
  
        this.totalItems = +res.total_search_results_count;
        for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
          this.pagelinks.push(i);
      }
  
        // Example dynamic pagination generation
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages; // Replace with your dynamic value
      this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
        // this.updateStatusCounts(this.data);
        this.loading = false;
      });
     
    
  }

  resetFilters() {
    this.schemId = null;
    sessionStorage.removeItem('startupSchemeId');
    this.resetMode = false;
    this.data = {};
    this.selectcity = "";
    this.selectstate = "";
    this.selectdomain = "";
    this.selectstatus = "";
    this.schemeSelected = false;
    this.selectedScheme = {}
    this.selectedStates = [];
    this.selectedStatus = [];
    this.selectedScheme = [];
    this.selectedindex = 0;
    if (this.paginationComponent) {
      this.paginationComponent.resetPageIndex();
    }
    localStorage.removeItem('selectedSchemes'); 
    localStorage.removeItem('filterArray');
    localStorage.removeItem('status');
    localStorage.removeItem('viewback')
    localStorage.removeItem('selectedStateId')
     
    this.activeFilter = ['APPROVED','PENDING','REJECTED'];
    this.viewback = 'false';
    this.removeFilterQueryParam();
    this.myArray = [];
    this.listAllStartups();
    this.filterValue = null;
    setTimeout(() => {
      this.searchText = ''; // ✅ Ensure UI updates properly
    });
  }
  getNextSet() {
    console.log('page_next ----',this.pageno);
    this.page_index = this.page_index + 10;
    console.log('page_index ----',this.page_index);
    this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10);
    console.log('initial_pages',this.initial_pages);
  }

  getPrevSet() {
    console.log('page no ----',this.pageno);
    if(this.page_index >= 10) {
      this.page_index = this.page_index - 10;
      this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10);
    }
    else {
      return;
    }
  }


  getSerialNo(i:number) {
    return i+(this.selectedindex*10);
  }

  // filtersItem(filterValue?:any) {
  //   console.log('filtervalue ----',filterValue);
  //   this.page_index = 0;
  //   this.selectedindex = 0;
  //   console.log("Filter Value:", filterValue);
  // // this.filterMode = false;
  //   this.resetMode = true;
  //   this.myArray = [];
  //   this.p = 1;
  //   var domains=[]
  
  //   if(this.selectdomain && this.selectdomain.length > 0)
  //       {
  //         for (var i = 0; i < this.selectdomain.length; i++) {
  //           domains.push(this.selectdomain[i].item_text);
  //         }
  //         if (this.selectdomain.length != 0) {
  //           this.myArray.push({
  //             name: "domain",
  //             value: domains,
  //           });
  //         }
  //       }
       
    
        
  //       if (this.selectcity && this.selectcity.length > 0) {
  //         this.myArray.push({
  //           name: 'city',
  //           value: this.selectcity
  //         });
        
        
  //         // console.log("selectcity:",this.myArray);
  //       }
  //       if (this.selectstate && this.selectstate.length > 0 ){
  //         this.myArray.push({
  //           name: 'state',
  //           value: this.selectstate
  //         })
  //       }
  //       console.log('domain_select',this.selectdomain);
       
    
    
  //       var schemes = [];
  //       if(this.selectedScheme!=undefined){
  //         for(var i = 0 ; i < this.selectedScheme.length ; i++){
  //           schemes.push(this.selectedScheme[i].item_id);
  //         }
  //         console.log('scheme', schemes);
  //       }
    
  //       if(schemes.length !=0){
  //         this.myArray.push({
  //           name: 'scheme_ids',
  //           value: this.selectedScheme.map(item => item.id)
  //         })
  //       }
  //         console.log("selectscheme:",this.myArray);
  
  //         if (filterValue) {//whatever i select
  //           this.activeFilter = filterValue === "ALL" ? null : filterValue;
  //           this.selectstatus = filterValue === "ALL" ? ["APPROVED", "PENDING", "DECLINED"] : [filterValue];
  //         }
  
  //   if (this.selectstatus && this.selectstatus.length > 0) {
  //     this.myArray.push({ name: "status", value: this.selectstatus 
        
  //     });
  //     this.activeFilter = this.selectstatus;
  //   }
  
  
  //   console.log("Filters Applied:", this.myArray);
  
  //   let requestData = {
  //     filters: this.myArray,
  //     search_entity: "STARTUP",
  //     page: 0,
  //     page_size: 10,
  //   };
  
  //   if(filterValue == null){
  //     // this.filterMode=true;
  
  //   this.startupService.filterStartup(requestData).subscribe((res: any) => {
  //     console.log("API Response:", res);
    
  //     this.data = res.results;
  //     // Update status counts
  //     this.approvedCount = res.approved_count ;
  //     this.rejectedCount = res.rejected_count ;
  //     this.pendingCount = res.pending_count;
  //             this.totalItems = +res.total_search_results_count;
  //       console.log('totalItems ----');
  //       console.log(this.totalItems);
  //       this.pagelinks = [];
  //     for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
  //       this.pagelinks.push(i);
  //   }
  //   console.log('filter_pages');
  //   console.log(this.pagelinks);
  //   this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
  //       this.onPageChange(1);

  //   });
  
  // }
  // else
  // {
    
  //   if (!this.myArray.some(item => item.name === 'status')) {
  //     this.myArray.push({ name: "status", value: Array.isArray(filterValue) ? filterValue : [filterValue] });
  //   }
    
  //   let requestData = {
  //     filters: this.myArray,
  //     search_entity: "STARTUP",
  //     page: 0,
  //     page_size: 10,
  //   };
  //   this.startupService.filterStartup(requestData).subscribe((res: any) => {
  //     this.data = res.results;
  
  //     this.loading = false;
  //     // Update status counts
  //     this.approvedCount = res.approved_count;
  //     this.rejectedCount = res.rejected_count;
  //     this.pendingCount = res.pending_count;
  //     this.loading = false;
  //         this.totalItems = +res.total_search_results_count;
  //         this.pagelinks = [];
  //         for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
  //           this.pagelinks.push(i);
  //       }
  //       console.log('filter_pages');
  //       console.log(this.pagelinks);
  //       this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
   
  // });
  // // this.filterMode=false;
  // }
  // }
  async filtersItem(filterValue:any) {

 
    this.page_index = 0;
  this.selectedindex = 0;
  if (this.paginationComponent) {
    this.paginationComponent.resetPageIndex();
  }
    if(filterValue != undefined){
      if (filterValue === 'All') {
        this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Select all
        this.selectstatus = ['APPROVED', 'REJECTED', 'PENDING']
      } else if(filterValue === 'APPROVED' || filterValue === 'PENDING' || filterValue === 'REJECTED') {
        this.selectstatus = [filterValue];
        if (this.activeFilter?.includes(filterValue)) {
          this.activeFilter = this.activeFilter?.filter(f => f !== filterValue); // 
        } else {
          this.activeFilter?.push(filterValue); // Add to selection
        }
      }
    }
    else {
      this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING'];
      console.log('af',this.activeFilter);
    }
    // If all three are selected, keep only "View All" active
    if (this.activeFilter?.length === 3) {
      this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Keep all three for View All
    }
    //console.log("Filter Value:", filterValue);
    this.resetMode = true;
    this.p = 1;
    var domains=[];
    console.log('select_domains----',this.selectdomain);

    if(this.selectdomain && this.selectdomain.length > 0)
        {
          for (var i = 0; i < this.selectdomain.length; i++) {
            console.log('selectdomain ----',this.selectdomain[i])
            domains.push(this.selectdomain[i].item_text);
          }
      
        }
  
        var schemes = [];
        if(this.selectedScheme!=undefined){
          for(var i = 0 ; i < this.selectedScheme.length ; i++){
            console.log('selectedScheme item:', this.selectedScheme[i]); // Debugging line

            schemes.push(this.selectedScheme[i].id);
          }
          console.log('scheme', schemes);
        }
        

        if(this.schemId){
          // if(this.selectedScheme && this.selectedScheme.length > 0) {
            this.selectstatus = this.selectstatus.length ? this.selectstatus : ['APPROVED']; // Ensure APPROVED status remains

          // }
    
      }
      this.myArray = this.myArray.filter(filter => filter.name !== "scheme_ids");

      // this.myArray = await this.setFilters(this.selectstate,this.selectcity,domains,schemes,this.selectstatus);
  //     const selectedStates = Array.isArray(this.selectstate) 
  // ? this.selectstate.map(state => state.item_text) 
  // : [];
  //     const selectedCities = Array.isArray(this.selectcity) 
  //     ? this.selectcity?.map(city => city.item_text) : [];

  //     this.myArray = await this.setFilters(selectedStates,selectedCities,domains,schemes,this.selectstatus);
  // if(this.viewback == 'true'){
 
// }
 
    
  // if (storedFilters) {
  //   console.log('✅ Stored filters found');
  //   this.myArray = JSON.parse(storedFilters);

  //   console.log('⚡ Calling onpageloadstateselect()');
  //   await this.onpageloadstateselect(4017); // Ensure this completes before proceeding

  //   console.log('🎯 Cities should be updated now:', this.selected_citiesapi);
  // } else {
      // ❌ No stored filters, create dynamically

      // const selectedStates = Array.isArray(this.selectstate) ? this.selectstate.map(state => state.item_text) : [];
      const selectedStates = Array.isArray(this.selectstate) 
  ? this.selectstate.map(state => state.item_text ?? state) 
  : [];
      const selectedCities = Array.isArray(this.selectcity) ? this.selectcity.map(city => city.item_text ?? city  ) : [];
      console.log('domains----',domains);
      this.myArray = await this.setFilters(selectedStates, selectedCities, domains, schemes, this.selectstatus);
  // }
      this.activeFilter = this.myArray[4].value.length !=0 ? this.myArray[4].value : ['APPROVED','PENDING','REJECTED'];
      console.log('activefilter',this.activeFilter);
  
    console.log("Filters Applied:", this.myArray);
  
    let requestData = {
      query:this.searchText,
      filters: this.myArray,
      search_entity: "STARTUP",
      page: 0,
      page_size: 10,
    };
  
    this.startupService.filterStartup(requestData).subscribe((res: any) => {
      console.log("API Response:", res);
    
      this.data = res.results;
      // Update status counts
      this.approvedCount = res.approved_count ;
      this.rejectedCount = res.rejected_count ;
      this.pendingCount = res.pending_count;
      this.totalItems = +res.total_search_results_count;
            console.log('totalItems ----',this.totalItems);
            this.pagelinks = [];
          for (let i = 1; i <= Math.ceil(this.totalItems/10); i++) {
            this.pagelinks.push(i);
        }
        console.log('filter_pages');
        console.log(this.pagelinks);
        var totalPages = Math.ceil(this.totalItems/10);
        this.totalpages = totalPages;
        this.initial_pages = this.pagelinks.slice(this.page_index,this.page_index+10)
            this.onPageChange(1);
    
           
            
      this.loading = false;
    });
  
  }
  async setFilters(...filters: any[][]) {
    //const filterNames = ["state", "city", "domain", "schemes", "status"];
  
    // Map through filterArray and update values based on corresponding position in filters array
    this.filterArray = this.filterArray.map((filter, index) => ({
        ...filter,
        value: filters[index] || [] // Assign values if present, else keep empty
    }));
    console.log('filterArray ----',this.filterArray);
    return this.filterArray;
  }
 
  
}
