import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChallengeService } from '../../services/challenge.service';
import * as _ from 'lodash';
import { states } from "../../../assets/js/states";
import { forkJoin, Subject } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CommondialogComponent } from '../../@core/miscellaneous/commondialog/commondialog.component';
import { ExcelService } from '../../services/excel.service';
import { StateService } from "../../services/state.service";
import { MentorService } from '../../mentor.service';
import { SELECT_ITEMS } from '../investor/investor.component';
import { DatePipe } from '@angular/common';
import { PaginationComponent } from '../pagination/pagination.component';
@Component({
  selector: 'ngx-mentor',
  templateUrl: './mentor.component.html',
  styleUrls: ['./mentor.component.scss'],
  providers: [DatePipe]
})
export class MentorComponent implements OnInit {
  @ViewChild(PaginationComponent) paginationComponent!: PaginationComponent;
  filterArray = [
    {
      "name":"country",
      "value":[]
    },
    {
      "name": "state",
      "value": []
    },
    {
      "name": "city",
      "value": []
    },
    {
        "name": "domain",
        "value": []
    },
    {
        "name": "schemes",
        "value": []
    },
    {
        "name": "status",
        "value": []
    },
    {
      "name": "score",
      "value": []
    }
]
  config = {
    id: 'custom-id',
  };
  searchText = '';
  projectmanager!:boolean;
  financemanager!:boolean;
  superadmin!:boolean;
  data: any;
  // visible:boolean = false
  filteredlist=[];
  selected_citiesapi:any = []

  filter:boolean = false;
  filterby = {};
  options: any[] = [];
  types = SELECT_ITEMS;
  dropdownSettings:IDropdownSettings = {};
  dropdownSettings_t:IDropdownSettings = {};
  dropdownSettings_s: IDropdownSettings={};
  dropdownSettings_schemes: IDropdownSettings={};
  dropdownSettings_range:IDropdownSettings ={};
  dropdownSettings_cities:IDropdownSettings ={};
  dropdownSettings_country:IDropdownSettings ={};
  selectedItems = [];
  dropdownList = [];
  sectors: any = [];
  dropdownCity:any[] =[];
  dropdownList1 = [];
  tempdata = [];
  selectedItems1 = [];
  public domain = [];
  public sector = [];
  public schemes = [];

  originalData: any[];

  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectcity:any;
  selectstate:any=[];
  selectdomain:any=[];
  selectsector:any=[];
  selectstatus:any=[];
  selectsrange:any = [];
  selectcountry:any = [];
  selectscheme:any=[];
  selectedStates:any[];
  dropdownstates: any = [];
dropdowncountry:any =[];
  selectedStatus:any[];
  dropdownstatus:any[]=['PENDING','REJECTED','APPROVED'];
  dropdownrange :any[]=[];
  selectedScheme:any[];
  dropdownscheme:any[]=[];
  modalUpdateForm:FormGroup;
  name: any;
  singledata = {name:''};
  action:any;
  currentData: any;
  inValidMessage: boolean;
  modaldata: any;
  id: any;
  schemeForm: FormGroup;
  alldomains: any;
  groupbydomains: any;
  submitted: boolean;
  filterMode: boolean = true;
  resetMode: boolean = false;
  filterValue: any;
  show:boolean=true;
  mat_data: string;
  // currentPage: number = 1;
  // tablearray: number;
  // paginationarr=[];
  sid: any;
  p: number = 1;
  // itemsPerPage: 50;
  totalItems: number;
  currentPage:number=1; 
  allStates: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  pageSize: number= 25;
  loading: boolean = true; 
  activeFilter: string[] = []; 
  filterValue1:any;
  viewback: any;
  pageno:any = 0;
  totalpages: number;
  selectedindex:number = 0;
  isDownloading:boolean=false;
  datafordownload:any;
  cities: any[];
  listCity: any;
  countrylist: any;
  constructor(
    private mentorService: MentorService,
    private router: Router,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private challengeService: ChallengeService,
    private _route:ActivatedRoute,
    private http: HttpClient,
    private dialogService: NbDialogService,
    public dialog: MatDialog,
    private excelService:ExcelService,
    private statesservice : StateService,
    private _datePipe: DatePipe
  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);
    this.activeFilter = ['APPROVED','PENDING','REJECTED'];

    // if (localStorage.getItem('status') ) {
    //   // If it exists, assign its value to this.filterValue
    //   this.filterValue = localStorage.getItem('status');
    // }  
    if(localStorage.getItem('viewback'))
    {
      this.viewback = localStorage.getItem('viewback');
    }
   }
  
  ngOnInit(): void {
    // console.log("states_____from____js", states);
    this.selectedItems = [];
    this.selectedStates = [];
    this.selectedStatus = [];
      this.generateDropdownRange();
this.getCountries();
    this.getAllSchemes();
// this.getCity();
    // this.getStates()

    this.getDomains();
    this.modalUpdateForm = this.fb.group({
      modalIncubatorName: [""],
      group: [""],
      schemes: ["", ],

    });

    if (this.viewback == 'true') {          
      this.backfilter();   
      if(localStorage.getItem('countdata'))
      {
        const storedJsonString = localStorage.getItem('countdata');
        const storedObject = JSON.parse(storedJsonString);
        console.log("count data is==>", storedObject);
        console.log(storedObject.approvedData)
        this.approvedCount = storedObject.approvedData;
        this.rejectedCount = storedObject.rejectedData;
        this.pendingCount = storedObject.pendingData;
       
  
      }  
      // localStorage.removeItem('viewback');
    } else if (this.filterValue != null) {
      this.filtersItem(this.filterValue);
      // localStorage.removeItem('viewback');
      // localStorage.removeItem('filterArray');
      // this.selectedStates = [];
      // this.selectedStatus = this.filterValue;
      // this.selectcity = '';
      // // this.selectsrange =[];
      // this.selectedItems = [];
      // this.activeFilter = this.filterValue;
      // this.viewback = 'true'
      
      

    } else {
      
      this.listAllIncubators();
    }
    // this.schemeForm=this.fb.group({
    // })
    
    if(sessionStorage.getItem('role_name')=='Project Manager'){
      this.projectmanager = true;
      this.financemanager = false;
    }
    else if(sessionStorage.getItem('role_name')=='Finance Manager'){
      this.projectmanager = false;
      this.financemanager = true;
    }
    else if(sessionStorage.getItem('role_name')=='Super Admin'){
      this.superadmin = true
    }

    // this.selectedItems = [];
    // this.dropdownList = [];
    this.selectedItems1 = [];
    this.filteredlist=[];
    // this.selectedStates = [];
    // this.selectedStatus = [];
    // this.dropdownstates = states;
    this.dropdownscheme= [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
    this.dropdownSettings_t = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    }; 
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
    this.dropdownSettings_schemes = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
    this.dropdownSettings_country = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    }
    this.dropdownSettings_range = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
   this.dropdownSettings_cities={
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 3
    allowSearchFilter: true
   }

     // pagination page redirection code
     this._route.queryParams.subscribe((params) => {
      // Use the 'page' parameter from the URL or default to 1
      this.p = +params['page'] || 1;
      // Load data for the current page
      this.loadData(this.p);
    });
     // pagination page redirection code

  

  
  }

  generateDropdownRange(){
for(let i =0; i < 50 ;i += 5){
  this.dropdownrange.push(`${i}-${i + 5}`);
}
  }
  ngOnDestroy(): void{
    // remove style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = ''; 
      htmlElement.style.position = '';
    }
   
      // remove style for ui break on scroll
      localStorage.removeItem('viewback');
 }

 //  pagination page redirection code
onPageChange(page: number): void {
  // Update the current page and navigate to the new URL
  this.p = page;
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: { page: page },
    queryParamsHandling: 'merge',
  });
 
  this.loadData(page);
}
private loadData(page: number): void {
  // Implement your logic to load data based on the page
  console.log('Loading data for page', page);
}
//  pagination page redirection code

  listAllIncubators(){
    // this.activeFilter = null;
    let data={
      search_entity: "MENTOR",
      "page": 0, "page_size": 10
    }
    this.loading = true;
    this.mentorService.filterIncubator(data)
      .subscribe((res:any)=>{
        console.log("fullll",res)
        this.data=res.results;
        this.originalData = this.data;
        this.totalItems = +res.total_search_results_count; 
        // this.updateStatusCounts(res);  
        this.approvedCount = res.approved_count ;
        this.rejectedCount = res.rejected_count ;
        this.pendingCount = res.pending_count;  
        this.totalItems = +res.total_search_results_count;
        var totalPages = Math.ceil(this.totalItems/10);
        this.totalpages = totalPages;
        // const statusCount = {
        //   approvedData: this.approvedCount,
        //   rejectedData: this.rejectedCount,
        //   pendingData: this.pendingCount
        // };
        // const countstring = JSON.stringify(statusCount);
        // localStorage.setItem('countdata', countstring);
        this.loading = false;
        this.onPageChange(1) 
      },(err)=>{
        console.log('err',err);
      })
  }
  async getAll(){
    
    console.log("aaaaaoni", this.totalItems);

    return new Promise((resolve,reject)=>{
      let data={
        search_entity: "MENTOR",
        "page": 0, "page_size": this.totalItems
      }
      this.mentorService.filterIncubator(data)
        .subscribe((res:any)=>{
          this.data=res.results;
          this.originalData = this.data;
        },
        (err)=>{
          console.log('err',err);
        })
        resolve(this.totalItems)
    })
}
 

 // ngAfterViewInit(): void {
    // document.getElementsByClassName('pagination-previous')[0].setAttribute('id','previous');
    // document.getElementById('previous').onclick = (res:any) => {
    //   //this.listAllIncubators();
    //   //place pagination api here for on page click
    //   // let p= res.target.outerText;
    //   // console.log("Page::", p);
    //   console.log('currentpage',this.currentPage-1);
    //   let data={
    //     "search_entity": "MENTOR",
    //     "page": this.currentPage-1, 
    //     "page_size": 50
    //   }
    //   console.log('payload_data',data);
    //   this.incubatorService.getIncubatorsList(data)
    //     .subscribe((res:any)=>{
    //       data=res.results;

    //       // this.currentPage= this.currentPage;
    //       console.log("216666", data);
  
  
    //     })
    // }
 // }


 async onCountrybackSelect(val:any) {
  // const selectedCountryId = event.item_id; // Get selected country's ID
 
  console.log('****', val)
  this.getallStates(val); // Fetch related states
  //this.selectstate = []; // Reset the selected states when country changes
}
async onStatebackSelect(val:any){
  // this.selectstate= val;
  // const selectedstateId = event.item_id; // Get selected country's ID
this.getCitiesbystate(val);
// this.selectcity =[];
 
}
 async backfilter(){  
  // const getArray = localStorage.getItem('filterArray');
  if(this.viewback == 'true')
  {
    const storedValue = localStorage.getItem('query');
    // this.searchText= storedValue;
    this.searchText = storedValue ? JSON.parse(storedValue) : ''; 
    const getArray = localStorage.getItem('filterArray');

    this.myArray = getArray ? JSON.parse(getArray) : [];
    const countryValue = this.myArray.find(item=>item.name === 'country')?.value || [];
   console.log('inback country',countryValue)
    const stateValue = this.myArray.find(item => item.name === 'state')?.value || [];
    const statusValue = this.myArray.find(item => item.name === 'status')?.value || [];
    const cityvalue = this.myArray.find(item => item.name === 'city')?.value || [];
    const domainvalue = this.myArray.find(item => item.name === 'domain')?.value || [];
    const scheme = this.myArray.find(item => item.name === 'schemes')?.value || [];
    const range = this.myArray.find(item => item.name === 'score')?.value || [];
    // if (range) {
    //   this.selectsrange = [`${range.start}-${range.end}`]; // Convert to expected format
    // }
if (range && Array.isArray(range) && range.length > 0) {
  this.selectsrange = range.map(r => `${r.start}-${r.end}`); // Convert all ranges properly
} else {
  this.selectsrange = []; // ✅ Reset if no valid score range exists
}
    console.log("Retrieved range from localStorage:", range);
console.log("Formatted selectsrange:", this.selectsrange);
console.log('4',stateValue);
console.log('5',countryValue);


        // this.selectsrange = range
    // this.selectstate = stateValue;
    // this.selectstate = stateValue.item_text??stateValue;
    for(var i = 0 ; i < stateValue?.length ; i++){
      console.log('statevalue_ind',stateValue[i]);
      this.selectstate.push({
        'item_text':stateValue[i]
      })
    }
    console.log('stat123',this.selectstate);

    this.selectstatus = statusValue;
    this.selectcity = cityvalue;
    this.selectdomain = domainvalue
    this.selectedScheme = scheme;
    this.selectcountry = countryValue;
    console.log('assingning to country',this.selectcountry)
  }
  const storedFilters = localStorage.getItem('filterArray');
  const countryId=localStorage.getItem('selectedCountryId')
  const stateId=localStorage.getItem('selectedStateId')
  console.log('stored',storedFilters);
  if (storedFilters) {
      console.log('✅ Stored filters found');
      this.myArray = JSON.parse(storedFilters);
  
      console.log('⚡ Calling onpageloadstateselect()');
      if (countryId) {
        console.log('🌍 Country ID found:', countryId);
        await this.onCountrybackSelect(countryId);
      }

      // Call only if stateId is present
      if (stateId) {
        console.log('🏙️ State ID found:', stateId);
        await this.onStatebackSelect(stateId);
        // this.selectstate= stateId
      }
    }
    if(localStorage.getItem('status'))
    {
      this.activeFilter = JSON.parse(localStorage.getItem('status'));
    }
    else {
      this.activeFilter = ['APPROVED','PENDING','REJECTED']; // Reset if no filters
  }

  if (this.selectstatus && this.selectstatus.length > 0) {
    this.activeFilter = this.myArray.find(item => item.name === 'status')?.value || ['APPROVED', 'PENDING', 'REJECTED'];
  }
  
  this.selectedindex  = Number(localStorage.getItem('selectedindex'))
const pageno = this.selectedindex

  let data = {
    query:this.searchText,
    filters: this.myArray,
    search_entity: "MENTOR",
    "page": pageno, "page_size": 10

  };

 
  console.log("data____0603", data);
 
 
  this.mentorService.filterIncubator(data).subscribe((res: any) => {
      // console.log("res myarray___0603:", res);
      this.data = res.results;  
      this.approvedCount = res.approved_count ?? this.approvedCount;
      this.rejectedCount = res.rejected_count ?? this.rejectedCount;
      this.pendingCount = res.pending_count ?? this.pendingCount;   
      this.totalItems = +res.total_search_results_count;
      console.log('totalItems',this.totalItems);
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages;   
      // this.updateStatusCounts(this.data);
      this.loading = false;
    });
   
  
}

  // filterItem(filterValue) {
  //   console.log(filterValue,'filterValue')
  //   if(filterValue == 'null')
  //   {
  //    this.activeFilter = null;
  //   } 
     
    
  //   // this.filterMode = false;
  //   this.resetMode = true;
  //   this.myArray = [];
  //   var domains = [];
  //   localStorage.removeItem('status');
  //   if(this.selectdomain && this.selectdomain.length > 0)
  //   {
  //     for (var i = 0; i < this.selectdomain.length; i++) {
  //       domains.push(this.selectdomain[i].item_text || this.selectdomain[i]);
  //     }
  //     if (this.selectdomain.length != 0) {
  //       this.myArray.push({
  //         name: "domain",
  //         value: domains,
  //       });
  //     }
  //   }
  //   console.log(domains,this.selectdomain,'domains000---')

  //   var sector = [];
  //   for(var i = 0 ; i < this.selectsector.length ; i++){
  //     sector.push(this.selectsector[i].item_text);
  //   }

  //   var schemes = [];
  //   // if(this.selectscheme!=undefined){
  //   //   for(var i = 0 ; i < this.selectscheme.length ; i++){
  //   //     schemes.push(this.selectscheme[i].item_text);
  //   //   }
  //   //   console.log('scheme',this.selectscheme, schemes);
  //   // }
 

  //   // var myItem =[{ name: "city", value: this.selectcity}]
  //   // var myItem1= [{ name: "state", value: [...this.selectstate] }]
  //   // var myItem2= [{ name: "domain", value: domains }]
  //   // var myItem3= [{ name: "sector", value: sector }]
  //   // var myItem4= [{ name: "status", value: [...this.selectstatus] }]
  //   // this.myArray.push(...myItem, ...myItem1, ...myItem2, ...myItem3, ...myItem4)
  //   // console.log("array:",this.myArray);

  //   if (this.selectcity && this.selectcity.length > 0) {
  //     this.myArray.push({
  //       name: 'city',
  //       value: this.selectcity
  //     });
  //   }
  //   if (this.selectsrange && this.selectsrange.length > 0) {
  //     this.selectsrange.forEach(range => {
  //       let [startValue, endValue] = range.split('-').map(Number); // Extract numbers
    
  //       this.myArray.push({
  //         name: 'score',
  //         value: {
  //           start: startValue, // Assign extracted values
  //           end: endValue
  //         }
  //       });
  //     });
  //   }
    
  //   if (this.selectstate && this.selectstate.length > 0 ){
  //     this.myArray.push({
  //       name: 'state',
  //       value: this.selectstate
  //     })
  //   }
  //   console.log('domain_select',this.selectstate,this.selectdomain);
  //   // if(this.selectdomain.length !=0){
  //   //   this.myArray.push({
  //   //     name: 'domain',
  //   //     value: domains
  //   //   })
  //   //   // console.log('domains',this.myArray);
  //   // }
  //   // if(this.selectsector.length !=0){
  //   //   this.myArray.push({
  //   //     name: 'sector',
  //   //     value: sector
  //   //   })
  //   // }


  //   // if(schemes.length !=0){
  //   //   this.myArray.push({
  //   //     name: 'schemes',
  //   //     value: schemes
  //   //   })
  //   // }
  //     console.log("selectscheme:",this.selectstatus,this.myArray);

  //   if(this.selectstatus && this.selectstatus.length !=0){
  //     this.myArray.push({
  //       name: 'status',
  //       value: this.selectstatus
  //     })
  //     this.activeFilter = this.selectstatus;
  //     this.filterValue1 = this.selectstatus
  //   }

  //   // if (this.selectstatus.length == 0) {
  //   //   this.myArray.push({
  //   //     name: "status",
  //   //     value: filterValue,
  //   //   });
      
      
  //   //   console.log("filter value by stats", this.myArray);
  //   // }
   
  //     let data = {
  //     "filters": this.myArray,
  //     "search_entity": "MENTOR",
  //     "page": 0, "page_size": 5000

  //   }
   
  //   if(this.filterValue == null){
  //     // this.filterMode=true;
  //     this.mentorService.filterIncubator(data)
  //     .subscribe((res:any)=>{
  //       console.log("res myarray___0603:", res);
  //       this.data = res.results;
  //       this.onPageChange(1) 
  //       this.updateStatusCounts(res);
  //       const statusCount = {
  //         approvedData: this.approvedCount,
  //         rejectedData: this.rejectedCount,
  //         pendingData: this.pendingCount
  //       };
  //       const countstring = JSON.stringify(statusCount);
  //       localStorage.setItem('countdata', countstring);
  //       const jsonString = JSON.stringify(this.myArray);
  //       localStorage.setItem('filterArray', jsonString); 
  //     })
     
  //   }
  //   else {
  //     // this.filterMode=false;
  //     if (!this.myArray.some(item => item.name === 'status')) {
  //       this.myArray.push({
  //         "name": "status",
  //         "value": Array.isArray(this.filterValue) ? this.filterValue : [this.filterValue]              
  //     });
  //    }
  //     let data = {
  //     "filters": this.myArray,
  //       "search_entity": "MENTOR",
  //       "page": 0, "page_size": 5000
  //   }
  //     console.log("data____filterdata",this.filterValue);
  //     this.mentorService.filterIncubator(data).subscribe((res: any) => {
  //       console.log("pendinggggg.........:", res);
  //       this.data = res.results;
  //       this.updateStatusCounts(res);
  //       const statusCount = {
  //         approvedData: this.approvedCount,
  //         rejectedData: this.rejectedCount,
  //         pendingData: this.pendingCount
  //       };
  //       const countstring = JSON.stringify(statusCount);
  //       localStorage.setItem('countdata', countstring);
  //       const jsonString = JSON.stringify(this.myArray);
  //       localStorage.setItem('filterArray', jsonString); 
  //       this.loading = false

  //     });
  //     // this.filterMode=false;

  //   }
  // }

  // countFilter(data)
  // {

  //   this.myArray = [];
  //   var domains = [];

    
    
  //   if(this.selectdomain && this.selectdomain.length > 0)
  //   {
  //     for (var i = 0; i < this.selectdomain.length; i++) {
  //       domains.push(this.selectdomain[i].item_text);
  //     }
  //     if (this.selectdomain.length != 0) {
  //       this.myArray.push({
  //         name: "domain",
  //         value: domains,
  //       });
  //     }
  //   }
  //   var sector = [];
  //   var schemes = [];
  //   if(this.selectscheme!=undefined){
  //     for(var i = 0 ; i < this.selectscheme.length ; i++){
  //       schemes.push(this.selectscheme[i].item_text);
  //     }
  //     console.log('scheme', schemes);
  //   }

  //   if (this.selectcity && this.selectcity.length > 0) {
  //     this.myArray.push({
  //       name: 'city',
  //       value: this.selectcity
  //     });
  //   }
  //   if (this.selectsrange && this.selectsrange.length > 0) {
  //     this.selectsrange.forEach(range => {
  //       let [startValue, endValue] = range.split('-').map(Number); // Extract numbers
    
  //       this.myArray.push({
  //         name: 'score',
  //         value: {
  //           start: startValue, // Assign extracted values
  //           end: endValue
  //         }
  //       });
  //     });
  //   }
  //   if (this.selectstate && this.selectstate.length > 0) {
  //     this.myArray.push({
  //       name: "state",
  //       value: this.selectstate,
  //     });
  //   }

  //   this.myArray.push({
  //     name: "status",
  //     value: data,
  //   });
  //   let fitlere_data = data;
  //   this.activeFilter = data;
  //   this.filterValue1 = data;   
  //   // this.onPageChange(1) 
  //   localStorage.removeItem('status');

  //   if(this.viewback == 'true')
  //   {
  //     const getArray = localStorage.getItem('filterArray');
  //    const parsedArray = JSON.parse(getArray);
  //    this.myArray = parsedArray;
  //     this.myArray = this.myArray.filter(item => item.name !== 'status');
  //   }
  //    // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //    if(data == 'All')
  //    {
  //      // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //      this.myArray.push({
  //        name: "status",
  //        value: ["PENDING", "REJECTED", "APPROVED"]
  //      });
  //      this.activeFilter = null;
  //      this.selectedStatus = ["PENDING", "REJECTED", "APPROVED"];
  //      const jsonString = JSON.stringify(this.myArray);
  //      localStorage.setItem('filterArray', jsonString); 
      
  //    }
  //    else {
       
  //       this.myArray = this.myArray.filter(item => item.name !== 'status');
      
      
  //      this.myArray.push({
  //        name: "status",
  //        value: [data],
  //      });
  //      this.selectedStatus = [data];
  //      const jsonString = JSON.stringify(this.myArray);
  //      localStorage.setItem('filterArray', jsonString); 
  //    }
   
    
     
  //    const jsonString = JSON.stringify(this.myArray);
  //    localStorage.setItem('filterArray', jsonString); 

  //   let data1 = {
  //     filters: this.myArray,
  //     "search_entity": "MENTOR",
  //     "page": 0, "page_size": 5000

  // }
  //   this.mentorService.filterIncubator(data1).subscribe((res: any) => {     
     
  //     this.data = res.results;
  //     console.log('count status data is ', this.data)
  //     // this.updateStatusCounts(this.data);
  //     this.onPageChange(1) 
  //     const statusCount = {
  //       approvedData: this.approvedCount,
  //       rejectedData: this.rejectedCount,
  //       pendingData: this.pendingCount
  //     };
  //     const countstring = JSON.stringify(statusCount);
  //     localStorage.setItem('countdata', countstring);
  //   });
  //   // this.p= 1;
  // }
  // countAllFilter()
  // {
  //   this.activeFilter = null;
  //   localStorage.removeItem('status');
  //   this.onPageChange(1) 
  //   this.resetFilters()
  //   this.listAllIncubators();
  //    this.updateStatusCounts(this.data);
  // }

  

 
 
  // paginate(page:any){
  //   console.log('event_1234',page);
  //   console.log("350", page.target.outerText);
  //   console.log('page1234',page);
  //   this.currentPage = +page.target.outerText;
  //   console.log('currentpage',this.currentPage);
  //   if(page != 1){
  //    console.log('pagination_previous',document.querySelector('.pagination-previous'));
  //    document.querySelector('.pagination-previous').classList.remove('disabled');
  //    console.log('pagination_previous_disabled',document.querySelector('.pagination-previous'));
  //   }
  //   else {
  //     document.querySelector('.pagination-previous').className +=' disabled'
  //   }
  //   let data={
  //     search_entity: "MENTOR",
  //     "page": this.currentPage, "page_size": 50
  //   }
  //   console.log('data_pagination',data);
  //   this.incubatorService.getIncubatorsList(data)
  //     .subscribe((res:any)=>{
  //       this.data=res.results;
  //       page= page;
  //       // console.log("359", page);
  //     })
  // }
  
  // onPageChanged(event: any) {
  //   this.currentPage = event.page;
  //   this.pageSize = event.itemsPerPage;
  //   // Fetch data from API based on currentPage and pageSize
  //   let data={
  //         search_entity: "MENTOR",
  //         "page": this.currentPage, "page_size": this.pageSize
  //       }
  //       console.log('data_pagination',data);
  //       this.incubatorService.getIncubatorsList(data)
  //         .subscribe((res:any)=>{
  //           this.data=res.results;
              
    
  //         })
     
  // }
  


  // handleNext(currentPage){
  //   let data={
  //     search_entity: "MENTOR",
  //     "page": currentPage + 1 , "page_size": 10
  //   }
  //   this.incubatorService.getIncubatorsList(data)
  //     .subscribe((res:any)=>{
  //       this.data=res.results;
  //     })
  // }
  // handlePrev(currentPage){
  //   let data={
  //     search_entity: "MENTOR",
  //     "page": currentPage - 1 , "page_size": 10
  //   }
  //   this.incubatorService.getIncubatorsList(data)
  //     .subscribe((res:any)=>{
  //       this.data=res.results;
  //     })
  //     // const url = `${this.data}?page=${data.page}`;
  //     // return this.http.get<any[]>(url);
  // }
  getAllSchemes(){
    this.mentorService.getschemeList()
      .subscribe((res:any)=>{
        this.options= res.results;
        console.log("SchemeLIST:", this.options);

        let data = [];
      for(var i = 0 ; i < this.options.length ; i++){
        data.push({
          item_id:i+1 , item_text:this.options[i].name
        })
      }
      this.dropdownscheme = [...data];
      console.log("schmeeeeeeeeee",this.dropdownscheme);
      })
  }
   changestatus(id:string,value:string){
    //  this.mat_data = value.toLowerCase(); 

    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = '0'; 
      htmlElement.style.position = 'sticky';
    }
    this.mat_data = value;
    // console.log('datamatdata::', this.mat_data);
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Mentor' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              "status": value
            }
            this.mentorService.actionIncubator(data,id).subscribe((res:any)=>{
                console.log('change_status',res);
                // this.getAllIncubatorsData();
                // this.listAllIncubators();
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
                const updatedIncubatorIndex = this.data.findIndex(incubator => incubator.id === id);
                if (updatedIncubatorIndex !== -1) {
                  this.data[updatedIncubatorIndex].status = value;
                }
              },
              (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Update Status!");
            })
          }
        })
  }
 
  editModal(modaldata){
    this.modaldata=modaldata;
    console.log("modeeee::", this.modaldata);
    this.modalUpdateForm.controls["modalIncubatorName"].setValue(modaldata.name);
    this.modalUpdateForm.controls["group"].setValue(modaldata.group);
    // this.modalUpdateForm.controls["schemes"].setValue(modaldata.scheme_ids);

    // this.singledata['name'] = modaldata.name
    // console.log("modalForm::", this.modalUpdateForm.value);
  }

  // updateModal(){
  //   let data={
  //     group: this.modalUpdateForm.controls.centerType.value,
  //     // scheme: this.modalUpdateForm.controls.schemes.value
  //   }
  //   this.incubatorService.editIncubatorModal(data, this.modaldata.id)
  //     .subscribe((res:any)=>{
  //       console.log("updateTSDAta::", res);
  //       this.modalUpdateForm.reset();
  //       this.toastrService.success("Data Updated Successfully!");
  //     },
  //       (error) => {
  //       this.inValidMessage = true;
  //       this.toastrService.warning("Unable to Update records!");
  //     })
  // }
  schemeval(event:any){
    console.log('event____scheme',event.target.value)
     this.sid= event.target.value;
  }
  updateData(){
    console.log("sID:", this.sid);
    console.log("md form::",this.modalUpdateForm);
       let data=  {
          "schemes": [
              {
                  "id": this.sid,
                  "group": this.modalUpdateForm.controls.group.value
              }
          ]
      }
    
    this.mentorService.updateScheme(data, this.modaldata.id)
      .subscribe((res:any)=>{
        // this.schemeForm.reset();
        this.listAllIncubators();
        console.log("schemUpdated:", res);
        this.toastrService.success("Schemes Updated Successfully!");
      },
        (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update Schemes!");
      })
  }

  // onCheckboxChange(checked: boolean) {
  //   const checkboxValue = checked ? 'Scheme' : 'TIDE 2.0';
  //   console.log(checkboxValue);
  // }
  

  // updateSchemes() {
  
  //   let data={
  //     group: this.modalUpdateForm.controls.group.value,
  //     scheme_ids:[+this.modalUpdateForm.controls.schemes.value]
  //   }
  //   let id=this.modaldata.id;
  //   const putRequest1 =this.incubatorService.editIncubatorModal(data, this.modaldata.id)
  //   const putRequest2=this.incubatorService.updateScheme(data, this.modaldata.id)
    
  //   // Use forkJoin to combine the two requests
  //   forkJoin([putRequest1, putRequest2]).subscribe(results => {
  //     // Handle the results of both requests here
  //     console.log('PUT request 1 result:', results[0]);
  //     console.log('PUT request 2 result:', results[1]);
  //     this.listAllIncubators();
  //     this.toastrService.success("Modal Updated Successfully!");
  //   },
  //   (error) => {
  //   this.inValidMessage = true;
  //   this.toastrService.warning("Unable to Update Modal!");
  // })
      
  // }
  
  getDomains(){
    this.challengeService.getalldomains().subscribe((res:any)=>{
      this.alldomains = res.results;
      let data = [];
      for(var i = 0 ; i < this.alldomains.length ; i++){
        data.push({
          item_id:i+1 , item_text:this.alldomains[i].name
        })
      }
      this.dropdownList = [...data];
      const getArray = localStorage.getItem('filterArray');
   
        const parsedArray = JSON.parse(getArray);
        const domainvalue = parsedArray?.find(item => item.name === 'domain')?.value;
        let filteredData = this.dropdownList.filter(item => domainvalue?.includes(item.item_text));
        this.selectedItems = filteredData || [];
      this.groupbydomains = _.groupBy(this.alldomains,'name');
      console.log('group domains',this.dropdownList,this.groupbydomains);
    })
  }

 
  async onItemSelectDomain(event){
    console.log('onItemSelect',this.groupbydomains,event);
    console.log('getsector',this.groupbydomains[`${event.item_text}`][0]['sectors'])
    await this.groupbysectors(event);
    console.log('sectors',this.sectors);
    for(var i = 0 ; i<this.sectors.length ; i++) {
      this.tempdata.push({
        item_id:i+1 , item_text:this.sectors[i].name
      })
    }
    this.dropdownList1 = [...this.tempdata];
    console.log('sectors_dropdown',this.dropdownList1);
  }

  async onItemDeSelectDomain(event){
    console.log('event',event);
  }
  async groupbysectors(event){
    return new Promise((resolve,reject)=>{
      this.challengeService.setSectorData(this.groupbydomains[`${event.item_text}`][0]['sectors'])
      this.sectors.push(...this.groupbydomains[`${event.item_text}`][0]['sectors']);
      resolve(this.sectors);
    })
  }  

  gotosection(el:any){
    // this.visible = !this.visible;
    el.scrollIntoView({behaviour:'smooth'});
  }
  preview(d){
    localStorage.setItem('selectedindex',this.selectedindex.toString())
    console.log("pCheck::", d);
    if(this.searchText&&this.searchText!==null){
      localStorage.setItem('query',JSON.stringify(this.searchText));
    }else{
      localStorage.removeItem('query');
    }
    if (this.filterValue1 && this.filterValue1.length > 0) {
      localStorage.setItem('status', JSON.stringify(this.filterValue1));
  } else {
      localStorage.removeItem('status');
  }


  if (this.filterValue1 && this.filterValue1.length > 0) {
    this.activeFilter = this.filterValue;

      const statusFilter = { name: "status", value: this.filterValue1 };
      this.myArray = this.myArray.filter(item => item.name !== "status"); // Remove existing status filter
      this.myArray.push(statusFilter);
  }
  if (this.myArray?.length > 0) {
    localStorage.setItem('filterArray', JSON.stringify(this.myArray));
} else {
    localStorage.removeItem('filterArray');  // ✅ Remove completely if empty
}
    this.router.navigateByUrl(`/admin/mentor/${d}`);
   
   
  }
  updateScheme(d){
    console.log("updatescc", d);
    this.router.navigateByUrl(`/admin/indincubator/${d}`);

  }

  exportData(dataArray: any) {
    const invalid = ['','null',null,undefined,['']]
    const data = dataArray.map((d) => ({
      "Created at":d.created_at?new Date(d.created_at):'-',
      Name: (d.first_name || d.last_name) ? d.first_name + ' ' + d.last_name : '-',
      Domain: Array.isArray(d?.domain) ? (d.domain.length > 0 ? d?.domain.join(', ') : '-') : d?.domain,
      //AffiliateName: d.affiliate_name,
      //Sector: Array.isArray(d?.sector) ? d?.sector.join(', ') : JSON.stringify(d?.sector),
      City: d?.city ? d?.city : '-',
      State: d?.state ? d?.state : '-',
      Country: d?.country ? d?.country : '-',
      'Registered Date': this._datePipe.transform(d?.created_at,'dd MMMM, yyyy HH:mm:ss'),
      //ZipCode:d?.contact_info?.addresses?.zipCode,
      // CeoDetails: d?.team_info ? `CEO Firstname=${d.team_info?.ceoFirstName}, Lastname=${d.team_info?.ceoLastName}, Phone=${d.team_info?.ceoPhoneNo}, Email=${d.team_info?.ceoEmail}` : '',
      // TeamDetails:Array.isArray(d?.team_info?.team_array) ? d?.team_info?.team_array.map(info => `First Name=${info.teamFirstName},Last Name= ${info.teamLastName},Mobile= ${info.teamPhoneNo},Email=${info.teamEmail}`).join(', ') : '',
      // SocialInfo: d?.social_info ? `Facebook=${d.social_info.facebook}, Instagram=${d.social_info.instagram},Twitter=${d.social_info.twitter},LinkedIn=${d.social_info.linkedIn},Youtube=${d.social_info.youtube},Koo=${d.social_info.koo}` : '',

      // Scheme:Array.isArray(d.scheme_names) ? d.scheme_names.join(', ') : d.scheme_names,
      Status: d?.status ? d?.status : '-',
      Email : d?.email ?d?.email : '-',
      Phone : d?.contact_no ? d?.contact_no : '-',
      Linkedin: d?.linked_in ? d?.linked_in : '-',
      'Company name': d?.company_name ? d?.company_name : '-',
      'Company Website' :d?.company_website ? d?.company_website : '-',
      'Designation' : d?.designation ? d?.designation : '-',
      'Languages': Array.isArray(d?.languages) && !invalid.includes(d?.languages.join('')) ? d.languages.join(', ') : '-',
      'Awards': Array.isArray(d?.awards) && !invalid.includes(d?.awards.join('')) ? d.awards.join(', ') : '-',
      'Score': !invalid.includes(d?.score) ? d?.score : '-',
      "Description" : !invalid.includes(d?.form_schema?.step2) && d.form_schema.step2
      ? d.form_schema.step2['Please Provide your brief Bio in less than 200 words.'] || '-'
      : '-',
      "Overall Years of experience" : !invalid.includes(d.form_schema.step2) && d.form_schema.step2
      ? d.form_schema.step2['Overall Years of experience'] || '-'
      : '-',
      "Mentorship Experience" :!invalid.includes(d.form_schema.step2) && d.form_schema.step2
      ? d.form_schema.step2['Mentorship Experience'] || '-'
      : '-',
      "Mentorship commitment level" :!invalid.includes(d.form_schema.step2) && d.form_schema.step2
      ? d.form_schema.step2['Mentorship commitment level'] || '-'
      : '-',
      "What describes you the best?" :
      !invalid.includes(d.form_schema.step2) && d.form_schema.step2
      ? d.form_schema.step2['What describes you the best?'] || '-'
      : '-',
      "Startup stages you can mentor." :
      !invalid.includes(d.form_schema.step2) && d.form_schema.step2
      ? d.form_schema.step2['Please specify the startup stages you can mentor.'] || '-'
      : '-',
      "Number of startups you have mentored to date?" :
      !invalid.includes(d.form_schema.step2) && d.form_schema.step2
      ? d.form_schema.step2['Please specify the number of startups you have mentored to date?'] || '-'
      : '-',
      "International exposure?" :
      !invalid.includes(d.form_schema.step2) && d.form_schema.step2
      ? d.form_schema.step2['Do you have any international exposure?'] || '-'
      : '-',
      "Any specialized functional area of mentoring?" :
      !invalid.includes(d.form_schema.step3) && d.form_schema.step3
      ? d.form_schema.step3['Any specialized functional area of mentoring? (Sector Specific) '] || '-'
      : '-',
      "Associated with any Incubator/Startup Agency" :
      !invalid.includes(d.form_schema.step3 ) && d.form_schema.step3 
      ? d.form_schema.step3['Associated with any Incubator/Startup Agency'] || '-'
      : '-',
      "preferred mentoring format" :
      !invalid.includes(d.form_schema.step3 ) && d.form_schema.step3 
      ? d.form_schema.step3['undefined'] || '-'
      : '-',
      "Consent for promotion & visibility" : 
      !invalid.includes(d.form_schema.step4) && d.form_schema.step4
      ? d.form_schema.step4['Do you consent your profile visibility and promotion by MSH to its Portal?'] || '-'
      : '-',
      "Updated at":d.updated_at?new Date(d.updated_at):'-',
    }));
    this.exportAsXLSX(data)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", data);
  }

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Mentor Report','Mentor_data');
  }
  // downloadIncubatorList(){
  //   this.exportData(this.data);
  // }

  async downloadIncubatorList(){
    this.isDownloading = true;  // Start loader
    let downloaddata = await this.listAllMentorsforDownload();
    this.exportData(downloaddata);
    this.isDownloading = false;  
  }

  async listAllMentorsforDownload() {

    return new Promise((resolve,reject)=>{
      let data= {
        filters: this.myArray,
        search_entity: "MENTOR",
        "page": 0, "page_size": 10000
  
      }
      this.mentorService.filterIncubator(data).subscribe((res: any) => {
        this.datafordownload = res.results;
        resolve(this.datafordownload);
      });
    })
  }




  // state
  // getStates() {
  //   this.statesservice.getallstates().subscribe((res: any) => {
  //     this.allStates = res.results;
  //     this.dropdownstates = this.allStates
  //   });
  //   {console.log(this.dropdownstates,'dropdownstates')}
  // }
  // state


  getCountsByStatus(startups) {
    const statusCounts = {};
    startups.forEach((startup) => {
      const status = startup.status;
      if (status) {
        if (statusCounts.hasOwnProperty(status)) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      }
    });   

    return statusCounts;
  }


  updateStatusCounts(res) {
    this.approvedCount = res.approved_count || 0;
    this.rejectedCount = res.rejected_count || 0;
    this.pendingCount = res.pending_count || 0;
  
    const statusCount = {
      approvedData: this.approvedCount,
      rejectedData: this.rejectedCount,
      pendingData: this.pendingCount
    };
  
    localStorage.setItem('countdata', JSON.stringify(statusCount));
  }
  removeFilterQueryParam(): void {
 
    // Clone the current query parameters
    const updatedQueryParams = { ...this._route.snapshot.queryParams };
  
    // Remove 'filter' from the cloned object
    delete updatedQueryParams['filter'];
  
    // Navigate to the updated query parameters
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: updatedQueryParams, // Set updated params explicitly
      queryParamsHandling: undefined, // Ensure Angular fully replaces the params
    });
  
    console.log('Updated Query Params:', updatedQueryParams);
  }
  resetFilters() {
    this.resetMode = false; 
    // this.filterMode = true;
    localStorage.removeItem('countdata');
    // clear the filter criteria object
    this.data = {};
    this.selectcity='';
    this.selectstate='';
    this.selectdomain='';
    this.selectsrange ='';
    this.searchText='';
    this.selectedindex=0;
    if (this.paginationComponent) {
      this.paginationComponent.resetPageIndex();
    }
    // this.selectsector='';
    this.selectscheme='';
    this.selectstatus='';
    this.selectedStates = [];
    this.dropdownstates = []; // ✅ Clear states when resetting filters
this.dropdownCity=[];
    this.selectcountry =[];
    // reset the filtered data to the original data
    // this.data = this.originalData;
    localStorage.removeItem('filterArray');    
    localStorage.removeItem('status'); 
    localStorage.removeItem('viewback');
    localStorage.removeItem('selectedStates');  // ✅ Also remove stored states
  
    // this.updateStatusCounts(this.data);
    localStorage.removeItem('status');
    // this.activeFilter = null;
    this.viewback = 'false';
    this.activeFilter = ['APPROVED','PENDING','REJECTED'];

    // this.onPageChange(1) 
    this.myArray = [];
    this.listAllIncubators();
        this.removeFilterQueryParam();

  }
  async filtersItem(filterValue:any) {
    console.log('filtervalue ----',filterValue);
    this.selectedindex = 0;
    if (this.paginationComponent) {
      this.paginationComponent.resetPageIndex();
    }
    if(filterValue != undefined){
      if (filterValue === 'All') {
        this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Select all
        this.selectstatus = ['APPROVED', 'REJECTED', 'PENDING']
      } else if(filterValue === 'APPROVED' || filterValue === 'PENDING' || filterValue === 'REJECTED') {
        this.selectstatus = [filterValue];
        if (this.activeFilter?.includes(filterValue)) {
          this.activeFilter = this.activeFilter?.filter(f => f !== filterValue); // Remove if already selected
        } else {
          this.activeFilter?.push(filterValue); // Add to selection
        }
      }
    }
    else {
      this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING'];
      console.log('af',this.activeFilter);
    }
    // If all three are selected, keep only "View All" active
    if (this.activeFilter?.length === 3) {
      this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Keep all three for View All
    }
    //console.log("Filter Value:", filterValue);
    this.resetMode = true;
    this.p = 1;
    var domains=[];
  
    if(this.selectdomain && this.selectdomain.length > 0)
        {
          for (var i = 0; i < this.selectdomain.length; i++) {
            console.log('selectdomain ----',this.selectdomain[i])
            domains.push(this.selectdomain[i].item_text ?? this.selectdomain[i]);
          }
          // if (this.selectdomain.length != 0) {
          //   this.myArray.push({
          //     name: "domain",
          //     value: domains,
          //   });
          // }
        }
  
        var schemes = [];
        if(this.selectscheme!=undefined){
          for(var i = 0 ; i < this.selectscheme.length ; i++){
            schemes.push(this.selectscheme[i].item_id ?? this.selectscheme[i]);
          }
          console.log('scheme', schemes);
        }
       // Prepare score ranges separately
let scoreRanges = [];
if (this.selectsrange && this.selectsrange.length > 0) {
  scoreRanges = this.selectsrange.map(range => {
    let [startValue, endValue] = range.split('-').map(Number); // Extract numbers
    return { start: startValue, end: endValue } ;
  });

}

var countries =[];
console.log('country1234',this.selectcountry);
if(this.selectcountry && this.selectcountry.length > 0)
  {
    for (var i = 0; i < this.selectcountry.length; i++) {
      console.log('selectdomain ----',this.selectcountry[i])
      countries.push(this.selectcountry[i].item_text??this.selectcountry[i]);
    }
 
  }
  console.log('country123',countries);
  // if(this.selectdomain && this.selectdomain.length > 0)
  //   {
  //     for (var i = 0; i < this.selectdomain.length; i++) {
  //       console.log('selectdomain ----',this.selectdomain[i])
  //       domains.push(this.selectdomain[i].item_text);
  //     }
  
  //   }
console.log('state123-',this.selectstate)
 let allstates =[];
  if(this.selectstate && this.selectstate.length > 0)
    {
      for (var i = 0; i < this.selectstate.length; i++) {
        console.log('selectdomain ----',this.selectstate[i])
        allstates.push(this.selectstate[i].item_text ??this.selectstate[i]);
      }
   
    }
    console.log('state123-',allstates)

  // const allstates = Array.isArray(this.selectstate) 
  // ? this.selectstate.map(state => state.item_text ?? state) 
  // : [];



  // const selectedStates = Array.isArray(this.selectstate) 
  // ? this.selectstate.map(state => state.item_text ?? state) 
  // : [];
  //     const selectedCities = Array.isArray(this.selectcity) ? this.selectcity.map(city => city.item_text ?? city  ) : [];


    let cities =[];
    if(this.selectcity && this.selectcity.length > 0)
      {
        for (var i = 0; i < this.selectcity.length; i++) {
          console.log('selectdomain ----',this.selectcity[i])
          cities.push(this.selectcity[i].item_text ?? this.selectcity[i]);
        }
     
      }
      this.myArray = await this.setFilters(countries,allstates,cities,domains,schemes,this.selectstatus,scoreRanges);
  
      this.activeFilter = this.myArray[5].value.length !=0 ? this.myArray[5].value : ['APPROVED','PENDING','REJECTED'];
      console.log('activefilter',this.activeFilter);
  
    console.log("Filters Applied:", this.myArray);
  
    let requestData = {
      query:this.searchText,
      filters: this.myArray,
      search_entity: "MENTOR",
      page: 0,
      page_size: 10,
    };
  
    this.mentorService.filterIncubator(requestData).subscribe((res: any) => {
      console.log("API Response:", res.total_search_results_count);
      
      this.data = res.results;
      // Update status counts
      this.approvedCount = res.approved_count ;
      this.rejectedCount = res.rejected_count ;
      this.pendingCount = res.pending_count;
      this.totalItems = +res.total_search_results_count;
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages;
      this.loading = false;
    });
  
  }
  
  async setFilters(...filters: any[][]) {
    //const filterNames = ["state", "city", "domain", "schemes", "status"];
  
    // Map through filterArray and update values based on corresponding position in filters array
    this.filterArray = this.filterArray.map((filter, index) => ({
        ...filter,
        value: filters[index] || [] // Assign values if present, else keep empty
    }));
    console.log('filterArray ----',this.filterArray);
    return this.filterArray;
  }

  paginate_startup(pageno) {
    this.pageno = pageno
    this.selectedindex = pageno-1;
    let data={
      // query:this.searchText,
      filters: this.myArray,
        search_entity: "MENTOR", 
        "page": pageno-1, "page_size": 10,
      }
    this.loading = true;
    this.mentorService.filterIncubator(data)
    .subscribe((res:any)=>{
      this.data=res.results;
      this.originalData = this.data;
      this.totalItems = +res.total_search_results_count; 
      // this.updateStatusCounts(res);  
      this.approvedCount = res.approved_count ;
      this.rejectedCount = res.rejected_count ;
      this.pendingCount = res.pending_count;  
      this.totalItems = +res.total_search_results_count;
      console.log('totalItems ----', this.totalItems);
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages;
      this.loading = false;
    },(err)=>{
      console.log('err',err);
    })
  }

  getSerialNo(i:number) {
    return i+(this.selectedindex*10);
  }


  getCountries() {
    this.mentorService.getCountryList().subscribe(
      (response: any) => {
        this.dropdowncountry = response.results.map((country: any) => ({
          item_id: country.id,  // Ensure ID is correctly assigned
          item_text: country.name  // Ensure Name is correctly assigned
        }));
  
        console.log('Dropdown Countries:', this.dropdowncountry);
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }
  
  getallStates(countryId: number) {
    this.mentorService.getStateList(countryId).subscribe(
      (response: any) => {
        console.log('142____',response.result)

        this.dropdownstates = response.result.map((state: any) => ({
          item_id: state.id,  // Ensure ID is correctly assigned
          item_text: state.name  // Ensure Name is correctly assigned
        }));
      },
      (error) => {
        console.error('Error fetching states:', error);
      }
    );
  }
  getCitiesbystate(stateid: any) {
    this.mentorService.getstateCityList(stateid).subscribe(
      (response: any) => {
        console.log('146____',response.result)

        this.selected_citiesapi = response.result.map((city: any) => ({
          item_id: city.id,  // Ensure ID is correctly assigned
          item_text: city.name  // Ensure Name is correctly assigned
        }));
        this.dropdownCity = [...this.dropdownCity,...this.selected_citiesapi, ].filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.item_id === value.item_id)
        );
      },
      (error) => {
        console.error('Error fetching states:', error);
      }
    );
  }

  onCountrySelect(event: any) {
    const selectedCountryId = event.item_id; // Get selected country's ID
    localStorage.setItem('selectedCountryId', selectedCountryId);
    console.log('****', selectedCountryId)
    this.getallStates(selectedCountryId); // Fetch related states
    this.selectstate = []; // Reset the selected states when country changes
  }
  onStateSelect(event:any){
    const selectedstateId = event.item_id; // Get selected country's ID
    localStorage.setItem('selectedStateId', selectedstateId);
this.getCitiesbystate(selectedstateId);
this.selectcity =[];

  }
  onStateDeSelect(event: any) {

    const deselectedStateId = event.item_id; // Get deselected state's ID
    console.log('Deselected State ID:', deselectedStateId);
  
    this.mentorService.getstateCityList(deselectedStateId).subscribe(
      (response: any) => {
        console.log('Cities to remove:', response.result);
  
        const citiesToRemove = response.result.map((city: any) => city.id);
  
        // Remove cities that belong to the deselected state
        this.dropdownCity = this.dropdownCity.filter(
          (city) => !citiesToRemove.includes(city.item_id)
        );
  
        // Clear selected cities if they were from the deselected state
        this.selectcity = this.selectcity.filter(
          (city) => !citiesToRemove.includes(city.item_id)
        );
        this.selectcity = [];
      },
      (error) => {
        console.error('Error fetching cities for deselected state:', error);
      }
    );
  }
  
  // async onStateDeSelect(event: any) {
  //   console.log('Deselected State:', event);
  
  //   const deselectedStateId = event.item_id;
  
  //   // Fetch cities of the deselected state
  //   this.cityservice.getCitiesByState(deselectedStateId).then(response => {
  //     const citiesToRemove = response.result.map(city => city.id);
  
  //     // Remove cities belonging to the deselected state
  //     this.selected_citiesapi = this.selected_citiesapi.filter(
  //       city => !citiesToRemove.includes(city.item_id)
  //     );
  //   }).catch(error => {
  //     console.error(`Error fetching cities for deselected state ID ${deselectedStateId}:`, error);
  //   });
  // }
}



