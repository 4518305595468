<div class="table-wrapper">
    <div class="d-flex justify-content-end mb-3">
        <a class="btn btn-primary " routerLink = "/admin/mentor/forms">Create Form</a>
    </div>
    <table class="table table-striped mt-3">
      <thead>
              <tr>
                <th>Sl No</th>
                  <th>Form Name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th class="text-center">Actions</th>
              </tr>
          </thead>
          <tbody *ngIf="forms?.length > 0; else noRecords">
            <tr *ngFor="let d of forms ; let i = index">
              <th scope="row" class="text-center">{{i+1}}</th>
              <td>{{d.form_name | titlecase}}</td>
              <td>{{d.created_at | date}}</td>
              <td>{{d.status}}</td>
              <td class="d-flex justify-content-center">
               
                <div class="d-flex">
                  <div *ngIf="d.status === 'PENDING'" class="d-flex">
                    <a class="dropbtn fa fa-check mr-2" style="color:blue"  (click)="changestatus(d.id, 'APPROVED')"></a>
                    <a class="dropbtn fa fa-close mr-2 " style="color:red" (click)="changestatus(d.id, 'REJECTED')"></a>
                  </div>
                  <div *ngIf="d.status === 'APPROVED'">
                    <a class="dropbtn fa fa-close mr-2 ml-4" style="color:red" (click)="changestatus(d.id, 'REJECTED')"></a>
                  </div >
                  <div *ngIf="d.status === 'REJECTED'">
                    <a class="dropbtn fa fa-check mr-4" style="color:blue" (click)="changestatus(d.id, 'APPROVED')"></a>
                  </div >
                  <div>
                    <a class="fa fa-eye arrow" routerLink = "/admin/mentorformpreview/{{d.id}}"  style = "color:green" ></a>  
                  </div>
              </div>

           </td>
            </tr>
          </tbody>
          <ng-template #noRecords>
            <tbody>
              <tr>
                <td colspan="8" class="no-records-found">No Data found.</td>
              </tr>
            </tbody>
          </ng-template>
     </table>

  </div>
