<div #section >


<div class="col-12 col-sm-12 topmargin" >
    <div class="col-md-6 m-auto" >
    <nb-card>
      <nb-card-header>Admin User</nb-card-header>
      <nb-card-body class="container-fluid">
        <form class="ng-pristine ng-invalid" [formGroup]="usersForm" >
            <div class="form-control-group">
                    <input type="text" nbInput fullWidth status="basic" name="fname" id="input-name" placeholder="First Name"
                     autofocus="" formControlName="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
                        class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">
                        <div *ngIf="submitted && f.firstname.errors" class="text-danger">
                            <div *ngIf="f.firstname.errors['required']">First name is required</div>
                          </div>
                 
            </div>  

            <div class="form-control-group">
                <input type="text" nbInput fullWidth status="basic" name="lname" id="input-name" placeholder="Last Name"
                [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" autofocus="" formControlName="lastname"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">
                    <div *ngIf="submitted && f.lastname.errors" class="text-danger">
                        <div *ngIf="f.lastname.errors['required']">Last name is required</div>
                      </div>
        </div> 
            <div class="form-control-group">
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Email"
                    fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autofocus="" formControlName="email"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">
                    <div *ngIf="submitted && f.email.errors" class="text-danger">
                        <div *ngIf="f.email.errors['required']">email is required</div>
                        <div *ngIf="f.email.errors['pattern']">email is invalid</div>
                      </div>
            </div>  
            <div class="form-control-group">
                <input nbinput="" fullwidth="" name="email" id="input-email" placeholder="Mobile Number"
                    fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" autofocus="" formControlName="mobile"
                    class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space">
                    <div *ngIf="submitted && f.mobile.errors" class="text-danger">
                        <div *ngIf="f.mobile.errors['required']">phone number required</div>
                        <div *ngIf="f.mobile.errors['pattern']">phone number should be of 10 digits</div>
                      </div>
            </div>

            <div class="form-control-group" >
                
                <!-- <input nbinput="" type="password" class="input-full-width size-large status-basic shape-rectangle ng-pristine ng-invalid nb-transition space" fullwidth="" name="password" id="input-email" placeholder="Password"
                    fieldsize="large" formControlName="password" value="" required >  -->
                    <input formControlName="password" id="input-email" nbinput="" fullwidth="" name="password" [type]="show?'text':'password'"
                    id="input-password" placeholder="Password" fieldsize="large" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                    class="input-full-width size-large status-basic shape-rectangle ng-untouched ng-pristine ng-invalid nb-transition space">
               

                     <i class="fa fa-eye-slash" id="eye" (click)="showpassword()" *ngIf="showEye;else hideeye"></i>
                    <ng-template #hideeye>
                        <i class="fa fa-eye" aria-hidden="true" (click)="hidepassword()"></i>
                    </ng-template>
                
                    <div *ngIf="submitted && f.password.errors" class="text-danger">
                        <div *ngIf="f.password.errors['required']">Password required</div>
                        <!-- <div *ngIf="f.password.errors['pattern']">Password must contain 1 letter</div> -->
                        <div *ngIf="usersForm.get('password').hasError('pattern')">Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 special character.</div>
                        <div *ngIf="usersForm.get('password').hasError('invalidPassword') && !f.password.errors['required']">
                            Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 special character.
                          </div>
                      </div>
                    
               
            </div>
            <!-- <div class="form-control-group">
                <nb-select formControlName="role" class="appearance-filled full-width size-medium shape-rectangle status-primary nb-transition space">
                    <nb-option *ngFor="let role of roleList" [value]="role.id">{{role.role_name}}</nb-option>
                </nb-select>
         
            </div> -->
            
            <div *ngIf="usersForm.get('role').value == 13 || usersForm.get('role').value == 95" class="form-control-group">
                <ng-multiselect-dropdown 
                    placeholder="select Implementing agencies"
                    [settings]="dropdownSettings"
                    [data]="allowedImplementingAgencies"
                    formControlName="implementing_agency"
                    ></ng-multiselect-dropdown>
            </div>
            <div *ngIf="usersForm.get('role').value == 13 || usersForm.get('role').value == 95" class="form-control-group">
                <ng-multiselect-dropdown 
                    placeholder="select Program divisions"
                    [settings]="dropdownSettings"
                    [data]="allowedProgramDivisions"
                    formControlName="program_division"
                ></ng-multiselect-dropdown>
            </div>
            <select name=""  id="" class="form-control space" formControlName = "role">
                <option value="">Select a role</option>
                <option value="{{r.id}}" *ngFor="let r of roles">{{r.name}}</option>
            </select>

            <div *ngIf="submitted && f.role.errors" class="text-danger">
                <div *ngIf="f.role.errors['required']">Role is required</div>
              </div>

            <!-- <div class="dropdown w-100">
                <button class="btn btn-info w-100 dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  {{selectrole}}
                </button>
                <div class="dropdown-menu w-100">
                  <a class="dropdown-item" (click)="addRoletoUser(r.id,r.name)"*ngFor="let r of roles">{{r.name}}</a>
                </div>
              </div> -->
            <!-- <div class="dropdown w-100">
                <button class="btn btn-primary form-control dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  {{selectrole}}
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" *ngFor = "let r of roles" (click)="addRoletoUser(r.id,r.role_name)">{{r.name}}</a>
                </div>
              </div> -->
            
            <div class="">
            
                <div class=" d-flex justify-content-center">
                <button nbButton status="primary"
                    size="small"
                    class="appearance-filled size-small shape-rectangle status-primary " (click)="submitForm()" *ngIf="createMode">Create</button>
                <button nbButton status="primary"
                    size="small"
                    class="appearance-filled size-small shape-rectangle status-primary " (click)="updateForms()" *ngIf="editMode"  [disabled]="!usersForm.valid">Update</button>

                    <button nbButton (click)="cancelData()" status="primary"
                    size="small"
                    class="appearance-filled size-small shape-rectangle status-primary" [disabled]="!usersForm.valid">Cancel</button> 
                </div>     
          
            </div>
        </form>
        
      </nb-card-body>
    </nb-card>
    </div>

        <nb-card>
            <nb-card-header>Admin User Details</nb-card-header>
            <nb-card-body>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Admin Name</th>
                        <th class="headerfix  d-lg-table-cell  ">Role Name</th>
                        <th>Email</th>
                        <th class="headerfix d-lg-table-cell">Mobile Number</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of allusers">
                        <td>{{data.first_name}} {{data.last_name}}</td>
                        <td class="headerfix">{{data.name}}</td>
                        <td>{{data.email}}</td>
                        <td>{{data.mobile}}</td>
                        <td>
                            <span  class="fa fa-edit arrow" style = "color:blue" (click)="editData(data,section)"></span>

                            <!-- <span class="fa fa-trash arrow" style = "color:red" (click)="deleteUser(data)"></span> -->
                        </td>                     
                    </tr>
                </tbody>
            </table>
        </nb-card-body>
    </nb-card>
</div>
</div>


