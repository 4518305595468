<div *ngIf="isDownloading" class="loader-overlay">
  <div class="loader"></div>
  <!-- <p>Downloading...</p> -->
</div>

<div class="container-grid col-sm-12">
    <!-- <nb-card class="col-sm-12">
        <nb-card-header class="float-right">Media</nb-card-header>
    </nb-card> -->
    <h2>Media Gallery</h2>
   
        <form
          [formGroup]="mediaForm"
          class="form-control-group col-12"
         enctype="multipart/form-data p-4"
        >
        <div class="row">
          <div class="col-sm-6">
            <label for="" class="label-required">Event</label>
           
            <select name="" id="" class="form-control"  formControlName="EventCategory">
                <option value="" selected [disabled]="!selectedValue">Select event</option>
                <option [value]="item.id" *ngFor="let item of categoryList" (click)="selectItem(item)">{{item.category_name}}</option>

            </select>
            <div *ngIf="mediaForm.get('EventCategory').invalid && mediaForm.get('EventCategory').touched">
              <div *ngIf="mediaForm.get('EventCategory').errors.required" class="text-danger">Event is required.</div>
            </div>
            <div class="addEvent">
                <button class="btn btn_event" data-toggle="modal" data-target="#eventModal">
                    Add Event  <i class="fa fa-plus text-white font-weight-bold"></i>
                </button>
               

            </div>
          </div>
            <div class="col-sm-6">
                <label for="" class="label-required"> Category </label>
                <div class="input-group mb-3">
                 
                  <input
                  type="text"
                  formControlName="EventName"
                  class="form-control"
                  placeholder="Category Name"
                />
                 
                </div>
                <div *ngIf="mediaForm.get('EventName').invalid && mediaForm.get('EventName').touched">
                    <div *ngIf="mediaForm.get('EventName').errors.required" class="text-danger"> Category is required.</div>
                  </div>
              </div>
       
         
          </div>
          <div class="row py-2">
          <div class="col-sm-6">
            <label class="" class="label-required">Select File</label>
            <div class="custom-file">
              <input type="file" class="custom-file-input" formControlName="mediaImage" id="customFile" name="uploadedImage"
                (change)="onnewFileSelect($event)" #UploadFileInput >
              <label class="custom-file-label" for="customFile">{{fileInputLabel || 'Choose File'}}</label>
            </div>
            <span style="color: black;">(File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG , SVG)</span>
            <div *ngIf="mediaForm.get('mediaImage').invalid && mediaForm.get('mediaImage').touched">
              <div *ngIf="mediaForm.get('mediaImage').errors.required" class="text-danger">Image is required.</div>
            </div>
            <div  *ngIf="filetypeerr">
              <p  class="text-danger"> File format is incorrect</p>
            </div>
            <div   *ngIf="filetypeerr">
              <p class="text-danger">File size should not exceed 2 MB</p>
            </div>
          </div>
          </div>
        </form>
        <div class="button-wrapper">
          <button class="btn btn-primary mr-2 custombtn" (click)="cancel()">Cancel</button>
          <button class="btn btn-primary custombtn"  (click)="publishEvent()" *ngIf="createMode">Create</button>
          <button class="btn btn-primary custombtn" (click)="updateForms()" *ngIf="editMode">Update</button>
        </div>


        <form [formGroup] ="pressFilterForm" class="p-2">
          <div class="row mb-3   ">
            
            <!-- <div class="col-md-4">
              <label class="mx-3">Status</label>    
              <ng-multiselect-dropdown
                          [placeholder]="'select status'"
                          [settings]="dropdownSettingst"
                          [data]="dropdownstatus"
                          [(ngModel)]="selectedStatus"
                          [ngModelOptions]="{standalone: true}"
                        >
                </ng-multiselect-dropdown>
            </div> -->


            <div class="col-md-4">
              <label  class="mx-3">From Date</label>
              <input type="date" formControlName="start_date" id="start_date"
                class="form-control date" style="margin: 0px;"
               
              />
            </div>
            <div class="col-md-4">
               <label class="mx-3">To Date</label>
                    <input type="date" formControlName="end_date" id="end_date"
                      class="form-control date" style="margin: 0px;" />
            </div>
            <div class=" col-md-4  mt-4">
              <input class="form-control " type="text" [(ngModel)]="searchText" placeholder="Search by Title..." [ngModelOptions]="{standalone: true}">
            </div>

          </div>

          <div class="col-md-12 d-flex justify-content-end pr-0 pb-4">
            <button type="button" class="btn btn-primary me-2" (click)="filtersItem()">Filter</button>
            <button type="button" class="btn btn-secondary mx-2" (click)="resetFilter()">Reset</button>
            <button type="button" class="btn btn-success" (click)="downloadMediaGalleryList()">
              <i class="fas fa-download"></i> Download
            </button>
          </div>

       
          
             </form>
      
<!-- 

        <div class="row">
          <div class="download-bt  col-12 d-flex button-wrapper justify-content-end">
            <button class="btn btn-success" (click)="downloadlabList()"><i class="fas fa-download"></i> Download</button>
          </div>
         </div> -->

        <table class="table table-striped table-responsive table-wrapper">
          <thead>
            <tr>
              <th scope="col" class=" headerfix text-center">Sl No</th>
              <th class="headerfix">Posted on</th>
              <th scope="col" class="text-center">Name</th>
              <th scope="col" class="text-center">Description</th>
              <th scope="col">Image</th> 
              <th class="headerfix" scope="col">Start Date</th> 
              <th class="headerfix" scope="col">End Date</th>            
              <th scope="col">Status</th>
              <th scope="col" width="200px">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="categoryList?.length > 0; else noRecords">
            <tr  *ngFor="let d of categoryList; let i = index" >
              <!-- <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th> -->
              <th scope="row" class="text-center">{{getSerialNo(i+1)}}</th>

              <td class="headerfix">{{d.created_at |  date}}</td>

              <td scope="col-2" width="200px">{{ truncateText(d.category_name, 100) }} <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore"  class="readMore" style="color:#005a95" > Read more</span></td> 
              <td scope="col-2">{{ truncateText(d.description, 100) }} <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore"    style="color:#005a95" class="readMore">Read more</span></td>              
              <td class="m_col headerfix" style="word-break: break-all;"><a href="{{d.images[0].url}}" target="_blank">Click Here</a></td>              
              <td class="headerfix">{{d.start_date |  date}}</td>
              <td class="headerfix">{{d.end_date |  date}}</td>
              <td>{{d.status}} </td>
  
              <td >
                 <!-- <div class="dropdown mr-3">
                  <a class="dropbtn fa fa-check" style = "color:blue"></a>
                  <div class="dropdown-content" >
                    <a *ngIf="d.news_status === 'active'" (click)="changestatus(d,'inactive')">inactive</a>
                    <a *ngIf="d.news_status === 'inactive'" (click)="changestatus(d,'active')">active</a>
                  </div>
                </div> -->
                <div class="d-flex align-items-center ">                 
                  <!-- <a class="fa fa-edit mr-3 arrow" style = "color:blue" (click)="editData(d, section)" ></a> -->
                  <!-- <a class="fa fa-trash mr-3 arrow" style = "color:red" (click)="deleteMediaData(d.media_id)" ></a> -->
                  <a class="fa fa-edit mr-3 arrow" style = "color:blue" data-toggle="modal" data-target="#exampleModal" (click)="preview(d.id)" ></a>
                  <!-- <a class="fa fa-edit mr-3 arrow" style = "color:blue" data-toggle="modal" data-target="#exampleModal" (click)=getIndividualCategory(d.id)></a> -->
                  <a class="fa fa-trash mr-3 arrow" style = "color:red" data-toggle="modal" (click)="deleteEventData(d.id)"></a>
                  </div>
  
              </td>
            </tr>
          
          </tbody>
          <ng-template #noRecords>
            <tbody>
              <tr>
                <td colspan="6" class="no-records-found">No Data found.</td>
              </tr>
            </tbody>
          </ng-template>
        </table>
        <app-pagination 
 [totalpages]="totalpages" 
 [pageno]="pageno"
 [selectedindex]="selectedindex"
 (pageChanged)="paginate_startup($event)">
</app-pagination>
        <!-- <pagination-controls *ngIf="categoryList?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls> -->
    </div>

    <!-- Modal -->
<div class="modal custom-modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">
      <form [formGroup]="editEvent">
      <div class="modal-header ">
        <h3 class="modal-title " id="exampleModalLabel">Preview</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="container-fluid ">
          <label for="basic-url">Category Name</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <input type="text" formControlName="category_name" class="form-control mb-2" placeholder="" aria-label="Username" value="{{event?.category_name}}" aria-describedby="basic-addon1">
          </div>

          <label for="basic-url">Start Date</label>
          <div class="input-group">
            <input type="date" formControlName = "start_date" class="form-control mb-2" aria-label="Username"  aria-describedby="basic-addon1">
          </div>

          <label for="basic-url">End Date</label>
          <div class="input-group">
            <input type="date" formControlName = "end_date" class="form-control mb-2" aria-label="Username" aria-describedby="basic-addon1">
          </div>

          <label for="basic-url">Description</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <textarea type="text" formControlName = "description" class="form-control mb-2" placeholder="" aria-label="Username" aria-describedby="basic-addon1"></textarea>
          </div>
          <label for="basic-url">Images</label>
          <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <div class="d-flex justify-content-center align-items-center mb-2">
          <div *ngFor = "let i of event?.images;let j = index" id="image_{{j}}">
            <img [src]="i.url" alt="image" style="width: 100%;">
            <i class="fa fa-times-circle float-right" (click)="removeimage(j)" aria-hidden="true"></i>
          </div>
          <!-- <div>
            <button>Add Images</button>
          </div> -->
          
        </div>
        </div>

  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="update_event()">Update</button>
      </div>
    </div>
  </form>
  </div>
  </div>
  </div>

  <!-- event modal -->
  <div class="modal custom-modal " id="eventModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header ">
          <h3 class="modal-title " id="exampleModalLabel">Add Event</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ">
            
          <div class="container-fluid ">
            <form [formGroup]="categoryForm">
            <div>
                <label for="basic-url">Event Name</label>
                <div class="input-group">                 
                  <input type="text" class="form-control mb-2" placeholder=""  formControlName="CategoryName" >
                </div>
            </div>

            <div>
                <label for="basic-url">Event Description</label>
                <div class="input-group">
                    <textarea name="" id="" cols="30" rows="5" formControlName="CategoryDesc" class="form-control mb-2"></textarea>            
                 
                </div>
            </div>
            <div>
                <label for="basic-url">Event Image</label>
                <div class="input-group">
                    <input type="file" class="form-control mb-2" placeholder=""  (change)="onFileSelect($event)" #UploadFileInput1>           
                 
                </div>
            </div>
            <div>
                <label for="basic-url">Start Date</label>
                <div class="input-group">
                    <input type="date" class="form-control mb-2" placeholder=""  formControlName="StartDate">           
                 
                </div>
            </div>
            <div>
                <label for="basic-url">End Date</label>
                <div class="input-group">
                    <input type="date" class="form-control mb-2" placeholder=""  formControlName="EndDate">           
                 
                </div>
            </div>
          
  
           
    
           
        </form>

        
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="createCategory()">Create</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
      
    </div>
    </div>
    </div>
  
   