<!-- Guest House form-->
<div class="mb-3 ml-1 mr-1">
  <div *ngIf="isDownloading" class="loader-overlay">
    <div class="loader"></div>
    <!-- <p>Downloading...</p> -->
  </div>
  <div>
    <h2 class="h2 bg-white mx-auto text-center">Venue Space Details</h2>
  </div>
  <div class="d-flex justify-content-end">
    <a
      class="btn btn-primary float-right mb-2 text-white mr-4 ml-5"
      (click)="gotosection(section)"
      >Venue Space Listing</a
    >
    <a
      class="btn btn-primary float-right mb-2 text-white"
      (click)="gotoBooking()"
      >Bookings Listing</a
    >
  </div>
  <div class="container-grid" id="lab_form">
    <form [formGroup]="guestHouseForm" #section1>
      <div class="row">
        <div class="col-md-6 ml-3 mt-3 mr-3">
          <div class="form-group">
            <label for="labFacility" class="form-label">Name of the Venue</label>
            <span class="text-danger">*</span>
            <input
              type="text"
              class="form-control"
              placeholder="Please enter Name of the Venue"
              formControlName="name"
            />
            <div
              *ngIf="
                guestHouseForm.get('name').invalid &&
                guestHouseForm.get('name').touched
              "
            >
              <div
                *ngIf="guestHouseForm.get('name').errors.required"
                class="text-danger"
              >
                Name is required.
              </div>
              <div
                *ngIf="guestHouseForm.get('name').errors.pattern"
                class="text-danger"
              >
               Valid Name is required.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="labFacility" class="form-label"
              >Total No. of occupancy in the Venue</label
            ><span class="text-danger">*</span>
            <input
              type="number"
              class="form-control"
              placeholder="Please enter Total No. of occupancy in the Venue"
              formControlName="availableRoomsNo"
            />
            <div
              *ngIf="
                guestHouseForm.get('availableRoomsNo').invalid &&
                guestHouseForm.get('availableRoomsNo').touched
              "
            >
              <div
                *ngIf="guestHouseForm.get('availableRoomsNo').errors.required"
                class="text-danger"
              >
              Total No. of occupancy in the Venue is required.
              </div>

              <div
                *ngIf="guestHouseForm.get('availableRoomsNo').errors.pattern"
                class="text-danger"
              >
                Number is required.
              </div>
            </div>
          </div>

          <!-- <div class="form-group">
            <label for="labFacility" class="form-label">Food Type</label>
            <span class="text-danger">*</span>
            <select class="custom-select" formControlName="foodType">
              <option disabled selected value="undefined">
                Select an option
              </option>
              <option
                *ngFor="let foodType of arrfoodType"
                [ngValue]="foodType.name"
              >
                {{ foodType.name }}
              </option>
            </select>

            <div
              *ngIf="
                guestHouseForm.get('foodType').invalid &&
                guestHouseForm.get('foodType').touched
              "
            >
              <div
                *ngIf="guestHouseForm.get('foodType').errors.required"
                class="text-danger"
              >
                Food Type is required.
              </div>
            </div>
          </div> -->

          <!-- <div class="form-group">
            <label for="labFacility" class="form-label">Sharing Type</label
            ><span class="text-danger">*</span>
            <select class="custom-select" formControlName="shareType">
              <option selected>Select an option</option>
              <option
                *ngFor="let shareType of arrshareType"
                [ngValue]="shareType.name"
              >
                {{ shareType.name }}
              </option>
            </select>

            <div
              *ngIf="
                guestHouseForm.get('shareType').invalid &&
                guestHouseForm.get('shareType').touched
              "
            >
              <div
                *ngIf="guestHouseForm.get('shareType').errors.required"
                class="text-danger"
              >
                Sharing Type is required.
              </div>
            </div>
          </div> -->
        </div>
        <!-- end of col-lg-6-->
        <!-- <div class="col-lg-5 mt-3">
            <div class="row clearfix">
                <div class="labfacility-image card">
                    <img
                      [src]="url"
                      *ngIf="url"
                      class="img-fluid img-responsive"
                    />
              </div>
              <div class="col-md-5">
                <label for="company-logo"
                  >Guest House Image1</label
                >
                  <input
                    type="file" 
                    (change)="selectFile($event)"
                  />
              </div>
            </div>
          </div> -->

        <div class="col-sm-5 mt-3">
          <div>
            <label>Venue Image 1</label><span class="text-danger">*</span>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                (change)="onFileSelect($event, 0)"
                accept=".jpg,.png,.jpeg"
                multiple
              />
              
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[0]" for="customFile"><a href="{{previewimageurl[0]}}" target="_blank">View Image</a></button>
              <label class="custom-file-label" for="customFile">{{
                images[0] || "Choose File"
              }}</label>
              <div
                *ngIf="
                  guestHouseForm.get('_img1').invalid &&
                  guestHouseForm.get('_img1').touched
                "
              >
                <div
                  *ngIf="guestHouseForm.get('_img1').errors.required"
                  class="text-danger"
                >
                  File is required.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <label>Venue Image 2</label>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                (change)="onFileSelect($event, 1)"
                accept=".jpg,.png,.jpeg"
                multiple
              />
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[1]" for="customFile"><a href="{{previewimageurl[1]}}" target="_blank">View Image</a></button>
            <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[1]" (click)="clearImage(1)">Remove Image</button>
              <label class="custom-file-label" for="customFile">{{
                images[1] || "Choose File"
              }}</label>
              <div
                *ngIf="
                  guestHouseForm.get('_img2').invalid &&
                  guestHouseForm.get('_img2').touched
                "
              >
                <div
                  *ngIf="guestHouseForm.get('_img2').errors.required"
                  class="text-danger"
                >
                  File is required.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <label>Venue Image 3</label><!--<span class="text-danger">*</span>-->
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                (change)="onFileSelect($event, 2)"
                accept=".jpg,.png,.jpeg"
                multiple
              />
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[2]" for="customFile"><a href="{{previewimageurl[2]}}" target="_blank">View Image</a></button>
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[2]" (click)="clearImage(2)">Remove Image</button>
              <label class="custom-file-label" for="customFile">{{
                images[2] || "Choose File"
              }}</label>
              <div
                *ngIf="
                  guestHouseForm.get('_img3').invalid &&
                  guestHouseForm.get('_img3').touched
                "
              >
                <div
                  *ngIf="guestHouseForm.get('_img3').errors.required"
                  class="text-danger"
                >
                  File is required.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <label>Venue Image 4</label><!--<span class="text-danger">*</span>-->
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="customFile"
                (change)="onFileSelect($event, 3)"
                accept=".jpg,.png,.jpeg"
                multiple
              />
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[3]" for="customFile"><a href="{{previewimageurl[3]}}" target="_blank">View Image</a></button>
              <button class="rm_bt" *ngIf="previewimageurl && previewimageurl[3]" (click)="clearImage(3)">Remove Image</button>
              <label class="custom-file-label" for="customFile">{{
                images[3] || "Choose File"
              }}</label>
              <div
                *ngIf="
                  guestHouseForm.get('_img4').invalid &&
                  guestHouseForm.get('_img4').touched
                "
              >
                <div
                  *ngIf="guestHouseForm.get('_img4').errors.required"
                  class="text-danger"
                >
                  File is required.
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <p class="text-danger">
              <span style="color: #444444">Note:</span> Please upload
              PNG/JPEG/JPG. Maximum size of the file is 2MB.
            </p>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <fieldset class="border p-2">
          <legend class="float-none w-auto p-2">Location</legend>

          <div class="form-group">
            <label for="labFacility" class="form-label"> Address</label
            ><span class="text-danger">*</span>
            <input
              type="text"
              class="form-control"
              formControlName="addressLine1"
              placeholder="Please enter Address"
            />
            <div
              *ngIf="
                guestHouseForm.get('addressLine1').invalid &&
                guestHouseForm.get('addressLine1').touched
              "
            >
              <div
                *ngIf="guestHouseForm.get('addressLine1').errors.required"
                class="text-danger"
              >
                Address is required.
              </div>

            <div
              *ngIf="guestHouseForm.get('addressLine1').errors.pattern"
              class="text-danger"
            >
             Valid Address is required.
            </div>

            </div>
          </div>
          <div class="form-group">
            <label for="labFacility" class="form-label">State </label>
            <span class="text-danger">*</span>
            <!-- <select class="custom-select" formControlName="stateName">
              <option selected value="">Please select State</option>
              <option *ngFor="let states of arrstates" [ngValue]="states">
                {{ states }}
              </option>
            </select> -->
            <ng-select 
            [items]="dropdownstates"
            bindLabel="label"  
  bindValue="value"  
            [ngClass]="{
              'is-invalid':
                (
                  guestHouseForm.get('stateName')?.invalid) ||
                (guestHouseForm.get('stateName')?.invalid &&
                guestHouseForm.get('stateName')?.touched)
            }"            theme="default"
            placeholder="Select state"
            (change)="onStateSelect($event)"
            formControlName="stateName"
            [dropdownPosition]="'top'"
          ></ng-select>


            <div
              *ngIf="
                guestHouseForm.get('stateName').invalid &&
                guestHouseForm.get('stateName').touched
              "
            >
              <div
                *ngIf="guestHouseForm.get('stateName').errors.required"
                class="text-danger"
              >
                State is required.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="labFacility" class="form-label">City </label>
            <span class="text-danger">*</span>
            <!-- <input
              type="text"
              class="form-control"
              formControlName="cityName"
              placeholder="Please enter City"
            /> -->
            <ng-select 
            [items]="selected_cities"
            [ngClass]="{
              'is-invalid':
                (
                  guestHouseForm.get('cityName')?.invalid) ||
                (guestHouseForm.get('cityName')?.invalid &&
                guestHouseForm.get('cityName')?.touched)
            }"            theme="default"
            placeholder="Select City"
            bindLabel="city"
            bindValue="city"
           
            formControlName="cityName"
            [dropdownPosition]="'top'"
          ></ng-select> 
            <div
              *ngIf="
                guestHouseForm.get('cityName').invalid &&
                guestHouseForm.get('cityName').touched
              "
            >
              <div
                *ngIf="guestHouseForm.get('cityName').errors.required"
                class="text-danger"
              >
                City is required.
              </div>

              <div
              *ngIf="guestHouseForm.get('cityName').errors.pattern"
              class="text-danger"
            >
             Valid City is required.
            </div>

            </div>
          </div>
         

          <div class="form-group">
            <label for="labFacility" class="form-label">Zip Code </label>
            <span class="text-danger">*</span>
            <input type="number" class="form-control" placeholder="Please enter Zip Code" formControlName="zipCode" />
            <div
              *ngIf="
                guestHouseForm.get('zipCode').invalid &&
                guestHouseForm.get('zipCode').touched
              "
            >
              <div
                *ngIf="guestHouseForm.get('zipCode').errors.required"
                class="text-danger"
              >
                Zip Code is required.
              </div>

              <div
                *ngIf="guestHouseForm.get('zipCode').errors.pattern"
                class="text-danger"
              >
                Valid Zip Code is required.
              </div>


            </div>
          </div>
        </fieldset>
      </div>
      <div class="form-group ml-3 mr-3 mt-3">
        <label for="labFacility" class="form-label"
          >Any Other Information</label
        >
        <!-- <textarea
          class="form-control text_area_control"
          id="textArea"
          formControlName="description"
          rows="8"
        ></textarea> -->
        <ckeditor
        [editor]="Editor"
        [config]="editorConfig"
        [config]="{ placeholder: 'Please enter Any Other Information' }"
        placeholder="Please enter Any Other Information"
        formControlName="description"
        #myEditor
      ></ckeditor>
        <!-- <ckeditor [editor]="Editor" [config]="editorConfig" formControlName="description" #myEditor></ckeditor> -->
        <div
          *ngIf="
            guestHouseForm.get('description').invalid &&
            guestHouseForm.get('description').touched
          "
        >
          <div
            *ngIf="guestHouseForm.get('description').errors.required"
            class="text-danger"
          >
            Other Info is required.
          </div>

          <div
          *ngIf="guestHouseForm.get('description').errors.pattern"
          class="text-danger"
        >
         Valid Any Other Information is required.
        </div>


        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <fieldset class="border p-2">
              <legend class="float-none w-auto p-2">Incharge Details</legend>

              <div class="form-group">
                <label for="labFacility" class="form-label">Name </label>
                <span class="text-danger">*</span>
                <input
                  type="text"
                  class="form-control"
                  formControlName="inchargeName"
                  placeholder="Please enter Name"
                />
                <div
                  *ngIf="
                    guestHouseForm.get('inchargeName').invalid &&
                    guestHouseForm.get('inchargeName').touched
                  "
                >
                  <div
                    *ngIf="guestHouseForm.get('inchargeName').errors.required"
                    class="text-danger"
                  >
                    Name is required.
                  </div>

                <div
                  *ngIf="guestHouseForm.get('inchargeName').errors.pattern"
                  class="text-danger"
                >
                Valid Name is required.
                </div>

                </div>
              </div>

              <div class="form-group">
                <label for="labFacility" class="form-label"
                  >Contact Number </label
                ><span class="text-danger">*</span>
                <input
                  type="number"
                  class="form-control"
                  formControlName="contactNumber"
                  placeholder="Please enter Contact Number"
                />
                <div
                  *ngIf="
                    guestHouseForm.get('contactNumber').invalid &&
                    guestHouseForm.get('contactNumber').touched
                  "
                >
                  <div
                    *ngIf="guestHouseForm.get('contactNumber').errors.required"
                    class="text-danger"
                  >
                    Contact is required.
                  </div>
                  <!-- <div
                    *ngIf="labFacilityForm.get('contactNumber').errors.pattern"
                    class="text-danger"
                  >
                    Contact number is invalid
                  </div> -->
                </div>
              </div>

              <div class="form-group">
                <label for="labFacility" class="form-label">Designation </label>
                <span class="text-danger">*</span>
                <input
                  type="text"
                  class="form-control"
                  formControlName="designation"
                  placeholder="Please enter Designation"
                />
                <div
                  *ngIf="
                    guestHouseForm.get('designation').invalid &&
                    guestHouseForm.get('designation').touched
                  "
                >
                  <div
                    *ngIf="guestHouseForm.get('designation').errors.required"
                    class="text-danger"
                  >
                    Designation is required.
                  </div>

                  <div
                  *ngIf="guestHouseForm.get('designation').errors.pattern"
                  class="text-danger"
                >
                Valid Designation is required.
                </div>
                </div>
              </div>

              <div class="form-group">
                <label for="labFacility" class="form-label"
                  >Email Address </label
                ><span class="text-danger">*</span>
                <input
                  type="email"
                  class="form-control"
                  formControlName="emailAddress"
                  placeholder="Please enter Email Address"
                />
                <div
                  *ngIf="
                    guestHouseForm.get('emailAddress').invalid &&
                    guestHouseForm.get('emailAddress').touched
                  "
                >
                  <div
                    *ngIf="guestHouseForm.get('emailAddress').errors.required"
                    class="text-danger"
                  >
                    Email is required.
                  </div>
                  <div
                    *ngIf="guestHouseForm.get('emailAddress').errors.pattern"
                    class="text-danger"
                  >
                    Valid Email is required.
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-md-6">
            <fieldset class="border p-2">
              <legend class="float-none w-auto p-2">Accomodation</legend>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label for="labFacility" class="form-label"
                      >Delegate's Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="delegatesName"
                      placeholder="Meity Delegate's"
                      readonly
                    />
                  </div>
                  <div class="col-sm-6">
                    <label for="labFacility" class="form-label"
                      >Charge (₹)</label
                    ><span class="text-danger">*</span>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="delegatesCharge"
                      placeholder="Please enter Meity Delegate's Charge"
                    />
                    <div
                      *ngIf="
                        guestHouseForm.get('delegatesCharge').invalid &&
                        guestHouseForm.get('delegatesCharge').touched
                      "
                    >
                      <div
                        *ngIf="
                          guestHouseForm.get('delegatesCharge').errors.required
                        "
                        class="text-danger"
                      >
                        Meity Delegate's Charge is required.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6 mt-3">
                    <label for="labFacility" class="form-label">StartUp </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="startupName"
                      placeholder="start Up"
                      readonly
                    />
                  </div>
                  <div class="col-sm-6 mt-3">
                    <label for="labFacility" class="form-label"
                      >Charge (₹)</label
                    ><span class="text-danger">*</span>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="startupCharge"
                      [pattern]="numericPattern"
                      placeholder="Please enter Startup Charge"
                    />

                    <div
                      *ngIf="
                        guestHouseForm.get('startupCharge').invalid &&
                        guestHouseForm.get('startupCharge').touched
                      "
                    >
                      <div
                        *ngIf="
                          guestHouseForm.get('startupCharge').errors.required
                        "
                        class="text-danger"
                      >
                        Startup Charge is required.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6 mt-3">
                    <label for="guestHouse" class="form-label"
                      >IC Delegates/Officials
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="icDelegates"
                      placeholder="IC Delegates/Officials "
                      readonly
                    />
                  </div>
                  <div class="col-sm-6 mt-3">
                    <label for="guestHouse" class="form-label">Charge (₹)</label
                    ><span class="text-danger">*</span>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="icDelegatesCharges"
                      [pattern]="numericPattern"
                      placeholder="Please enter IC Delegates/Officials Charge"
                    />

                    <div
                      *ngIf="
                        guestHouseForm.get('icDelegatesCharges').invalid &&
                        guestHouseForm.get('icDelegatesCharges').touched
                      "
                    >
                      <div
                        *ngIf="
                          guestHouseForm.get('icDelegatesCharges').errors
                            .required
                        "
                        class="text-danger"
                      >
                        IC Delegates Charge is required.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6 mt-3">
                    <label for="labFacility" class="form-label">Other <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="otherName"
                      placeholder="Please enter Other Name"
                    />

                    <div
                      *ngIf="
                        guestHouseForm.get('otherName').invalid &&
                        guestHouseForm.get('otherName').touched
                      "
                    >
                      <div
                        *ngIf="
                          guestHouseForm.get('otherName').errors
                            .required
                        "
                        class="text-danger"
                      >
                        Other Name is required.
                      </div>

                      <div
                      *ngIf="guestHouseForm.get('otherName').errors.pattern"
                      class="text-danger"
                    >
                    Valid Other Name is required.
                    </div>

                    </div>


                  </div>
                  <div class="col-sm-6 mt-3">
                    <label for="labFacility" class="form-label"
                      >Charge (₹) <span class="text-danger">*</span></label
                    >
                    <input
                      type="number"
                      class="form-control"
                      formControlName="otherCharges"
                      [pattern]="numericPattern"
                      placeholder="Please enter Other Charge"
                    />

                    <div
                      *ngIf="
                        guestHouseForm.get('otherCharges').invalid &&
                        guestHouseForm.get('otherCharges').touched
                      "
                    >
                      <div
                        *ngIf="
                          guestHouseForm.get('otherCharges').errors
                            .required
                        "
                        class="text-danger"
                      >
                        Other Charge is required.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="d-flex w-100 justify-content-center">
        <button
          class="btn custombtn mr-3 mb-3 mt-3"
          (click)="submitGuestData()"
          *ngIf="createMode"
        >
          Create
        </button>
        <button
          class="btn custombtn mr-3 mb-3 mt-3"
          (click)="updateGuesthouse()"
          *ngIf="editMode"
        >
          Update
        </button>
        <button class="btn custombtn mb-3 mt-3" (click)="cancel()">
          Cancel
        </button>
      </div>
    </form>

    <form [formGroup]="venueFilterForm">
    <div class="d-flex mb-3"> 
      <div class="col-sm-4">
        <label class="mx-3">User Type</label>    
        <select  id="selectedUserType"   class="form-select usertypeselect" formControlName="selectedUserType" (change)="onUserSelectionChange($event)"> 
          <option value="ALL">Select User Type</option>
          <option value="INCUBATOR" selected >INCUBATOR</option>
           <option value="COE" >COE</option>
           <option value="ACCELERATOR">ACCELERATOR</option>
           <option value="ALL">SUPERADMIN</option>
       </select>     
       
    
    </div>
    <div class="col-sm-4">
      <label class="mx-3">User</label>    
    <ng-multiselect-dropdown 
                [placeholder]="''"
                [settings]="dropdownSettings"
                [data]="dropdownuser"
                [(ngModel)]="selectedUser"
                [ngModelOptions]="{standalone: true}"
                
              >
      </ng-multiselect-dropdown>
     
  
  </div>
  <div class="col-sm-4">
    <label class="mx-3">Status</label>    
  <ng-multiselect-dropdown 
              [placeholder]="''"
              [settings]="dropdownSettingst"
              [data]="dropdownstatus"
              [(ngModel)]="selectedStatus"
              [ngModelOptions]="{standalone: true}"
              
            >
    </ng-multiselect-dropdown>
  </div>
  
        </div>
        <div class="d-flex">
          <div class="col-sm-8">
            <div class="input_row">
              <div class="input_box">
                <label for="" class="mx-3">From Date</label>
                <input type="date" formControlName="start_date" id="start_date"
                  class="form-control date"
                 
                />
              </div>
             
              <div class="input_box">
                <label for="">To</label>
                <input type="date" formControlName="end_date" id="end_date"
                  class="form-control date"/>
              </div>
            </div>
           
            
          </div>
        



          <div class="col-4 d-flex justify-content-end align-self-end ms-auto">
       
            <button class="btn btn-primary mr-1" (click)="filtersItem()" *ngIf="filterMode" id="filter"> Filter </button> 
            <button class="btn btn-secondary mr-1" (click)="resetFilters()" >Reset</button>
            <button class="btn btn-success mr-1" (click)="downloadvenueList()"><i class="fas fa-download"></i> Download</button>
         
        </div>
        </div>

        <!-- <div class="d-flex mb-3 justify-content-end">
        
      
        </div> -->
    </form>

 <!-- filter count row -->
 <!-- <div class="filter-container">
     
  <div class="filter-row justify-content-center mt-3">
    <p (click)="filtersItem('ALL')" [class.active]="activeFilter == null">ALL  <span> {{+approvedCount + +rejectedCount + +pendingCount}}</span></p>
    <p (click)="filtersItem('APPROVED')" [class.active]="activeFilter == 'APPROVED'">APPROVED  <span> {{approvedCount}}</span></p>
    <p (click)="filtersItem('DECLINED')" [class.active]="activeFilter == 'DECLINED'">DECLINED  <span> {{rejectedCount}}</span></p>
    <p (click)="filtersItem('PENDING')" [class.active]="activeFilter == 'PENDING'">PENDING  <span> {{pendingCount}}</span></p>
  </div>


</div> -->
<!-- filter count row -->

<div class="filter-container">
  <div class="filter-row justify-content-center mt-3">

  <!-- "View All" should be active only when all three statuses are selected -->
  <p (click)="filtersItem('All')"
     [class.active]="activeFilter?.length === 3">
    View All <span>{{ +approvedCount + +rejectedCount + +pendingCount }}</span>
  </p>

  <p (click)="filtersItem('APPROVED')" 
     [class.active]="activeFilter?.length < 3 && activeFilter?.includes('APPROVED')">
    APPROVED <span>{{approvedCount}}</span>
  </p>

  <p (click)="filtersItem('DECLINED')" 
     [class.active]="activeFilter?.length < 3 && activeFilter?.includes('DECLINED')">
     DECLINED <span>{{rejectedCount}}</span>
  </p>

  <p (click)="filtersItem('PENDING')" 
     [class.active]="activeFilter?.length < 3 && activeFilter?.includes('PENDING')">
    PENDING <span>{{pendingCount}}</span>
  </p>
</div>
</div>



    <div class="table-responsive table-wrapper" #section>
      <table class="table table-striped">
        <thead class="">
          <tr>
            <th scope="col" class="headerfix">Sl No</th>
            <th class="headerfix">Updated Time</th>
            <th class="headerfix">Venue title</th>
            <!-- <th>Sharing Type</th> -->
            <th>Description</th>
            <th class="headerfix" >Incharge Designation</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of list | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
            <th scope="row" class="text-center">{{getSerialNo(i+1)}}</th>

            <!-- <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th> -->
            <td>{{data.updated_at |  date}}</td>
            <td>{{ data.name }}</td>
            <!-- <td>{{ data.sharing_type }}</td> -->
            <td [innerHTML]="data.description"></td>
            <td>{{ data.incharge_details.designation }}</td>
            <td>{{ data.status }}</td>
            <td class="d-flex">

              <div *ngIf="data.status === 'PENDING'" class="d-flex">
                <a
                  class="dropbtn fa fa-check mr-2"
                  style="color: blue"
                  (click)="
                    changestatus(data.id, 'APPROVED', 'guesthouseresource', data.creator)
                  "
                ></a>
                <a
                  class="dropbtn fa fa-close mr-2"
                  style="color: red"
                  (click)="
                    changestatus(data.id, 'DECLINED', 'guesthouseresource', data.creator)
                  "
                ></a>
              </div>
              <div *ngIf="data.status === 'APPROVED'">
                <a
                  class="dropbtn fa fa-close mr-2 ml-4"
                  style="color: red"
                  (click)="
                    changestatus(data.id, 'DECLINED', 'guesthouseresource', data.creator)
                  "
                ></a>
              </div>
              <div *ngIf="data.status === 'DECLINED'">
                <a
                  class="dropbtn fa fa-check mr-4"
                  style="color: blue"
                  (click)="
                    changestatus(data.id, 'APPROVED', 'guesthouseresource', data.creator)
                  "
                ></a>
              </div>

              <span
                class="fa fa-edit cursor-pointer"
                style="color: blue"
                (click)="editData(data, section1)"
              ></span>
              <!-- <span
                class="fa fa-trash ml-2 cursor-pointer"
                style="color: red"
                (click)="deleteData(data.id)"
              ></span> -->
              <a
                class="fa fa-eye ml-2 cursor-pointer"
                style="color: green"
                data-toggle="modal"
                data-target="#exampleModal"
                (click)="preview(data)"
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="text-center" *ngIf="list.length < 1">No data found</p>
      <!-- <pagination-controls *ngIf="paginationEnabled && list?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls> -->
    </div>
    <!-- <Guest House> form-->
  </div>
  <app-pagination 
  [totalpages]="totalpages" 
  [pageno]="pageno"
  [selectedindex]="selectedindex"
  (pageChanged)="paginate_startup($event)">
  </app-pagination>
</div>

<!-- Modal -->
<div
  class="modal custom-modal"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content d-flex">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Preview</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <label for="basic-url">Venue Name</label>
          <div class="input-group">
            <div class="input-group-prepend"></div>
            <input
              type="text"
              class="form-control mb-2"
              placeholder=""
              aria-label="Username"
              value="{{ modaldata.name }}"
              aria-describedby="basic-addon1"
              readonly
            />
          </div>
          <label for="basic-url">Venue Images</label>
          <div class="d-flex justify-content-between align-items-center mb-2">         

            <div  class="col" *ngFor="let img of modaldata.img1">
              <img [src]="img" alt="image" class="rounded" width="150" height="150"  />
            </div>
            <!-- <div  class="col">
              <img *ngIf="modaldata.img2" [src]="modaldata.img2" alt="image" width="150" height="150" />
            </div>
            <div  class="col">
              <img *ngIf="modaldata.img3" [src]="modaldata.img3" alt="image" width="150" height="150" />
            </div>
            <div  class="col">
              <img *ngIf="modaldata.img4" [src]="modaldata.img4" alt="image" width="150" height="150" />
            </div> -->

          </div>

          <div class="mb-2">
            <label for="basic-url">Total No. of Rooms Available </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.availableRoomsNo }}"
              id="fileInput"
              readonly
            />
            <!-- <label for="basic-url">Food Type </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.foodType }}"
              id="fileInput"
              readonly
            /> -->
            <!-- <label for="basic-url">Sharing Type </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.shareType }}"
              id="fileInput"
              readonly
            /> -->
            <label for="basic-url">Any Other Information</label>
            <!-- <input
              class="form-control mb-2"
              value="{{ modaldata.description }}"
              id="fileInput"
              readonly
            /> -->
            <textarea class="form-control" [innerHTML]="modaldata.description" readonly></textarea>
            <div>
              <h2>Incharge Details:</h2>
            </div>

            <label for="basic-url">Name </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.name }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Contact Number</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.phone }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Designation </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.designation }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Email Address</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.incharge_details.email }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">User Type</label>
            <input
              class="form-control mb-2"
              value="{{ userType }}"
              id="fileInput"
              readonly
            /> 
            <label for="basic-url">User Name</label>
            <input
              class="form-control mb-2"
              value="{{ userName }}"
              id="fileInput"
              readonly
            />
            <div>
              <h2>Location:</h2>
            </div>
            <label for="basic-url">Address</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.address_line1 }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">City</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.city }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">State </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.state }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">ZipCode</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.zip_code }}"
              id="fileInput"
              readonly
            />
            <label for="basic-url">Delegate's Name</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.delegatesName }}"
              id="fileInput"
              readonly
            /><label for="basic-url"> Delegate's Charge </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.delegatesCharge }}"
              id="fileInput"
              readonly
            /><label for="basic-url">StartUp Name </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.startupName }}"
              id="fileInput"
              readonly
            /><label for="basic-url">StartUp Charge </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.startupCharge }}"
              id="fileInput"
              readonly
            /><label for="basic-url">IC Delegates/Officials Name</label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.icDelegates }}"
              id="fileInput"
              readonly
            /><label for="basic-url">IC Delegates/Officials Charge </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.icDelegatesCharges }}"
              id="fileInput"
              readonly
            />
            <!-- <label for="basic-url">Other </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.otherName }}"
              id="fileInput"
              readonly/> -->
            <label for="basic-url">{{modaldata.otherCharges}} </label>
            <input
              class="form-control mb-2"
              value="{{ modaldata.otherName }}"
              id="fileInput"
              readonly
            />
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
