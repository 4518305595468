<div *ngIf="isDownloading" class="loader-overlay">
  <div class="loader"></div>

 </div>
<div class="container-grid col-sm-12">
    <nb-card class="col-sm-12">
        <nb-card-header class="float-right">Media</nb-card-header>
    </nb-card>
   
        <form
          [formGroup]="mediaForm"
          class="form-control-group col-12"
         enctype="multipart/form-data"
        >
        <div class="row">
        <div class="col-sm-6">
            <label for="" class="label-required">Media Name</label>
            <input
              type="text"
              formControlName="mediaTitle"
              class="form-control"
              placeholder="Enter Name"
            />
            <div *ngIf="(ismediaFormSubmited && _mediaTitle.invalid) || (_mediaTitle.touched && _mediaTitle.invalid)" class="text-danger p-1">
              <div *ngIf="mediaForm?.get('mediaTitle').errors?.required">
                  <p class="text-danger">Media Name is mandatory</p>
                </div>
             </div>
          </div>
          <div class="col-sm-6">
            <label for="" class="label-required">Link</label>
            <div class="mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">@</span>
                </div>
                <input type="text" class="form-control" formControlName="mediaLink"
                placeholder="" aria-label="" aria-describedby="basic-addon1"  placeholder="Enter Link">
               
              </div>
              <div *ngIf="(ismediaFormSubmited && _mediaLink.invalid) || (_mediaLink.touched && _mediaLink.invalid)" class="text-danger p-1">
                <div *ngIf="mediaForm?.get('mediaLink').errors?.required">
                    <p class="text-danger">Link is mandatory</p>
                  </div>
                  </div>
            </div>
            
          </div>
          </div>
          <div class="row">
          <div class="col-sm-6">
           
           
              <label class="form-label label-required">Select File</label>
              <div class="custom-file">
                  <input
                    type="file"
                    class="form-control custom-file-input"
                    id="customFile"
                    (change)="onFileSelect($event)"
                    name="logo"
                    #galleryFileInput
                  />
                  <label class="custom-file-label" for="customFile">{{
                    fileInputLabel || "Choose File"
                  }}</label>
                  <span style="color: black"
                  >(File Size Should Not Exceed 2MB. Allowed Extensions: PNG , JPG
                  , SVG )</span>
      
                </div>
                <div *ngIf="(ismediaFormSubmited && _mediaImage.invalid) || (_mediaImage.touched && _mediaImage.invalid) || filesizeerr || filetypeerr" class="text-danger p-1">
                  <div *ngIf="majorForm?.get('logo').errors?.required">
                      <p class="text-danger">Thumbnail is mandatory</p>
                    </div>
                     <p class="text-danger" *ngIf="filetypeerr">
                        File format is incorrect
                      </p>
                      <p class="text-danger" *ngIf="filesizeerr">
                        File size should not exceed 2 MB
                      </p>
                     
                 
          </div>
          </div>
          </div>
        </form>
        <div class="button-wrapper">
          <button class="btn btn-primary mr-2 custombtn" (click)="cancel()">Cancel</button>
          <button class="btn btn-primary custombtn"  (click)="publishMedia()">{{ editMode ? 'Update' : 'Create' }}</button>
          <!-- <button class="btn btn-primary custombtn" (click)="updateForms()" *ngIf="editMode">Update</button> -->
        </div>
<div class="row d-flex p-3">
        <form [formGroup] ="mediaFilterForm" class="col-md-12 d-flex">
        
            <div class="col-md-4">
              <label  class="mx-3">From Date</label>
              <input type="date" formControlName="start_date" id="start_date"
                class="form-control date" style="margin: 0px;"
               
              />
            </div>
            <div class="col-md-4">
               <label class="mx-3">To Date</label>
                    <input type="date" formControlName="end_date" id="end_date"
                      class="form-control date" style="margin: 0px;" />
            </div>
            <div class="col-md-4 ">
              <input class="form-control mt-4 " type="text" [(ngModel)]="searchText" placeholder="Search by Title..."  [ngModelOptions]="{standalone: true}">

           </div>
         
          </form>
  <div class="d-flex row col-12 justify-content-end">
    <div class="col-4 pt-4">
      <button type="button" class="btn btn-primary " (click)="filtersItem()" >Filter</button>
      <button type="button" class="btn btn-secondary mx-2 " (click)="resetFilter()" >Reset </button>

      <button class="btn btn-success" (click)="downloadlabList()"><i class="fas fa-download"></i> Download</button>

    </div>
  </div>
        
         </div>
        <div class="row">
          <div class="download-bt  col-12 d-flex button-wrapper justify-content-end">
          </div>
         </div>



        <!-- <div class="row">
          <div class="download-bt  col-12 d-flex button-wrapper justify-content-end">
            <button class="btn btn-success" (click)="downloadlabList()"><i class="fas fa-download"></i> Download</button>
          </div>
         </div> -->



        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="headerfix">Sl No</th>
              <th class="headerfix">Updated Time</th>
              <th class="text-center" scope="col">Name</th>
              <th scope="col">Link</th>
              <th scope="col">Image</th>            
              <th scope="col">Status</th>
              <th scope="col" width="200px">Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="filteredDataTable?.length > 0; else noRecords">
            <tr  *ngFor="let d of filteredDataTable; let i = index" >
              <th scope="row" class="text-center">{{getSerialNo(i+1)}}</th>
              <!-- <th scope="row" class="text-center">{{i+1 + (p - 1) * pageSize}}</th> -->
              <td>{{d.updated_at |  date}}</td>
              <td class="m_col text-center" style="word-break: break-all;">{{ truncateText(d.media_name, 100) }} <span data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" *ngIf="readMore" style="color:#005a95" class="readMore">Read more</span></td>
              <td class="m_col headerfix" style="word-break: break-all;"><a href="{{d.media_link}}" target="_blank">Click Here</a></td>
              <td class="m_col headerfix" style="word-break: break-all;"><a href="{{d.images[0].url}}" target="_blank">Click Here</a></td>              
              <td>{{d.media_status}} </td>
  
              <td >
               
                <div class="d-flex align-items-center ">                 
                  <a class="fa fa-edit mr-3 arrow" style = "color:blue" (click)="editData(d, section)" ></a>
                  <a class="fa fa-trash mr-3 arrow" style = "color:red" (click)="selectedItemId = d.media_id" data-bs-toggle="modal" data-bs-target="#deleteNewsletterModal"></a>
                  <a class="fa fa-eye mr-3 arrow" style = "color:green" data-toggle="modal" data-target="#exampleModal" (click)="preview(d)" ></a>
                  </div>
  
              </td>
            </tr>
          
          </tbody>
          <ng-template #noRecords>
            <tbody>
              <tr>
                <td colspan="6" class="no-records-found">No Data found.</td>
              </tr>
            </tbody>
          </ng-template>
        </table>
        <!-- <pagination-controls *ngIf="filteredDataTable?.length > 0;" (pageChange)="p = $event" class="float-right"></pagination-controls> -->
    </div>
    <app-pagination 
          [totalpages]="totalpages" 
          [pageno]="pageno"
          [selectedindex]="selectedindex"
          (pageChanged)="paginate_startup($event)">
          </app-pagination>
    <!-- Modal -->
<div class="modal custom-modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">
      <div class="modal-header ">
        <h3 class="modal-title " id="exampleModalLabel">Preview</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="container-fluid ">
          <label for="basic-url">Media Name</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <input type="text" class="form-control mb-2" placeholder="" aria-label="Username" value="{{modaldata.mediaName}}" aria-describedby="basic-addon1" readonly>
          </div>

          <label for="basic-url">Media Link</label>
          <div class="input-group">
            <div class="input-group-prepend">
            </div>
            <input type="text" class="form-control mb-2" placeholder="" aria-label="Username" value="{{modaldata.link}}" aria-describedby="basic-addon1" readonly>
          </div>
          <label for="basic-url">Media Image</label>
          <div class="input-group">
          <div class="input-group-prepend">
          </div>
          <div class="d-flex justify-content-center align-items-center mb-2">
          <img [src]="modaldata.image" alt="image" style="width: 100%;">
        </div>
        </div>
  
         
  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
    
  </div>
  </div>
  </div>
  <div class="modal fade" id="deleteNewsletterModal" tabindex="-1" aria-labelledby="deleteNewsletterModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="deleteNewsletterModalLabel">Media Record</h1>
        </div>
        <div class="modal-body">
          Are you sure you want to delete this Media?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" (click)="deleteMediaData(selectedItemId)" data-bs-dismiss="modal"  data-bs-dismiss="modal">Delete</button>
        </div>
      </div>
    </div>
  </div>