import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NewsService } from "../../services/news.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as _ from "lodash";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { environment } from "../../../environments/environment";
import { ShowcaseDialogComponent } from "../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { ExcelService } from "../../services/excel.service";
import { ActivatedRoute,Router } from "@angular/router";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
const headers = new HttpHeaders().set(
  "Authorization",
  `Bearer ${sessionStorage.getItem("admintoken")}`
);

@Component({
  selector: "ngx-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
})
export class NewsComponent implements OnInit {
  baseUrl = environment.apiurl;
  @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;
  filterArray = [
    {
      "name": "status",
      "value": []
    },
  ];

  page_index:number = 0;
  pageno:any = 0;
  totalpages: number;
  totalItems: number;
  selectedindex: number=0;

  newsForm: FormGroup;
  newsFilterForm: FormGroup;
  selectedStatus: any = [];
  filterEnable: boolean = false; 
  fileInputLabel: string;
  editMode: boolean;
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "alignment", // <--- ADDED
        "bold",
        "italic",
        "link",
        "bulletedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    link: {
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true, // This option will be selected by default.
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
  };
  htmlValue: any;
  title = "angular-image-file-upload-tutorial";
  data: {
    news_image: string;
    news_subject: string;
    news_description: string;
    news_link: string;
  };
  dataTable: any;
  currentData: any;
  inValidMessage: boolean;
  modaldata = { newsTitle: "", link: "", image: "", description: "" };
  newsData: any;
  news_id: number;
  createMode: boolean = true;
  formSubmitted: boolean;
  status: string;
  filterStatus: '';
  searchText ='';
  p: number = 1;
  pageSize: number = 25;
  fileInputLabel_img: any;
  imageUrl: string | ArrayBuffer | null = null;
  uploadMode: boolean=false;
  id = "section";
  mat_data: any;

  isDownloading;boolean;
  filetypeerr: boolean= false;
  filesizeerr: boolean =false;
  readMore: boolean = false;
  dropdownSettingst: IDropdownSettings = {};
  dropdownstatus: any[] = ["active", "inactive"]
  filterValue: any;
  myArray: any[] = [];
  filterMode: boolean = true;
  activeFilter: string [] =[];
  filterResponseData: any;
  selectstatus: any = [];
  // filterStatus: any[] = []
  selectedStatuses: any[];
  constructor(
    private newsService: NewsService,
    private fb: FormBuilder,
    private http: HttpClient,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private excelService:ExcelService,
    private route:ActivatedRoute,
    private datePipe: DatePipe,


  ) {
    this.route.queryParams.subscribe(params => { 
        this.filterStatus = params['status'];
    })

    this.filterValue = this.route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);
  }

  ngOnInit(): void {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.newsForm = this.fb.group({
      newsLink: ['', [Validators.required, Validators.pattern(urlRegex)]],
      newsTitle: ['', Validators.required],
      newsDescription: ['', Validators.required],
      // uploadedImage: ['', Validators.required],
      uploadedImage: ['', [Validators.required, this.fileSizeValidator, this.fileTypeValidator]],
      status:['']
    });
    this.newsFilterForm = this.fb.group({

      status:['']    
    });
    this.setFilters()
    this.getAllNews();
    this.dropdownSettingst={
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    }
  }
    
  onFileSelect(event:any) {
    // const file = event.target.files[0];
    // const reader = new FileReader();
    // reader.onload = (event:any) => {
    //   this.imageUrl = event.target.result
    //   console.log('file upload',this.imageUrl);
    // }
    // reader.readAsDataURL(file);
    // // console.log("file", file);
    // this.fileInputLabel = file.name;
    // this.newsForm.get("uploadedImage").setValue(file);
    // this.uploadMode=true;

    let af = ['image/png','image/jpeg','image/jpg','image/svg','image/webp']
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event:any) => {
      this.imageUrl = event.target.result
      console.log('file upload',this.imageUrl);
    }
    reader.readAsDataURL(file);
    this.uploadMode=true;

    const filesize = file.size;
    const filesizemb = filesize/1024 ** 2;
    console.log('filesize in mb',filesizemb);
    console.log('file',file);
    if(!af.includes(file.type)){
      this.filetypeerr = true
    }
    else
    {
      this.filetypeerr = false
    }

  if(filesizemb > 2){
    this.filesizeerr = true
    }
  else
  {
    this.filesizeerr = false
  }
  this.fileInputLabel = file.name;
  this.newsForm.get("uploadedImage").setValue(file);
  }
  fileSizeValidator(control: FormControl) {
    const fileSize = control?.value?.size / 1024 / 1024; // size in MB
    const maxSize = 2; // maximum file size in MB
    return fileSize > maxSize ? { fileSizeExceeded: true } : null;
  }
  fileTypeValidator(control: FormControl) {
    const allowedTypes = ['image/png', 'image/jpeg']; // allowed file types
    const fileType = control?.value?.type; // file type
    return allowedTypes.includes(fileType) ? null : { invalidFileType: true };
  }
    
  getAllNews() {
    let data= {
      search_entity: "NEWS",
      "page": 0, "page_size": 10}
    this.newsService.filterPress(data).subscribe((res: any) => {
      this.dataTable = res.results;
      console.log("ts data::", this.dataTable);

      this.totalItems=+res.total_search_results_count;  
      var totalPagesss = Math.ceil(this.totalItems/10);
      this.totalpages = totalPagesss;
     



    });
  }
  get filteredData() {
    // let filteredDataByStatus;
    // if(this.filterStatus) {
    //     filteredDataByStatus = this.dataTable?.filter(d => d.news_status == this.filterStatus.toLowerCase())
    // } else { filteredDataByStatus = this.dataTable }
    // return filteredDataByStatus?.filter(d => {
    //   // Check if name property exists and is not undefined
    //   if (d && d.news_subject || d && d.news_description ||d && d.news_status) {
    //     return d.news_subject.toLowerCase().includes(this.searchText.toLowerCase())
    //         || d.news_description.toLowerCase().includes(this.searchText.toLowerCase())
    //         || d.news_status.toLowerCase().includes(this.searchText.toLowerCase());
    //   }
    //   // If name property is undefined, return false to exclude from filtered data
    //   return false;
    // });
    return this.dataTable
  }
  formatDate(date){
    console.log('date_formatted',date);
    let myDate=new Date(date);
    let formattedDate=this.datePipe.transform(myDate,'dd-MM-yyyy hh:mm');
    return formattedDate;
   }
  publishNews() {
    this.formSubmitted = true;
    if (this.newsForm.valid) {
      this.editMode = false;
      this.createMode = true;
      const formData = new FormData();
      formData.append("file", this.newsForm.get("uploadedImage").value);
      this.http
        .post<any>(this.baseUrl + "files?intent=create-edition", formData, {
          headers: headers,
        })
        .subscribe((response) => {
          console.log("image response", response.results[0].url);
          if (response.statusCode === 200) {
            this.uploadFileInput.nativeElement.value = "";
            this.fileInputLabel = undefined;
          }
          this.data = {
            news_image: response.results[0].url,
            news_subject: this.newsForm.controls.newsTitle.value,
            news_description: this.newsForm.controls.newsDescription.value,
            news_link: this.newsForm.controls.newsLink.value,
          };
          this.newsService.createNews(this.data).subscribe(
            (res: any) => {
              this.data = res.results;
              console.log("publish data::", this.data);
              this.newsForm.reset();
              this.fileInputLabel = 'Choose File';
              this.getAllNews();
              this.imageUrl='';
              this.toastrService.success("Data Added Successfully!");
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Record already Exist!");
            }
          );
        });
    } else {
      this.newsForm.markAllAsTouched();
    }
  }
  changestatus(data:any, status:any){

    this.mat_data = status;
    const dialogRef=this.dialog.open(CommondialogComponent,{
      data: { status: this.mat_data, name: 'News' }
    })
    dialogRef.afterClosed().subscribe(result => {
      var result=result;
      if(result==true){
        var value = {
          news_id: data.news_id,
          news_image: data.news_image,
          news_subject: data.news_subject,
          news_description: data.news_description,
          news_link: data.news_link,
          news_status: status
        }
        this.newsService.actionNews(value).subscribe((res:any)=>{
            console.log('change_status',res);
            this.getAllNews();
            localStorage.removeItem('myData');
            this.toastrService.success("Successfully updated Status!");
          },
          (error) => {
          this.inValidMessage = true;
          this.toastrService.warning("Unable to Update Status!");
        })
      } 
    })
  }
  preview(item: any) {
    console.log("item", item);
    this.modaldata.newsTitle = item.news_subject;
    this.modaldata.link = item.news_link;
    this.modaldata.description = item.news_description;
    this.modaldata.image = item.news_image;
  }
  editData(newsData: any, el:any) {
    el.scrollIntoView({behaviour:'smooth'});

    this.uploadMode=false;
    this.editMode = true; //change btn to update
    this.createMode = false;
    this.newsForm.controls["status"].setValue(newsData.news_status);
    this.news_id = newsData.news_id;
    this.newsForm.controls["newsTitle"].setValue(newsData.news_subject);
    this.newsForm.controls["newsDescription"].setValue(newsData.news_description);
    this.newsForm.controls["newsLink"].setValue(newsData.news_link); //static passing
    //this.newsForm.controls["uploadedImage"].setValue(newsData.news_image);

    const url = newsData.news_image;
    const last_part=url.split('/');
    this.fileInputLabel = last_part[last_part.length-1];

    this.imageUrl = newsData.news_image;
  }

  updateForms() {
    if (this.newsForm.invalid) {
      this.currentData = this.newsForm.value;
      console.log("Form Data:", this.currentData);
      this.currentData["news_status"] = this.status;
      
      this.newsData = {
        news_id: this.news_id,
        news_subject: this.currentData.newsTitle,
        news_description: this.currentData.newsDescription,
        news_link: this.currentData.newsLink,
        news_status: this.currentData.status,
        news_image: this.imageUrl || null // Ensure news_image is explicitly sent as null if not available
      };
      
      console.log("News Data before submission:", this.newsData);
      
      this.newsService.editnewsRelease(this.newsData).subscribe(
        (res) => {
          console.log("Response from API:", res);
          this.newsForm.reset();
          this.getAllNews();
          this.createMode = true;
          this.editMode = false;
          this.uploadMode = false;
          this.toastrService.success("News Updated Successfully!");
        },
        (error) => {
          console.error("Error updating news:", error);
          this.inValidMessage = true;
          this.toastrService.warning("Unable to update News!");
        }
      );
    }
  }
  
  
  deleteNewsData(id) {
    this.dialogService
      .open(ShowcaseDialogComponent, {
        context: {
          message: "Are you sure you want to delete this News?",
        },
      })
      .onClose.subscribe((confirmed) => {
        if (confirmed) {
          console.log("deletedata:", id);
          this.newsService.deletenewsRow(id).subscribe(
            (data) => {
              this.getAllNews();
              this.toastrService.success("News Deleted Successfully!");
              this.fileInputLabel = 'Choose File';
            },
            (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Delete News!");
            }
          );
        }
      });
  }

  // publishNews(){
  //   const formData = new FormData();
  //   formData.append('file', this.myForm.get('fileInput').value);
  //   this.http.post('/api/upload', formData).subscribe(
  //     response => console.log(response),
  //     error => console.error(error)
  //   );

  // }

  cancel() {
    this.newsForm.reset();
    this.editMode = false;
    this.createMode = true;
    this.fileInputLabel = 'Choose File';
    this.imageUrl='';
    
  }
   // truncate text
   truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {       
      this.readMore = false;
      return text;
     
    } else {
      this.readMore = true;
      return text.substring(0, maxLength) + '...';
    }
  }
   // truncate text

   // xls download file
   
  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'News','news_data');
  }
  // downloadlabList(){ 
  //   var exportTableData
  //   if(this.filterStatus) {
  //       exportTableData = this.dataTable?.filter(d => d.news_status == this.filterStatus.toLowerCase())
  //   } else { exportTableData = this.dataTable }
  //   this.exportData(exportTableData);
   
  // }

  async listAllNewsDownload(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const request = {
        query:this.searchText,
        filters: this.myArray,
        search_entity: "NEWS",
        page: 0,
        page_size: 10000,
      };
  
      this.newsService.filterPress(request).subscribe(
        (res: any) => {
          resolve(res.results);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  
 async downloadlabList(){
    this.isDownloading = true;

  try {
    const NewsData = await this.listAllNewsDownload();
    this.exportData(NewsData);
  } catch (err) {
    console.error('Download failed', err);
  }

  this.isDownloading = false;
  };

 
  stripHtmlTags(text: string): string {
    return text ? text.replace(/<\/?[^>]+(>|$)/g, '') : '-';
  }
  exportData(data2: any[]) {    
    const labData = data2.map((d) => ({      
      "Title": d.news_subject ?d.news_subject:'-',
      "Link": d.news_link ? d.news_link :'-',
      "Image" : d.news_image ? d.news_image :'-',
     "Description": d.news_description ? this.stripHtmlTags(d.news_description) : '-',
      "Status" : d.news_status ? d.news_status :'-',   
      'Created at':new Date(d?.created_at),      
      'Updated at':this.formatDate(d?.updated_at),      
    }));
    this.exportAsXLSX(labData)
    // this.generateExcelFile(data, "myData.xlsx");
  }

 
  // xls download file

  truncateTextt(text: string, limit: number): string {
    if (!text) return '';
    
    // Remove all UL and LI tags using regex
    let cleanText = text.replace(/<\/?ul>/g, '').replace(/<\/?li>/g, '');

    // Truncate the cleaned text
    if (cleanText.length <= limit) return cleanText;
    
    return cleanText.substring(0, limit) + '...';
}

async filtersItem() {
    
  this.p = 1;
  // var dateFilter = [];
  // let startDate = this.prFilterForm.get('start_date').value;
  // let endDate = this.pressFilterForm.get('end_date').value;
  // if(startDate && endDate){
  //   dateFilter.push({ "start_date": startDate, "end_date": endDate });
  // }
 
this.myArray = await this.setFilters(this.selectedStatus);
  console.log('myarray',this.myArray)
  console.log("Filters Applied:", this.myArray);

  let requestData = {
    query:this.searchText,
    filters: this.myArray,
    search_entity: "NEWS",
    
    page: 0,
    page_size: 10,
  };

  this.newsService.filterPress(requestData).subscribe((res: any) => {
    console.log("API Response:", res);
  
    this.dataTable = res.results;

    this.totalItems=+res.total_search_results_count;  
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages; // Replace with your dynamic value
  
    
  });
  this.filterEnable = true;
}

async setFilters(...filters: any[][]) {
  //const filterNames = ["state", "city", "domain", "schemes", "status"];

  // Map through filterArray and update values based on corresponding position in filters array
  this.filterArray = this.filterArray.map((filter, index) => ({
      ...filter,
      value: filters[index] || [] // Assign values if present, else keep empty
  }));
  console.log('filterArray ----',this.filterArray);
  return this.filterArray;
}
resetfilter() {
  this.selectedStatus = []; // Reset selected filters in dropdown
  this.myArray = []; // Reset filter array
  this.filterEnable = false; // Disable filter applied flag if needed
  this.p = 1; // Reset pagination to first page
  setTimeout(() => {
    this.searchText = ''; // ✅ Ensure UI updates properly
  });
 
  this.getAllNews();
}





paginate_startup(pageno) {
  this.pageno = pageno;

  console.log('pageno----',this.pageno);
  this.selectedindex = pageno-1;
  console.log('selectedindex-----',this.selectedindex)
  console.log('myarray ----',this.myArray);
  let data={
    query:this.searchText,
    "filters": this.myArray,
    "search_entity": "NEWS",
    "page": pageno- 1,
    "page_size": 10,
      
    }
   

  
  console.log('data_payload',data);
  // this.loading = true;
  this.newsService.filterPress(data).subscribe((res)=>{
    this.data = res.results;
    this.dataTable = res.results;
    console.log('data----');
    console.log(this.data);
   

  })
}

getSerialNo(i:number) {
  return i+(this.selectedindex*10);
}



}
