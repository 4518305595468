import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AxiosServicesService } from "./axios/axios-services.service";
import { environment } from "../../environments/environment";

const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

@Injectable({
  providedIn: "root",
})
export class NewsService {
  // baseUrl = "http://10.0.53.134:8090";
  //baseUrl = "http://10.0.53.134:8090"
  // baseUrl = "http://127.0.0.1:8095"
  baseUrl = environment.apiurl 

  constructor(
    private http: HttpClient,
    private axiosServices: AxiosServicesService
  ) {}

  actionNews(currentData) {
    let url = this.baseUrl + "news/update";
    let result: any = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  createNews(data: any) {
    const url = this.baseUrl + "news/add";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getallNews() {
    const url = this.baseUrl + "news/getall";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  editnewsRelease(currentData) {
    let url = this.baseUrl + "news/update";
    console.log("data::", currentData);
    // let param = {
    //   news_id: 1,
    //   news_image: currentData.uploadedImage,
    //   news_subject: currentData.newsTitle,
    //   news_description: currentData.newsDescription,
    //   news_link: currentData.link
    // };
    let result = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  deletenewsRow(id: number) {
    let url = this.baseUrl + "news/delete/" + id;
    let result: any = this.http
      .put(url, {}, { headers: headers })
      .pipe(catchError(this.handleError));

    return result;
  }

  //service called in press Release component//
  createPressRelease(data: any) {
    const url = this.baseUrl + "pressrelease/add";
    let result: any = this.http
      .post(url, data, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  actionPressRelease(currentData) {
    let url = this.baseUrl + "pressrelease/update";
    let result: any = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getallRelease() {
    const url = this.baseUrl + "pressrelease/getall";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  editpressRelease(currentData) {
    console.log('data from component',currentData);
    let url = this.baseUrl + "pressrelease/update";
    // let param = {
    //   pressrelease_id: 1,
    //   pressrelease_title: currentData.pressTitle,
    //   pressrelease_description: currentData.pressDescription,
    //   pressrelease_link: currentData.link,
    //   pressrelease_date: currentData.date,
    //   pressrelease_status: currentData.status
    // };
    let result = this.http
      .put(url, currentData, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  deletepressRow(id: number) {
    let url = this.baseUrl + "pressrelease/delete/" + id;
    let result: any = this.http.put(url, {}, {headers: headers}).pipe(catchError(this.handleError));

    return result;
  }
  //service End press Release component//

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  //newsLetters
  createNewsLetters(data: any) {
    const url = this.baseUrl + "newsletter";
    let result: any = this.http.post(url, data, { headers: headers }).pipe(catchError(this.handleError));
    return result;
  }
  getallNewsLetters() {
    const url = this.baseUrl + "newsletter/list";
    let result: any = this.http
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError));
    return result;
  }
  getbyIdNewsLetters(id:any){
    const url = this.baseUrl + `newsletter/${id}`;
    return this.http.get(url, { headers: headers }).pipe(catchError((err) => throwError(err)));
  }
  getbyUpdateData(id: any, data:any){
    const url = `${this.baseUrl}newsletter/${id}`;
    return this.http.put(url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).pipe(
      catchError((err) => throwError(err))
    );
  }
  deletenewsLetter(id: any) {
    const url = this.baseUrl + `newsletter/${id}`;
    return this.http.delete(url, { headers: headers }).pipe(catchError((err) => throwError(err)))
  }

  //press release api
   filterPress(data) {
      const url = this.baseUrl + "search";
      let result: any = this.http
        .post(url, data, { headers: headers })
        .pipe(catchError(this.handleError));
      return result;
    }
}
