import { Component, OnInit,ViewChild } from '@angular/core';
import * as XLSX from "xlsx";
import { COEService } from '../../services/coe.service';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ExportToCsv } from "export-to-csv";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChallengeService } from "../../services/challenge.service";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { citiesOnly, states } from "../../../assets/js/states";
import { MatDialog } from "@angular/material/dialog";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { ExcelService } from "../../services/excel.service";
import { CityService } from '../../services/statecity/city.service';
import { StateService } from "../../services/state.service";
import { PaginationComponent } from '../pagination/pagination.component';
const styles = {
  header: {
    fontSize: 18,
    bold: true,
  },
  subheader: {
    fontSize: 14,
    bold: true,
  },
  body: {
    fontSize: 12,
  },
};

@Component({
  selector: 'ngx-coe',
  templateUrl: './coe.component.html',
  styleUrls: ['./coe.component.scss']
})
export class CoeComponent implements OnInit {
  @ViewChild(PaginationComponent) paginationComponent!: PaginationComponent;
  filterArray = [
    {
      "name": "state",
      "value": []
    },
    {
      "name": "city",
      "value": []
    },
    {
        "name": "status",
        "value": []
    }
]

data: any;
  items = [{ title: "PDF" }, { title: "EXCEL" }];
  list: any = [];
  modalUpdateForm!: FormGroup;
  action: { status: string };
  inValidMessage: boolean;
  alldomains: any;
  groupbydomains: any;
  dropdownList = [];
  sectors: any = [];
  dropdownList1 = [];
  tempdata = [];
  selectedItems1 = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings_t: IDropdownSettings = {};
  dropdownSettings_s: IDropdownSettings = {};
  dropdownSettings_city: IDropdownSettings={};

  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectcity:any ;
  selectstate: any = [];
  selectdomain: any = [];
  selectsector: any = [];
  selectstatus: any = [];
  selectedItems: any[];
  selectedStates: any[];
  // dropdownstates: any[] = [
  //   'Karnataka','UP','Telengana', 'Andhra Pradesh'
  // ];
  dropdownstates: any = [];
  selectedStatus: any[];
  dropdowncities:any = [];
  searchText = '';
  dropdownstatus: any[] = ["PENDING", "REJECTED", "APPROVED"];
  originalData: any[];
  public domain = [];
  public sector = [];
  filteredlist = [];
  filterMode: boolean = true;
  resetMode: boolean = false;
  p: number = 1;
  filterValue: any;
  mat_data: string;
  totalItems: number;
  coeList:any;
  paginationEnabled: boolean = true;
  allStates: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  pageSize: number = 25;
  loading: boolean = true; 
  activeFilter: string[] = [];
  filterValue1:any;
  viewback: any;
  pageno:any = 0;
  totalpages: number;
  selectedindex = 0;
  datafordownload: any;
  isDownloading:boolean=false
  countryid=101;
  dropdownstatesapi: any[] = []; // To store state names
  dropdownCity: any[] = []; //
  selected_citiesapi:any = []
  constructor(
    private coeService: COEService,
    private toastrService: NbToastrService,
    private router: Router,
    private fb: FormBuilder,
    private challengeService: ChallengeService,
    private _route:ActivatedRoute,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private excelService:ExcelService,
    private statesservice : StateService,
    private cityservice:CityService


  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);
   
    this.activeFilter = ['APPROVED','PENDING','REJECTED'];
    if(localStorage.getItem('viewback'))
    {
      this.viewback = localStorage.getItem('viewback');
    }
  }

  ngOnInit(): void {
    this.modalUpdateForm = this.fb.group({
      modalcoeName: [""],
      modalDocumentName: [""],
    });


    this.selectcity = "";
    this.selectstate = "";
    this.selectdomain = "";
   
    this.selectstatus = "";
    // console.log("states_____from____js", states);
    this.selectedItems = [];
    this.selectedStates = [];
    this.selectedStatus = [];
    // this.getStates()
    // this.citiesData();
    this.loadStates()
    // if(this.filterValue != null) {
    //   this.filterItem(this.filterValue);
    //   this.filterValue = null;
    // }
    // else{
    //   this.listAllcoes();
    // }
    // this.getAllcoeData();
    if (this.viewback == 'true') {          
      this.backfilter();   
      if(localStorage.getItem('countdata'))
      {
        const storedJsonString = localStorage.getItem('countdata');
        const storedObject = JSON.parse(storedJsonString);
        console.log("count data is==>", storedObject);
        console.log(storedObject.approvedData)
        this.approvedCount = storedObject.approvedData;
        this.rejectedCount = storedObject.rejectedData;
        this.pendingCount = storedObject.pendingData;
       
  
      }  
      // localStorage.removeItem('viewback');
    } else if (this.filterValue != null) {
     
      this.filtersItem(this.filterValue);
      // localStorage.removeItem('viewback');
      // localStorage.removeItem('filterArray');
      // this.selectedStates = [];
      // this.selectedStatus = this.filterValue;
      // this.selectcity = '';
      // this.selectedItems = [];
      // this.activeFilter = this.filterValue;
      // this.viewback = 'true'
      
      

    } else {
      
      this.listAllcoes();
    }
    this.getDomains();

 
    // this.selectedItems = [];
    this.dropdownList = [];
    // this.dropdownstates = states;
    // this.selectedStates = [];
    // this.selectedStatus = [];
    this.selectedItems1 = [];
    this.filteredlist = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_t = {
      singleSelection: true,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_city = {

      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true

    };
    // this.exportData(this.coedata)
    // const coeDataArray = this.coedata1; // Array containing multiple coeData objects
    // this.exportData(coeDataArray);

     // pagination page redirection code
     this._route.queryParams.subscribe((params) => {
      // Use the 'page' parameter from the URL or default to 1
      this.p = +params['page'] || 1;
      // Load data for the current page
      this.loadData(this.p);
    });
     // pagination page redirection code
  }

//  citiesData(){
//   this.dropdowncities = Object.values(citiesOnly);
//   console.log('dropdown_____', this.dropdowncities)

//  }
async loadStates() {
  try {
    const response = await this.cityservice.getDefaultState(this.countryid); // API call
    console.log('Fetched Response:', response);
 
   
    const states = response.result || [];
    // console.log('states 1234 ----',states);
 
    
    this.dropdownstatesapi = states.map((state: { name: any; id: any; }) => ({
      item_text: state.name,  
      item_id: state.id    
    }));
    console.log('hfhyfgh',this.dropdownstatesapi)
  } catch (error) {
    console.error('Error fetching states:', error);
  }
}
 async onStateSelect(event: any) {
  console.log('Selected State:', event);    

  const selectedStateId = event.item_id; // Get state ID
  
  localStorage.setItem('selectedStateId', selectedStateId);

  try {
    const response = await this.cityservice.getCitiesByState(selectedStateId);
    console.log('Fetched Cities Response:', response);

    // Extract `result` array safely
    const cities = response.result || [];

    // Convert cities to expected format
    const newCities = cities.map((city: { id: any; name: any }) => ({
      item_id: city.id,
      item_text: city.name
    }));

    // Merge with existing cities (avoiding duplicates)
    this.selected_citiesapi = [...this.selected_citiesapi, ...newCities].filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.item_id === value.item_id)
    );

    // Sort the updated list
    // this.selected_citiesapi.sort((a, b) => a.item_text.localeCompare(b.item_text));

  } catch (error) {
    console.error(`Error fetching cities for state ID ${selectedStateId}:`, error);
  }
}
async backdeselect(val:any){
  this.cityservice.getCitiesByState(val).then(response => {
    const citiesToRemove = response.result.map(city => city.id);

    // Remove cities belonging to the deselected state
    this.selected_citiesapi = this.selected_citiesapi.filter(
      city => !citiesToRemove.includes(city.item_id)
    );
    this.selectcity = [];

  }).catch(error => {
    console.error(`Error fetching cities for deselected state ID ${val}:`, error);
  });

}

async onStateDeSelect(event: any) {

  if (this.viewback == 'true') {
    console.log('viewback3234')
        const backdeselectstateid=localStorage.getItem('selectedStateId')
        this.backdeselect(backdeselectstateid)
    
      } 
else{
  console.log('Deselected State:', event);
  const deselectedStateId = event.item_id;

  // Fetch cities of the deselected state
  this.cityservice.getCitiesByState(deselectedStateId).then(response => {
    const citiesToRemove = response.result.map(city => city.id);

    // Remove cities belonging to the deselected state
    this.selected_citiesapi = this.selected_citiesapi.filter(
      city => !citiesToRemove.includes(city.item_id)
    );
    this.selectcity = [];
  }).catch(error => {
    console.error(`Error fetching cities for deselected state ID ${deselectedStateId}:`, error);
  });
}
}
  ngOnDestroy(): void{
    // remove style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = ''; 
      htmlElement.style.position = '';
    }
   
      // remove style for ui break on scroll
      localStorage.removeItem('viewback');
 }


  //  pagination page redirection code
onPageChange(page: number): void {
  // Update the current page and navigate to the new URL
  this.p = page;
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: { page: page },
    queryParamsHandling: 'merge',
  });
 
  this.loadData(page);
}
private loadData(page: number): void {
  // Implement your logic to load data based on the page
  console.log('Loading data for page', page);
}
//  pagination page redirection code

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'COE Report','COE_Data');
  }

  // state
  // getStates() {
  //   this.statesservice.getallstates().subscribe((res: any) => {
  //     this.allStates = res.results;
  //     console.log("all states =>", this.allStates);
  //     this.dropdownstates = this.allStates
     
   
  //   });
  // }

  
  // state

  listAllcoes() {
    let data={
      search_entity: "COE",
      "page": 0, "page_size": 10
    }
    this.loading = true;
    this.coeService.getcoeList(data).subscribe((res: any) => {
      this.data = res.results;
      this.coeList=res.results;
      console.log("coes::", this.data);
      this.originalData = this.data;
    

      this.totalItems = +res.total_search_results_count;
      console.log("334___________", this.totalItems);
      this.approvedCount = res.approved_count ;
      this.rejectedCount = res.rejected_count ;
      this.pendingCount = res.pending_count;
      this.totalItems = +res.total_search_results_count;
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages; 
      // this.updateStatusCounts(res);
      this.loading = false;
      this.onPageChange(1) 
    });
  }
  // downloadcoeList(){
  //   // const coeDataArray = this.originalData; // Array containing multiple coeData objects
  //   this.exportData(this.data);
  //   // this.exportAsXLSX(this.data)
  // }

  async downloadcoeList(){
    this.isDownloading = true; 
        let downloaddata = await this.listAllCOEforDownload();
        this.exportData(downloaddata);
    this.isDownloading = false;  // Stop loader after process

  }

  async listAllCOEforDownload() {

    return new Promise((resolve,reject)=>{
      let data= {

        query:this.searchText,
        filters: this.myArray,
        search_entity: "COE",
        "page": 0, "page_size": 10000
  
      }
      this.coeService.getcoeList(data).subscribe((res: any) => {
        this.datafordownload = res.results;
        resolve(this.datafordownload);
      });
    })
  }

  
  exportData(dataArray: any) {
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };
    const data = dataArray.map((d) => {
    const result: any = {
     
      Name: d.name ? d.name : '-',
      // Domain: d.domain ? (Array.isArray(d.domain) ? d.domain.join(', ') : d.domain) : '-',
      // Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
      City: d.city ? d.city : '-',
      State: d.state ? d.state : '-',

      Ceo:d.data?.ceo ? d.data.ceo : '-',

      Country: d.country ? d.country : '-',
      'Website URL': d.website_url ? d.website_url : '-',
      Brief: d.brief ? d.brief : '-',
      'Company Logo': d.profile_image && d.profile_image[0]?.url ? d.profile_image[0].url : '-',

  
      'Contact Number': d.data?.contact_number ? d.data?.contact_number : '-',
      'Email Address': d.data?.email_id ? d.data?.email_id : '-',
      'Implementing Agency': d.implementing_agency ? d.implementing_agency : '-',
      'Program Division': d.program_division ? d.program_division : '-',
      'Chief Mentor': d.data?.chief_mentor ? d.data?.chief_mentor : '-',
      // 'Approved By':d.approved_by,
      Metro: d.metro ? d.metro : '-',
'Lab Resources Created':d.labresources_count ? d.labresources_count:'-',
'Co-working Spaces Created':d.coworkingresources_count ? d.coworkingresources_count:'-',
'Cquipment Resource Created':d.equipmentresources_count ? d.equipmentresources_count:'-',
'Guesthouse Resource Created':d.guesthouseresources_count ? d.guesthouseresources_count:'-',
'Labs booked':d.lab_bookings_count ? d.lab_bookings_count:'-',
'Co-working spaces Booked':d.coworking_bookings_count ? d.coworking_bookings_count:'-',
'Equipments Booked':d.equipment_bookings_count ? d.equipment_bookings_count:'-',
'Guesthouse Booked':d.guesthouse_bookings_count ? d.guesthouse_bookings_count:'-',
      Industry: d.data?.industry ? d.data?.industry : '-',
      Status: d.status ? d.status : '-',
      'Establishment Year': d.data?.establishment_year ? d.data?.establishment_year : '-',
      //'Total Outlay': d.data?.total_outlay ? d.data?.total_outlay : '-',
      //'State Govt Contribution': d.data?.state_govt_contribution ? d.data?.state_govt_contribution : '-',
      //'Implementing Agency Contribution': d.data?.implementing_agency_contribution ? d.data?.implementing_agency_contribution : '-',
      //'Industry Partner Contribution': d.data?.industry_partner_contribution ? d.data?.industry_partner_contribution : '-',
      //'MeitY Contribution': d.data?.meity_contribution ? d.data?.meity_contribution : '-',
      //'Startups Graduated': d.data?.startups_graduated ? d.data?.startups_graduated : '-',
      //'Startups Supported': d.data?.startups_supported ? d.data?.startups_supported : '-',
      //'No. of Employment Generated': d.data?.no_of_employment_generated ? d.data?.no_of_employment_generated : '-',
      //'No. of IPS Created': d.data?.no_of_ips_created ? d.data?.no_of_ips_created : '-',
      //'No. of Products Developed': d.data?.no_of_products_developed ? d.data?.no_of_products_developed : '-',
      //'No. of Prototypes_developed': d.data?.no_of_prototypes_developed ? d.data?.no_of_prototypes_developed : '-',
      //'No. of Seats': d.data?.no_of_seats ? d.data?.no_of_seats : '-',
      //Occupancy: d.data?.occupancy ? d.data?.occupancy : '-',
      //'No. of Technology Commercialised': d.data?.no_of_technology_commercialised ? d.data?.no_of_technology_commercialised : '-',
      //'No. of Technology Transfers': d.data?.no_of_technology_transfers ? d.data?.no_of_technology_transfers : '-',
      //'Resource Sharing Component': d.data?.resource_sharing_component ? d.data?.resource_sharing_component : '-',
      //'No. of Startups with External Funding': d.data?.no_of_startups_with_external_funding ? d.data?.no_of_startups_with_external_funding :'-',
      //'External Funding Raised': d.data?.external_funding_raised ?d.data?.external_funding_raised : '-',
      //'Labs Equipment Available': d.data?.labs_equipment_available ? d.data?.labs_equipment_available : '-',
      //'No. of Mentoring Sessions': d.data?.no_of_mentoring_sessions ? d.data?.no_of_mentoring_sessions : '-',
      //'No. of Mentors Associated': d.data?.no_of_mentors_associated ? d.data?.no_of_mentors_associated : '-',
      //'No. of Investor Connect Events': d.data?.no_of_investor_connect_events ? d.data?.no_of_investor_connect_events : '-',
      //'No. of Customer Connect Events': d.data?.no_of_customer_connect_events ? d.data?.no_of_customer_connect_events : '-',
      //'No. of Startup Skilling Trainings Workshops': d.data?.no_of_startup_skilling_trainings_workshops ? d.data?.no_of_startup_skilling_trainings_workshops : '-',
      //Strength: d.data?.strength ? d.data?.strength : '-',
      //'Team Size': d.data?.team_size ? d.data?.team_size : '-',
      //Documents: d.documents, 
      // Domain: d.domain,
      //'List of Awards Received by COE': d.data?.list_of_awards_received_by_coe ? d.data?.list_of_awards_received_by_coe : '-',
      //id: d.id,
      // koo: d?.social_info?.koo,
      Twitter : d?.social_info?.twitter ? d?.social_info?.twitter :'-',
      Youtube : d?.social_info?.youtube ?d?.social_info?.youtube : '-',
      Facebook : d?.social_info?.facebook ? d?.social_info?.facebook : '-',
      Linkedin : d?.social_info?.linkedin ? d?.social_info?.linkedin : '-',
      Instagram : d?.social_info?.instagram ?  d?.social_info?.instagram : '-',
      // 'Email': d?.data?.email_id,
       'Login Email':d?.login_email ?d?.login_email : '-',
      'Created At': d.created_at ? new Date(d.created_at) : '-',
      'Updated Time': d.updated_at ? new Date(d?.updated_at):'-',
      'Last Login Date':d?.login_info ? formatDate(new Date(d.login_info.last_login_time) ) : '-',
      'Last Login Time':d?.login_info ? formatTime(new Date(d.login_info.last_login_time)):'-'

   };
 
   if (this.activeFilter === ['APPROVED']) {
     result.ApprovedBy = d.approved_by;
   }else if(this.activeFilter === ['REJECTED']){
    result.RejectedBy = d.approved_by;
  }else if(this.activeFilter !== ['PENDING']){
    result['Approved/Rejected By'] = d.approved_by;
  }
 
   return result;
  });
    this.exportAsXLSX(data)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", data);
  }
  
 
  downloadFile(d) {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Applications",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "applications",
      headers: ["Name", "Domain", "Sector", "Location", "Description", "Stage", "ceo"],
    };
    const csvdata = [
      {
        Name: d.name,
        Domain: d.domain,
        City: d.city,
        Sector: d.sector,
        Description: d.short_description,
        Stage: d.coe_stage,
        ceo: d.ceo,
      },
    ];
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvdata);
  }

  editData(data) {
    this.data = data;
    console.log("edit statupDATA:", this.data);
    this.modalUpdateForm.controls["modalcoeName"].setValue(data.name);
  }
  updatecoeModel(id) {
    let data = {};
    this.coeService.editcoe(data, id).subscribe(
      (res: any) => {
        console.log("statSucces:", res);
        this.toastrService.success("Data Updated Successfully!");
      },
      (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update records!");
      }
    );
  }

  changestatus(id: string, value: string) {
    // localStorage.setItem('myData', 'coe');
    this.mat_data = value;
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'coe' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              status: value,
            };
            this.coeService.actioncoe(data, id).subscribe(
              (res: any) => {
                console.log("change_status", res);
                // this.getAllcoeData();
                // this.listAllcoes();
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
              },
              (error) => {
                this.inValidMessage = true;
                this.toastrService.warning("Unable to Update Status!");
              }
            );
          }
        })
  }

  getDomains() {
    this.challengeService.getalldomains().subscribe((res: any) => {
      this.alldomains = res.results;
      let data = [];
      for (var i = 0; i < this.alldomains.length; i++) {
        data.push({
          item_id: i + 1,
          item_text: this.alldomains[i].name,
        });
      }
      this.dropdownList = [...data];
      // console.log('data_domain',data);
      this.groupbydomains = _.groupBy(this.alldomains, "name");
      console.log("group domains", this.groupbydomains);
    });
  }
  async onItemSelectDomain(event) {
    console.log("onItemSelect", this.groupbydomains, event);
    console.log(
      "getsector",
      this.groupbydomains[`${event.item_text}`][0]["sectors"]
    );
    await this.groupbysectors(event);
    console.log("sectors", this.sectors);
    for (var i = 0; i < this.sectors.length; i++) {
      this.tempdata.push({
        item_id: i + 1,
        item_text: this.sectors[i].name,
      });
    }
    this.dropdownList1 = [...this.tempdata];
    console.log("sectors_dropdown", this.dropdownList1);
  }

  async onItemDeSelectDomain(event) {
    console.log("event", event);
  }
  async groupbysectors(event) {
    return new Promise((resolve, reject) => {
      this.challengeService.setSectorData(
        this.groupbydomains[`${event.item_text}`][0]["sectors"]
      );
      this.sectors.push(
        ...this.groupbydomains[`${event.item_text}`][0]["sectors"]
      );
      resolve(this.sectors);
    });
  }



  async onpageloadstateselect(val:any){

    try {
      console.log('val',val);
      const response = await this.cityservice.getCitiesByState(val);
      console.log('Fetched Cities Response:', response);
  
      // Extract `result` array safely
      const cities = response.result || [];
  
      // Convert cities to expected format
      const newCities = cities.map((city: { id: any; name: any }) => ({
        item_id: city.id,
        item_text: city.name
      }));
  
      // Merge with existing cities (avoiding duplicates)
      this.selected_citiesapi = [...this.selected_citiesapi, ...newCities].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.item_id === value.item_id)
      );
  
      // Sort the updated list
      // this.selected_citiesapi.sort((a, b) => a.item_text.localeCompare(b.item_text));
  
    } catch (error) {
      console.error(`Error fetching cities for state`, error);
    }
  }
  


//   backfilter(){   
//     if(this.viewback == 'true')
//     {
//       const getArray = localStorage.getItem('filterArray');
//       const parsedArray = JSON.parse(getArray);
//       this.myArray = parsedArray;
//       const stateValue = parsedArray?.find(item => item.name === 'state')?.value;
//       const statusValue = parsedArray?.find(item => item.name === 'status')?.value;
//       const cityvalue = parsedArray?.find(item => item.name === 'city')?.value;
//       // const domainvalue = parsedArray.find(item => item.name === 'domain')?.value;      
//       this.selectedStates = stateValue;
//     this.selectedStatus = statusValue;
//       this.selectcity = cityvalue
//       // this.selectedItems = domainvalue;
     
//     }
    
  
//       if(localStorage.getItem('status'))
//       {
//         this.activeFilter = localStorage.getItem('status');
//       }
  
     
  
//       this.loading = true;

//       let data = {
//         filters: this.myArray,
//         search_entity: "COE",
//         "page": 0, "page_size": 2984
  
//       };
//       console.log("data____0603", data);
     
//       if(this.filterValue == null){
//         this.coeService.filtercoe(data).subscribe((res: any) => {
//         // console.log("res myarray___0603:", res);
//         this.data = res.results;        
//         // this.updateStatusCounts(this.data);
//         this.loading = false;
//       });
     
    
//   }
// }


  // filterItem(filterValue) {
  //   // this.filterMode = false;
  //   // this.resetMode = true;
  //   if(filterValue == 'null')
  //   {
  //    this.activeFilter = null;
  //   }
  //   this.resetMode = true;

  //   this.myArray = [];
  //   var domains = [];
  //   localStorage.removeItem('status');
    
  //   // if(this.selectdomain && this.selectdomain.length > 0)
  //   // {
  //   //   for (var i = 0; i < this.selectdomain.length; i++) {
  //   //     domains.push(this.selectdomain[i].item_text);
  //   //   }
  //   //   if (this.selectdomain.length != 0) {
  //   //     this.myArray.push({
  //   //       name: "domain",
  //   //       value: domains,
  //   //     });
  //   //   }
  //   // }
  //   var sector = [];

  //   if (this.selectcity && this.selectcity.length > 0) {
  //     this.myArray.push({
  //       name: 'city',
  //       value: this.selectcity
  //     });
  //   }
  //   if (this.selectstate && this.selectstate.length > 0) {
  //     this.myArray.push({
  //       name: "state",
  //       value: this.selectstate,
  //     });
  //   }
  
  //   if (this.selectstatus.length != 0) {
  //     this.myArray.push({
  //       name: "status",
  //       value: this.selectstatus,
  //     });
  //     this.activeFilter = this.selectstatus;
  //     this.filterValue1 = this.selectstatus
  //     console.log("selectcity1223:", this.myArray);
  //   }
  //   const jsonString = JSON.stringify(this.myArray);
  //   localStorage.setItem('filterArray', jsonString); 
  //   let data = {
  //     filters: this.myArray,
  //     search_entity: "COE",
  //     "page": 0, "page_size": 2984

  //   };
  //   console.log("data____0603", data);
   
  //   if(this.filterValue == null){
  //     this.coeService.filtercoe(data).subscribe((res: any) => {
  //       // console.log("res myarray___0603:", res);
  //       this.data = res.results;
  //       this.onPageChange(1) 
  //       if(data.filters.length > 0 && data.filters[0]['name'] === 'status'){
  //         // this.updateStatusCounts(this.data);
  //       }else{
  //         this.updateStatusCounts(res);
  //       } 
  //       const statusCount = {
  //         approvedData: this.approvedCount,
  //         rejectedData: this.rejectedCount,
  //         pendingData: this.pendingCount
  //       };
  //       const countstring = JSON.stringify(statusCount);
  //       localStorage.setItem('countdata', countstring);
  //     });
     
  //   }

  //   else{
  //     if (!this.myArray.some(item => item.name === 'status')) {
  //       this.myArray.push({
  //         "name": "status",
  //         "value":Array.isArray(this.filterValue) ? this.filterValue : [this.filterValue]              
  //     });
  //   }
  // //   let data = {
  // //     "filters": [
  // //       {
  // //         "name": "status",
  // //         "value": filterValue
  // //     }
  // //     ],
  // //     "search_entity": "COE",
  // //     "page": 0, "page_size": 2984

  // // }
  // let data = {
  //   "filters": this.myArray,
  //   "search_entity": "COE",
  //   "page": 0, "page_size": 5000
  // }
  //   console.log("data____filterdata", data);
  //   this.coeService.filtercoe(data).subscribe((res: any) => {
  //     console.log("pendinggggg.........:", res);
  //     this.data = res.results;
  //     this.updateStatusCounts(res);
  //     this.loading = false;
  //   });
  // }
  // }


  // countFilter(data)
  // {

  //   this.myArray = [];
  //   var domains = [];

    
    
  //   // if(this.selectdomain && this.selectdomain.length > 0)
  //   // {
  //   //   for (var i = 0; i < this.selectdomain.length; i++) {
  //   //     domains.push(this.selectdomain[i].item_text);
  //   //   }
  //   //   if (this.selectdomain.length != 0) {
  //   //     this.myArray.push({
  //   //       name: "domain",
  //   //       value: domains,
  //   //     });
  //   //   }
  //   // }
  //   var sector = [];
    

  //   if (this.selectcity && this.selectcity.length > 0) {
  //     this.myArray.push({
  //       name: 'city',
  //       value: this.selectcity
  //     });
  //   }
  //   if (this.selectstate && this.selectstate.length > 0) {
  //     this.myArray.push({
  //       name: "state",
  //       value: this.selectstate,
  //     });
  //   }
  //   // if (this.selectdomain.length != 0) {
  //   //   this.myArray.push({
  //   //     name: "domain",
  //   //     value: domains,
  //   //   });
  //   // }
  //   // if (this.selectsector.length != 0) {
  //   //   this.myArray.push({
  //   //     name: "sector",
  //   //     value: sector,
  //   //   });
  //   // }
  //   this.myArray.push({
  //     name: "status",
  //     value: data,
  //   });
  //   let fitlere_data = data;
  //   this.activeFilter = data;
  //   this.filterValue1 = data;   
  //   // this.onPageChange(1) 
  //   localStorage.removeItem('status')

  //   if(this.viewback == 'true')
  //   {
  //     const getArray = localStorage.getItem('filterArray');
  //    const parsedArray = JSON.parse(getArray);
  //    this.myArray = parsedArray;
  //     this.myArray = this.myArray.filter(item => item.name !== 'status');
  //   }
  //    // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //    if(data == 'All')
  //    {
  //      // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //      this.myArray.push({
  //        name: "status",
  //        value: ["PENDING", "REJECTED", "APPROVED"]
  //      });
  //      this.activeFilter = null;
  //      this.selectedStatus = ["PENDING", "REJECTED", "APPROVED"];
  //      const jsonString = JSON.stringify(this.myArray);
  //      localStorage.setItem('filterArray', jsonString); 
      
  //    }
  //    else {
       
  //        this.myArray = this.myArray.filter(item => item.name !== 'status');
      
      
  //      this.myArray.push({
  //        name: "status",
  //        value: [data],
  //      });
  //      this.selectedStatus = [data];
  //      const jsonString = JSON.stringify(this.myArray);
  //      localStorage.setItem('filterArray', jsonString); 
  //    }
   
    
     
  //    const jsonString = JSON.stringify(this.myArray);
  //    localStorage.setItem('filterArray', jsonString); 

  //   let data1 = {
  //     filters: this.myArray,
  //     "search_entity": "COE",
  //     "page": 0, "page_size": 5000

  // }
  // this.coeService.filtercoe(data1).subscribe((res: any) => {     
     
  //     this.data = res.results;
  //     console.log('count status data is ', this.data);      
  //     // this.updateStatusCounts(this.data);
  //     this.onPageChange(1) 
  //     const statusCount = {
  //       approvedData: this.approvedCount,
  //       rejectedData: this.rejectedCount,
  //       pendingData: this.pendingCount
  //     };
  //     const countstring = JSON.stringify(statusCount);
  //     localStorage.setItem('countdata', countstring);
  //   });
  //   // this.p= 1;
  // }



  preview(d) {
    console.log("pCheck::", d);
    // if(this.filterValue1?.length > 0)
    // {
    //   localStorage.setItem('status',this.filterValue1)
    //   console.log("filte value is ==> ", this.filterValue1)
    // }
    if (this.filterValue1 && this.filterValue1.length > 0) {
      localStorage.setItem('status', JSON.stringify(this.filterValue1));
  } else {
      localStorage.removeItem('status');
  }
  if(this.searchText&&this.searchText!==null){
    localStorage.setItem('query',JSON.stringify(this.searchText));
  }else{
    localStorage.removeItem('query');
  }
  if (this.filterValue1 && this.filterValue1.length > 0) {
    this.activeFilter = this.filterValue;

      const statusFilter = { name: "status", value: this.filterValue1 };
      this.myArray = this.myArray.filter(item => item.name !== "status"); // Remove existing status filter
      this.myArray.push(statusFilter);
  }
  if (this.myArray?.length > 0) {
    localStorage.setItem('filterArray', JSON.stringify(this.myArray));
} else {
    localStorage.removeItem('filterArray');  // ✅ Remove completely if empty
}
localStorage.setItem('selectedindex',this.selectedindex.toString())
this.router.navigateByUrl(`/admin/coetemp/${d}`);

  }
  async backfilter() {   
    if (this.viewback == 'true') {
      const storedValue = localStorage.getItem('query');
      this.searchText = storedValue ? JSON.parse(storedValue) : ''; 
      const getArray = localStorage.getItem('filterArray');
      const parsedArray = JSON.parse(getArray);
      this.myArray = parsedArray;


      // const storedFilters = localStorage.getItem('filterArray');
      // console.log('stored',storedFilters);
      // if (storedFilters) {
      //     console.log('✅ Stored filters found');
      //     this.myArray = JSON.parse(storedFilters);

             
      //   console.log('⚡ Calling onpageloadstateselect()');
      //   await this.onpageloadstateselect(4012);
      // }
  
      const stateValue = parsedArray?.find(item => item.name === 'state')?.value;
      const statusValue = parsedArray?.find(item => item.name === 'status')?.value;
      const cityvalue = parsedArray?.find(item => item.name === 'city')?.value;
  
      this.selectstate = stateValue;
      this.selectstatus = statusValue;
      this.selectcity = cityvalue;
    }

    const storedFilters = localStorage.getItem('filterArray');
    const stateId=localStorage.getItem('selectedStateId')
  
    console.log('stored',storedFilters);
    if (storedFilters) {
        console.log('✅ Stored filters found');
        this.myArray = JSON.parse(storedFilters);
  
           
      console.log('⚡ Calling onpageloadstateselect()');
      await this.onpageloadstateselect(stateId);
    }
  
    if (localStorage.getItem('status')) {
      this.activeFilter = JSON.parse(localStorage.getItem('status'));
 // If scheme is selected, default to APPROVED
  } else {
      this.activeFilter = ['APPROVED','PENDING','REJECTED']; // Reset if no filters
  }
    // if (this.selectstatus && this.selectstatus.length > 0) {
    //   this.activeFilter = this.selectstatus[0]; // Set active filter based on restored status
    // }
    if (this.selectstatus && this.selectstatus.length > 0) {
      this.activeFilter = this.myArray.find(item => item.name === 'status')?.value || ['APPROVED', 'PENDING', 'REJECTED'];
    }
    
    // if (localStorage.getItem('status')) {
    //   // this.activeFilter = localStorage.getItem('status');
    //   this.activeFilter = JSON.parse(localStorage.getItem('status'));

    // }
  
    this.loading = true;
    this.selectedindex  = Number(localStorage.getItem('selectedindex'));
    const pageno = this.selectedindex;
    let data = {
      query:this.searchText,

      filters: this.myArray || [],  // Ensure it's never undefined
      search_entity: "COE",
      "page": pageno, "page_size": 10
    };
  
    console.log("Calling API with data:", data);
  
    // Call API to reload the data when coming back
    this.coeService.filtercoe(data).subscribe((res: any) => {
      this.data = res.results;        
      this.approvedCount = res.approved_count ?? this.approvedCount;
      this.rejectedCount = res.rejected_count ?? this.rejectedCount;
      this.pendingCount = res.pending_count ?? this.pendingCount;   
      this.totalItems = +res.total_search_results_count;
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages;  
      this.loading = false;
    });
  }

  
  removeFilterQueryParam(): void {
 
    // Clone the current query parameters
    const updatedQueryParams = { ...this._route.snapshot.queryParams };
  
    // Remove 'filter' from the cloned object
    delete updatedQueryParams['filter'];
  
    // Navigate to the updated query parameters
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: updatedQueryParams, // Set updated params explicitly
      queryParamsHandling: undefined, // Ensure Angular fully replaces the params
    });
  
    console.log('Updated Query Params:', updatedQueryParams);
  }
  resetFilters() {
    this.resetMode = false;
    this.filterMode = true;

    // clear the filter criteria object
    this.data = {};
      this.selectcity = "";
      this.selectstate = "";
      this.selectdomain = "";
     
      this.selectstatus = "";
      this.selectedindex = 0;
      if (this.paginationComponent) {
        this.paginationComponent.resetPageIndex();
      }
    localStorage.removeItem('status');
    this.activeFilter = null;
    localStorage.removeItem('filterArray');    
    localStorage.removeItem('viewback');
    localStorage.removeItem('selectstatus');
    localStorage.removeItem('selectedStateId');
    this.viewback = 'false'; 
    this.myArray = [];
    this.listAllcoes();
    this.removeFilterQueryParam();
    this.activeFilter = ['APPROVED','PENDING','REJECTED'];

    // this.updateStatusCounts(this.data);

    setTimeout(() => {
      this.searchText = ''; // ✅ Ensure UI updates properly
    });

  }

//   filtersItem(filterValue) {
//     console.log("Filter Value:", filterValue);
//   // this.filterMode = false;
//     this.resetMode = true;
//     this.myArray = [];
//     this.p = 1;
  
//  if (this.selectcity && this.selectcity.length > 0) {
//           this.myArray.push({
//             name: 'city',
//             value: this.selectcity
//           });
        
        
//           // console.log("selectcity:",this.myArray);
//         }
//         if (this.selectstate && this.selectstate.length > 0 ){
//           this.myArray.push({
//             name: 'state',
//             value: this.selectstate
//           })
//         }
//         console.log('domain_select',this.selectdomain);
       
    
    
      
//           console.log("selectscheme:",this.myArray);
  
//           if (filterValue) {//whatever i select
//             this.activeFilter = filterValue === "ALL" ? null : filterValue;
//             this.selectstatus = filterValue === "ALL" ? ["APPROVED", "PENDING", "DECLINED"] : [filterValue];
//           }
  
//     if (this.selectstatus && this.selectstatus.length > 0) {
//       this.myArray.push({ name: "status", value: this.selectstatus 
        
//       });
//       this.activeFilter = this.selectstatus;
//     }
  
  
//     console.log("Filters Applied:", this.myArray);
  
//     let requestData = {
//       filters: this.myArray,
//       search_entity: "COE",
//       page: 0,
//       page_size: 2984,
//     };
  
//     if(filterValue == null){
//       // this.filterMode=true;
  
//     this.coeService.filtercoe(requestData).subscribe((res: any) => {
//       console.log("API Response:", res);
    
//       this.data = res.results;
//       // Update status counts
//       this.approvedCount = res.approved_count ;
//       this.rejectedCount = res.rejected_count ;
//       this.pendingCount = res.pending_count;
//     });
  
//   }
//   else
//   {
    
//     if (!this.myArray.some(item => item.name === 'status')) {
//       this.myArray.push({ name: "status", value: Array.isArray(filterValue) ? filterValue : [filterValue] });
//     }
    
//     let requestData = {
//       filters: this.myArray,
//       search_entity: "COE",
//       page: 0,
//       page_size: 2984,
//     };
//     this.coeService.filtercoe(requestData).subscribe((res: any) => {
//       this.data = res.results;
  
//       this.loading = false;
//       // Update status counts
//       this.approvedCount = res.approved_count;
//       this.rejectedCount = res.rejected_count;
//       this.pendingCount = res.pending_count;
      
//       // this.list = this.data;
  
  
//       // this.downloadButton = true;
  
//   })
//   // this.filterMode=false;
//   }
//   }

async filtersItem(filterValue:any) {
  console.log('filtervalue ----',filterValue);
  this.selectedindex = 0;
  if (this.paginationComponent) {
    this.paginationComponent.resetPageIndex();
  }
  if(filterValue != undefined){
    if (filterValue === 'All') {
      this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Select all
      this.selectstatus = ['APPROVED', 'REJECTED', 'PENDING']
    } else if(filterValue === 'APPROVED' || filterValue === 'PENDING' || filterValue === 'REJECTED') {
      this.selectstatus = [filterValue];
      if (this.activeFilter?.includes(filterValue)) {
        this.activeFilter = this.activeFilter?.filter(f => f !== filterValue); // Remove if already selected
      } else {
        this.activeFilter?.push(filterValue); // Add to selection
      }
    }
  }
  else {
    this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING'];
    console.log('af',this.activeFilter);
  }
  // If all three are selected, keep only "View All" active
  if (this.activeFilter?.length === 3) {
    this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Keep all three for View All
  }
  //console.log("Filter Value:", filterValue);
  this.resetMode = true;
  this.p = 1;
//   const storedFilters = localStorage.getItem('filterArray');
//   if (storedFilters) {
//     // ✅ Use stored filters
//     this.myArray = JSON.parse(storedFilters);
// } else {
//   const selectedStates = Array.isArray(this.selectstate) 
//   ? this.selectstate?.map(state => state.item_text) : [];
//   const selectedCities = Array.isArray(this.selectcity) 
//   ?this.selectcity?.map(city => city.item_text) : [];
//  this.myArray = await this.setFilters(selectedStates,selectedCities,this.selectstatus);
// }

// const storedFilters = localStorage.getItem('filterArray');
// const stateId=localStorage.getItem('selectedStateId')
// let selectedStates: string[] = [];
// let selectedCities: string[] = [];
//   if (storedFilters) {
//     console.log('✅ Stored filters found');
//     this.myArray = JSON.parse(storedFilters);
//     console.log('✅ Stored filters found');
//     const parsedFilters = JSON.parse(storedFilters);
//  selectedStates = (parsedFilters.find(filter => filter.name === "state")?.value?.length 
//                       ? parsedFilters.find(filter => filter.name === "state").value 
//                       : (this.selectstate?.map(state => state.item_text) || []));

//     selectedCities = (parsedFilters.find(filter => filter.name === "city")?.value?.length 
//                       ? parsedFilters.find(filter => filter.name === "city").value 
//                       : (this.selectcity?.map(city => city.item_text) || []));

    // 🔥 Apply similar logic to `domain` and `scheme_ids`
    // domains = (parsedFilters.find(filter => filter.name === "domain")?.value?.length 
    //            ? parsedFilters.find(filter => filter.name === "domain").value 
    //            : (domains) || []);

    // schemes = (parsedFilters.find(filter => filter.name === "scheme_ids")?.value?.length 
    //            ? parsedFilters.find(filter => filter.name === "scheme_ids").value 
    //            : (schemes || []));
  
  // } else {
     
// console.log('in else block');
//  selectedStates = Array.isArray(this.selectstate) ? this.selectstate.map(state => state.item_text) : [];
//  selectedCities = Array.isArray(this.selectcity) ? this.selectcity.map(city => city.item_text) : [];
  // }
const selectedStates = Array.isArray(this.selectstate) 
? this.selectstate.map(state => state.item_text ?? state) 
: [];
    const selectedCities = Array.isArray(this.selectcity) ? this.selectcity.map(city => city.item_text ?? city  ) : [];

this.myArray = await this.setFilters(selectedStates, selectedCities, this.selectstatus);
    this.activeFilter = this.myArray[2]?.value.length !=0 ? this.myArray[2]?.value : ['APPROVED','PENDING','REJECTED'];
    console.log('activefilter',this.activeFilter);

  console.log("Filters Applied:", this.myArray);

  let requestData = {
    query:this.searchText,
    filters: this.myArray,
    search_entity: "COE",
    page: 0,
    page_size: 10,
  };

  this.coeService.filtercoe(requestData).subscribe((res: any) => {
    console.log("API Response:", res);
  
    this.data = res.results;
    // Update status counts
    this.approvedCount = res.approved_count ;
    this.rejectedCount = res.rejected_count ;
    this.pendingCount = res.pending_count;
    this.totalItems = +res.total_search_results_count;
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages; 
    this.loading = false;
  });

}

async setFilters(...filters: any[][]) {
  //const filterNames = ["state", "city", "domain", "schemes", "status"];

  // Map through filterArray and update values based on corresponding position in filters array
  this.filterArray = this.filterArray.map((filter, index) => ({
      ...filter,
      value: filters[index] || [] // Assign values if present, else keep empty
  }));
  console.log('filterArray ----',this.filterArray);
  return this.filterArray;
}

paginate_startup(pageno) {
  this.pageno = pageno
  console.log('pageno----',this.pageno);
  this.selectedindex = pageno-1;
  let data={
    query:this.searchText,

    filters: this.myArray,
    search_entity: "COE",
      "page": pageno-1, "page_size": 10,
    }
    
    this.coeService.getcoeList(data).subscribe((res: any) => {
      this.data = res.results;
      this.coeList=res.results;
      console.log("coes::", this.data);
      this.originalData = this.data;
    

      this.totalItems = +res.total_search_results_count;
      console.log("334___________", this.totalItems);
      this.approvedCount = res.approved_count ;
      this.rejectedCount = res.rejected_count ;
      this.pendingCount = res.pending_count;
      this.totalItems = +res.total_search_results_count;
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages; 
      // this.updateStatusCounts(res);
      this.loading = false;
    });
}
getSerialNo(i:number) {
  return i+(this.selectedindex*10);
}


}
