<!-- <div class="bg-white"> -->

<div class="d-flex justify-content-center bg-white w-100" *ngIf="super">
  <h2 class="h2 mt-3">Welcome to Super Admin Dashboard</h2>
</div>

<div>
  <!-- <div class="col-md-12 bg-white d-flex justify-content-center align-items-center">
      <div id="bar">

      </div>
  </div> -->
</div>

<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="super">
  <h2 class="d-flex justify-content-center" *ngIf="super">Startups</h2>
  <div class="row my-4">
    <div class="col-md-4">
      <div
        class="column-a d-flex shadow p-3 justify-content-center alignitemscenter pending"
        (click)="gotostartup('PENDING')"
      >
        <p class="text-white font-weight-bold mb-0 h4 arrow">
          Pending StartUps: {{ data?.startup_counts?.pending }}
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
        (click)="gotostartup('APPROVED')"
      >
        <p class="text-white font-weight-bold mb-0 h4 arrow">
          Approved StartUps: {{ data?.startup_counts?.approved }}
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
        (click)="gotostartup('REJECTED')"
      >
        <p class="text-white font-weight-bold mb-0 h4 arrow">
          Rejected StartUps: {{ data?.startup_counts?.rejected }}
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- <div class="col column-a bg-grey shadow approved p-5 ml-3 mr-3" *ngFor="let option of options">
      <p class="text-black font-weight-bold mb-0 h3 arrow">
        {{ option.name | uppercase }}          
      </p>
      <p style="color:white"> {{data?.scheme_counts?.startups_granted[0]?.count}} </p>
    </div> -->
    <div class="col column-a incubatorP mr-2 ml-3" (click)="filterStartupByScheme(2)">
      <p class="text-center font-weight-bold arrow">
        SAMRIDH
      </p>
      <p class="text-center font-weight-bold arrow">
        {{ data?.startup_scheme_count["Samridh"] }}
      </p>
    </div>
    <div class="col column-a incubatorP mr-3" (click)="filterStartupByScheme(1)">
      <p class="text-center font-weight-bold arrow">
        TIDE 2.0
      </p>
      <p class="text-center font-weight-bold arrow">
        {{ data?.startup_scheme_count["Tide"] }}
      </p>
    </div>
  </div>
</div>

<!-- <div class="bg-white p-3 my-3 m-0 w-100" *ngIf="super">
  <h2 class="d-flex justify-content-center" *ngIf="super">Freelancers</h2>
  <div class="row my-4">
    <div class="col-md-4">
      <div
        class="column-a d-flex shadow p-3 justify-content-center alignitemscenter pending"
      >
        <p class="text-white font-weight-bold mb-0 h4 arrow">
          Pending Freelancers: 5
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
      >
        <p class="text-white font-weight-bold mb-0 h4 arrow">
          Approved Freelancers: 6
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
      >
        <p class="text-white font-weight-bold mb-0 h4 arrow">
          Rejected Freelancers: 0
        </p>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class="card-deck mt-4">
        <div class="card bg-white border-0">
          <div class="card-body p-0">
            <div class="d-flex justify-center my-3">
              <div class="card bg-white shadow border-0">
                <div class="card-body p-2 w-100">
                  <p class="mb-0 text-center">Budget</p>
                  <p class="mb-0 text-center">100</p>
                </div>
              </div>
              <div class="card bg-white shadow border-0">
                <div class="card-body p-2 w-100">
                  <p class="mb-0 text-center">Start</p>
                  <p class="mb-0 text-center">100</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card bg-white border-0">
          <div class="card-body p-0">
            <div class="d-flex justify-center my-3">
              <div class="card bg-white shadow border-0">
                <div class="card-body p-2 w-100">
                  <p class="mb-0 text-center">Budget</p>
                  <p class="mb-0 text-center">100</p>
                </div>
              </div>
              <div class="card bg-white shadow border-0">
                <div class="card-body p-2 w-100">
                  <p class="mb-0 text-center">Start</p>
                  <p class="mb-0 text-center">100</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card bg-white border-0">
          <div class="card-body p-0">
            <div class="d-flex justify-center my-3">
              <div class="card bg-white shadow border-0">
                <div class="card-body p-2 w-100">
                  <p class="mb-0 text-center">Budget</p>
                  <p class="mb-0 text-center">100</p>
                </div>
              </div>
              <div class="card bg-white shadow border-0">
                <div class="card-body p-2 w-100">
                  <p class="mb-0 text-center">Start</p>
                  <p class="mb-0 text-center">100</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

<!-- </nb-card>  -->
<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="super">
  <h2 class="d-flex justify-content-center">Incubators</h2>
  <div class="" *ngIf="super">
    <div class="row my-4">
      <div class="col-md-4">
        <div
          class="column-a d-flex bg-grey shadow p-3 justify-content-center alignitemscenter pending"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoincubator('PENDING')"
          >
            Pending Incubators: {{ data?.incubator_counts?.pending }}
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="column-b d-flex bg-grey p-3 shadow justify-content-center alignitemscenter approved"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoincubator('APPROVED')"
          >
            Approved Incubators: {{ data?.incubator_counts?.approved }}
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="column-c d-flex bg-grey shadow p-3 justify-content-center alignitemscenter rejected"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoincubator('REJECTED')"
          >
            Rejected Incubators: {{ data?.incubator_counts?.rejected }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col column-b bg-grey shadow pending p-5 ml-3 mr-3" *ngFor="let option of options" >
        <p class="text-black font-weight-bold mb-0 h3 arrow">{{ option.name | uppercase}}
          <span>{{data?.scheme_counts?.incubators_granted[0]?.count}}</span>
        </p>
      </div> -->
      <div class="col column-a incubatorP mr-2 ml-3" (click)="filterIncubatorsByScheme(4)">
        <p class="text-center font-weight-bold arrow">
          GENESIS
        </p>
          
        <p class="text-center font-weight-bold arrow">
            {{ data?.incubator_scheme_count["Genesis"] }}
        </p>
      </div>
      <div class="col column-a incubatorP mr-3" (click)="filterIncubatorsByScheme(1)">
        <p class="text-center font-weight-bold arrow" >
          TIDE 2.0
        </p>
        <p class="text-center font-weight-bold arrow">     
            {{ data?.incubator_scheme_count["Tide"] }}
        </p>
      </div>
    </div>
  </div>
</div>


<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="super">
  <h2 class="d-flex justify-content-center">Accelerators</h2>
  <div class="" *ngIf="super">
    <div class="row my-4">
      <div class="col-md-4">
        <div
          class="column-a d-flex bg-grey shadow p-3 justify-content-center alignitemscenter pending"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoAccelerator('PENDING')"
          >
            Pending Accelerators: {{ data?.accelerators_count?.pending }}
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="column-b d-flex bg-grey p-3 shadow justify-content-center alignitemscenter approved"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoAccelerator('APPROVED')"
          >
            Approved Accelerators: {{ data?.accelerators_count?.approved }}
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="column-c d-flex bg-grey shadow p-3 justify-content-center alignitemscenter rejected"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoAccelerator('REJECTED')"
          >
            Rejected Accelerators: {{ data?.accelerators_count?.rejected }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col column-b bg-grey shadow pending p-5 ml-3 mr-3" *ngFor="let option of options" >
        <p class="text-black font-weight-bold mb-0 h3 arrow">{{ option.name | uppercase}}
          <span>{{data?.scheme_counts?.incubators_granted[0]?.count}}</span>
        </p>
      </div> -->
      <div class="col column-a incubatorP mr-2 ml-3" (click)="filterAccelertorByScheme(2)">
        <p class="text-center font-weight-bold arrow">
          SAMRIDH
        </p>
        <p class="text-center font-weight-bold arrow">
            {{ data?.accelerator_scheme_count["Samridh"] }}
        </p>
      </div>
      <!-- <div class="col column-a incubatorP mr-3"  (click)="filterAccelertorByScheme(4)">
        <p class="text-center font-weight-bold arrow">
          GENESIS
        </p>
     
        <p>0</p>
      </div> -->
    </div>
  </div>
</div>

<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="super">
  <h2 class="d-flex justify-content-center">COE</h2>
  <div class="" *ngIf="super">
    <div class="row my-4">
      <div class="col-md-4">
        <div
          class="column-a d-flex bg-grey shadow p-3 justify-content-center alignitemscenter pending"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoCoe('PENDING')"
          >
            Pending COE: {{ data?.coes_count?.pending }}
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="column-b d-flex bg-grey p-3 shadow justify-content-center alignitemscenter approved"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoCoe('APPROVED')"
          >
            Approved COE: {{ data?.coes_count?.approved }}
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div
          class="column-c d-flex bg-grey shadow p-3 justify-content-center alignitemscenter rejected"
        >
          <p
            class="text-white font-weight-bold mb-0 h4 arrow"
            (click)="gotoCoe('REJECTED')"
          >
            Rejected COE: {{ data?.coes_count?.rejected }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="super">
  <h2 class="d-flex justify-content-center">Cohorts/Challenges</h2>
  <div class="" *ngIf="super">
    <div class="row">
      <div class="col column-a p-3 pending mr-2">
        <p class="text-white font-weight-bold arrow" (click)="gotochallenge()">
          Total Challenges
        </p>
        <p class="text-white font-weight-bold arrow">
            {{ data?.challenge_counts?.external_challenges_count + data?.challenge_counts?.internal_challenges_count }}
        </p>
      </div>
      <div class="col column-b p-3 rejected">
        <p class="text-white font-weight-bold arrow" (click)="showChallenge()">
          Challenge Hosted by MSH portal 2.0
        </p>
        <p class="text-white font-weight-bold arrow" (click)="showChallenge()">
          {{ data?.challenge_counts?.internal_challenges_count  }}
        </p>
      </div>
    </div>
    <div class="row my-2" *ngIf="challegeView">
      <div class="col column-a p-3 pending mr-2">
        <p class="text-center font-weight-bold arrow" (click)="gotochallenge()">
          Pending Challenges
        </p>
        <p class="text-center font-weight-bold arrow" (click)="gotochallenge()">
          {{ data?.challenge_counts?.inprogress_count }}
        </p>
      </div>
      <div class="col column-b p-3 approved">
        <p class="text-center font-weight-bold arrow" (click)="gotochallenge()">
          Expired Challenges
        </p>
        <p class="text-center font-weight-bold arrow" (click)="gotochallenge()">
          {{ data?.challenge_counts?.completed_count }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="super">
  <h2 class="d-flex justify-content-center bg-white">Mentors</h2>

  <div class="row my-4">
    <div class="col-md-4">
      <div
        class="d-flex column-a p-3 justify-content-center alignitemscenter pending cursor-pointer" (click)="gotomentor('PENDING')"
      >
        <p class="text-white font-weight-bold mb-0 h4">
          Pending Mentors: {{ data?.mentor_counts?.pending }}
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="d-flex column-b bg-fundraised p-3 justify-content-center alignitemscenter approved cursor-pointer" (click)="gotomentor('APPROVED')"
      >
        <p class="text-white font-weight-bold mb-0 h4">Approved Mentors: {{ data?.mentor_counts?.approved }}</p>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="d-flex column-c bg-incubatee p-3 justify-content-center alignitemscenter rejected cursor-pointer" (click)="gotomentor('REJECTED')"
      >
        <p class="text-white font-weight-bold mb-0 h4">Rejected Mentors: {{ data?.mentor_counts?.rejected }}</p>
      </div>
    </div>
  </div>

  <div class="row d-none">
    <!-- <div class="col-md-3">
        <div
          class="d-flex column-d bg-grey p-3 shadow justify-content-center alignitemscenter"
          style="flex-direction: column"
        >
          <p class="text-black font-weight-bold mb-0 h3">Chief Mentor: 420</p>
          <p class="font-weight-bold mb-0 h3">Count: 500</p>
          <p class="font-weight-bold mb-0 h3">Session: 5</p>
        </div>
      </div> -->
    <div class="col-md-3">
      <div
        class="d-flex column-d incubatorP shadow p-3 justify-content-center alignitemscenter"
        style="flex-direction: column"
      >
        <p class="text-black font-weight-bold mb-0 h3">Strategic Mentor: 40</p>
        <p class="font-weight-bold mb-0 h3">Count: 500</p>
        <p class="font-weight-bold mb-0 h3">Session: 5</p>
      </div>
    </div>
    <div class="col-md-3">
      <div
        class="d-flex column-d incubatorP p-3 shadow justify-content-center alignitemscenter"
        style="flex-direction: column"
      >
        <p class="text-black font-weight-bold mb-0 h3">Domain Mentor: 340</p>
        <p class="font-weight-bold mb-0 h3">Count: 500</p>
        <p class="font-weight-bold mb-0 h3">Session: 5</p>
      </div>
    </div>

    <div class="col-md-3">
      <div
        class="d-flex column-d incubatorP p-3 shadow justify-content-center alignitemscenter"
        style="flex-direction: column"
      >
        <p class="text-black font-weight-bold mb-0 h3">Generic Mentor: 340</p>
        <p class="font-weight-bold mb-0 h3">Count: 500</p>
        <p class="font-weight-bold mb-0 h3">Session: 5</p>
      </div>
    </div>

    <div class="col-md-3">
      <div
        class="d-flex column-d incubatorP p-3 shadow justify-content-center alignitemscenter"
        style="flex-direction: column"
      >
        <p class="text-black font-weight-bold mb-0 h3">
          Technology Mentor: 340
        </p>
        <p class="font-weight-bold mb-0 h3">Count: 500</p>
        <p class="font-weight-bold mb-0 h3">Session: 5</p>
      </div>
    </div>
  </div>
</div>


<div *ngIf="super">
  <div class="bg-white p-1 my-3 m-0 w-100">
    <h2 class="d-flex justify-content-center">
      Labs And Facilities (Resource Booking Modules)
    </h2>
  </div>

  <div class="row bg-white p-3 my-3 m-0 w-100">
    <div class="col-md-6 shadow">
      <div
        class="d-flex justify-content-center alignitemscenter customborder incubatorP"
      >
        <h2>Labs</h2>
      </div>
      <div class="row p-3">
        <div class="col-md-6">
          <div
            class="w-100 column-a pending arrow"
            (click)="gotoLab('PENDING')"
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.lab?.pending }}</p>
            <p class="text-center text-white font-weight-bold">Pending</p>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="w-100 column-b rejected arrow"
            (click)="gotoLab('DECLINED')"
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.lab?.rejected }}</p>
            <p class="text-center text-white font-weight-bold">Rejected</p>
          </div>
        </div>
        <div class="col-md-6 middle-grid">
          <div
            class="w-100 column-c approved arrow"
            (click)="gotoLab('APPROVED')"
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.lab?.approved }}</p>
            <p class="text-center text-white font-weight-bold">Approved</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 shadow bg-white">
      <div
        class="justify-content-center alignitemscenter customborder incubatorP"
      >
        <h2 class="text-center">Venue Space</h2>
      </div>
      <div class="row p-3">
        <div class="col-md-6">
          <div
            class="w-100 column-a pending arrow"
            (click)="
              gotoVenue('PENDING')
            "
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.guesthouse?.pending }}</p>
            <p class="text-center text-white font-weight-bold">Pending</p>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="w-100 column-b rejected arrow"
            (click)="
              gotoVenue('DECLINED')
            "
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.guesthouse?.rejected }}</p>
            <p class="text-center text-white font-weight-bold">Rejected</p>
          </div>
        </div>
        <div class="col-md-6 middle-grid">
          <div
            class="w-100 column-c approved arrow"
            (click)="
              gotoVenue('APPROVED')
            "
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.guesthouse?.approved }}</p>
            <p class="text-center text-white font-weight-bold">Approved</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row bg-white p-3 my-3 m-0 w-100">
    <div class="col-md-6 shadow bg-white">
      <div
        class="justify-content-center alignitemscenter customborder incubatorP"
      >
        <h2 class="text-center">Co-Working Spaces</h2>
      </div>
      <div class="row p-3">
        <div class="col-md-6">
          <div
            class="w-100 column-a pending arrow"
            (click)="
              gotoCowork('PENDING')
            "
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.coworking?.pending }}</p>
            <p class="text-center text-white font-weight-bold">Pending</p>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="w-100 column-b rejected arrow"
            (click)="
              gotoCowork('DECLINED')
            "
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.coworking?.rejected }}</p>
            <p class="text-center text-white font-weight-bold">Rejected</p>
          </div>
        </div>
        <div class="col-md-6 middle-grid">
          <div
            class="w-100 column-c approved arrow"
            (click)="
              gotoCowork('APPROVED')
            "
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.coworking?.approved }}</p>
            <p class="text-center text-white font-weight-bold">Approved</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 shadow bg-white">
      <div
        class="justify-content-center alignitemscenter customborder incubatorP"
      >
        <h2 class="text-center">Equipments</h2>
      </div>
      <div class="row p-3">
        <div class="col-md-6">
          <div
            class="w-100 column-a pending arrow"
            (click)="gotoEquipment('PENDING')"
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.equipment?.pending }}</p>
            <p class="text-center text-white font-weight-bold">Pending</p>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="w-100 column-b rejected arrow"
            (click)="
              gotoEquipment('DECLINED')
            "
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.equipment?.rejected }}</p>
            <p class="text-center text-white font-weight-bold">Rejected</p>
          </div>
        </div>
        <div class="col-md-6 middle-grid">
          <div
            class="w-100 column-c approved"
            (click)="
              gotoEquipment('APPROVED')
            "
          >
            <p class="text-center text-white font-weight-bold">{{ data?.resource_counts?.equipment?.approved }}</p>
            <p class="text-center text-white font-weight-bold">Approved</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="super">
    <div class="bg-white p-3 my-3 m-0 w-100">
      <h2 class="d-flex justify-content-center">Stats Comparision View</h2>
  
      <table class="table table-striped justify-content-center">
        <thead class="incubatorP">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Pending</th>
            <th scope="col">Approved</th>
            <th scope="col">Rejected</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Startup</td>
            <td>{{ data?.startup_counts?.pending }}</td>
            <td>{{ data?.startup_counts?.approved }}</td>
            <td>{{ data?.startup_counts?.rejected }}</td>
          </tr>
          <tr>
            <td>Incubator</td>
            <td>{{ data?.incubator_counts?.pending }}</td>
            <td>{{ data?.incubator_counts?.approved }}</td>
            <td>{{ data?.incubator_counts?.rejected }}</td>
          </tr>
          <tr>
            <td>Accelerators</td>
            <td>{{ data?.accelerators_count?.pending }}</td>
            <td>{{ data?.accelerators_count?.approved }}</td>
            <td>{{ data?.accelerators_count?.rejected }}</td>
          </tr>
          <tr>
            <td>COEs</td>
            <td>{{ data?.coes_count?.pending }}</td>
            <td>{{ data?.coes_count?.approved }}</td>
            <td>{{ data?.coes_count?.rejected }}</td>
          </tr>
          <tr>
            <td>Mentor</td>
            <td>{{ data?.mentor_counts?.pending }}</td>
            <td>{{ data?.mentor_counts?.approved }}</td>
            <td>{{ data?.mentor_counts?.rejected }}</td>
          </tr>
          <tr>
            <td>Investor</td>
            <td>{{ data?.investor_counts?.pending }}</td>
            <td>{{ data?.investor_counts?.approved }}</td>
            <td>{{ data?.investor_counts?.rejected }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

<div class="w-100 mt-3 p-3 bg-white" *ngIf="projectmanager">
  <div class="d-flex justify-content-center bg-white mb-3">
    <h2 class="" style="font-size: 27px">
      Welcome to Project Manager Dashboard
    </h2>
  </div>
  <div class="row py-2">
    <div class="col">
      <div class="column-1">
        <btn
          class="btn btn-primary btnDashA font-weight-bold arrow float-left"
          routerLink="/admin/startups"
        >
          New Start Up
        </btn>
        <p
          class="text-center text-white font-weight-bold arrow py-5"
          routerLink="/admin/startups"
        >
          {{ data?.startup_counts?.pending }}
        </p>
      </div>
    </div>

    <div class="col">
      <div class="column-2">
        <btn
          class="btn btn-primary btnDash font-weight-bold arrow float-left"
          routerLink="/admin/incubator"
        >
          Incubators
        </btn>
        <p
          class="text-center text-white py-5 font-weight-bold arrow"
          routerLink="/admin/incubator"
        >
          {{ data?.incubator_counts?.pending }}
        </p>
      </div>
    </div>

    <div class="col">
      <div class="column-3">
        <btn
          class="btn btn-primary btnDashB font-weight-bold arrow float-left"
          routerLink="/admin/addchallenge"
        >
          Open Challenges
        </btn>
        <p
          class="text-center text-white py-5 font-weight-bold arrow"
          routerLink="/admin/addchallenge"
        >
          {{ data?.challenge_counts?.inprogress_count }}
        </p>
      </div>
    </div>
  </div>

  <div class="row py-2">
    <div class="col">
      <div class="column-1">
        <btn
          class="btn btn-primary btnDashA font-weight-bold arrow float-left"
          routerLink="/admin/startups"
        >
          Completed Start Up
        </btn>
        <p
          class="text-center text-white font-weight-bold arrow py-5"
          routerLink="/admin/startups"
        >
          {{ data?.startup_counts?.approved }}
        </p>
      </div>
    </div>

    <div class="col">
      <div class="column-2">
        <btn
          class="btn btn-primary btnDash font-weight-bold arrow float-left"
          routerLink="/admin/incubator"
        >
          Completed Incubators
        </btn>
        <p
          class="text-center text-white py-5 font-weight-bold arrow"
          routerLink="/admin/incubator"
        >
          {{ data?.incubator_counts?.approved }}
        </p>
      </div>
    </div>

    <div class="col">
      <div class="column-3">
        <btn
          class="btn btn-primary btnDashB font-weight-bold arrow float-left"
          routerLink="/admin/addchallenge"
        >
          Completed Challenges
        </btn>
        <p
          class="text-center text-white py-5 font-weight-bold arrow"
          routerLink="/admin/addchallenge"
        >
          {{ data?.challenge_counts?.completed_count }}
        </p>
      </div>
    </div>
  </div>
</div>

<!-------------------------------------------- SOCIAL MANAGER ---------------------------------------->
<div class="d-flex justify-content-center bg-white w-100" *ngIf="socialmanager">
    <h2 class="h2 mt-3">Welcome to Social Manager Dashboard</h2>
</div>

<!-- NEWS ------------>
<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="socialmanager">
    <h2 class="d-flex justify-content-center">News</h2>
    <div class="row my-4">
        <div class="col-md-4">
            <div class="column-a d-flex shadow p-3 justify-content-center alignitemscenter pending"
                [routerLink]="'/admin/news'" [queryParams]="{status: 'ACTIVE'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Active: {{ news?.published_news }}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
            [routerLink]="'/admin/news'" [queryParams]="{status: 'INACTIVE'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Inactive: {{ news?.draft_news }}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
            [routerLink]="'/admin/news'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Total {{ news?.published_news + news?.draft_news }}
                </p>
            </div>
        </div>
    </div>
</div>

<!-- FAQs-->
<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="socialmanager">
    <h2 class="d-flex justify-content-center">FAQs</h2>
    <div class="row my-4">
        <div class="col-md-4">
            <div class="column-a d-flex shadow p-3 justify-content-center alignitemscenter pending"
                [routerLink]="'/admin/faqs'" [queryParams]="{status: 'ACTIVE'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Active: {{ faqs?.active_faqs }}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
            [routerLink]="'/admin/faqs'" [queryParams]="{status: 'INACTIVE'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Inactive: {{ faqs?.inactive_faqs }}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
            [routerLink]="'/admin/faqs'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Total {{ faqs?.active_faqs + faqs?.inactive_faqs }}
                </p>
            </div>
        </div>
    </div>
</div>

<!-- PRESS RELEASES -->
<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="socialmanager">
    <h2 class="d-flex justify-content-center">Press Releases</h2>
    <div class="row my-4">
        <div class="col-md-4">
            <div class="column-a d-flex shadow p-3 justify-content-center alignitemscenter pending"
                [routerLink]="'/admin/press'" [queryParams]="{status: 'ACTIVE'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Active: {{ press?.published_pressreleases }}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
            [routerLink]="'/admin/press'" [queryParams]="{status: 'INACTIVE'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Inactive: {{ press?.draft_pressreleases }}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
            [routerLink]="'/admin/press'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Total {{ press?.published_pressreleases + press?.draft_pressreleases }}
                </p>
            </div>
        </div>
    </div>
</div>

<!-- MEDIA ------->
<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="socialmanager">
    <h2 class="d-flex justify-content-center">Media</h2>
    <div class="row my-4">
        <div class="col-md-4">
            <div class="column-a d-flex shadow p-3 justify-content-center alignitemscenter pending"
                [routerLink]="'/admin/media'" [queryParams]="{status: 'ACTIVE'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Active: {{ media?.active_media }}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
            [routerLink]="'/admin/media'" [queryParams]="{status: 'INACTIVE'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Inactive: {{ media?.inactive_media }}
                </p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
            [routerLink]="'/admin/media'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Total {{ media?.active_media + media?.inactive_media }}
                </p>
            </div>
        </div>
    </div>
</div>

<!-- EVENTS ---------->
<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="socialmanager">
    <h2 class="d-flex justify-content-center">Events</h2>
    <div class="row my-4">
        <div class="col-md-3">
            <div class="column-a d-flex shadow p-3 justify-content-center alignitemscenter pending"
            [routerLink]="'/admin/userEvents'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    User Events
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
            [routerLink]="'/admin/userEvents'" [queryParams]="{status: 'APPROVED'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Active: {{ userevents?.approved }}
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
            [routerLink]="'/admin/userEvents'" [queryParams]="{status: 'PENDING'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Inactive: {{ userevents?.pending }}
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
            [routerLink]="'/admin/userEvents'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Total {{ userevents?.approved + userevents?.pending }}
                </p>
            </div>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-md-3">
            <div class="column-a d-flex shadow p-3 justify-content-center alignitemscenter pending"
            [routerLink]="'/admin/flagShipEvents'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    MSH Events
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
            [routerLink]="'/admin/flagShipEvents'" [queryParams]="{status: 'APPROVED'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Active: {{ flagshipevents?.approved }}
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
            [routerLink]="'/admin/flagShipEvents'" [queryParams]="{status: 'PENDING'}">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Inactive: {{ flagshipevents?.pending }}
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="column-c d-flex shadow p-3 justify-content-center alignitemscenter rejected"
            [routerLink]="'/admin/flagShipEvents'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Total {{ flagshipevents?.approved + flagshipevents?.pending }}
                </p>
            </div>
        </div>
    </div>
</div>

<!-- CHALLENGES ------>
<div class="bg-white p-3 my-3 m-0 w-100" *ngIf="socialmanager">
    <h2 class="d-flex justify-content-center">Challenges</h2>
    <div class="row my-4">
        <div class="col-md-6">
            <div class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved"
            [routerLink]="'/admin/externalchallenge'">
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    External Challenges: {{ challenges?.external }}
                </p>
            </div>
        </div>
        <div class="col-md-6">
            <div class="column-b d-flex p-3 shadow justify-content-center alignitemscenter approved" >
                <p class="text-white font-weight-bold mb-0 h4 arrow">
                    Internal Challenges: {{ challenges?.internal }}
                </p>
            </div>
        </div>
    </div>
</div>
<!-------------------------------------- END OF SOCIAL MANAGER ---------------------------------------->





<div class="mt-5 mb-2 p-3 bg-white" *ngIf="financemanager">
  <div class="d-flex justify-content-center bg-white mb-3">
    <h2 class="" style="font-size: 27px">
      Welcome to Finance Manager Dashboard
    </h2>
  </div>
  <div class="row">
    <div
      *ngFor="let scheme of schemeResponse; let i = index"
      [ngClass]="'column-' + (i + 1)"
      class="col-md-4 parent-container mr-3 my-3 ml-5"
    >
    <!-- *ngIf="scheme.name.toLowerCase().includes('tide'); else notide" -->
<ng-container *ngIf="scheme.name.toLowerCase().includes('tide')|| scheme.name.toLowerCase().includes('samridh'); else notide">
        <a
        class="text-decoration-none" (click)="newfinancialdata(scheme.id)"
        
        title="{{ scheme.name }}"
        alt="{{ scheme.name }}"
      >
        <div class="header">{{ scheme.name }}</div>
        <div class="content-container flex-wrap content-color">
          <div class="left-column">Released: {{scheme?.stats?.approved}}</div>
          <div class="middle-column" style="width: 2px"></div>
          <div class="right-column">Pending: {{scheme?.stats?.approved - scheme?.stats?.received}}</div>
        </div>
      </a>
      </ng-container>
      <ng-template #notide>
        <div
        class="disabled-scheme text-decoration-none"
        title="{{ scheme.name }}"
        alt="{{ scheme.name }}"
        style="cursor: not-allowed;"
      >
        <div class="header">{{ scheme.name }}</div>
        <div class="content-container flex-wrap content-color">
          <div class="left-column">Released:{{scheme?.stats?.approved}}</div>
          <div class="middle-column" style="width: 2px"></div>
          <div class="right-column">Pending: {{scheme?.stats?.approved - scheme?.stats?.received}}</div>
        </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<!-- <div class="bg-white" *ngIf="!projectmanager && !socialmanager"> -->
<!-- <h2 class="h2 bg-white mt-3 ">
    {{ financemanager ? "Budget" : super ? "Mentors" : "" }}
  </h2> -->
<!-- <table class="table" *ngIf = "financemanager">
        <thead>
          <tr>
            <th scope="col">Partner</th>
            <th scope="col">Declared(Rs in cr)</th>
            <th scope="col">Recieved(Rs in cr)</th>
            <th scope="col">Utilized(Rs in cr)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>TIDE 2.0</th>
            <td>119.51</td>
            <td>32.40</td>
            <td>17.22</td>
          </tr>
          <tr>
            <th>Samridh</th>
            <td>63.99</td>
            <td>39.26</td>
            <td>0.00</td>
          </tr>
          <tr>
            <th>Sasact</th>
            <td>59.60</td>
            <td>5.61</td>
            <td>0.00</td>
          </tr>
          <tr>
            <th>Others</th>
            <td>34.31</td>
            <td>7.90</td>
            <td>0.00</td>
          </tr>
          <tr>
            <th>Total</th>
            <td>277.41</td>
            <td>84.17</td>
            <td>17.22</td>
          </tr>
        </tbody>
      </table> -->
<!-- </div> -->
<div class="container" *ngIf="financemanager">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-body">
        <table class="table table-condensed table-striped">
          <thead>
            <tr>
              <th scope="col">Scheme Name</th>
              <th scope="col">Declared(INR)</th>
              <th scope="col">Recieved(INR)</th>
              <th scope="col">Utilized(INR)</th>
              <th scope="col">Assign Funds</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let scheme of schemeResponse"
              data-toggle="collapse"
              data-target="#demo1"
              class="accordion-toggle"
            >
              <th>{{ scheme.name }}</th>
              <td>{{scheme?.stats?.approved}}</td>
              <td>{{scheme?.stats?.received}}</td>
              <td>{{scheme?.stats?.utilized}}</td>
              <td>
                <a
                  class="fa fa-tasks fa-lg"
                  routerLink="/admin/grant-management/{{ scheme.id }}"
                  aria-hidden="true"
                ></a>
              </td>
            </tr>
            <tr
              data-toggle="collapse"
              data-target="#demo2"
              class="accordion-toggle"
            >
              <th>Total</th>
              <td>{{approvedtotal}}</td>
              <td>{{releasedtotal}}</td>
              <td>{{pendingtotal}}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
