import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { Subject } from 'rxjs';


const headers = new HttpHeaders()
  .set("content-type", "application/json")
  .set("Authorization", `Bearer ${sessionStorage.getItem("admintoken")}`);

  @Injectable({
  providedIn: 'root'
})
export class DashboardService {
 //  baseUrl = "http://10.0.53.134:8080";
 baseUrl = environment.apiurl 
 private resetFiltersSubject = new Subject<void>();
 resetFilters$ = this.resetFiltersSubject.asObservable();
 private schemeid = new Subject<void>();
 scheme_id = this.schemeid.asObservable();
 private inputSubject = new ReplaySubject<string>(2);
 inputSubject$ = this.inputSubject.asObservable();
 oldValues:string[] = [];
 currentValue:string = '';

 constructor(
   private http: HttpClient,
 ) {}
 
storeOldValues(val:string) {
  this.inputSubject.next(val);
}

setSchemeId(val:any){
  this.schemeid.next(val);
}

getSchemeId():Observable<any>{
  return this.scheme_id;
}

 getdashboardData() {
  const url = this.baseUrl + "dash";
  let result: any = this.http
    .get(url, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
}
getPressNewsData() {
  const url = this.baseUrl + "dashpressnews";
  let result: any = this.http
    .get(url, { headers: headers })
    .pipe(catchError(this.handleError));
  return result;
}

createForm(data:any){
  const url = this.baseUrl + "reports/forms";
  let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}

getForm(schemeid:any){
  const url = this.baseUrl + `reports/forms/list/${schemeid}`;
  let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}
searchForm(data:any){
  const url = this.baseUrl + "reports/forms/list";
  let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}

getFormById(id:any){
  const url = this.baseUrl + `reports/forms/${id}`;
  let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}
deleteFormById(id: any) {
  const url = this.baseUrl + `reports/forms/${id}`;
  return this.http
    .delete(url, { headers })
    .pipe(catchError(this.handleError));
}

updateIncForm(id:any,data:any){
  const url = this.baseUrl + `reports/forms/${id}`;
  let result = this.http.put(url,data,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}

getAllHeads(data:any){
  const url = this.baseUrl + `reports/dashboard`;
  let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}

getHeadsByGroup(data:any){
  const url = this.baseUrl + `reports/query/head`;
  let result = this.http.post(url,data,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}

getDomains(){
  const url = this.baseUrl + `domains`;
  let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}


getReportFormUsers(formid:any,entity:any){
  const url = this.baseUrl + `reports/dashboard/${formid}?entity=${entity}`;
  let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}

getReportIndividualData(id:any,entity:any){
  const url = this.baseUrl + `reports/dashboard/individual/${id}?entity=${entity}`;
  let result = this.http.get(url,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}

updateReportFormStatus(id:any,data:any,entity:any){
  const url = this.baseUrl + `reports/dashboard/individual/${id}?entity=${entity}`;
  let result = this.http.put(url,data,{'headers':headers}).pipe(catchError(this.handleError));
  return result;
}

handleError(error: HttpErrorResponse) {
  return throwError(error);
}
triggerResetFilters() {
  this.resetFiltersSubject.next();
}
}
