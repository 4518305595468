<ul *ngIf="visiblePages.length > 0" class="d-flex list-unstyled float-right">
    <li class="mr-2 p-3">
        <a (click)="getPrevSet()">&laquo;</a>
    </li>

    <li class="mr-2 p-3" 
        *ngFor="let page of visiblePages"
        [ngClass]="{'bg-primary text-white': selectedindex == page-1}">
        <a class="paginationlink text-decoration-underline" (click)="selectPage(page)"> {{ page }} </a>
    </li>

    <li class="mr-2 p-3">
        <a (click)="getNextSet()">&raquo;</a>
    </li>
</ul>
