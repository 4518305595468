<div class="upload-container border rounded p-4 bg-light mx-auto" style="max-width: 600px;">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="upload-title text-center mb-0 fs-2">Upload Teams</h1>
      <a href="https://d38ibwa0xdgwxx.cloudfront.net/incubator-profile-image/teaminfo.xlsx" download="teaminfo.xlsx">Download Team Details</a>

    </div>
  
    <div class="d-flex align-items-center gap-2 flex-column flex-md-row">
        <div class="file-input-wrapper flex-grow-1 ">
            <input 
                type="file" 
                (change)="onFileChange($event)" 
                id="file-input" 
                class="position-relative w-100 h-100 opacity-0 cursor-pointer form-control" 
                accept=".xls, .xlsx" 
                #galleryFileInput
                />
            <label for="file-input" class="custom-file-label form-control text-center">Choose File</label>
        
            <div *ngIf="fileError" class="text-danger mt-2">
                Please upload a valid Excel file (.xls or .xlsx).
            </div>
            <!-- <div *ngIf="fileUploaded" class="text-success mt-2">
                File uploaded successfully!
            </div> -->
        </div>
        
        
      
        <button class="btn btn-success upload-button fw-bold ml-3" (click)="uploadFile()" [disabled]="!file">
            Upload
        </button>
    </div>
</div>



  
  
  <section id="ourteam" class="about section-bg pt-0 containerpad meitysection">
    <div class="container-fluid containerpad">
  
      <!-- Team CEO Section -->
      <div class="row">
        <div class="mx-auto col-md-6 col-lg-6">
          <div class="mt-4 text-center">
            <h2>Shri. Rajnish Kumar</h2>
            <p class="m-0"><strong>Chief Executive Officer (CEO)</strong></p>
            <p class="m-0">MeitY Startup Hub <br/>
              ceo.msh&#64;digitalindia.gov.in<br/>
              +91-11-24301244</p>
          </div>
        </div>
      </div>
  
      <!-- Team Section -->
      <div class="row mt-2">
        <h2 class="mt-6 text-center">MSH Team</h2>
      </div>
  
      <!-- Table for Desktop View -->
      <div class="table-responsive-md d-none d-md-block"> <!-- Show only on larger screens -->
        <table class="table">
          <thead>
            <tr class="row-bg">
              <th>Sl No</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Email ID</th>
              <th>LinkedIn</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let member of teamsdata ; let i = index">
             <td>{{i+1}}</td>
              <td>{{ member.name }}</td>
          <td>{{ member.designation }}</td>
          <td>
            
                  <span *ngIf="member.email_id; else noEmail">
                    <a href="mailto:{{ member.email_id }}">{{ member.email_id }}</a>
                  </span>
                  <ng-template #noEmail>-</ng-template>
          </td>
              
              <td>
                <ng-container *ngIf="member.linkedin_url; else noLink">
                  <a [href]="member.linkedin_url" target="_blank">Click Here</a>
                </ng-container>
                <ng-template #noLink>-</ng-template>
              </td>



                <td>{{ member.status }}</td>
<td class="d-flex justify-content-center">                     
  <div class="d-flex">

    
    <ng-container *ngIf="member.status !== 'active'">
        <i class="dropbtn fa fa-check px-1" style="color:green" (click)="openUpdateStatusModal(member, 'active')" data-bs-toggle="modal" data-bs-target="#updateStatusModal"></i>
      </ng-container>
      <ng-container *ngIf="member.status !== 'inactive'">
        <i class="dropbtn fa fa-close" style="color:red" (click)="openUpdateStatusModal(member, 'inactive')" data-bs-toggle="modal" data-bs-target="#updateStatusModal"></i>
      </ng-container>

      <a class="fa fa-edit mr-3 arrow ml-2" style="color: blue" data-toggle="modal" data-target="#editMemberModal" (click)="editData(member, section)"></a>
    </div>
  
</td>
            </tr>
          </tbody>
        </table>
      </div>
  
<!--      
      <div class="d-md-none"> 
        <div class="row">
          <div *ngFor="let member of teamsdata" class="col-12 mb-3">
            <div class="card">
              <div class="card-body">
               
                <p class="card-text"><strong>Name : </strong> {{ member.name }}</p>
                <p class="card-text"><strong>Designation : </strong> {{ member.designation }}</p>
                <p class="card-text">
                  <strong>Email : </strong>
                  <span *ngIf="member.email; else noEmail">
                    <a href="mailto:{{ member.email_id }}">{{ member.email_id }}</a>
                  </span>
                  <ng-template #noEmail>-</ng-template>
                </p>
                

                <p class="card-text"><strong>LinkedIn : </strong> 
                  <ng-container *ngIf="member.linkedin_url; else noLink">
                    <a [href]="member.linkedin_url" target="_blank"> Click Here</a>
                  </ng-container>
                  <ng-template #noLink>-</ng-template>
                </p>

                   <p class="card-text"><strong>Status : </strong> {{ member.status }}</p> 
                   <p class="card-text"><strong>Actions : </strong> {{ member.actions }}</p> 
              
              </div>
            </div>
          </div>
        </div>
      </div>

       -->

       <!-- Card View for Mobile -->
       <div class="d-md-none" id="Width" > 
        <div class="row" >
          <div *ngFor="let member of teamsdata; let i = index" class="col-12 mb-3">
            <div class="card larger-card ">
              <div class="card-body">
                <p class="card-text"><strong>Name : </strong> {{ member.name }}</p>
                <p class="card-text"><strong>Designation : </strong> {{ member.designation }}</p>
                
                <p class="card-text">
                  <strong>Email : </strong>
                  <span *ngIf="member.email_id; else noEmail">
                    <a href="mailto:{{ member.email_id }}">{{ member.email_id }}</a>
                  </span>
                  <ng-template #noEmail>-</ng-template>
                </p>
                
                <p class="card-text"><strong>LinkedIn : </strong> 
                  <ng-container *ngIf="member.linkedin_url; else noLink">
                    <a [href]="member.linkedin_url" target="_blank">Click Here</a>
                  </ng-container>
                  <ng-template #noLink>-</ng-template>
                </p>
      
                <p class="card-text"><strong>Status : </strong> {{ member.status }}</p> 
      
                <!-- Actions Section -->
                <div class="card-text d-flex align-items-center">
                  <strong>Actions : </strong> 
                  <div class="d-flex ml-2">
                    <ng-container *ngIf="member.status !== 'active'">
                      <i class="dropbtn fa fa-check px-1" style="color:green" 
                         (click)="openUpdateStatusModal(member, 'active')" 
                         data-bs-toggle="modal" 
                         data-bs-target="#updateStatusModal"></i>
                    </ng-container>
                    <ng-container *ngIf="member.status !== 'inactive'">
                      <i class="dropbtn fa fa-close px-1" style="color:red" 
                         (click)="openUpdateStatusModal(member, 'inactive')" 
                         data-bs-toggle="modal" 
                         data-bs-target="#updateStatusModal"></i>
                    </ng-container>
                    <a class="fa fa-edit ml-2" style="color: blue" 
                       data-toggle="modal" 
                       data-target="#editMemberModal" 
                       (click)="editData(member, section)"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
          </div>
</section>



     <!-- Edit Member Modal -->
<div class="modal fade" id="editMemberModal" tabindex="-1" aria-labelledby="editMemberModalLabel" aria-hidden="true" #editMemberModal>
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="editMemberModalLabel">Edit Member</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="editMemberForm">
            <div class="form-group">
              <label for="name">Name<span style="color: red;">*</span></label>
              <input type="text" class="form-control" id="name" formControlName="name" />
              <div *ngIf="editMemberForm.get('name').invalid && editMemberForm.get('name').touched">
                <div *ngIf="editMemberForm.get('name')?.errors?.['required']" class="text-danger">
                  Name is required.
                </div>
            </div>
  
            </div>
            <div class="form-group">
              <label for="designation">Designation<span style="color: red;">*</span></label>
              <input type="text" class="form-control" id="designation" formControlName="designation" />
              <div *ngIf="editMemberForm.get('designation').invalid && editMemberForm.get('designation').touched">
                <div *ngIf="editMemberForm.get('designation')?.errors?.['required']" class="text-danger">
                 Designation is required.
                </div>

            </div>
            <div class="form-group">
              <label for="email">Email ID</label>
              <input type="email" class="form-control" id="email" formControlName="email_id" />
            </div>
            <div class="form-group">
              <label for="linkedin">LinkedIn URL</label>
              <input type="url" class="form-control" id="linkedin" formControlName="linkedin_url" />
            </div>
         
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="saveChanges()" data-dismiss="modal"   [disabled]="editMemberForm.invalid">
            Save changes</button>
        </div>
    </form>
      </div>
    </div>
  </div>
  </div>
  




