import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { MentorService } from '../../../services/mentor.service';
import { Location } from '@angular/common';
import html2canvas from "html2canvas";
import jspdf from "jspdf";
@Component({
  selector: 'ngx-individualmentor',
  templateUrl: './individualmentor.component.html',
  styleUrls: ['./individualmentor.component.scss']
})
export class IndividualmentorComponent implements OnInit {
  modaldata: any = { title: '', description: '' };
  readMore: boolean = false;
  id: string;
  data: any;
  ceo: any;
  modalTitle: string = '';
  modalContent: string = '';
  funding: any;
  contactInfo: any;
  teamInfo: any;
  team: any;
  market: any;
  OpportunityDetail:any;
  intellectual_detail:any;
  documentList:any;
  facebook_link: any;
  instagram_link: any;
  koo_link:any;
  linkedin_link:any;
  youtube_link:any;
  twitter_link:any;
  profile_image:any;
  mentorid:string;
  language:any;
  mentordata:any={};
  cleanedLanguages:any;
  Object = Object;
  filteredAwards: any;
  showDocuments: boolean = false;
  showLinksFlag: boolean = false;

  constructor(private _route:ActivatedRoute, private mentorService:MentorService,private router: Router,private location: Location) { }

  ngOnInit(): void {
    this._route.params.subscribe((params)=>{
      this.mentorid = params['id'];
    })

    this.getMentor();
    this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js');

  }
  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  openModal(title: string, content: string) {
    this.modalTitle = title;
    // this.modalContent = this.mentordata?.['Please Provide your brief Bio in less than 200 words.'];
    this.modalContent=content;
    // if(this.scheme_id == 1){
  }
  getMentor(){
    this.mentorService.getIndividualMentor(this.mentorid).subscribe((res)=>{
      var data = {...res.results};
      delete data['form_schema'];
      var mentor = {data,...res.results.form_schema.step2,...res.results.form_schema.step3,...res.results.form_schema.step4};
      this.mentordata = {...mentor.data,...res.results.form_schema.step2,...res.results.form_schema.step3,...res.results.form_schema.step4}
      if (res.results.profile_img && res.results.profile_img.length > 0) {
        this.profile_image = res.results.profile_img[0].url; // Extract first image URL
      } else {
        this.profile_image = null; // Fallback to default image
      }
      this.cleanedLanguages = this.mentordata?.languages?.filter(lang => lang.trim() !== '');
    })
    this.filteredAwards = this.mentordata?.awards?.filter(a => a.trim()) || [];
  }
  removeKeys(obj, keysToRemove) {
    keysToRemove.forEach(key => delete obj[key]);
}
truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {       
    this.readMore = false;
    return text;
   
  } else {
    this.readMore = true;
    return text.substring(0, maxLength) + '...';
  }
}
preview(item: any) {
  console.log("Item clicked:", item);
  this.modaldata.title = "Brief"; // Setting modal title
  this.modaldata.description = item['Please Provide your brief Bio in less than 200 words.'];
}
previewspecializedsector(item: any) {
  console.log("Item clicked:", item);
  this.modaldata.title = "Specialized Sector"; // Setting modal title
  this.modaldata.description = item['Any specialized functional area of mentoring? (Sector Specific) '];
}
previewsector(item: any) {
  console.log("Item clicked:", item);
  this.modaldata.title = " Sector"; // Setting modal title
  this.modaldata.description = item['sector'];
}
previewdomain(item: any) {
  console.log("Item clicked:", item);
  this.modaldata.title = "Domain"; // Setting modal title
  this.modaldata.description = item['domain'];
}
formatUrl(url:any)
{
  const invalid = ['',null,undefined]
  if(!invalid.includes(url)){
    var httpString = "http://";
    var httpsString = "https://";
    if (url.substr(0, httpString.length).toLowerCase() !== httpString && url.substr(0, httpsString.length).toLowerCase() !== httpsString)
                url = httpString + url;
      return url;
  }else{
    return null
  }
    // return url;
}

isEmptyValue(value: any): boolean {
  return !value || (Array.isArray(value) && value.length === 0);
}

handleRoute(){
 this.location.back();
 localStorage.setItem('viewback','true')
}

mentorlink(url){
  var httpString = "http://";
    var httpsString = "https://";
    if (url.substr(0, httpString.length).toLowerCase() !== httpString && url.substr(0, httpsString.length).toLowerCase() !== httpsString)
                url = httpString + url;
                console.log("url",url)

    return url;
}
showDocs() {
  this.showDocuments = true;
  this.showLinksFlag = false;
}

showLinks() {
  this.showDocuments = false;
  this.showLinksFlag = true;
}
// downloadPdf() {
//   try {
//     if (this.profile_image) {
//       await this.preloadImage(this.profile_image); // Ensure image is loaded
//     }
  
//   var data1 = document.getElementById("pdfSample") as HTMLElement;
//   const currentDate = new Date();
//   const day = String(currentDate.getDate()).padStart(2, '0');
//   const month = String(currentDate.getMonth() + 1).padStart(2, '0');
//   const year = currentDate.getFullYear();
//   const formattedDate = `${day}-${month}-${year}`;
//   html2canvas(data1, {allowTaint: false,  useCORS: true}).then((canvas) => {
//     // Few necessary setting options
//     var imgWidth = 208;
//     // var imgWidth = 101.6;
//     var pageHeight = 295;
//     var imgHeight = (canvas.height * imgWidth) / canvas.width;
//     var heightLeft = imgHeight;
//     const contentDataURL = canvas.toDataURL("image/png");
//     let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
//     var position = 0;
//     while (heightLeft > 0) {
//       pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
//       heightLeft -= pageHeight;
//       position -= 295; // Move to the next page
//       if (heightLeft > 0) {
//         pdf.addPage();
//       }
//     }}} catch (error) {
//       console.error("Image Preload Error:", error);
//     }
   
//     pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
//     pdf.save(`Mentor_reports_${formattedDate}.pdf`); // Generated PDF
//   });
// }
downloadPdf() {
  try {
    if (this.profile_image) {
      this.preloadImage(this.profile_image)
        .then(() => this.generatePdf()) // Ensure image is loaded before generating PDF
        .catch(error => console.error("Image Preload Error:", error));
    } else {
      this.generatePdf(); // Proceed if no image is set
    }
  } catch (error) {
    console.error("Download PDF Error:", error);
  }
}

generatePdf() {
  try {
    var data1 = document.getElementById("pdfSample") as HTMLElement;
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    html2canvas(data1, { allowTaint: false, useCORS: true })
      .then((canvas) => {
        try {
          var imgWidth = 208;
          var pageHeight = 295;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;
          const contentDataURL = canvas.toDataURL("image/png");
          let pdf = new jspdf("p", "mm", "a4"); // A4 size page of PDF
          var position = 0;

          while (heightLeft > 0) {
            pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            position -= 295; // Move to the next page
            if (heightLeft > 0) {
              pdf.addPage();
            }
          }

          pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
          pdf.save(`Mentor_reports_${formattedDate}.pdf`); // Generated PDF
        } catch (pdfError) {
          console.error("PDF Generation Error:", pdfError);
        }
      })
      .catch(html2canvasError => console.error("HTML2Canvas Error:", html2canvasError));
  } catch (error) {
    console.error("Generate PDF Error:", error);
  }
}

preloadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Fix CORS issues
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
  });
}

}
