import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LabFacilitiesComponent } from "./lab-facilities.component";
import { LabsComponent } from "./labs/labs.component";
import { CoworkingSpaceComponent } from "./coworking-space/coworking-space.component";
import { EquipmentDetailsComponent } from "./equipment-details/equipment-details.component";
import { GuestHouseComponent } from "./guest-house/guest-house.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserModule } from "@angular/platform-browser";
import { ResourceBookingComponent } from "./resource-booking/resource-booking.component";
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PaginationComponent } from "../pagination/pagination.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  declarations: [
    LabFacilitiesComponent,
    LabsComponent,
    CoworkingSpaceComponent,
    EquipmentDetailsComponent,
    GuestHouseComponent,
    ResourceBookingComponent,
    // PaginationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgSelectModule,
    BrowserModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule,
    SharedModule
  ],
})
export class LabFacilitiesModule {}
