import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IncubatorService } from '../../services/incubator.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChallengeService } from '../../services/challenge.service';
import * as _ from 'lodash';
import {  citiesOnly, states } from "../../../assets/js/states";
import { forkJoin, Subject } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CommondialogComponent } from '../../@core/miscellaneous/commondialog/commondialog.component';
import { ExcelService } from '../../services/excel.service';
import { StateService } from "../../services/state.service";
import { PaginationComponent } from '../pagination/pagination.component';

import { CityService } from "../../services/statecity/city.service";

declare let $: any;

export let SELECT_ITEMS = [
  { value: 'G1', label: 'G1' },
  { value: 'G2', label: 'G2' },
  { value: 'G3', label: 'G3' },
];

@Component({
  selector: 'ngx-incubator',
  templateUrl: './incubator.component.html',
  styleUrls: ['./incubator.component.scss']
})
export class IncubatorComponent implements OnInit {
  baseUrl = environment.apiurl 
  @ViewChild(PaginationComponent) paginationComponent!: PaginationComponent;
  filterArray = [
    {
      "name": "state",
      "value": []
    },
    {
      "name": "city",
      "value": []
    },
    {
        "name": "domain",
        "value": []
    },
    {
        "name": "schemes",
        "value": []
    },
    {
        "name": "status",
        "value": []
    }
]

  config = {
    id: 'custom-id',
  };




  // selectstate: any;
  // selectcity: any;
  // dataapi: any[] = states; // Assigning states from JS file
  // dropdownstates: any[] = []; // To store state names
  // dropdownCity: any[] = []; // To store filtered cities
  countryid=101;
  selected_citiesapi:any[] = [];
  dropdownstatesapi: any;
  dropdowncitiesapi:any = [];


  projectmanager!:boolean;
  financemanager!:boolean;
  superadmin!:boolean;
    data: any;
  filteredlist=[];
  filter:boolean = false;
  filterby = {};
  options: any[] = [];
  types = SELECT_ITEMS;
  dropdownSettings:IDropdownSettings = {};
  dropdownSettings_t:IDropdownSettings = {};
  dropdownSettings_s: IDropdownSettings={};
  dropdownSettings_schemes: IDropdownSettings={};
  dropdownSettings_city: IDropdownSettings={};
  selectedItems = [];
  dropdownList = [];
  sectors: any = [];
  dropdownList1 = [];
  tempdata = [];
  selectedItems1 = [];
  public domain = [];
  public sector = [];
  public schemes = [];

  originalData: any[];

  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectcity:any;
  selectstate:any=[];
  selectdomain:any=[];
  selectsector:any=[];
  selectstatus:any=[];
  selectscheme:any=[];
  selectedStates:any[];
  dropdownstates: any = [];
  dropdowncities:any = [];
  selectedStatus:any[];
  dropdownstatus:any[]=['PENDING','REJECTED','APPROVED'];
  selectedScheme:any[];
  dropdownscheme:any[]=[];
  modalUpdateForm:FormGroup;
  name: any;
  singledata = {name:''};
  action:any;
  currentData: any;
  inValidMessage: boolean;
  modaldata: any;
  id: any;
  schemeForm: FormGroup;
  alldomains: any;
  groupbydomains: any;
  submitted: boolean;
  filterMode: boolean = true;
  resetMode: boolean = false;
  filterValue: any;
  show:boolean=true;
  mat_data: string;
  // currentPage: number = 1;
  // tablearray: number;
  // paginationarr=[];
  sid: any;
  p: number = 1;
  schemsData:any;
  // itemsPerPage: 50;
  totalItems: number;
  currentPage:number=1; 
  allStates: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  pageSize: number= 10;
  loading: boolean = true; 
  activeFilter: string[] = [];
  filterValue1:any;
  viewback: any;
  schemId:any
  scheme:any;
  schemeName:any;
  newList: any;
  pageno:any = 0;
  totalpages: number;
  dots: string;
  selectedindex:number = 0;
  isDownloading:boolean = false;
  datafordownload: any;
  searchText = '';

  constructor(
    private incubatorService: IncubatorService,
    private router: Router,
    private fb: FormBuilder,
    private toastrService: NbToastrService,
    private challengeService: ChallengeService,
    private _route:ActivatedRoute,
    private http: HttpClient,
    private dialogService: NbDialogService,
    public dialog: MatDialog,
    private excelService:ExcelService,
    private statesservice : StateService,
     private cityservice:CityService,
  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    console.log("filtervalue", this.filterValue);

    this.activeFilter = ['APPROVED','PENDING','REJECTED'];

   
    if(localStorage.getItem('viewback'))
    {
      this.viewback = localStorage.getItem('viewback');
    }
   }

   
  
  ngOnInit(): void {
    // console.log("states_____from____js", states);



    this.selectedItems = [];
    this.selectedStates = [];
    // this.selectedStatus = [];
    this.selectcity = [];
    // this.getStates()
    // this.processCities();

    console.log('selectstate:', this.selectstate);
    console.log('selectcity:', this.selectcity);
    console.log('selectdomain:', this.selectdomain);
    console.log('selectstatus:', this.selectstatus);
    console.log('selectscheme:', this.selectedScheme);
    console.log('searchText:', this.searchText);
    this.loadStates();
    this.schemsData = sessionStorage.getItem('schemeId');
    if (!this.schemsData) {
      sessionStorage.removeItem('schemeId');  // Clear schemeId if not from Dashboard
    }
 

    this.getAllSchemes();
   

    this.getDomains();
    this.modalUpdateForm = this.fb.group({
      modalIncubatorName: [""],
      group: [""],
      schemes: ["", ],

    });
    console.log('viewback',this.viewback);

    if (this.viewback == 'true') {          
      this.backfilter();  

      if(localStorage.getItem('countdata'))
      {
        const storedJsonString = localStorage.getItem('countdata');
        const storedObject = JSON.parse(storedJsonString);
        console.log("count data is==>", storedObject);
        console.log(storedObject.approvedData)
        this.approvedCount = storedObject.approvedData;
        this.rejectedCount = storedObject.rejectedData;
        this.pendingCount = storedObject.pendingData;
       
  
      }  
    }
    
   else if (this.filterValue != null) {
     
      this.filtersItem(this.filterValue)
      }
    
    
    else {
      
      this.listAllIncubators();
    }
    // this.schemeForm=this.fb.group({
    // })
    
    if(sessionStorage.getItem('role_name')=='Project Manager'){
      this.projectmanager = true;
      this.financemanager = false;
    }
    else if(sessionStorage.getItem('role_name')=='Finance Manager'){
      this.projectmanager = false;
      this.financemanager = true;
    }
    else if(sessionStorage.getItem('role_name')=='Super Admin'){
      this.superadmin = true
    }

    // this.selectedItems = [];
    this.dropdownList = [];
    this.selectedItems1 = [];
    this.filteredlist=[];
    // this.selectedStates = [];
    // this.selectedStatus = [];
    // this.dropdownstates = states;
    this.dropdownscheme= [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
    this.dropdownSettings_t = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    }; 
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };

    this.dropdownSettings_city = {

      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true

    };
    this.dropdownSettings_schemes = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };

     // pagination page redirection code
     this._route.queryParams.subscribe((params) => {
      // Use the 'page' parameter from the URL or default to 1
      this.p = +params['page'] || 1;
      // Load data for the current page
      this.loadData(this.p);
    });
     // pagination page redirection code

  

  
  }

  async onpageloadstateselect(val:any){

    try {
      console.log('val',val);
      const response = await this.cityservice.getCitiesByState(val);
      console.log('Fetched Cities Response:', response);
  
      // Extract `result` array safely
      const cities = response.result || [];
  
      // Convert cities to expected format
      const newCities = cities.map((city: { id: any; name: any }) => ({
        item_id: city.id,
        item_text: city.name
      }));
  
      // Merge with existing cities (avoiding duplicates)
      this.selected_citiesapi = [...this.selected_citiesapi, ...newCities].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.item_id === value.item_id)
      );
  
      // Sort the updated list
      // this.selected_citiesapi.sort((a, b) => a.item_text.localeCompare(b.item_text));
  
    } catch (error) {
      console.error(`Error fetching cities for state`, error);
    }
  }
 
  async loadStates() {
    try {
      const response = await this.cityservice.getDefaultState(this.countryid); // API call
      console.log('Fetched Response:', response);
   
     
      const states = response.result || [];
      // console.log('states 1234 ----',states);
   
      
      this.dropdownstatesapi = states.map((state: { name: any; id: any; }) => ({
        item_text: state.name,  
        item_id: state.id    
      }));
      console.log('hfhyfgh',this.dropdownstatesapi)
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  }


  async backdeselect(val:any){
    this.cityservice.getCitiesByState(val).then(response => {
      const citiesToRemove = response.result.map(city => city.id);
  
      // Remove cities belonging to the deselected state
      this.selected_citiesapi = this.selected_citiesapi.filter(
        city => !citiesToRemove.includes(city.item_id)
      );
      this.selectcity = [];
  
    }).catch(error => {
      console.error(`Error fetching cities for deselected state ID ${val}:`, error);
    });
  
  }
  
  async onStateSelect(event: any) {




    console.log('Selected State:', event);
  
    const selectedStateId = event.item_id; // Get state ID
    localStorage.setItem('selectedStateId', selectedStateId);
    // localStorage.removeItem('selectedStateId');

  
    try {
      const response = await this.cityservice.getCitiesByState(selectedStateId);
      console.log('Fetched Cities Response:', response);
  
      // Extract `result` array safely
      const cities = response.result || [];
  
      // Convert cities to expected format
      const newCities = cities.map((city: { id: any; name: any }) => ({
        item_id: city.id,
        item_text: city.name
      }));
  
      // Merge with existing cities (avoiding duplicates)
      this.selected_citiesapi = [...this.selected_citiesapi, ...newCities].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.item_id === value.item_id)
      );
  
      // Sort the updated list
      this.selected_citiesapi.sort((a, b) => a.item_text.localeCompare(b.item_text));
  
    } catch (error) {
      console.error(`Error fetching cities for state ID ${selectedStateId}:`, error);
    }
  }
 

 


  // processCities() {
  //   // Flatten cities object into an array of city names
  //   this.dropdowncities = Object.values(citiesOnly);
  //   console.log('dropdown_____', this.dropdowncities)
  // }
  removeFilterQueryParam(): void {
 
    // Clone the current query parameters
    const updatedQueryParams = { ...this._route.snapshot.queryParams };
  
    // Remove 'filter' from the cloned object
    delete updatedQueryParams['filter'];
  
    // Navigate to the updated query parameters
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: updatedQueryParams, // Set updated params explicitly
      queryParamsHandling: undefined, // Ensure Angular fully replaces the params
    });
  
    console.log('Updated Query Params:', updatedQueryParams);
  }
  
  
  getAllSchemes(){
    this.incubatorService.getschemeList()
      .subscribe((res:any)=>{
        this.options= res.results;
        console.log("SchemeLIST:", this.options);

        let data = [];
      for(var i = 0 ; i < this.options.length ; i++){
        data.push({
          item_id:this.options[i].id , item_text:this.options[i].name
        })
      }
      this.dropdownscheme = [...data];
      console.log("schmeeeeeeeeee",this.dropdownscheme);
      if (this.schemsData) {
        this.selectscheme = this.dropdownscheme.filter(scheme => scheme.item_id == this.schemsData);
        console.log('Filtered Scheme:', this.selectscheme);
      }
      })
  }
  ngOnDestroy(): void{
    // remove style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = ''; 
      htmlElement.style.position = '';
    }
   
      // remove style for ui break on scroll
      localStorage.removeItem('viewback');
      // sessionStorage.removeItem('schemeId')
 }

 //  pagination page redirection code
onPageChange(page: number): void {
  // Update the current page and navigate to the new URL
  this.p = page;
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: { page: page },
    queryParamsHandling: 'merge',
  });
 
  this.loadData(page);
}
private loadData(page: number): void {
  // Implement your logic to load data based on the page
  console.log('Loading data for page', page);
}




async onStateDeSelect(event: any) {
  
 
  if (this.viewback == 'true') {
    console.log('viewback3234')
        const backdeselectstateid=localStorage.getItem('selectedStateId')
        this.backdeselect(backdeselectstateid)
    
      }
      else{
        console.log('Deselected State:', event);
  const deselectedStateId = event.item_id;

  // Fetch cities of the deselected state
  this.cityservice.getCitiesByState(deselectedStateId).then(response => {
    const citiesToRemove = response.result.map(city => city.id);
 
    // Remove cities belonging to the deselected state
    this.selected_citiesapi = this.selected_citiesapi.filter(
      city => !citiesToRemove.includes(city.item_id)
    );
    this.selectcity = [];
  }).catch(error => {
    console.error(`Error fetching cities for deselected state ID ${deselectedStateId}:`, error);
  });
}}
 
//  pagination page redirection code

  listAllIncubators(){
    //this.activeFilter = null;
    var data;
    if (this.schemsData  == null) {
      data={
        search_entity: "INCUBATOR",
        "page": 0, "page_size": 10
      }
    }
    else {
      this.activeFilter = ["APPROVED"]
      data={
        search_entity: "INCUBATOR",
        "page": 0, "page_size": 10,
        "filters": [
        {
            "name": "schemes",
            "value": [this.schemsData]
        },
        {
          name: "status",
          value: ["APPROVED"] // ✅ Added status filter
        }
        
    ],
      }
      // sessionStorage.removeItem('schemeId');  

    }
    this.loading = true;
    
    this.incubatorService.getIncubatorsList(data)
      .subscribe((res:any)=>{
        console.log("fullll",res)
        this.data=res.results;
        this.newList = res.results
        this.originalData = this.data;
        this.totalItems = +res.total_search_results_count; 
        // this.updateStatusCounts(res);    
        this.approvedCount = res.approved_count ;
        this.rejectedCount = res.rejected_count ;
        this.pendingCount = res.pending_count;


        var totalPages = Math.ceil(this.totalItems/10);
        this.totalpages = totalPages; // Replace with your dynamic value
      
        if (totalPages > 10) {
            this.dots = '...'
            //paginationHTML += '<li class="mr-2"><span>...</span></li>';
        }
        this.loading = false; // Stop loader
        this.onPageChange(1) 

      },(err)=>{
        console.log('err',err);
        this.loading = false; // Stop loader

      })
  }


//   async getAll(){
    
//     console.log("aaaaaoni", this.totalItems);

//     return new Promise((resolve,reject)=>{
//       let data={
//         search_entity: "INCUBATOR",
//         "page": 0, "page_size": this.totalItems
//       }
//       this.incubatorService.getIncubatorsList(data)
//         .subscribe((res:any)=>{
//           this.data=res.results;
//           this.originalData = this.data;
//         },
//         (err)=>{
//           console.log('err',err);
//         })
//         resolve(this.totalItems)
//     })
// }
 

 

  // filterItem(filterValue) {
  //   console.log("selectscheme before filtering:", this.selectscheme);

  //   if(filterValue == 'null')
  //   {
  //    this.activeFilter = 'All';
  //   } 
     
    
  //   // this.filterMode = false;
  //   this.resetMode = true;
  //   this.myArray = [];
  //   var domains = [];
  //   localStorage.removeItem('status');

  //   if(this.selectdomain && this.selectdomain.length > 0)
  //   {
  //     for (var i = 0; i < this.selectdomain.length; i++) {
  //       domains.push(this.selectdomain[i].item_text);
  //     }
  //     if (this.selectdomain.length != 0) {
  //       this.myArray.push({
  //         name: "domain",
  //         value: domains,
  //       });
  //     }
  //   }
  //   var sector = [];
  //   for(var i = 0 ; i < this.selectsector.length ; i++){
  //     sector.push(this.selectsector[i].item_text);
  //   }

  //   var schemes = [];
  //   if(this.selectscheme!=undefined){
  //     for(var i = 0 ; i < this.selectscheme.length ; i++){
  //       schemes.push(this.selectscheme[i].item_id);
  //     }
  //     console.log('scheme', schemes);
  //   }

  //   if (this.selectcity && this.selectcity.length > 0) {
  //     this.myArray.push({
  //       name: 'city',
  //       value: this.selectcity
  //     });
    
    
  //     // console.log("selectcity:",this.myArray);
  //   }
  //   if (this.selectstate && this.selectstate.length > 0 ){
  //     this.myArray.push({
  //       name: 'state',
  //       value: this.selectstate
  //     })
  //   }
  //   console.log('domain_select',this.selectdomain);
   


  //   if(schemes.length !=0){
  //     this.myArray.push({
  //       name: 'schemes',
  //       value: schemes
  //     })
  //   }
  //     console.log("selectscheme:",this.myArray);

  //   if(this.selectstatus?.length !=0){
  //     this.myArray.push({
  //       name: 'status',
  //       value: this.selectstatus
  //     })
  //     this.activeFilter = this.selectstatus;
  //     this.filterValue1 = this.selectstatus
  //   }

   
  //   const jsonString = JSON.stringify(this.myArray);
  //   localStorage.setItem('filterArray', jsonString); 
   
  //     let data = {
  //     "filters": this.myArray,
  //     "search_entity": "INCUBATOR",
  //     "page": 0, "page_size": 5000

  //   }
  //     console.log('data____final',data);
      
  //   if(this.filterValue == null){
  //     // this.filterMode=true;
  //     this.incubatorService.filterIncubator(data)
  //     .subscribe((res:any)=>{
  //       console.log("res myarray___0603:", res);
  //       this.data = res.results;
  //       this.onPageChange(1) 
  //       if(data.filters.length > 0 && data.filters[0]['name'] === 'status'){
  //       }else{
  //         this.updateStatusCounts(res);
  //       }
  //       const statusCount = {
  //         approvedData: this.approvedCount,
  //         rejectedData: this.rejectedCount,
  //         pendingData: this.pendingCount
  //       };
  //       const countstring = JSON.stringify(statusCount);
  //       localStorage.setItem('countdata', countstring);
  //     })
     
  //   }
  //   else {

  //             if (!this.myArray.some(item => item.name === 'status')) {
  //               this.myArray.push({
  //                 "name": "status",
  //                 "value": Array.isArray(this.filterValue) ? this.filterValue : [this.filterValue]               
  //             });
  //           }
  //         let data = {
  //           "filters": this.myArray,
  //           "search_entity": "INCUBATOR",
  //           "page": 0, "page_size": 5000
  //         }
  //     console.log("data____filterdata", data);
  //     this.incubatorService.filterIncubator(data).subscribe((res: any) => {
  //       console.log("pendinggggg.........:", res);
  //       this.data = res.results;
  //       this.updateStatusCounts(res);
  //       this.loading = false

  //     });
  //     // this.filterMode=false;

  //   }
  // }

  // countFilter(data)
  // {

  //   this.myArray = [];
  //   var domains = [];

    
    
  //   if(this.selectdomain && this.selectdomain.length > 0)
  //   {
  //     for (var i = 0; i < this.selectdomain.length; i++) {
  //       domains.push(this.selectdomain[i].item_text);
  //     }
  //     if (this.selectdomain.length != 0) {
  //       this.myArray.push({
  //         name: "domain",
  //         value: domains,
  //       });
  //     }
  //   }
   
  //   var sector = [];
  //   var schemes = [];
  //   if(this.selectscheme!=undefined){
  //     for(var i = 0 ; i < this.selectscheme.length ; i++){
  //       schemes.push(this.selectscheme[i].id);
  //       console.log("Schem ids+++++++++++",this.selectscheme[i].id)
  //     }
  //     console.log('scheme', schemes);
  //   }

  //   // if (this.selectcity !== "") {
  //   //   this.myArray.push({
  //   //     name: "city",
  //   //     value: this.selectcity,
  //   //   });
  //   //   console.log("selectcity:", this.myArray);
  //   // }
  //   if (this.selectcity && this.selectcity.length > 0) {
  //     this.myArray.push({
  //       name: 'city',
  //       value: this.selectcity
  //     });
  //   }
  //   if (this.selectstate && this.selectstate.length > 0) {
  //     this.myArray.push({
  //       name: "state",
  //       value: this.selectstate,
  //     });
  //   }
 
  //   this.myArray.push({
  //     name: "status",
  //     value: data,
  //   });
  //   let fitlere_data = data;
  //   this.activeFilter = data;
  //   this.filterValue1 = data;   
  //   // this.onPageChange(1) 
  //   localStorage.removeItem('status');

  //   if(this.viewback == 'true')
  //   {
  //     const getArray = localStorage.getItem('filterArray');
  //    const parsedArray = JSON.parse(getArray);
  //    this.myArray = parsedArray;
  //     this.myArray = this.myArray?.filter(item => item.name !== 'status') || [];
  //   }
  //    // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //    if(data == 'All')
  //    {
  //      // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //      this.myArray.push({
  //        name: "status",
  //        value: ["PENDING", "REJECTED", "APPROVED"]
  //      });
  //      this.activeFilter = 'All';
  //      this.selectedStatus = ["PENDING", "REJECTED", "APPROVED"];
  //      const jsonString = JSON.stringify(this.myArray);
  //      localStorage.setItem('filterArray', jsonString); 
      
  //    }
  //    else {
       
  //        this.myArray = this.myArray.filter(item => item.name !== 'status');
      
      
  //      this.myArray.push({
  //        name: "status",
  //        value: [data],
  //      });
  //      this.selectedStatus = [data];
  //      const jsonString = JSON.stringify(this.myArray);
  //      localStorage.setItem('filterArray', jsonString); 
  //    }
   
    
     
  //    const jsonString = JSON.stringify(this.myArray);
  //    localStorage.setItem('filterArray', jsonString); 

  //   let data1 = {
  //     filters: this.myArray,
  //     "search_entity": "INCUBATOR",
  //     "page": 0, "page_size": 5000

  // }
  //   this.incubatorService.filterIncubator(data1).subscribe((res: any) => {     
     
  //     this.data = res.results;
  //     console.log('count status data is ', this.data)
  //     // this.updateStatusCounts(this.data);
  //     this.onPageChange(1) 
  //     const statusCount = {
  //       approvedData: this.approvedCount,
  //       rejectedData: this.rejectedCount,
  //       pendingData: this.pendingCount
  //     };
  //     const countstring = JSON.stringify(statusCount);
  //     localStorage.setItem('countdata', countstring);
  //   });
  //   // this.p= 1;
  // }
  // countAllFilter()
  // {
  //   this.activeFilter = 'All';
  //   localStorage.removeItem('status');
  //   this.onPageChange(1) 
  //   this.resetFilters()
  //   this.listAllIncubators();
  //    this.updateStatusCounts(this.data);
  // }

  

 
 
 
 
   changestatus(id:string,value:string){
    //  this.mat_data = value.toLowerCase(); 

    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = '0'; 
      htmlElement.style.position = 'sticky';
    }
    this.mat_data = value;
    // console.log('datamatdata::', this.mat_data);
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'Incubator' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              "status": value
            }
            this.incubatorService.actionIncubator(data,id).subscribe((res:any)=>{
                console.log('change_status',res);
                // this.getAllIncubatorsData();
                // this.listAllIncubators();
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
                const updatedIncubatorIndex = this.data.findIndex(incubator => incubator.id === id);
                if (updatedIncubatorIndex !== -1) {
                  this.data[updatedIncubatorIndex].status = value;
                  this.data[updatedIncubatorIndex].approved_by = res?.results[0]?.approved_by || '';
                }
              },
              (error) => {
              this.inValidMessage = true;
              this.toastrService.warning("Unable to Update Status!");
            })
          }
        })
  }
 
  editModal(modaldata){
    this.modaldata=modaldata;
    console.log("modeeee::", this.modaldata);
    this.modalUpdateForm.controls["modalIncubatorName"].setValue(modaldata.name);
    this.modalUpdateForm.controls["group"].setValue(modaldata.group);
    // this.modalUpdateForm.controls["schemes"].setValue(modaldata.scheme_ids);

    // this.singledata['name'] = modaldata.name
    // console.log("modalForm::", this.modalUpdateForm.value);
  }

  // updateModal(){
  //   let data={
  //     group: this.modalUpdateForm.controls.centerType.value,
  //     // scheme: this.modalUpdateForm.controls.schemes.value
  //   }
  //   this.incubatorService.editIncubatorModal(data, this.modaldata.id)
  //     .subscribe((res:any)=>{
  //       console.log("updateTSDAta::", res);
  //       this.modalUpdateForm.reset();
  //       this.toastrService.success("Data Updated Successfully!");
  //     },
  //       (error) => {
  //       this.inValidMessage = true;
  //       this.toastrService.warning("Unable to Update records!");
  //     })
  // }
  schemeval(event:any){
    console.log('event____scheme',event.target.value)
     this.sid= event.target.value;
  }
  updateData(){
    console.log("sID:", this.sid);
    console.log("md form::",this.modalUpdateForm);
       let data=  {
          "schemes": [
              {
                  "id": this.sid,
                  "group": this.modalUpdateForm.controls.group.value
              }
          ]
      }
    
    this.incubatorService.updateScheme(data, this.modaldata.id)
      .subscribe((res:any)=>{
        // this.schemeForm.reset();
        this.listAllIncubators();
        console.log("schemUpdated:", res);
        this.toastrService.success("Schemes Updated Successfully!");
      },
        (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update Schemes!");
      })
  }

  // onCheckboxChange(checked: boolean) {
  //   const checkboxValue = checked ? 'Scheme' : 'TIDE 2.0';
  //   console.log(checkboxValue);
  // }
  

  // updateSchemes() {
  
  //   let data={
  //     group: this.modalUpdateForm.controls.group.value,
  //     scheme_ids:[+this.modalUpdateForm.controls.schemes.value]
  //   }
  //   let id=this.modaldata.id;
  //   const putRequest1 =this.incubatorService.editIncubatorModal(data, this.modaldata.id)
  //   const putRequest2=this.incubatorService.updateScheme(data, this.modaldata.id)
    
  //   // Use forkJoin to combine the two requests
  //   forkJoin([putRequest1, putRequest2]).subscribe(results => {
  //     // Handle the results of both requests here
  //     console.log('PUT request 1 result:', results[0]);
  //     console.log('PUT request 2 result:', results[1]);
  //     this.listAllIncubators();
  //     this.toastrService.success("Modal Updated Successfully!");
  //   },
  //   (error) => {
  //   this.inValidMessage = true;
  //   this.toastrService.warning("Unable to Update Modal!");
  // })
      
  // }
  
  getDomains(){
    this.challengeService.getalldomains().subscribe((res:any)=>{
      this.alldomains = res.results;
      let data = [];
      for(var i = 0 ; i < this.alldomains.length ; i++){
        data.push({
          item_id:i+1 , item_text:this.alldomains[i].name
        })
      }
      this.dropdownList = [...data];
      // console.log('data_domain',data);
      this.groupbydomains = _.groupBy(this.alldomains,'name');
      console.log('group domains',this.groupbydomains);
    })
  }

 
  async onItemSelectDomain(event){
    console.log('onItemSelect',this.groupbydomains,event);
    console.log('getsector',this.groupbydomains[`${event.item_text}`][0]['sectors'])
    await this.groupbysectors(event);
    console.log('sectors',this.sectors);
    for(var i = 0 ; i<this.sectors.length ; i++) {
      this.tempdata.push({
        item_id:i+1 , item_text:this.sectors[i].name
      })
    }
    this.dropdownList1 = [...this.tempdata];
    console.log('sectors_dropdown',this.dropdownList1);
  }

  async onItemDeSelectDomain(event){
    console.log('event',event);
  }
  async groupbysectors(event){
    return new Promise((resolve,reject)=>{
      this.challengeService.setSectorData(this.groupbydomains[`${event.item_text}`][0]['sectors'])
      this.sectors.push(...this.groupbydomains[`${event.item_text}`][0]['sectors']);
      resolve(this.sectors);
    })
  }  

  gotosection(el:any){
    // this.visible = !this.visible;
    el.scrollIntoView({behaviour:'smooth'});
  }
  // preview(d){
  //   console.log("pCheck::", d);
  //   this.router.navigateByUrl(`/admin/incubatortemp/${d}`);
  //   if(this.filterValue1.length > 0)
  //   {
  //     localStorage.setItem('status',this.filterValue1)
  //     console.log("filte value is ==> ", this.filterValue1)
  //   }
   
  // }
  // preview(d) {
  //   console.log("pCheck::", d);
  //   if (this.filterValue1 && this.filterValue1.length > 0) {
  //     localStorage.setItem('status', this.filterValue1);
  //     console.log("filter value is ==> ", this.filterValue1);
  //   }
  
  //   // Save selected filters before navigating
  //   const jsonString = JSON.stringify(this.myArray);
  //   localStorage.setItem('filterArray', jsonString); 
  
  //   if (this.selectscheme && this.selectscheme.length > 0) {
  //     localStorage.setItem('selectedSchemes', JSON.stringify(this.selectscheme));
  //   }
  //   this.router.navigateByUrl(`/admin/incubatortemp/${d}`);
  // }
  preview(d) {
    console.log("pCheck::", d);

   

    if(this.searchText&&this.searchText!==null){
      localStorage.setItem('query',JSON.stringify(this.searchText));
    }else{
      localStorage.removeItem('query');
    }


    if (this.filterValue1 && this.filterValue1.length > 0) {
        localStorage.setItem('status', JSON.stringify(this.filterValue1));
    } else {
        localStorage.removeItem('status');
    }

    if (this.filterValue1 && this.filterValue1.length > 0) {
      this.activeFilter = this.filterValue;
 
        const statusFilter = { name: "status", value: this.filterValue1 };
        this.myArray = this.myArray.filter(item => item.name !== "status"); // Remove existing status filter
        this.myArray.push(statusFilter);
    }
    if (this.myArray?.length > 0) {
      localStorage.setItem('filterArray', JSON.stringify(this.myArray));
  } else {
      localStorage.removeItem('filterArray');  // ✅ Remove completely if empty
  }

    if (this.selectscheme && this.selectscheme.length > 0) {
      localStorage.setItem('selectedSchemes', JSON.stringify(this.selectscheme));

      const schemeFilter = { name: "schemes", value: this.selectscheme };
      this.myArray = this.myArray.filter(item => item.name !== "schemes"); // Remove duplicates
      this.myArray.push(schemeFilter);
  } else {
      localStorage.removeItem('selectedSchemes');
  }

  

    if (this.selectscheme && this.selectscheme.length > 0) {
      localStorage.setItem('selectedSchemes', JSON.stringify(this.selectscheme));
    }
    // ✅ Ensure the selected status is added to `filterArray`
    localStorage.setItem('selectedindex',this.selectedindex.toString())
    this.router.navigateByUrl(`/admin/incubatortemp/${d}`);
}

// backfilter() {  
//   if (this.viewback == 'true') {
    
//       const getArray = localStorage.getItem('filterArray');
//       this.myArray = getArray ? JSON.parse(getArray) : [];

//       // ✅ Restore stored filters
//       const stateValue = this.myArray.find(item => item.name === 'state')?.value || [];
//       const statusValue = this.myArray.find(item => item.name === 'status')?.value || [];
//       const cityValue = this.myArray.find(item => item.name === 'city')?.value || [];
//       const domainValue = this.myArray.find(item => item.name === 'domain')?.value || [];
//       // const schemData = this.myArray.find(item => item.name ==='schemes')?.value || [];
//       const storedSchemes = localStorage.getItem('selectedSchemes');
//       this.selectedStates = stateValue;
//       this.selectedStatus = statusValue;  // ✅ Restore selected status
//       this.selectcity = cityValue;
//       this.selectedItems = domainValue;
//       this.selectscheme = storedSchemes ? JSON.parse(storedSchemes) : [];
//       console.log('aaaaaaaaaaa',this.selectscheme)
  
//   if (localStorage.getItem('status')) {
//       this.activeFilter = JSON.parse(localStorage.getItem('status'));
//   }

//   if (this.schemsData) {
//     const schemeFilter = { name: "schemes", value: this.selectscheme.map(s => s.item_id) };
    
//     const statusFilter = { name: "status", value: ["APPROVED"] };
 
//     this.myArray = this.myArray.filter(item => item.name !== "schemes" && item.name !== "status");
 
//     this.myArray.push(schemeFilter, statusFilter);
// }


// console.log("Restored Filters:", this.myArray);
// }




//   let data = {
//       filters: this.myArray,
//       search_entity: "INCUBATOR",
//       "page": 0, "page_size": 5000
//   };

//   console.log("data____0603", data);

//   this.incubatorService.filterIncubator(data).subscribe((res: any) => {
//       this.data = res.results;        
//       this.loading = false;
//   });
// }

async backfilter() {  
  if (this.viewback == 'true') {
    const storedValue = localStorage.getItem('query');
    // this.searchText= storedValue;
     this.searchText = storedValue ? JSON.parse(storedValue) : ''; 
      const getArray = localStorage.getItem('filterArray');
   
      this.myArray = getArray ? JSON.parse(getArray) : [];

      // ✅ Restore stored filters
      const stateValue = this.myArray.find(item => item.name === 'state')?.value || [];
      const statusValue = this.myArray.find(item => item.name === 'status')?.value || [];
      const cityValue = this.myArray.find(item => item.name === 'city')?.value || [];
      const domainValue = this.myArray.find(item => item.name === 'domain')?.value || [];
      console.log('domainValue_________________',domainValue);
      for(var i = 0 ; i < domainValue.length ; i++){
        this.selectdomain.push({
          'item_text':domainValue[i]
        })
      }
      this.selectstate = stateValue;
      this.selectstatus = statusValue; 
      console.log('select----------', this.selectstatus) 
      this.selectcity = cityValue;
      // this.selectdomain = domainValue;
      console.log('selectdomain', this.selectdomain)

      // ✅ Restore selected scheme from localStorage
      const storedSchemes = localStorage.getItem('selectedSchemes');
      this.selectscheme = storedSchemes ? JSON.parse(storedSchemes) : [];

      console.log('Restored Schemes:', this.selectscheme);
  }
  const storedFilters = localStorage.getItem('filterArray');
  const stateId=localStorage.getItem('selectedStateId')

  console.log('stored',storedFilters);
  if (storedFilters) {
      console.log('✅ Stored filters found');
      this.myArray = JSON.parse(storedFilters);

         
    console.log('⚡ Calling onpageloadstateselect()');
    if(stateId){
      await this.onpageloadstateselect(stateId);

    }
  }


  if (localStorage.getItem('status')) {
    this.activeFilter = JSON.parse(localStorage.getItem('status'));
} else if (this.schemsData) {
    this.activeFilter = ["APPROVED"];  // If scheme is selected, default to APPROVED
} else {
    this.activeFilter = ['APPROVED','PENDING','REJECTED']; // Reset if no filters
}


// if (this.selectedStatus && this.selectedStatus.length > 0) {
//   this.activeFilter = this.myArray[4].value.length !=0 ? this.myArray[4].value : ['APPROVED','PENDING','REJECTED']; // Set active filter based on restored status
//   console.log('selected123 *******', this.activeFilter);
  

// }
if (this.selectstatus && this.selectstatus.length > 0) {
  this.activeFilter = this.myArray.find(item => item.name === 'status')?.value || ['APPROVED', 'PENDING', 'REJECTED'];
}



console.log('selected________', this.selectstatus)
  if (this.selectscheme.length > 0) {
      const schemeFilter = { name: "schemes", value: this.selectscheme.map(s => s.item_id) };
      this.myArray = this.myArray.filter(item => item.name !== "schemes"); // Remove old scheme filters
      this.myArray.push(schemeFilter);
  }

  if (this.schemsData) {
    const schemeFilter = { name: "schemes", value: [this.schemsData] };
    const statusFilter = { name: "status", value: ["APPROVED"] };
    // Check if 'schemes' filter already exists
    this.activeFilter = ["APPROVED"]
    // this.myArray = this.myArray.filter(item => item.name !== "schemes");
    this.myArray = this.myArray.filter(item => item.name !== "schemes" && item.name !== "status");
    this.myArray.push(schemeFilter,statusFilter);

    // Store updated filterArray in localStorage
    localStorage.setItem('filterArray', JSON.stringify(this.myArray));
}

  console.log("Restored Filters:", this.myArray);
  this.selectedindex  = Number(localStorage.getItem('selectedindex'));
  const pageno = this.selectedindex;
  let data = {
       query : this.searchText,
      filters: this.myArray,
      search_entity: "INCUBATOR",
      page: pageno,
      page_size: 10
  };

  console.log("Final Filter Data:", data);

  this.incubatorService.filterIncubator(data).subscribe((res: any) => {
      this.data = res.results;  
      this.approvedCount = res.approved_count ?? this.approvedCount;
      this.rejectedCount = res.rejected_count ?? this.rejectedCount;
      this.pendingCount = res.pending_count ?? this.pendingCount;
      this.totalItems = +res.total_search_results_count;
      var totalPages = Math.ceil(this.totalItems/10);
        this.totalpages = totalPages;
      this.loading = false;
  });
}


resetFilters() {

  //this.router.navigate(["/admin/incubator"]); 
  //this.filterValue =  null;

  this.schemsData = null;
  sessionStorage.removeItem('schemeId');
  this.resetMode = false;

  this.data = {};

  // ✅ Reset all selection fields
  this.selectcity = '';
  this.selectstate = '';  // ✅ Properly reset state
  this.selectdomain = '';
  this.selectscheme = '';
  this.selectstatus = '';
this.filterValue = null;
  this.selectedStates = [];
  this.selectedItems = [];
  this.selectedStatus = [];
this.selectedScheme = [];
this.selectedindex = 0;
if (this.paginationComponent) {
  this.paginationComponent.resetPageIndex();
}
  // ✅ Completely clear filters from local storage
  localStorage.removeItem('filterArray');    
  localStorage.removeItem('status'); 
  localStorage.removeItem('selectedSchemes'); 
  localStorage.removeItem('viewback');
  localStorage.removeItem('selectedStates');  // ✅ Also remove stored states
  localStorage.removeItem('selectedStateId');
  this.activeFilter = ['APPROVED','PENDING','REJECTED'];
  this.viewback = 'false';

  // ✅ Clear `this.myArray` and refetch data
  this.myArray = [];
  this.listAllIncubators();
  this.removeFilterQueryParam();
  // this.loading = false;
  //this.onPageChange(1);
  
  setTimeout(() => {
    this.searchText = ''; // ✅ Ensure UI updates properly
  });
}


  updateScheme(d){
    console.log("updatescc", d);
    this.router.navigateByUrl(`/admin/indincubator/${d}`);

  }

  exportData(dataArray: any) {
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };
    const data = dataArray.map((d) => {
    
    const result: any = {
      Name: d.name ? d.name : '-',
      'Affiliate Name': d.affiliate_name ? d.affiliate_name : '-',
    //  : 0,
      Brief: d.brief ? d.brief : '-',
      'Company Logo': d.profile_image && d.profile_image[0]?.url ? d.profile_image[0].url : '-',
      'Established Year': d.establishment_year ? d.establishment_year : '-',
      'Supported under any Government Schemes': d.support_govt_scheme ? (Array.isArray(d.support_govt_scheme) ? d.support_govt_scheme.join(', ') : d.support_govt_scheme) : '-',
      'Registered Entity Type': d.registered_entity_type ? (Array.isArray(d.registered_entity_type) ? d.registered_entity_type.join(', ') : d.registered_entity_type) : '-',
      'Supporting Incorporation Document': d?.section_8_upload ? (d.section_8_upload[0] ? d.section_8_upload[0].url : d?.section_8_upload) : '-',
      Domain: d.domain ? (Array.isArray(d.domain) ? d.domain.join(', ') : d.domain) : '-',      
      Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
      'Registered Address' : d.contact_info.address1 ? d.contact_info.address1 : '-',
      City: d.city ? d.city : '-',
      // Name: d.name ? d.name : '-',
      // AffiliateName: d.affiliate_name ? d.affiliate_name : '-',
      // Domain: d.domain ? (Array.isArray(d.domain) ? d.domain.join(', ') : d.domain) : '-',      
      // Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
      // City: d.city ? d.city : '-',
      // Description: d.short_description,
      State: d.state ? d.state : '-',
      Country: d.country ? d.country : '-',
      'Zip Code': d?.contact_info?.addresses?.zipCode ? d?.contact_info?.addresses?.zipCode : '-',
      'Website Url':d?.contact_info?.weburl ? d?.contact_info?.weburl : '-',
      Documents: d.documents ? (Array.isArray(d.documents) ? d.documents.map(info => `${info.file_name} URL: ${info.url}`).join('\n') : '-') : '-',
      
      'CEO Firstname': d?.team_info ? d.team_info?.ceoFirstName : '-', 
      'CEO Lastname' : d?.team_info ? d.team_info?.ceoLastName : '-',
      'CEO Phone No.': d?.team_info ? d.team_info?.ceoPhoneNo : '-',
      'CEO Email' : d?.team_info ? d.team_info?.ceoEmail : '-',
      // CeoDetails:  d?.team_info?.ceoFirstName ? `CEO Firstname=${d.team_info?.ceoFirstName}` : '',
      // [
      //   d?.team_info?.ceoFirstName ? `CEO Firstname=${d.team_info?.ceoFirstName}` : '',
      //   d?.team_info?.ceoLastName ? `CEO Lastname=${d.team_info?.ceoLastName}` : '',
      //   d?.team_info?.ceoPhoneNo ? `Mobile=${d.team_info?.ceoPhoneNo}` : '',
      //   d?.team_info?.ceoEmail ? `Email=${d.team_info?.ceoEmail}` : '',
        
      // ],
      'Team Size': Array.isArray(d?.team_info?.team_array) ? d?.team_info?.team_array.length : '-',
      'Team Details':Array.isArray(d?.team_info?.team_array) ? d?.team_info?.team_array.map(info => `First Name=${info.teamFirstName},Last Name= ${info.teamLastName},Mobile= ${info.teamPhoneNo},Email=${info.teamEmail}`).join('\n') : '-',
      // 'Social Info': d?.social_info ? `Facebook=${d.social_info.facebook ? d.social_info.facebook : '-'}\n Instagram=${d.social_info.instagram ? d.social_info.instagram : '-'}\n Twitter=${d.social_info.twitter ? d.social_info.twitter : '-'}\n LinkedIn=${d.social_info.linkedIn ? d.social_info.linkedIn : '-'}\n Youtube=${d.social_info.youtube ? d.social_info.youtube : '-'}\n Koo=${d.social_info.koo ? d.social_info.koo : '-'}` : '-',
      'Lab Resources Created':d.labresources_count ? d.labresources_count:'-',
'Co-working Spaces Created ':d.coworkingresources_count ? d.coworkingresources_count:'-',
'Equipment resource Created':d.equipmentresources_count ? d.equipmentresources_count:'-',
'Guesthouse resource Created':d.guesthouseresources_count ? d.guesthouseresources_count:'-',
'Labs booked':d.lab_bookings_count ? d.lab_bookings_count:'-',
'Co-working Spaces Booked':d.coworking_bookings_count ? d.coworking_bookings_count:'-',
'Equipments Booked':d.equipment_bookings_count ? d.equipment_bookings_count:'-',
'Guesthouse Booked':d.guesthouse_bookings_count ? d.guesthouse_bookings_count:'-',
 
      Facebook: d?.social_info?.facebook ? d.social_info.facebook : '-',
      Instagram: d?.social_info?.instagram ? d.social_info.instagram : '-',
      Twitter: d?.social_info?.twitter ? d.social_info.twitter : '-',
      LinkedIn: d?.social_info?.linkedIn ? d.social_info.linkedIn : '-',
      Youtube: d?.social_info?.youtube ? d.social_info.youtube : '-',
      // Koo: d?.social_info?.koo ? d.social_info.koo : '-',
      // SocialInfo: [
      //   d?.social_info?.facebook ? `Facebook=${d.social_info.facebook}` : '',
      //   d?.social_info?.instagram ? `Instagram=${d.social_info.instagram}` : '',
      //   d?.social_info?.twitter ? `Twitter=${d.social_info.twitter}` : '',
      //   d?.social_info?.linkedIn ? `LinkedIn=${d.social_info.linkedIn}` : '',
      //   d?.social_info?.youtube ? `Youtube=${d.social_info.youtube}` : '',
      //   d?.social_info?.koo ? `Koo=${d.social_info.koo}` : ''
      // ],
      Schemes:Array.isArray(d.scheme_names) ? d.scheme_names.join(', ') : d.scheme_names,
      Status: d.status ? d.status : '-',
      // 'Approved By':d.approved_by,
      // 'Profile Email': d?.team_info?.ceoEmail,
      'Login Email':d?.login_email ? d?.login_email : '-',
      'Created At': d?.created_at ? new Date(d?.created_at) : '-',
      'Updated_at': d?.updated_at ? new Date(d?.updated_at):'-',
      // 'Last Login': d?.login_info ? new Date(d.login_info.last_login_time).toLocaleString('en-US', {
      //   year: 'numeric',
      //   month: 'short',
      //   day: 'numeric',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   second: '2-digit',
      //   hour12: true
      // }) : '-',
      'Last Login Date':d?.login_info ? formatDate(new Date(d.login_info.last_login_time) ) : '-',
      'Last Login Time':d?.login_info ? formatTime(new Date(d.login_info.last_login_time)):'-'

      
    };

    if (this.activeFilter === ['APPROVED']) {
      result.ApprovedBy = d.approved_by ? d.approved_by : '-';
    }else if(this.activeFilter === ['REJECTED']){
      result.RejectedBy = d.approved_by ? d.approved_by : '-';
    }else if(this.activeFilter !== ['PENDING']){
      result['Approved/Rejected By'] = d.approved_by ? d.approved_by :'-';
    }

    return result;
  });
    this.exportAsXLSX(data)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", data);
  }

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Incubator Report','Incubator_Data');
  }
  // downloadIncubatorList(){
  //   this.exportData(this.data);
  // }

  async downloadIncubatorList(){
    this.isDownloading = true;  // Start loader
    let downloaddata = await this.listAllIncubatorforDownload();
    this.exportData(downloaddata);
    this.isDownloading = false;  
  }

  async listAllIncubatorforDownload() {

    return new Promise((resolve,reject)=>{
      let data= {

        query:this.searchText,
        filters: this.myArray,
        search_entity: "INCUBATOR",
        "page": 0, "page_size": 100
  
      }
      // this.isDownloading = true;

      // this.loading = true;
      this.incubatorService.getIncubatorsList(data).subscribe((res: any) => {
        this.datafordownload = res.results;
        resolve(this.datafordownload);
      });
    })
  }

  // state
  // getStates() {
  //   this.statesservice.getallstates().subscribe((res: any) => {
  //     this.allStates = res.results;
  //     console.log("all states =>", this.allStates);
  //     this.dropdownstates = this.allStates
     
   
  //   });
  // }
  
  // state


  getCountsByStatus(startups) {
    const statusCounts = {};
    startups.forEach((startup) => {
      const status = startup.status;
      if (status) {
        if (statusCounts.hasOwnProperty(status)) {
          statusCounts[status]++;
        } else {
          statusCounts[status] = 1;
        }
      }
    });   

    return statusCounts;
  }
  // updateStatusCounts(data) {
  //   const statusCounts = this.getCountsByStatus(data);
  //   console.log("Status Counts:", statusCounts);
  //   // Separate the counts
  //   this.approvedCount = statusCounts["APPROVED"] || 0;
  //   this.rejectedCount = statusCounts["REJECTED"] || 0;
  //   this.pendingCount = statusCounts["PENDING"] || 0;
    
  // }

// updateStatusCounts(res) {
//   this.approvedCount = res.approved_count || 0;
//   this.rejectedCount = res.rejected_count || 0;
//   this.pendingCount = res.pending_count || 0;

//   const statusCount = {
//     approvedData: this.approvedCount,
//     rejectedData: this.rejectedCount,
//     pendingData: this.pendingCount
//   };

//   localStorage.setItem('countdata', JSON.stringify(statusCount));
// }
// filtersItem(filterValue) {
//   console.log('filterValue', filterValue)
//   // this.downloadButton = false;
//   // this.filterEnable = true;
//   this.resetMode = true;
//   this.myArray = [];
//   this.p = 1;
//  var domains =[]
//   if(this.selectdomain && this.selectdomain.length > 0)
//     {
//       for (var i = 0; i < this.selectdomain.length; i++) {
//         domains.push(this.selectdomain[i].item_text);
//       }
//       if (this.selectdomain.length != 0) {
//         this.myArray.push({
//           name: "domain",
//           value: domains,
//         });
//       }
//     }



//     if (this.selectcity && this.selectcity.length > 0) {
//       this.myArray.push({
//         name: 'city',
//         value: this.selectcity
//       });
    
    
//       // console.log("selectcity:",this.myArray);
//     }
//     if (this.selectstate && this.selectstate.length > 0 ){
//       this.myArray.push({
//         name: 'state',
//         value: this.selectstate
//       })
//     }
//     console.log('domain_select',this.selectdomain);
   
//     var schemes = [];
//     if(this.selectscheme!=undefined){
//       for(var i = 0 ; i < this.selectscheme.length ; i++){
//         schemes.push(this.selectscheme[i].item_id);
//       }
//       console.log('scheme', schemes);
//     }

//     if(schemes && schemes.length>0){
//       this.myArray.push({
//         name: 'schemes',
//         value: schemes
//       })
//     }
//       console.log("selectscheme:",this.myArray);



//   if (filterValue) {//whatever i select
//     this.activeFilter = filterValue === "ALL" ? null : filterValue;
//     this.selectstatus = filterValue === "ALL" ? ["APPROVED", "PENDING", "REJECTED"] : [filterValue];
//   }

//   // ✅ Use `this.selectedStatus` directly
//   if (this.selectstatus.length > 0) {
//     this.myArray.push({ name: "status", value: this.selectstatus });
//     this.activeFilter = this.selectstatus
//   }
 
 
//   // Add date filter
 

//   console.log("Filters Applied:", this.myArray);

//   let requestData = {
//     "filters": this.myArray,
//     "search_entity": "INCUBATOR",
//     "page": 0, "page_size": 5000

//   }
 
// if(filterValue == null){
//   this.incubatorService.filterIncubator(requestData).subscribe((res: any) => {
//     this.data = res.results;
//     // this.list=[];
//     // this.approvedCount = this.totalApproved;
//     // this.rejectedCount = this.totalRejected;
//     // this.pendingCount = this.totalPending;
  
//     // Update status counts
//     this.approvedCount = res.approved_count;
//     this.rejectedCount = res.rejected_count;
//     this.pendingCount = res.pending_count;
//     // this.list = this.data;

//   });
// }
// else{
  
//      if (!this.myArray.some(item => item.name === 'status')) {
//     this.myArray.push({ name: "status", value: Array.isArray(this.filterValue) ? this.filterValue : [this.filterValue] });
//   }

//   let requestData = {
//     "filters": this.myArray,
//     "search_entity": "INCUBATOR",
//     "page": 0, "page_size": 5000

//   }
//   this.incubatorService.filterIncubator(requestData).subscribe((res: any) => {
//     this.data = res.results;

//     // this.list=[];

//     // Update status counts
//     this.approvedCount = res.approved_count;
//     this.rejectedCount = res.rejected_count;
//     this.pendingCount = res.pending_count;
//     // this.list = this.data;


//     // this.downloadButton = true;

// })
// }
// }


async filtersItem(filterValue:any) {
  this.selectedindex = 0;
  if (this.paginationComponent) {
    this.paginationComponent.resetPageIndex();
  }
  console.log('filtervalue ----',filterValue);
  if(filterValue != undefined){
    if (filterValue === 'All') {
      this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Select all
      this.selectstatus = ['APPROVED', 'REJECTED', 'PENDING']
    } else if(filterValue === 'APPROVED' || filterValue === 'PENDING' || filterValue === 'REJECTED') {
      this.selectstatus = [filterValue];
      if (this.activeFilter?.includes(filterValue)) {
        this.activeFilter = this.activeFilter?.filter(f => f !== filterValue); // Remove if already selected
      } else {
        this.activeFilter?.push(filterValue); // Add to selection
      }
    }
  }
  else {
    this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING'];
    console.log('af',this.activeFilter);
  }
  // If all three are selected, keep only "View All" active
  if (this.activeFilter?.length === 3) {
    this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Keep all three for View All
  }
  //console.log("Filter Value:", filterValue);
  this.resetMode = true;
  this.p = 1;
  var domains=[];

  if(this.selectdomain && this.selectdomain.length > 0)
      {
        for (var i = 0; i < this.selectdomain.length; i++) {
          console.log('selectdomain ----',this.selectdomain[i])
          domains.push(this.selectdomain[i].item_text);
        }
        
      }

      var schemes = [];
      if(this.selectscheme!=undefined){
        for(var i = 0 ; i < this.selectscheme.length ; i++){
          schemes.push(this.selectscheme[i].item_id);
        }
        console.log('scheme', schemes);
      }
     
      if(this.schemsData){
        // if(this.selectedScheme && this.selectedScheme.length > 0) {
          this.selectstatus = this.selectstatus.length ? this.selectstatus : ['APPROVED']; // Ensure APPROVED status remains

        // }
  
    }
 
console.log('this domain',domains,)
// const selectedStates = Array.isArray(this.selectstate) 
// ? this.selectstate?.map(state => state.item_text) : [];
// const selectedCities = Array.isArray(this.selectcity) 
// ?this.selectcity?.map(city => city.item_text) : [];

// const storedFilters = localStorage.getItem('filterArray');
// const stateId=localStorage.getItem('selectedStateId')
// let selectedStates: string[] = [];
// let selectedCities: string[] = [];
//   if (storedFilters) {
//     console.log('✅ Stored filters found');
//     this.myArray = JSON.parse(storedFilters);
//     console.log('✅ Stored filters found');
//     const parsedFilters = JSON.parse(storedFilters);
//  selectedStates = (parsedFilters.find(filter => filter.name === "state")?.value?.length 
//                       ? parsedFilters.find(filter => filter.name === "state").value 
//                       : (this.selectstate?.map(state => state.item_text) || []));

//     // 🔥 If stored `city` is empty but user selected cities, use them
//     selectedCities = (parsedFilters.find(filter => filter.name === "city")?.value?.length 
//                       ? parsedFilters.find(filter => filter.name === "city").value 
//                       : (this.selectcity?.map(city => city.item_text) || []));

//     // 🔥 Apply similar logic to `domain` and `scheme_ids`
//     domains = (parsedFilters.find(filter => filter.name === "domain")?.value?.length 
//                ? parsedFilters.find(filter => filter.name === "domain").value 
//                : (domains) || []);

//     schemes = (parsedFilters.find(filter => filter.name === "scheme_ids")?.value?.length 
//                ? parsedFilters.find(filter => filter.name === "scheme_ids").value 
//                : (schemes || []));
  
//   } else {
     
// console.log('in else block');
// 
// 
// 
// //  selectedStates = Array.isArray(this.selectstate) ? this.selectstate.map(state => state.item_text) : [];
//  selectedCities = Array.isArray(this.selectcity) ? this.selectcity.map(city => city.item_text) : [];

 const selectedStates = Array.isArray(this.selectstate) 
 ? this.selectstate.map(state => state.item_text ?? state) 
 : [];
     const selectedCities = Array.isArray(this.selectcity) ? this.selectcity.map(city => city.item_text ?? city  ) : [];

  // }



this.myArray = await this.setFilters(selectedStates, selectedCities, domains, schemes, this.selectstatus);

 

    this.activeFilter = this.myArray[4].value.length !=0 ? this.myArray[4].value : ['APPROVED','PENDING','REJECTED'];
    console.log('activefilter',this.activeFilter);

  console.log("Filters Applied:", this.myArray);

  let requestData = {
    query:this.searchText,

    filters: this.myArray,
    search_entity: "INCUBATOR",
    page: 0,
    page_size: 10,
  };

  this.incubatorService.filterIncubator(requestData).subscribe((res: any) => {
    console.log("API Response:", res);
  
    this.data = res.results;
    // Update status counts
    this.approvedCount = res.approved_count ;
    this.rejectedCount = res.rejected_count ;
    this.pendingCount = res.pending_count;
    this.totalItems = +res.total_search_results_count;
    var totalPages = Math.ceil(this.totalItems/10);
    this.totalpages = totalPages;
    this.loading = false;
  });

}

async setFilters(...filters: any[][]) {
  //const filterNames = ["state", "city", "domain", "schemes", "status"];

  // Map through filterArray and update values based on corresponding position in filters array
  this.filterArray = this.filterArray.map((filter, index) => ({
      ...filter,
      value: filters[index] || [] // Assign values if present, else keep empty
  }));
  console.log('filterArray ----',this.filterArray);
  return this.filterArray;
}

paginate_startup(pageno) {
  this.pageno = pageno
  console.log('pageno----',this.pageno);
  this.selectedindex = pageno-1;
  console.log('selectedindex-----',this.selectedindex)
  console.log('myarray ----',this.myArray);
  let data={
    query:this.searchText,

      filters: this.myArray,
      search_entity: "INCUBATOR", 
      "page": pageno-1, "page_size": 10,
    }
    // this.isDownloading = true;

  
  console.log('data_payload',data);
  this.loading = true;
  this.incubatorService.getIncubatorsList(data).subscribe((res)=>{
    this.data = res.results
    console.log('data----');
    console.log(this.data);
    this.loading = false;
    // this.isDownloading = true;

  })
}

getSerialNo(i:number) {
  return i+(this.selectedindex*10);
}


}
