<!-- <div class="col-12 col-sm-12" *ngIf="projectmanager || superadmin">
    <nb-card>
      <nb-card-header>Incubator Details</nb-card-header>
      <nb-card-body>
        <div class="container-lg">
          <div class="table-responsive">
              <div class="table-wrapper">
                  <table class="table table-bordered">
                      <thead>
                          <tr>
                              <th>Incubator</th>
                              <th>Location</th>
                              <th class="col-sm-3">Stages</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr >
                              <td>Centre for Innovation Incubation and Entrepreneurship (CIIE)</td>
                              <td>IIM Ahmedabad</td>
                              <td >
                                <a class="style" ><i class="fa fa-circle solid"></i></a>
                                <a class="style" ><i class="fa fa-circle solid"></i></a>
                                <a class="style" ><i class="fa fa-circle thin"></i></a>
                                <a class="style" ><i class="fa fa-circle thin"></i></a>
                              </td>
                          </tr>
                          <tr >
                            <td>KLE Centre for Technology Innovation and Entrepreneurship</td>
                            <td>KLE Technological University, Dharwad</td>
                            <td >
                              <a class="style" ><i class="fa fa-circle solid"></i></a>
                              <a class="style" ><i class="fa fa-circle solid"></i></a>
                              <a class="style" ><i class="fa fa-circle thin"></i></a>
                              <a class="style" ><i class="fa fa-circle thin"></i></a>
                            </td>
                        </tr>
                      
                        <tr >
                          <td>optum</td>
                          <td>New Delhi</td>
                          <td >
                            <a class="style" ><i class="fa fa-circle solid"></i></a>
                            <a class="style" ><i class="fa fa-circle solid"></i></a>
                            <a class="style" ><i class="fa fa-circle thin"></i></a>
                            <a class="style" ><i class="fa fa-circle thin"></i></a>
                            </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>  
    </nb-card-body>
  </nb-card> 
  </div> -->
<!-- <div class="container-grid col-12 col-sm-12" *ngIf="financemanager || superadmin"> -->

  

 <div class="row d-flex flex-column mb-3">
  <!-- First Row -->
  <div class="row d-flex flex-wrap justify-content-between mb-2">
    <div class="col-4">
      <label class="mx-3">State</label>
      
      <ng-multiselect-dropdown
        [(ngModel)]="selectstate"
        [placeholder]="''"
        [settings]="dropdownSettings_s"
        [data]="dropdownstatesapi"
        [ngModelOptions]="{ standalone: true }"
          (onSelect)="onStateSelect($event)"
          (onDeSelect)="onStateDeSelect($event)"
      ></ng-multiselect-dropdown>


    </div>


    <div class="col-4">
      <label class="mx-3">City</label>
      <!-- <ng-select 
        [items]="citiesList" 
        bindLabel="city"
        [(ngModel)]="selectcity"
        placeholder="Select City">
      </ng-select> -->


  

      <ng-multiselect-dropdown
      [(ngModel)]="selectcity"
      [placeholder]="''"
      [settings]="dropdownSettings_city"
      [data]= "selected_citiesapi"
      [ngModelOptions]="{ standalone: true }"
    ></ng-multiselect-dropdown>

    </div>
    
    <div class="col-4">
      <label class="mx-3">Domain</label>
      <ng-multiselect-dropdown
        [(ngModel)]="selectdomain"
        [placeholder]="''"
        [settings]="dropdownSettings"
        [data]="dropdownList"
        
        [ngModelOptions]="{ standalone: true }"
        (onSelect)="onItemSelectDomain($event)"
        (onDeSelect)="onItemDeSelectDomain($event)"
      ></ng-multiselect-dropdown>
    </div>
  </div>

  <!-- Second Row (Fixed to Match First Row) -->
  <div class="row d-flex flex-wrap justify-content-between mb-2">
    <div class="col-4">
      <label class="mx-3">Schemes</label>
      <ng-multiselect-dropdown
        [(ngModel)]="selectscheme"
        [placeholder]="''"
        [settings]="dropdownSettings_schemes"
        [data]="dropdownscheme"
      
        [ngModelOptions]="{ standalone: true }"
      ></ng-multiselect-dropdown>
    </div>

    <div class="col-4">
      <label class="mx-3">Status</label>
      <ng-multiselect-dropdown
        [(ngModel)]="selectstatus"
        [placeholder]="''"
        [settings]="dropdownSettings"
        [data]="dropdownstatus"
       
        [ngModelOptions]="{ standalone: true }"
      ></ng-multiselect-dropdown>
    </div>
    <!-- [(ngModel)]="selectedStatus" -->

    <div class="col-md-4 mt-4 float-right search_bar">
      <input class="form-control" type="text" [(ngModel)]="searchText" placeholder="Search by Name..."  [ngModelOptions]="{standalone: true}">
    
    </div>

   
</div>

<div  class="col-4 my-3  d-flex justify-content-end align-self-end ms-auto mt-1">
  <button [disabled]="selectstate =='' &&  selectcity == '' && selectdomain ==''  && selectstatus == '' && selectscheme == '' && searchText == '' "  class="btn btn-primary ml-3" (click)="filtersItem()" *ngIf="filterMode" id="filter">
    Filter
  </button>
  <button class="btn btn-secondary mx-3" (click)="resetFilters()">
    Reset
  </button>
  <button class="btn btn-success" (click)="downloadIncubatorList()">
    <i class="fas fa-download"></i> Download
  </button>
</div>




   <!-- filter count row -->
   <div class="filter-container p-2">
     
    <div class="filter-row">
      <!-- "View All" should be active only when all three statuses are selected -->
      <p (click)="filtersItem('All')"
         [class.active]="activeFilter?.length === 3">
        View All <span>{{ +approvedCount + +rejectedCount + +pendingCount }}</span>
      </p>
    
      <p (click)="filtersItem('APPROVED')" 
         [class.active]="activeFilter?.length < 3 && activeFilter?.includes('APPROVED')">
        APPROVED <span>{{approvedCount}}</span>
      </p>
    
      <p (click)="filtersItem('REJECTED')" 
         [class.active]="activeFilter?.length < 3 && activeFilter?.includes('REJECTED')">
        REJECTED <span>{{rejectedCount}}</span>
      </p>
    
      <p (click)="filtersItem('PENDING')" 
         [class.active]="activeFilter?.length < 3 && activeFilter?.includes('PENDING')">
        PENDING <span>{{pendingCount}}</span>
      </p>
    </div>
    

 
</div>
<!-- filter count row -->

<div class="container-grid col-12 col-sm-12">
  <div class="p-2">
    <strong class="d-flex justify-content-center" style="font-size: 27px"
      >Incubator Details</strong
    >
  </div>
  <div class="container-lg bg-white">
    <!-- <div class="table-responsive"> -->
    <div class="table-wrapper table-responsive">
      <div class="loader-container" *ngIf="loading">
        <div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <table class="table table-striped" *ngIf="!loading">
        <thead>
          <tr class="info">
            <th scope="col" class="text-center headerfix">Sl No</th>
            <th class="headerfix">Updated Time</th>
            <th>Name</th>
            <!-- <th>Center</th> -->
            <th>State</th>
            <th>City</th>
            <th style="text-align:center">Domain</th>
            <!-- <th>Sector</th> -->
            <th>Schemes</th>
            <th>Status</th>
            <th *ngIf="activeFilter !== 'PENDING'" class ="headerfix" style="text-align: center;">{{ activeFilter || 'Approved/Rejected' }} By</th>
            <th  class="headerfix">Last Logged In</th>
            <th >view</th>
          </tr>
        </thead>
        <tbody *ngIf="data?.length > 0; else noRecords">
          <tr *ngFor="let d of data | paginate: { itemsPerPage: pageSize, currentPage: p }; let i = index">
          <!-- <tr  *ngFor=" let d of data | paginate: { itemsPerPage: 10, currentPage: p}    " > -->
            <th scope="row" class="text-center">{{getSerialNo(i+1)}}</th>
            <td>{{d.updated_at |  date}}</td>
            <td>{{ d.name | titlecase }}</td>
            <!-- <td>{{ d.affiliate_name | slice : 70 }}</td> -->
            <td>{{ d.state | titlecase }}</td>
            <td>{{ d.city | titlecase }}</td>
            <td class="textstructure text-center">{{ d.domain  }}</td>
            <!-- <td>{{ d.sector }}</td> -->
            <td>{{ d.scheme_names }}</td>
            <td >{{ d.status }}</td>
            <td class="textstructure" *ngIf="activeFilter !== 'PENDING'">{{d.approved_by}}</td>
            <td class="text-center">{{ d.login_info != null ? (d.login_info.last_login_time | date) : '-' }}</td>
            <td class="d-flex justify-content-center">
              <!-- <div class="dropdown mr-3">
                  <a class="dropbtn fa fa-check" style="color: blue"></a>
                  <div class="dropdown-content">
                    <a *ngIf="d.status === 'APPROVED'" (click)="changestatus(d.id, 'REJECTED')">Reject</a>
                    <a *ngIf="d.status === 'REJECTED'" (click)="changestatus(d.id, 'APPROVED')">Approve</a>
                  </div>
                  <div class="dropdown-content" *ngIf="d.status === 'PENDING'">
                    <a (click)="changestatus(d.id, 'REJECTED')">Reject</a>
                    <a (click)="changestatus(d.id, 'APPROVED')">Approve</a> -->
              <!-- <a *ngIf="d.status === 'REJECTED'" disabled>Rejected</a> -->
              <!-- </div>
                </div> -->
              <div class="d-flex">
                <div *ngIf="d.status === 'PENDING'" class="d-flex">
                  <a
                    class="dropbtn fa fa-check mr-2"
                    style="color: blue"
                    (click)="changestatus(d.id, 'APPROVED')"
                  ></a>
                  <a
                    class="dropbtn fa fa-close mr-2"
                    style="color: red"
                    (click)="changestatus(d.id, 'REJECTED')"
                  ></a>
                </div>
                <div *ngIf="d.status === 'APPROVED'">
                  <a
                    class="dropbtn fa fa-close mr-2 ml-4"
                    style="color: red"
                    (click)="changestatus(d.id, 'REJECTED')"
                  ></a>
                </div>
                <div *ngIf="d.status === 'REJECTED'">
                  <a
                    class="dropbtn fa fa-check mr-4"
                    style="color: blue"
                    (click)="changestatus(d.id, 'APPROVED')"
                  ></a>
                </div>
                <div>
                  <!-- <a
                    class="fa fa-edit ml-2 mr-2"
                    (click)="editModal(d)"
                    data-toggle="modal"
                    data-target="#editModal"
                    style="color: green"
                  ></a> -->
                  <!-- <a class="fa fa-edit ml-2 mr-2" (click)="updateScheme(d.id)" style="color: green" ></a> -->

                  <a class="fa fa-eye ml-2" (click)="preview(d.id)"></a>
                </div>
              </div>
              <!-- <a class="fa fa-check mr-3" data-target="#approvalmodel" data-toggle="modal"  style = "color:green"></a> -->
            </td>
          </tr>
        </tbody>
        <ng-template #noRecords>
          <tbody>
            <tr>
              <td colspan="12" class="no-records-found">No Data found.</td>
            </tr>
          </tbody>
        </ng-template>
      </table>
    </div>

    <!-- <pagination-controls (pageChange)="onPageChanged($event)"  directionLinks="true" autoHide="false"></pagination-controls> -->
    <!-- <pagination-controls *ngIf="data?.length > 0;" (pageChange)="onPageChange($event)" directionLinks="true" class="float-right"></pagination-controls> -->

    <!-- <pagination-controls  (click)="paginate($event)" class="float-right"></pagination-controls> -->

    <!-- <div class="d-flex justify-content-end ">
      <button class="btn btn-primary" (click)="handleNext(currentPage -1)" [disabled]="currentPage === 1">Previous</button>
      <a class="btn " *ngFor="let p of paginationarr" [disabled]="currentPage === p" (click)="paginate(p)" >{{p+1}}</a> 
      <button class="btn btn-primary" (click)="handlePrev(currentPage +1)">Next</button>
    </div> -->
    <!-- </div> -->
    <app-pagination 
    [totalpages]="totalpages" 
    [pageno]="pageno"
    [selectedindex]="selectedindex"
    (pageChanged)="paginate_startup($event)">
    </app-pagination>
  </div>
</div>

<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#approveModel">
        Launch demo modal
      </button> -->
<!-- Modal -->
<!-- <div
    class="modal fade"
    id="approveModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div> -->

<!-- Update Modal -->
<div
  class="modal custom-modal"
  id="editModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header modal-title-center">
        <strong class="modal-title-center">Update Incubator Details</strong>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container col-sm-12 mt-5" #section>
          <form [formGroup]="modalUpdateForm">
            <div class="form-group">
              <strong for="">Incubator Name:</strong>
              <input
                class="form-control"
                type="text"
                placeholder=""
                formControlName="modalIncubatorName"
                readonly
              />
            </div>
            <div class="row">
              <div class="custom-control custom-checkbox col-md-5 my-3 ml-4">
                <!-- <div *ngFor="let option of options" [value]="option.value" >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                    formControlName="schemes"
                    [value]="option.value" 
                  />
                  <label class="custom-control-label" for="customCheck1">{{option.name }}</label>
                </div> -->
                <label *ngFor="let option of options">
                  <input type="checkbox" (change)="schemeval($event)" [value]="option.id" formControlName="schemes">
                  {{option.name}}
                </label>
              </div>
             <!-- <div class="custom-control custom-checkbox col-md-5 my-3 ml-4">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                  formControlName="schemes"
                  [value]="Samridh"                  />
                <label class="custom-control-label" for="customCheck1">Samridh</label>
              </div>
              <div class="custom-control custom-checkbox col-md-5 my-3 ml-4">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck2"
                  formControlName="schemes"
                  value="TIDE 2.0"                 />
                <label class="custom-control-label" for="customCheck2">TIDE 2.0</label>
              </div> -->
              <div class="form-group col-md-5">
                <label for="exampleFormControlSelect1">Group</label>
                <select class="form-control" formControlName="group">
                  <option *ngFor="let option of types" [value]="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" (click)="updateSchemes()" class="btn btn-primary">
            Update
          </button> -->
          <button
            class="btn btn-primary"
            (click)="updateData()"
            data-dismiss="modal"
          >
            Save
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
