import { Component, OnInit,ViewChild } from '@angular/core';
import * as XLSX from "xlsx";
import { AcceleratorService } from '../../services/accelerator.service';
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { ExportToCsv } from "export-to-csv";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChallengeService } from "../../services/challenge.service";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { citiesOnly, states } from "../../../assets/js/states";
import { MatDialog } from "@angular/material/dialog";
import { CommondialogComponent } from "../../@core/miscellaneous/commondialog/commondialog.component";
import { ExcelService } from "../../services/excel.service";
import { StateService } from "../../services/state.service";
import { CityService } from '../../services/statecity/city.service';
import { environment } from '../../../environments/environment';
import { PaginationComponent } from '../pagination/pagination.component';

const styles = {
  header: {
    fontSize: 18,
    bold: true,
  },
  subheader: {
    fontSize: 14,
    bold: true,
  },
  body: {
    fontSize: 12,
  },
};

@Component({
  selector: 'ngx-accelerator',
  templateUrl: './accelerator.component.html',
  styleUrls: ['./accelerator.component.scss']
})
export class AcceleratorComponent implements OnInit {
   baseUrl = environment.apiurl 
 @ViewChild(PaginationComponent) paginationComponent!: PaginationComponent;
 
  filterArray = [
    {
      "name": "state",
      "value": []
    },
    {
      "name": "city",
      "value": []
    },
    {
        "name": "domain",
        "value": []
    },
    {
        "name": "schemes",
        "value": []
    },
    {
        "name": "status",
        "value": []
    }
]

  data: any;
  items = [{ title: "PDF" }, { title: "EXCEL" }];
  list: any = [];
  modalUpdateForm: FormGroup;
  action: { status: string };
  inValidMessage: boolean;
  alldomains: any;
  groupbydomains: any;
  dropdownList = [];
  sectors: any = [];
  schemsData:any;
  dropdownList1 = [];
  dropdowncities:any = [];
  // storedSearchText: string|null=null;
  tempdata = [];
  selectedItems1 = [];
  dropdownscheme:any[]=[];

  dropdownSettings: IDropdownSettings = {};
  dropdownSettings_t: IDropdownSettings = {};
  dropdownSettings_s: IDropdownSettings = {};
  dropdownSettings_schemes: IDropdownSettings={};
  dropdownSettings_city: IDropdownSettings={};

  myArray: any[] = [];
  itemName: string;
  itemCategory: string;
  selectcity: any;
  selectstate: any = [];
  selectdomain: any = [];
  selectsector: any = [];
  selectstatus: any = [];
  selectedItems: any[];
  selectscheme:any =[];

  selectedStates: any[];
  options:any;
  // dropdownstates: any[] = [
  //   'Karnataka','UP','Telengana', 'Andhra Pradesh'
  // ];
  dropdownstates: any = [];
  selectedStatus: any[];
  selectedScheme:any;
  dropdownSettings_scheme: IDropdownSettings = {};

  dropdownstatus: any[] = ["PENDING", "REJECTED", "APPROVED"];
  
  originalData: any[];
  public domain = [];
  public sector = [];
  filteredlist = [];
  filterMode: boolean = true;
  resetMode: boolean = false;
  p: number = 1;
  filterValue: any;
  mat_data: string;
  totalItems: number;
  StartupList:any;
  paginationEnabled: boolean = true;
  allStates: any;
  approvedCount: number = 0;
  rejectedCount: number = 0;
  pendingCount: number = 0;
  pageSize: number = 25; 
  loading: boolean = true; 
  activeFilter: string[] = [];
  filterValue1:any;
  viewback: any;
  pageno:any = 0;
  totalpages: number;
  selectedindex = 0;
  isDownloading:boolean=false;
  datafordownload:any
  searchText : string|null;
  countryid=101;
  dropdownstatesapi: any[] = []; // To store state names
  dropdownCity: any[] = []; //
  selected_citiesapi:any = [];
  constructor(
    private AcceletorService: AcceleratorService,
    private toastrService: NbToastrService,
    private router: Router,
    private fb: FormBuilder,
    private challengeService: ChallengeService,
    private _route:ActivatedRoute,
    private dialogService: NbDialogService,
    private dialog: MatDialog,
    private excelService:ExcelService,
    private cityservice:CityService,
    private statesservice : StateService


  ) {
    this.filterValue = this._route.snapshot.queryParamMap.get('filter');
    
    this.activeFilter = ['APPROVED','PENDING','REJECTED'];

    if(localStorage.getItem('viewback'))
    {
      this.viewback = localStorage.getItem('viewback');
    }
   
    console.log("filtervalue", this.filterValue);
  }

  ngOnInit(): void {



  this.selectscheme = '';
  this.selectcity = "";
  this.selectstate = "";
  //this.selectdomain = "";
  this.selectstatus = "";
  
    this.loadStates();
    this.schemsData = sessionStorage.getItem('acceleratorSchemeId');
    if (!this.schemsData) {
      sessionStorage.removeItem('acceleratorSchemeId');  // Clear schemeId if not from Dashboard
    }
    this.getAllSchemes();
    // console.log("states_____from____js", states);
    this.selectedItems = [];
    this.selectedStates = [];
    // this.selectedStatus = [];
    // this.getStates();
    // this.citiesData();
   
    // if(this.filterValue != null) {
    //   this.filterItem(this.filterValue);
    //   this.filterValue = null;
    // }
    // else{
    //   this.listAllAccelerators();
     
    // }
    // this.getAllStartUpData();
    this.getDomains();
    if (this.viewback == 'true') {          
      this.backfilter();   
      if(localStorage.getItem('countdata'))
      {
        const storedJsonString = localStorage.getItem('countdata');
        const storedObject = JSON.parse(storedJsonString);
        console.log("count data is==>", storedObject);
        console.log(storedObject.approvedData)
        this.approvedCount = storedObject.approvedData;
        this.rejectedCount = storedObject.rejectedData;
        this.pendingCount = storedObject.pendingData;
       
  
      }  
      // localStorage.removeItem('viewback');
    } else if (this.filterValue != null) {
     
      this.filtersItem(this.filterValue);
      // localStorage.removeItem('viewback');
      // localStorage.removeItem('filterArray');
      // this.selectedStates = [];
      // this.selectedStatus = this.filterValue;
      // this.selectcity = '';
      // this.selectedItems = [];
      // this.activeFilter = this.filterValue;
      // this.viewback = 'true'
      
      

    } else {
      
      this.listAllAccelerators();
    }

    this.modalUpdateForm = this.fb.group({
      modalStartUpName: [""],
      modalDocumentName: [""],
    });

    // this.selectedItems = [];
    this.dropdownList = [];
    // this.dropdownstates = states;
    // this.selectedStates = [];
    // this.selectedStatus = [];
    this.selectedItems1 = [];
    this.filteredlist = [];

    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_s = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };
    this.dropdownSettings_t = {
      singleSelection: true,
      idField: "item_id",
      textField: "item_text",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 3
      allowSearchFilter: true,
    };

    this.dropdownSettings_city = {

      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true

    };
    this.dropdownSettings_schemes = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3
      allowSearchFilter: true
    };
    // this.exportData(this.startupdata)
    // const startupDataArray = this.startupdata1; // Array containing multiple startupData objects
    // this.exportData(startupDataArray);

      // pagination page redirection code
      this._route.queryParams.subscribe((params) => {
        // Use the 'page' parameter from the URL or default to 1
        this.p = +params['page'] || 1;
        // Load data for the current page
        this.loadData(this.p);
      });
       // pagination page redirection code
  }

//  citiesData(){
//   this.dropdowncities = Object.values(citiesOnly);
//   console.log('dropdown_____', this.dropdowncities)

//  }



async onpageloadstateselect(val:any){

  try {
    console.log('val',val);
    const response = await this.cityservice.getCitiesByState(val);
    console.log('Fetched Cities Response:', response);

    // Extract `result` array safely
    const cities = response.result || [];

    // Convert cities to expected format
    const newCities = cities.map((city: { id: any; name: any }) => ({
      item_id: city.id,
      item_text: city.name
    }));

    // Merge with existing cities (avoiding duplicates)
    this.selected_citiesapi = [...this.selected_citiesapi, ...newCities].filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.item_id === value.item_id)
    );

    // Sort the updated list
    // this.selected_citiesapi.sort((a, b) => a.item_text.localeCompare(b.item_text));

  } catch (error) {
    console.error(`Error fetching cities for state`, error);
  }
}

  getAllSchemes(){
    this.AcceletorService.getschemeList()
      .subscribe((res:any)=>{
        this.options= res.results;
        console.log("SchemeLIST:", this.options);

        let data = [];
      for(var i = 0 ; i < this.options.length ; i++){
        data.push({
          item_id:this.options[i].id , item_text:this.options[i].name
        })
      }
      this.dropdownscheme = [...data];
      console.log("schmeeeeeeeeee",this.dropdownscheme);
      if (this.schemsData) {
        this.selectscheme = this.dropdownscheme.filter(scheme => scheme.item_id == this.schemsData);
        console.log('Filtered Scheme:', this.selectscheme);
      }
      })
  }
  async loadStates() {
    try {
      const response = await this.cityservice.getDefaultState(this.countryid); // API call
      console.log('Fetched Response:', response);
   
     
      const states = response.result || [];
      // console.log('states 1234 ----',states);
   
      
      this.dropdownstatesapi = states.map((state: { name: any; id: any; }) => ({
        item_text: state.name,  
        item_id: state.id    
      }));
      console.log('hfhyfgh',this.dropdownstatesapi)
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  }
  // async onStateSelect(event: any) {
  //   console.log('Selected State:', event);
    
  //   const selectedStateId = event.value; // State ID
 
  //   try {
  //     const response = await this.cityservice.getCitiesByState(selectedStateId);
  //     console.log('Fetched Cities Response:', response);
  
  //     // Extract `result` array safely
  //     const cities = response.result || [];
 
  //     this.selected_citiesapi = cities.map((city: { name: any; }) => ({ city: city.name })) // Assuming `name` field
  //       .sort((a: { city: string; }, b: { city: any; }) => a.city.localeCompare(b.city));
 
  //     // Reset selected city
  //     // this.coeForm.get('city').patchValue(null);
  //   } catch (error) {
  //     console.error(`Error fetching cities for state ID ${selectedStateId}:`, error);
  //   }
  // }
  async onStateSelect(event: any) {
  console.log('Selected State:', event);

  const selectedStateId = event.item_id; // Get state ID
  localStorage.setItem('selectedStateId', selectedStateId);

  try {
    const response = await this.cityservice.getCitiesByState(selectedStateId);
    console.log('Fetched Cities Response:', response);

    // Extract `result` array safely
    const cities = response.result || [];

    // Convert cities to expected format
    const newCities = cities.map((city: { id: any; name: any }) => ({
      item_id: city.id,
      item_text: city.name
    }));

    // Merge with existing cities (avoiding duplicates)
    this.selected_citiesapi = [...this.selected_citiesapi, ...newCities].filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.item_id === value.item_id)
    );

    // Sort the updated list
    // this.selected_citiesapi.sort((a, b) => a.item_text.localeCompare(b.item_text));

  } catch (error) {
    console.error(`Error fetching cities for state ID ${selectedStateId}:`, error);
  }
}
async backdeselect(val:any){
  this.cityservice.getCitiesByState(val).then(response => {
    const citiesToRemove = response.result.map(city => city.id);

    // Remove cities belonging to the deselected state
    this.selected_citiesapi = this.selected_citiesapi.filter(
      city => !citiesToRemove.includes(city.item_id)
    );
    this.selectcity = [];

  }).catch(error => {
    console.error(`Error fetching cities for deselected state ID ${val}:`, error);
  });

}
async onStateDeSelect(event: any) {

  if (this.viewback == 'true') {
console.log('viewback3234')
    const backdeselectstateid=localStorage.getItem('selectedStateId')
    this.backdeselect(backdeselectstateid)

  }
  else{
  console.log('Deselected State:', event);

  const deselectedStateId = event.item_id;
  console.log('Deselected State 1234 sai:', deselectedStateId);


  // Fetch cities of the deselected state
  this.cityservice.getCitiesByState(deselectedStateId).then(response => {
    const citiesToRemove = response.result.map(city => city.id);

    // Remove cities belonging to the deselected state
    this.selected_citiesapi = this.selected_citiesapi.filter(
      city => !citiesToRemove.includes(city.item_id)
    );
    this.selectcity = [];

  }).catch(error => {
    console.error(`Error fetching cities for deselected state ID ${deselectedStateId}:`, error);
  });
}
}
  
  ngOnDestroy(): void{
    // remove style for ui break on scroll
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = ''; 
      htmlElement.style.position = '';
    }
   
      // remove style for ui break on scroll
      localStorage.removeItem('viewback');
      // sessionStorage.removeItem('schemeId')

 }

  //  pagination page redirection code
onPageChange(page: number): void {
  // Update the current page and navigate to the new URL
  this.p = page;
  this.router.navigate([], {
    relativeTo: this._route,
    queryParams: { page: page },
    queryParamsHandling: 'merge',
  });
 
  this.loadData(page);
}
private loadData(page: number): void {
  // Implement your logic to load data based on the page
  console.log('Loading data for page', page);
}
//  pagination page redirection code

  exportAsXLSX(data):void {
    this.excelService.exportAsExcelFile(data, 'Accelerator Report','Accelerator_Data');
  }

  // state
  getStates() {
    this.statesservice.getallstates().subscribe((res: any) => {
      this.allStates = res.results;
      console.log("all states =>", this.allStates);
      this.dropdownstates = this.allStates
     
   
    });
  }
  // state

  // listAllAccelerators() {
  //  var data;
  //  if(this.schemsData == null){
  //     data={
  //     search_entity: "ACCELERATOR",
  //     "page": 0, "page_size": 99999
  //   }
  // }
  //   else{
  //     data={
  //       search_entity: "ACCELERATOR",
  //       "page": 0, "page_size": 5000,
  //       "filters": [
  //       {
  //           "name": "schemes",
  //           "value": [
  //               this.schemsData
  //           ]
  //       }
        
  //   ],
  //     }
    
  //  }
  //   // let data={
  //   //   search_entity: "ACCELERATOR",
  //   //   "page": 0, "page_size": 99999
  //   // }
  //   this.loading = true;
  //   this.AcceletorService.getacceleratorsList(data).subscribe((res: any) => {
  //     this.data = res.results;     
  //     this.StartupList=res.results;
  //     console.log("acceletor::", this.data);
     
 
  //     this.originalData = this.data;
    

  //     this.totalItems = +res.total_search_results_count;
  //     console.log("334___________", this.totalItems);
      
  //     this.updateStatusCounts(res);
  //     this.loading = false;
  //     this.onPageChange(1) 

  //   });
  // }
  listAllAccelerators() {
    let data;
    
    if (this.schemsData == null) {
     
      data = {
        search_entity: "ACCELERATOR",
        page: 0,
        page_size: 10
      };
    } else {
      this.activeFilter = ["APPROVED"];
      data = {
        search_entity: "ACCELERATOR",
        page: 0,
        page_size: 10,
        filters: [
          {
            name: "schemes",
            value: [this.schemsData]
          },
          {
            name: "status",
            value: ["APPROVED"] // ✅ Added status filter
          }
        ]
      };
    }
  
    this.loading = true;
    this.AcceletorService.getacceleratorsList(data).subscribe((res: any) => {
      this.data = res.results;
      this.StartupList = res.results;
      console.log("acceletor::", this.data);
  
      this.originalData = this.data;
      this.totalItems = +res.total_search_results_count;
      console.log("334___________", this.totalItems);
      this.approvedCount = res.approved_count ;
      this.rejectedCount = res.rejected_count ;
      this.pendingCount = res.pending_count;
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages; // Replace with your dynamic value
      // this.updateStatusCounts(res);
      this.loading = false;
      this.onPageChange(1);
    });
  }
  
  // downloadStartupList(){
  //   // const startupDataArray = this.originalData; // Array containing multiple startupData objects
  //   this.exportData(this.data);
  //   console.log('this.data', this.data)
  //   // this.exportAsXLSX(this.data)
  // }

  async downloadStartupList(){
    this.isDownloading = true;  // Start loader
    let downloaddata = await this.listAllAcceleratorforDownload();
    this.exportData(downloaddata);
    this.isDownloading = false;  
  }

  async listAllAcceleratorforDownload() {

    return new Promise((resolve,reject)=>{
      let data= {
        query:this.searchText,
        filters: this.myArray,
        search_entity: "ACCELERATOR",
        "page": 0, "page_size": 10000
  
      }
      // this.isDownloading = true;

      // this.loading = true;
      this.AcceletorService.getacceleratorsList(data).subscribe((res: any) => {
        this.datafordownload = res.results;
        resolve(this.datafordownload);
      });
    })
  }

  exportData(dataArray: any) {
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
    };
    const data = dataArray.map((d) => {
    const result: any = {
      Name: d.name ? d.name : '-',
      'Affiliate Name': d.affiliate_name ? d.affiliate_name : '-',
      Domain: d.domain ? ((d.domain.length > 0) ? d.domain.join(', ') : '-') : '-',
      Sector: d.sector ? (Array.isArray(d.sector) ? d.sector.join(', ') : d.sector) : '-',
      'Company Logo': d.profile_image && d.profile_image[0]?.url ? d.profile_image[0].url : '-',

      City: d.city ? d.city : '-',
      State: d.state ? d.state : '-',
      Country: d.country ? d.country : '-',
      Website: d.website_url ? d.website_url : '-',
      // 'Full Description': d.full_description ? d.full_description : '-',
      Status: d.status ? d.status : '-',
      // Id : d.id ? d.id : '-',
      // Documents: d.documents ? (Array.isArray(d.documents) ? d.documents.map(info => `${info.file_name} URL: ${info.url}`).join('\n') : '-') : '-',

      'CEO Firstname': d?.team_info ? d.team_info?.ceoFirstName : '-', 
      'CEO Lastname' : d?.team_info ? d.team_info?.ceoLastName : '-',
      'CEO Phone No.': d?.team_info ? d.team_info?.ceoPhoneNo : '-',
      'CEO Email' : d?.team_info ? d.team_info?.ceoEmail : '-',
      'Team Size': Array.isArray(d?.team_info?.team_array) ? d?.team_info?.team_array.length : '-',
      
      'Team Details':Array.isArray(d?.team_info?.team_array) ? d?.team_info?.team_array.map(info => `First Name=${info.teamFirstName},Last Name= ${info.teamLastName},Mobile= ${info.teamPhoneNo},Email=${info.teamEmail}`).join('\n') : '-',
   'Lab Resources Created':d.labresources_count ? d.labresources_count:'-',
'Co-working Spaces Created':d.coworkingresources_count ? d.coworkingresources_count:'-',
'Equipment Resource Created':d.equipmentresources_count ? d.equipmentresources_count:'-',
'Guesthouse Resource Created':d.guesthouseresources_count ? d.guesthouseresources_count:'-',
'Labs booked':d.lab_bookings_count ? d.lab_bookings_count:'-',
'Co-working Spaces Booked':d.coworking_bookings_count ? d.coworking_bookings_count:'-',
'Equipments Booked':d.equipment_bookings_count ? d.equipment_bookings_count:'-',
'Guesthouse Booked':d.guesthouse_bookings_count ? d.guesthouse_bookings_count:'-',
      // Sucess_stories: d.success_stories,
      // 'Social Info': d?.social_info ? `Facebook=${d.social_info.facebook ? d.social_info.facebook : '- '}\n Instagram=${d.social_info.instagram ? d.social_info.instagram : '-'}\n Twitter=${d.social_info.twitter ? d.social_info.twitter : '-'}\n LinkedIn=${d.social_info.linkedIn ? d.social_info.linkedIn : '-'}\n Youtube=${d.social_info.youtube ? d.social_info.youtube : '-'}\nKoo=${d.social_info.koo ? d.social_info.koo : '-'}` : '-',
      Facebook: d?.social_info?.facebook ? d.social_info.facebook : '-',
      Instagram: d?.social_info?.instagram ? d.social_info.instagram : '-',
      Twitter: d?.social_info?.twitter ? d.social_info.twitter : '-',
      LinkedIn: d?.social_info?.linkedIn ? d.social_info.linkedIn : '-',
      Youtube: d?.social_info?.youtube ? d.social_info.youtube : '-',

      // Koo: d?.social_info?.koo ? d.social_info.koo : '-',
    //  'Profile Email': d?.team_info?.ceoEmail,
     'Login Email':d?.login_email ? d?.login_email : '-',
     'Created At': d.created_at ? new Date(d.created_at) : '-',
     'Updated At': d. updated_at? new Date(d?.updated_at):'-',
    'Last Login Date':d?.login_info ? formatDate(new Date(d.login_info.last_login_time) ) : '-',
      'Last Login Time':d?.login_info ? formatTime(new Date(d.login_info.last_login_time)):'-'
  };

  if (this.activeFilter === ['APPROVED']) {
    result.ApprovedBy = d.approved_by ? d.approved_by : '-';
  }else if(this.activeFilter === ['REJECTED']){
    result.RejectedBy = d.approved_by ? d.approved_by : '-';
  }else if(this.activeFilter !== ['PENDING']){
    result['Approved/Rejected By'] = d.approved_by ? d.approved_by : '-';
  }
  return result;
  }
  );
    this.exportAsXLSX(data)
    // this.generateExcelFile(data, "myData.xlsx");
    console.log("Export:", data);
  }
  
 
  downloadFile(d) {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Applications",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "applications",
      headers: ["Name", "Domain", "Sector", "Location", "Description", "Stage"],
    };
    const csvdata = [
      {
        Name: d.name,
        Domain: d.domain,
        City: d.city,
        Sector: d.sector,
        Description: d.short_description,
        Stage: d.startup_stage,
      },
    ];
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvdata);
  }

  editData(data) {
    this.data = data;
    console.log("edit statupDATA:", this.data);
    this.modalUpdateForm.controls["modalStartUpName"].setValue(data.name);
  }
  updatestartupModel(id) {
    let data = {};
    this.AcceletorService.editaccelerator(data, id).subscribe(
      (res: any) => {
        console.log("statSucces:", res);
        this.toastrService.success("Data Updated Successfully!");
      },
      (error) => {
        this.inValidMessage = true;
        this.toastrService.warning("Unable to Update records!");
      }
    );
  }

  changestatus(id: string, value: string) {
    var htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.style.top = '0'; 
      htmlElement.style.position = 'sticky';
    }
    // localStorage.setItem('myData', 'Startup');
    this.mat_data = value;
    if(this.mat_data=='REJECTED'){
      this.mat_data= 'Reject'
    }else if(this.mat_data=="APPROVED"){
      this.mat_data= 'Approve'
    }
    const dialogRef=this.dialog.open(CommondialogComponent,{
          data: { status: this.mat_data, name: 'accelerators' }
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result==true){
            var data = {
              status: value,
            };
            this.AcceletorService.actionaccelerator(data, id).subscribe(
              (res: any) => {
                console.log("change_status", res);              
                this.listAllAccelerators();
                localStorage.removeItem('myData');
                this.toastrService.success("Successfully updated Status!");
                
              },
              (error) => {
                this.inValidMessage = true;
                this.toastrService.warning("Unable to Update Status!");
              }
            );
          }
        })
  }

  getDomains() {
    
    this.challengeService.getalldomains().subscribe((res: any) => {
      this.alldomains = res.results;
      let data = [];
      for (var i = 0; i < this.alldomains.length; i++) {
        
        data.push({
          item_id: i + 1,
          item_text: this.alldomains[i].name,
        
         
        });
       
      }
    //  console.log("data domaing ",data)
      this.dropdownList = [...data];
      // console.log("data dropdownList ", this.dropdownList)
      // console.log('data_domain',data);
      this.groupbydomains = _.groupBy(this.alldomains, "name");
      console.log("group domains", this.groupbydomains);
    });
  }
  async onItemSelectDomain(event) {
    console.log("onItemSelect", this.groupbydomains, event);
    console.log(
      "getsector",
      this.groupbydomains[`${event.item_text}`][0]["sectors"]
    );
    await this.groupbysectors(event);
    console.log("sectors", this.sectors);
    for (var i = 0; i < this.sectors.length; i++) {
      this.tempdata.push({
        item_id: i + 1,
        item_text: this.sectors[i].name,
      });
    }
    this.dropdownList1 = [...this.tempdata];
    console.log("sectors_dropdown", this.dropdownList1);
  }

  async onItemDeSelectDomain(event) {
    console.log("event", event);
  }
  async groupbysectors(event) {
    return new Promise((resolve, reject) => {
      this.challengeService.setSectorData(
        this.groupbydomains[`${event.item_text}`][0]["sectors"]
      );
      this.sectors.push(
        ...this.groupbydomains[`${event.item_text}`][0]["sectors"]
      );
      resolve(this.sectors);
    });
  }

//   backfilter(){   
//     if(this.viewback == 'true')
//     {
//       const getArray = localStorage.getItem('filterArray');
//       const parsedArray = JSON.parse(getArray);
//       this.myArray = parsedArray;
//       const stateValue = parsedArray.find(item => item.name === 'state')?.value;
//       const statusValue = parsedArray.find(item => item.name === 'status')?.value;
//       const cityvalue = parsedArray.find(item => item.name === 'city')?.value;
//       const domainvalue = parsedArray.find(item => item.name === 'domain')?.value;      
//       const storedSchemes = localStorage.getItem('selectedSchemes');

//       this.selectedStates = stateValue;
//       this.selectedStatus = statusValue;
//       this.selectcity = cityvalue
//       this.selectedItems = domainvalue;
//       this.selectscheme = storedSchemes ? JSON.parse(storedSchemes) : [];

//     }
    
  
//       if(localStorage.getItem('status'))
//       {
//         this.activeFilter = localStorage.getItem('status');
//       }
  
     
  
  
//       let data = {
//         filters: this.myArray,
//         search_entity: "ACCELERATOR",
//         "page": 0, "page_size": 2984
  
//       };
  
//       console.log("data____0603", data);
   
//       if(this.filterValue == null){
//         this.AcceletorService.filteraccelerator(data).subscribe((res: any) => {
//         // console.log("res myarray___0603:", res);
//         this.data = res.results;        
//         // this.updateStatusCounts(this.data);
//         this.loading = false;
//       });
     
    
//   }
// }


  // filterItem(filterValue) {
  //   // this.filterMode = false;
  //   // this.resetMode = true;   
  //   if(filterValue == 'null')
  //   {
  //    this.activeFilter = null;
  //   } 
  //   this.resetMode = true;

  //   this.myArray = [];
  //   var domains = [];
  //   localStorage.removeItem('status');
   
    
  //   if(this.selectdomain && this.selectdomain.length > 0)
  //   {
  //     for (var i = 0; i < this.selectdomain.length; i++) {
  //       domains.push(this.selectdomain[i].item_text);
  //     }
  //     if (this.selectdomain.length != 0) {
  //       this.myArray.push({
  //         name: "domain",
  //         value: domains,
  //       });
  //     }
  //   }
  //   var sector = [];
  //   for(var i = 0 ; i < this.selectsector.length ; i++){
  //     sector.push(this.selectsector[i].item_text);
  //   }
  //   var schemes = [];
  //   if(this.selectscheme!=undefined){
  //     for(var i = 0 ; i < this.selectscheme.length ; i++){
  //       schemes.push(this.selectscheme[i].item_id);
  //     }
  //     console.log('scheme', schemes);
  //   }
  //   if (this.selectcity && this.selectcity.length > 0) {
  //     this.myArray.push({
  //       name: 'city',
  //       value: this.selectcity
  //     });
    
  //     console.log("selectcity:", this.myArray);
  //   }
  //   if (this.selectstate && this.selectstate.length > 0) {
  //     this.myArray.push({
  //       name: "state",
  //       value: this.selectstate,
  //     });
  //   }
   
  //   if(schemes.length !=0){
  //     this.myArray.push({
  //       name: 'schemes',
  //       value: schemes
  //     })
  //   }
  
  //   if(this.selectstatus?.length !=0){
  //     this.myArray.push({
  //       name: 'status',
  //       value: this.selectstatus
  //     })
  //     this.activeFilter = this.selectstatus;
  //     this.filterValue1 = this.selectstatus
  //   }

  
  //   const jsonString = JSON.stringify(this.myArray);
  //   localStorage.setItem('filterArray', jsonString); 
  //   let data = {
  //     filters: this.myArray,
  //     search_entity: "ACCELERATOR",
  //     "page": 0, "page_size": 2984

  //   };

  //   console.log("data____0603", data);
 
  //   if(this.filterValue == null){
  //     this.AcceletorService.filteraccelerator(data).subscribe((res: any) => {
  //       // console.log("res myarray___0603:", res);
       
  //       this.data = res.results;
  //       //console.log("filter result" + res.result.state)
  //       this.onPageChange(1);
  //       if(data.filters.length > 0 && data.filters[0]['name'] === 'status'){
  //         // this.updateStatusCounts(this.data);
  //       }else{
  //         this.updateStatusCounts(res);
  //       } 
  //       const statusCount = {
  //         approvedData: this.approvedCount,
  //         rejectedData: this.rejectedCount,
  //         pendingData: this.pendingCount
  //       };
  //       const countstring = JSON.stringify(statusCount);
  //       localStorage.setItem('countdata', countstring);
      

    
  //     });
     
  //   }

  //   else{
   
  //     if (!this.myArray.some(item => item.name === 'status')) {
  //       this.myArray.push({
  //         "name": "status",
  //         "value": Array.isArray(this.filterValue) ? this.filterValue : [this.filterValue]               
  //     });
  //   }
  //   // let data = {
  // //     "filters": [
  // //         {
  // //             "name": "status",
  // //             "value": filterValue
  // //         }
  // //     ],
  // //     "search_entity": "ACCELERATOR",
  // //     "page": 0, "page_size": 2984

  // // }
  // let data = {
  //   "filters": this.myArray,
  //   "search_entity": "ACCELERATOR",
  //   "page": 0, "page_size": 2984
  // }
  //   console.log("data____filterdata", data);
  //   this.AcceletorService.filteraccelerator(data).subscribe((res: any) => {
  //     console.log("pendinggggg.........:", res);
  //     this.data = res.results;
  //     this.updateStatusCounts(res);
  //     this.loading = false;
  //   });
  // }
  // }

  // countFilter(data)
  // {

  //   this.myArray = [];
  //   var domains = [];

    
    
  //   if(this.selectdomain && this.selectdomain.length > 0)
  //   {
  //     for (var i = 0; i < this.selectdomain.length; i++) {
  //       domains.push(this.selectdomain[i].item_text);
  //     }
  //     if (this.selectdomain.length != 0) {
  //       this.myArray.push({
  //         name: "domain",
  //         value: domains,
  //       });
  //     }
  //   }
  //   var sector = [];
    
  //   var schemes = [];
  //   if(this.selectscheme!=undefined){
  //     for(var i = 0 ; i < this.selectscheme.length ; i++){
  //       schemes.push(this.selectscheme[i].id);
  //       console.log("Schem ids+++++++++++",this.selectscheme[i].id)
  //     }
  //     console.log('scheme', schemes);
  //   }
  //   if (this.selectcity && this.selectcity.length > 0) {
  //     this.myArray.push({
  //       name: 'city',
  //       value: this.selectcity
  //     });
  //   }
  //   if (this.selectstate && this.selectstate.length > 0)  {
  //     this.myArray.push({
  //       name: "state",
  //       value: this.selectstate,
  //     });
  //   }
  //   // if (this.selectdomain.length != 0) {
  //   //   this.myArray.push({
  //   //     name: "domain",
  //   //     value: domains,
  //   //   });
  //   // }
  //   // if (this.selectsector.length != 0) {
  //   //   this.myArray.push({
  //   //     name: "sector",
  //   //     value: sector,
  //   //   });
  //   // }
  //   this.myArray.push({
  //     name: "status",
  //     value: data,
  //   });
  //   let fitlere_data = data;
  //   this.activeFilter = data;
  //   this.filterValue1 = data;   
  //   // this.onPageChange(1) 
  //   localStorage.removeItem('status');

  //   if(this.viewback == 'true')
  //   {
  //     const getArray = localStorage.getItem('filterArray');
  //    const parsedArray = JSON.parse(getArray);
  //    this.myArray = parsedArray;
  //     this.myArray = this.myArray.filter(item => item.name !== 'status');
  //   }
  //    // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //    if(data == 'All')
  //    {
  //      // this.myArray = this.myArray.filter(item => item.name !== 'status');
  //      this.myArray.push({
  //        name: "status",
  //        value: ["PENDING", "REJECTED", "APPROVED"]
  //      });
  //      this.activeFilter = null;
  //      this.selectedStatus = ["PENDING", "REJECTED", "APPROVED"];
  //      const jsonString = JSON.stringify(this.myArray);
  //      localStorage.setItem('filterArray', jsonString); 
      
  //    }
  //    else {
       
  //        this.myArray = this.myArray.filter(item => item.name !== 'status');
      
      
  //      this.myArray.push({
  //        name: "status",
  //        value: [data],
  //      });
  //      this.selectedStatus = [data];
  //      const jsonString = JSON.stringify(this.myArray);
  //      localStorage.setItem('filterArray', jsonString); 
  //    }
   
    
     
  //    const jsonString = JSON.stringify(this.myArray);
  //    localStorage.setItem('filterArray', jsonString); 

  //   let data1 = {
  //     filters: this.myArray,
  //     "search_entity": "ACCELERATOR",
  //     "page": 0, "page_size": 5000

  // }
  // this.AcceletorService.filteraccelerator(data1).subscribe((res: any) => {     
     
  //     this.data = res.results;
  //     console.log('count status data is ', this.data)
  //     // this.updateStatusCounts(this.data);
  //     this.onPageChange(1) 
  //     const statusCount = {
  //       approvedData: this.approvedCount,
  //       rejectedData: this.rejectedCount,
  //       pendingData: this.pendingCount
  //     };
  //     const countstring = JSON.stringify(statusCount);
  //     localStorage.setItem('countdata', countstring);
  //   });
  //   // this.p= 1;
  // }
  // countAllFilter()
  // {
  //   this.activeFilter = null;
  //   localStorage.removeItem('status');
  //   this.onPageChange(1) 
  //   this.resetFilters()
  //   this.listAllAccelerators();
  //    this.updateStatusCounts(this.data);
  // }
  removeFilterQueryParam(): void {
 
    // Clone the current query parameters
    const updatedQueryParams = { ...this._route.snapshot.queryParams };
  
    // Remove 'filter' from the cloned object
    delete updatedQueryParams['filter'];
  
    // Navigate to the updated query parameters
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: updatedQueryParams, // Set updated params explicitly
      queryParamsHandling: undefined, // Ensure Angular fully replaces the params
    });
  
    console.log('Updated Query Params:', updatedQueryParams);
  }
  

//   resetFilters() {
//     //debugger;
//     this.schemsData = null;
//   sessionStorage.removeItem('acceleratorSchemeId')
//     this.resetMode = false;
//     this.filterMode = true;
//     this.selectscheme='';
// this.filterValue = null;
//     // clear the filter criteria object
//     this.data = {};
//       this.selectcity = "";
//       this.selectstate = "";
//       this.selectdomain = "";
//       // this.selectsector = "";
//       this.selectstatus = "";
//       this.selectedStatus = []
//     // reset the filtered data to the original data
//     // this.data = this.originalData;
//     // this.paginationEnabled = true;    
//     localStorage.removeItem('status');
//     this.activeFilter = null;
//     // this.onPageChange(1) 
//     localStorage.removeItem('filterArray');    
//     localStorage.removeItem('viewback')
//     this.viewback = 'false'; 
//     this.listAllAccelerators();
//     this.removeFilterQueryParam()
//     // this.updateStatusCounts(this.data);
//   }
resetFilters() {
  this.schemsData = null;
  sessionStorage.removeItem('acceleratorSchemeId');
  this.resetMode = false;
  this.filterMode = true;

  this.selectscheme = '';
  this.filterValue = null;
  
  // Clear filter selections
  this.data = {};
  this.selectcity = "";
  this.selectstate = "";
  this.selectdomain = "";
  this.selectstatus = "";
  this.selectedStatus = [];
  this.selectedindex = 0
  if (this.paginationComponent) {
    this.paginationComponent.resetPageIndex();
  }
  // ✅ Clear all relevant localStorage items
  localStorage.removeItem('status');
  localStorage.removeItem('filterArray');
  localStorage.removeItem('viewback');
  localStorage.removeItem('selectedSchemes');
  localStorage.removeItem('countdata');
  localStorage.removeItem('selectedStateId');
  

  // ✅ Reset activeFilter completely
  this.activeFilter = ['APPROVED','PENDING','REJECTED'];
  this.viewback = 'false';
  this.myArray = [];
  setTimeout(() => {
    this.searchText = null; // ✅ Ensure UI updates properly
  });
  this.listAllAccelerators();
  this.removeFilterQueryParam();
}

  preview(d) {
    console.log("pCheck::", d);

    
    // ✅ Store the status filter in localStorage
    if (this.filterValue1 && this.filterValue1.length > 0) {
        localStorage.setItem('status', JSON.stringify(this.filterValue1));
    } else {
        localStorage.removeItem('status');
    }
if(this.searchText&&this.searchText!==null){
  localStorage.setItem('query',JSON.stringify(this.searchText));
}else{
  localStorage.removeItem('query');
}
// }
    if (this.filterValue1 && this.filterValue1.length > 0) {
      this.activeFilter = this.filterValue;
 
        const statusFilter = { name: "status", value: this.filterValue1 };
        this.myArray = this.myArray.filter(item => item.name !== "status"); // Remove existing status filter
        this.myArray.push(statusFilter);
    }
    if (this.myArray?.length > 0) {
      localStorage.setItem('filterArray', JSON.stringify(this.myArray));
  } else {
      localStorage.removeItem('filterArray');  // ✅ Remove completely if empty
  }

    if (this.selectscheme && this.selectscheme.length > 0) {
      localStorage.setItem('selectedSchemes', JSON.stringify(this.selectscheme));

      const schemeFilter = { name: "schemes", value: this.selectscheme };
      this.myArray = this.myArray.filter(item => item.name !== "schemes"); // Remove duplicates
      this.myArray.push(schemeFilter);
  } else {
      localStorage.removeItem('selectedSchemes');
  }

  

    if (this.selectscheme && this.selectscheme.length > 0) {
      localStorage.setItem('selectedSchemes', JSON.stringify(this.selectscheme));
    }
    // ✅ Ensure the selected status is added to `filterArray`
    localStorage.setItem('selectedindex',this.selectedindex.toString())
    this.router.navigateByUrl(`/admin/acceleratortemp/${d}`);
}
  async backfilter() {  
    if (this.viewback == 'true') {

     
        const getArray = localStorage.getItem('filterArray');
        this.myArray = getArray ? JSON.parse(getArray) : [];
        const storedValue = localStorage.getItem('query');
        // this.searchText= storedValue;
        this.searchText = storedValue ? JSON.parse(storedValue) : ''; 

    

        // const storedFilters = localStorage.getItem('filterArray');
        // console.log('stored',storedFilters);
        // if (storedFilters) {
        //     console.log('✅ Stored filters found');
        //     this.myArray = JSON.parse(storedFilters);
  
               
        //   console.log('⚡ Calling onpageloadstateselect()');
        //   await this.onpageloadstateselect(4017);
        // }
  

        const stateValue = this.myArray.find(item => item.name === 'state')?.value || [];
        const statusValue = this.myArray.find(item => item.name === 'status')?.value || [];
        const cityValue = this.myArray.find(item => item.name === 'city')?.value || [];
        const domainValue = this.myArray.find(item => item.name === 'domain')?.value || [];
        this.selectstate = stateValue;
        this.selectstatus = statusValue;  
       
        this.selectcity = cityValue;
        for(var i = 0 ; i < domainValue.length ; i++){
          this.selectdomain.push({
            'item_text':domainValue[i]
          })
        }
        // this.selectdomain = domainValue;
  
        // ✅ Restore selected scheme from localStorage
        const storedSchemes = localStorage.getItem('selectedSchemes');
        this.selectscheme = storedSchemes ? JSON.parse(storedSchemes) : [];
  
        console.log('Restored Schemes:', this.selectscheme);
    }

    const storedFilters = localStorage.getItem('filterArray');
    const stateId=localStorage.getItem('selectedStateId')
  
    console.log('stored',storedFilters);
    if (storedFilters) {
        console.log('✅ Stored filters found');
        this.myArray = JSON.parse(storedFilters);
  
           
      console.log('⚡ Calling onpageloadstateselect()');
      await this.onpageloadstateselect(stateId);
    }
  
    if (localStorage.getItem('status')) {
      this.activeFilter = JSON.parse(localStorage.getItem('status'));
  } else if (this.schemsData) {
      this.activeFilter = ["APPROVED"];  // If scheme is selected, default to APPROVED
  } else {
      this.activeFilter = ['APPROVED','PENDING','REJECTED']; // Reset if no filters
  }
  
  if (this.selectstatus && this.selectstatus.length > 0) {
    this.activeFilter = this.myArray.find(item => item.name === 'status')?.value || ['APPROVED', 'PENDING', 'REJECTED'];
  }
  
    if (this.selectscheme.length > 0) {
        const schemeFilter = { name: "schemes", value: this.selectscheme.map(s => s.item_id) };
        this.myArray = this.myArray.filter(item => item.name !== "schemes"); // Remove old scheme filters
        this.myArray.push(schemeFilter);
    }
  
    if (this.schemsData) {
      const schemeFilter = { name: "schemes", value: [this.schemsData] };
      const statusFilter = { name: "status", value: ["APPROVED"] };
      // Check if 'schemes' filter already exists
      this.activeFilter = ["APPROVED"]
      // this.myArray = this.myArray.filter(item => item.name !== "schemes");
      this.myArray = this.myArray.filter(item => item.name !== "schemes" && item.name !== "status");
      this.myArray.push(schemeFilter,statusFilter);
  
      // Store updated filterArray in localStorage
      localStorage.setItem('filterArray', JSON.stringify(this.myArray));
  }
  
    console.log("Restored Filters:", this.myArray);

    this.selectedindex  = Number(localStorage.getItem('selectedindex'));
    const pageno = this.selectedindex;
    let data = {
      query:this.searchText,
        filters: this.myArray,
        search_entity: "ACCELERATOR",
        page: pageno,
        page_size: 10
    };
  
    console.log("Final Filter Data:", data);
  
    this.AcceletorService.filteraccelerator(data).subscribe((res: any) => {
        this.data = res.results;    
        this.approvedCount = res.approved_count ?? this.approvedCount;
        this.rejectedCount = res.rejected_count ?? this.rejectedCount;
        this.pendingCount = res.pending_count ?? this.pendingCount;   
        this.totalItems = +res.total_search_results_count;
        var totalPages = Math.ceil(this.totalItems/10);
        this.totalpages = totalPages;  
        this.loading = false;
    });
  }
  

 

  // updateStatusCounts(res) {
  //   this.approvedCount = res.approved_count || 0;
  //   this.rejectedCount = res.rejected_count || 0;
  //   this.pendingCount = res.pending_count || 0;
  
  //   const statusCount = {
  //     approvedData: this.approvedCount,
  //     rejectedData: this.rejectedCount,
  //     pendingData: this.pendingCount
  //   };
  
  //   localStorage.setItem('countdata', JSON.stringify(statusCount));
  // }
  // filtersItem(filterValue) {
  //   console.log("Filter Value:", filterValue);
  // // this.filterMode = false;
  //   this.resetMode = true;
  //   this.myArray = [];
  //   this.p = 1;
  //   var domains=[]
  
  //   if(this.selectdomain && this.selectdomain.length > 0)
  //       {
  //         for (var i = 0; i < this.selectdomain.length; i++) {
  //           domains.push(this.selectdomain[i].item_text);
  //         }
  //         if (this.selectdomain.length != 0) {
  //           this.myArray.push({
  //             name: "domain",
  //             value: domains,
  //           });
  //         }
  //       }
       
    
  //       var schemes = [];
  //       if(this.selectscheme!=undefined){
  //         for(var i = 0 ; i < this.selectscheme.length ; i++){
  //           schemes.push(this.selectscheme[i].item_id);
  //         }
  //         console.log('scheme', schemes);
  //       }
    
  //       if (this.selectcity && this.selectcity.length > 0) {
  //         this.myArray.push({
  //           name: 'city',
  //           value: this.selectcity
  //         });
        
        
  //         // console.log("selectcity:",this.myArray);
  //       }
  //       if (this.selectstate && this.selectstate.length > 0 ){
  //         this.myArray.push({
  //           name: 'state',
  //           value: this.selectstate
  //         })
  //       }
  //       console.log('domain_select',this.selectdomain);
       
    
    
  //       if(schemes.length >0){
  //         this.myArray.push({
  //           name: 'schemes',
  //           value: schemes
  //         })
  //       }
  //         console.log("selectscheme:",this.myArray);
  
  //         if (filterValue) {//whatever i select
  //           this.activeFilter = filterValue === "ALL" ? null : filterValue;
  //           this.selectstatus = filterValue === "ALL" ? ["APPROVED", "PENDING", "DECLINED"] : [filterValue];
  //         }
  
  //   if (this.selectstatus && this.selectstatus.length > 0) {
  //     this.myArray.push({ name: "status", value: this.selectstatus 
        
  //     });
  //     this.activeFilter = this.selectstatus;
  //   }
  
  
  //   console.log("Filters Applied:", this.myArray);
  
  //   let requestData = {
  //     filters: this.myArray,
  //     search_entity: "ACCELERATOR",
  //     page: 0,
  //     page_size: 2984,
  //   };
  
  //   if(filterValue == null){
  //     // this.filterMode=true;
  
  //   this.AcceletorService.filteraccelerator(requestData).subscribe((res: any) => {
  //     console.log("API Response:", res);
    
  //     this.data = res.results;
  //     // Update status counts
  //     this.approvedCount = res.approved_count ;
  //     this.rejectedCount = res.rejected_count ;
  //     this.pendingCount = res.pending_count;
  //   });
  
  // }
  // else
  // {
    
  //   if (!this.myArray.some(item => item.name === 'status')) {
  //     this.myArray.push({ name: "status", value: Array.isArray(filterValue) ? filterValue : [filterValue] });
  //   }
    
  //   let requestData = {
  //     filters: this.myArray,
  //     search_entity: "ACCELERATOR",
  //     page: 0,
  //     page_size: 2984,
  //   };
  //   this.AcceletorService.filteraccelerator(requestData).subscribe((res: any) => {
  //     this.data = res.results;
  
  //     this.loading = false;
  //     // Update status counts
  //     this.approvedCount = res.approved_count;
  //     this.rejectedCount = res.rejected_count;
  //     this.pendingCount = res.pending_count;
      
  //     // this.list = this.data;
  
  
  //     // this.downloadButton = true;
  
  // })
  // // this.filterMode=false;
  // }
  // }
  async filtersItem(filterValue:any) {
    console.log('filtervalue ----',filterValue);
    this.selectedindex = 0;
    if (this.paginationComponent) {
      this.paginationComponent.resetPageIndex();
    }
    if(filterValue != undefined){
      if (filterValue === 'All') {
        this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Select all
        this.selectstatus = ['APPROVED', 'REJECTED', 'PENDING']
      } else if(filterValue === 'APPROVED' || filterValue === 'PENDING' || filterValue === 'REJECTED') {
        this.selectstatus = [filterValue];
        if (this.activeFilter?.includes(filterValue)) {
          this.activeFilter = this.activeFilter?.filter(f => f !== filterValue); // Remove if already selected
        } else {
          this.activeFilter?.push(filterValue); // Add to selection
        }
      }
    }
    else {
      this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING'];
      console.log('af',this.activeFilter);
    }
    // If all three are selected, keep only "View All" active
    if (this.activeFilter?.length === 3) {
      this.activeFilter = ['APPROVED', 'REJECTED', 'PENDING']; // Keep all three for View All
    }
    //console.log("Filter Value:", filterValue);
    this.resetMode = true;
    this.p = 1;
    var domains=[];
  
    if(this.selectdomain && this.selectdomain.length > 0)
        {
          for (var i = 0; i < this.selectdomain.length; i++) {
            console.log('selectdomain ----',this.selectdomain[i])
            domains.push(this.selectdomain[i].item_text);
          }
       
        }
  
        var schemes = [];
        if(this.selectscheme!=undefined){
          for(var i = 0 ; i < this.selectscheme.length ; i++){
            schemes.push(this.selectscheme[i].item_id);
          }
          console.log('scheme', schemes);
        }
        if(this.schemsData){
          // if(this.selectedScheme && this.selectedScheme.length > 0) {
            this.selectstatus = this.selectstatus.length ? this.selectstatus : ['APPROVED']; // Ensure APPROVED status remains

          // }
    
      }
      // const selectedStates = Array.isArray(this.selectstate) 
      // ? this.selectstate?.map(state => state.item_text) : [];
      // const selectedCities = Array.isArray(this.selectcity) 
      // ? this.selectcity?.map(city => city.item_text) : [];
      
      //  }
      const selectedStates = Array.isArray(this.selectstate) 
      ? this.selectstate.map(state => state.item_text ?? state) 
      : [];
          const selectedCities = Array.isArray(this.selectcity) ? this.selectcity.map(city => city.item_text ?? city  ) : [];
     
     this.myArray = await this.setFilters(selectedStates, selectedCities, domains, schemes, this.selectstatus);
     
    
     

//      const selectedStates = Array.isArray(this.selectstate) ? this.selectstate.map(state => state.item_text) : [];
// const selectedCities = Array.isArray(this.selectcity) ? this.selectcity.map(city => city.item_text) : [];

// this.myArray = await this.setFilters(selectedStates, selectedCities, domains, schemes, this.selectstatus);
       this.activeFilter = this.myArray[4].value.length !=0 ? this.myArray[4].value : ['APPROVED','PENDING','REJECTED'];
      console.log('activefilter',this.activeFilter);
  
    console.log("Filters Applied:", this.myArray);
  
    let requestData = {
      query:this.searchText,
      filters: this.myArray,
      search_entity: "ACCELERATOR",
      page: 0,
      page_size: 10,
    };
  
    this.AcceletorService.filteraccelerator(requestData).subscribe((res: any) => {
      console.log("API Response:", res);
    
      this.data = res.results;
      // Update status counts
      this.approvedCount = res.approved_count ;
      this.rejectedCount = res.rejected_count ;
      this.pendingCount = res.pending_count;
      this.totalItems = +res.total_search_results_count;
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages; 
      this.loading = false;
    });
  
  }
  
  async setFilters(...filters: any[][]) {
    //const filterNames = ["state", "city", "domain", "schemes", "status"];
  
    // Map through filterArray and update values based on corresponding position in filters array
    this.filterArray = this.filterArray.map((filter, index) => ({
        ...filter,
        value: filters[index] || [] // Assign values if present, else keep empty
    }));
    console.log('filterArray ----',this.filterArray);
    return this.filterArray;
  }

  paginate_startup(pageno) {
    this.pageno = pageno
    console.log('pageno----',this.pageno);
    this.selectedindex = pageno-1;
    let data={
      query:this.searchText,
      filters: this.myArray,
      "search_entity": "ACCELERATOR",
        "page": pageno-1, "page_size": 10,
      }
      
      this.AcceletorService.getacceleratorsList(data).subscribe((res: any) => {
        this.data = res.results;
        this.StartupList = res.results;
        console.log("acceletor::", this.data);
    
        this.originalData = this.data;
        this.totalItems = +res.total_search_results_count;
        console.log("334___________", this.totalItems);
        this.approvedCount = res.approved_count ;
        this.rejectedCount = res.rejected_count ;
        this.pendingCount = res.pending_count;
        // this.updateStatusCounts(res);
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages; // Replace with your dynamic value
        this.loading = false;
        this.onPageChange(1);
      });
  }
  getSerialNo(i:number) {
    return i+(this.selectedindex*10);
  }

}
