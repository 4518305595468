import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MediaService } from '../../services/media.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import * as _ from "lodash";
import { ShowcaseDialogComponent } from "../../@core/miscellaneous/dialog/showcase-dialog/showcase-dialog.component";
import { ActivatedRoute,Router,Route } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { split } from 'ts-node';
import { ExcelService } from '../../services/excel.service';

const headers = new HttpHeaders().set(
  "Authorization",
  `Bearer ${sessionStorage.getItem("admintoken")}`
);
@Component({
  selector: 'ngx-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
  @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;
  filterArray = [
    {
      "name": "status",
      "value": ["active"]
    },
    {
      "name" : "date",
      "value":[
          {
              "start_date":"",
              "end_date": "",
          }
        ]
    },
  ];
  page_index:number = 0;
  pageno:any = 0;
  totalpages: number;
  totalItems: number;
  selectedindex: number=0;
  searchText = '';
  mediaFilterForm: FormGroup;
  selectedStatus: any = [];
  filterEnable: boolean = false; 
  baseUrl = environment.apiurl;
  fileInputLabel: string;
  editMode:boolean;
  uploadingFile = false;
  team_id:any;
  selectedItemId:any;
  formSubmitted: boolean;
  mediaForm: FormGroup;
  pageSize: number = 25;
  p : number = 1;
  dropdownSettingst: IDropdownSettings = {};
  dropdownstatus: any[] = ["active", "inactive"]
  filterValue: any;
  myArray: any[] = [];
  filterMode: boolean = true;
  activeFilter: string [] =[];
  filterResponseData: any;
  selectstatus: any = [];
  // filterStatus: any[] = []
  selectedStatuses: any[];
  // data: {
  //   images: any;
  //   media_name: string;    
  //   media_link: string;
  // };
  id: string;
  createMode: boolean = true;
  ismediaFormSubmited:boolean = false;

  inValidMessage: boolean;
  dataTable: any;
  readMore: boolean;
  uploadMode: boolean;
  media_id:number;
  currentData: any;
  updatedData: any;
  imageUrl: string | ArrayBuffer | null = null;
  filetypeerr: boolean= false;
  filesizeerr: boolean =false;
  modaldata = { mediaName: "", link: "", image: "" };
  
  filterStatus:any
  filteredDataTable:any;
  MediaData: any;
  mediaData: any;
  editedItem: any;

  constructor(
    private fb: FormBuilder,
    private mediaService: MediaService,
    private http: HttpClient,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private route:ActivatedRoute,
    private excelService: ExcelService

  ) { 
    this.route.queryParams.subscribe(params => { 
        this.filterStatus = params['status'];
    })
  }

  ngOnInit(): void {
    this.mediaForm = this.fb.group({
      mediaLink: ['', [Validators.required, this.urlValidator()] ],
      mediaTitle: ['', Validators.required, ],
      mediaImage: ['', Validators.required, ],
      // status:['']
  })
  this.mediaFilterForm=this.fb.group({
    start_date: [''], 
    end_date: [''], 
  })
  this.getAllMedia();
  this.selectedStatus=this.filterArray[0]?.value

     this.loadScript('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js');
  }

  
 
  getAllMedia() {

    let data= {

      search_entity: "MEDIA",
      "page": 0, 
      "page_size": 10
    }

    this.mediaService.filterPress(data).subscribe((res: any) => {
      this.dataTable = res.results;
      if(this.filterStatus) {
        this.filteredDataTable = this.dataTable.filter(d => d.media_status.toLowerCase() == this.filterStatus.toLowerCase()) 
      } else {
        this.filteredDataTable = this.dataTable
      }
      console.log("ts data::", this.dataTable);

      this.totalItems=+res.total_search_results_count;  
      var totalPages = Math.ceil(this.totalItems/10);
      this.totalpages = totalPages; 

    });
  }
  async publishMedia() {
    this.ismediaFormSubmited = true;
    console.log(this.mediaForm)
    if (this.mediaForm.valid && !this.filesizeerr && !this.filetypeerr) {
      let upload_logo: any;
      const file = this.mediaForm.get('mediaImage')?.value;
      if (file instanceof File) {
        // A new file was selected, so upload it
        upload_logo = await this.UploadLogo();
      } else if (this.editMode && this.editedItem?.images?.length) {
        // No new file, but we are editing and have an existing image
        upload_logo = this.editedItem.images[0]; 
      }
      // if (this.mediaForm.get('mediaImage').value) {
      //   upload_logo = await this.UploadLogo();
      // }
  
      const newsData = {
        media_name: this.mediaForm.value.mediaTitle,
        media_link: this.mediaForm.value.mediaLink,
        images:upload_logo ? [upload_logo] : []
      }
          if (this.editMode) {
           this.updatemajorList(newsData,this.team_id)
          } else 
          {
          this.mediaService.createMedia(newsData).subscribe(
            (res: any) => {
              this.toastrService.success('Media Created Successfully!');

              this.getAllMedia();
               this.cancel();
               this.ismediaFormSubmited = false;

              // this.imageUrl='';
            },
            (error) => {
              console.error('Error fetching the data: ', error);

            }
          );
        }
        console.log(this.mediaForm)

    }
     else {
      this.mediaForm.markAllAsTouched();
      console.log('Form not submitted');

    
  }
}

  get _mediaImage(){
    return this.mediaForm.get('mediaImage')
  }

  get _mediaLink(){
    return this.mediaForm.get('mediaLink')

  }
  get _mediaTitle(){
    return this.mediaForm.get('mediaTitle')

  }
 

  async UploadLogo() {
    if (this.uploadingFile) {
      this.uploadingFile = false;
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', this.mediaForm.get('mediaImage').value);
        this.http.post<any>(this.baseUrl + 'files?intent=create-edition', formData, { headers: headers })
          .subscribe((response) => {
            resolve(response.results[0]);
          });
      });
    }
     else {
      return new Promise((resolve) => {
        if (this.editedItem?.images) {
          this.editedItem.get('mediaImage').setValue(this.editedItem.images[0]?.url || this.editedItem.images);
          resolve(this.editedItem.images[0] || null);
        } else {
          this.mediaForm.get('mediaImage').setValue(null);
          resolve(null);
        }
      });
    }
     
  }
  onFileSelect(event:any) {
    let af = ['image/png','image/jpeg','image/jpg','image/svg','image/webp']
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event:any) => {
      this.imageUrl = event.target.result
      console.log('file upload',this.imageUrl);
    }
    reader.readAsDataURL(file);
    this.uploadMode=true;

    const filesize = file.size;
    const filesizemb = filesize/1024 ** 2;
    console.log('filesize in mb',filesizemb);
    console.log('file',file);
    if(!af.includes(file.type)){
      this.filetypeerr = true
    }
    else
    {
      this.filetypeerr = false
    }

  if(filesizemb > 2){
    this.filesizeerr = true
    }
  else
  {
    this.filesizeerr = false
  }
  // this.fileInputLabel = file.name;
  this.fileInputLabel = file ? file.name : 'Choose File';
  this.uploadingFile = true;  

  this.mediaForm.get("mediaImage").setValue(file);
  this.uploadFileInput.nativeElement.value = '';

  }
//  async editData(id:any){
//     // this.invalidDimensions=false;

//     this.editMode = true; 
//    this. team_id = id
// console.log('id', this.team_id)
//     this.mediaService.editMedia(id).subscribe(
//      ( res:any) =>{

//         console.log('data',res.results)
//         this.mediaData = res.results[0];
//         // this.checkImageDimensions(this.editedData.thumbnail_upload[0]?.url)

//         console.log('editData', this.mediaData)

//         this.mediaForm.patchValue({
//           mediaLink:this.mediaData.media_link,
//           mediaTitle: this.mediaData.media_name,
//           status:this.mediaData.media_status,
//           mediaImage:[this.mediaData.images] ,
//         })

//         this.fileInputLabel = this.mediaData.thumbnail_upload ? this.mediaData.thumbnail_upload[0]?.path?.split('/')[1]:''
//         // console.log('fike',this.fileInputLabel)

//     }
//     )
//   }
// editData(mediaData: any) {
//   this.editMode = true;
//   this.editedItem = mediaData;
//   console.log('editItem',this.editedItem)
//   // Save the actual id (a primitive) in team_id (or another variable)
//   this.team_id = mediaData.media_id;
  
//   console.log('team_id',this.team_id)

//   this.mediaForm.patchValue({
//     mediaLink: mediaData.media_link,
//     mediaTitle: mediaData.media_name,
//     // mediaImage: mediaData.images || []
//   });
  
//   this.fileInputLabel = mediaData.images && mediaData.images[0]
//     ? mediaData.images[0].path?.split('/').pop()
//     : '';
  
//   console.log("Editing item:", mediaData);
// }
editData(mediaData: any) {
  this.editMode = true;
  this.editedItem = mediaData;
  this.team_id = mediaData.media_id; // ensure this is a primitive
  
  this.mediaForm.patchValue({
    mediaLink: mediaData.media_link,
    mediaTitle: mediaData.media_name,
    // Do not set mediaImage with an actual file; leave it null
    mediaImage: null 
  });

  // Clear the validators on mediaImage so the control is not required during editing
  this.mediaForm.get('mediaImage')?.clearValidators();
  this.mediaForm.get('mediaImage')?.updateValueAndValidity();
  
  // Set the label (for display purposes) based on the existing image
  this.fileInputLabel = mediaData.images && mediaData.images[0]
    ? mediaData.images[0].path?.split('/').pop()
    : '';

  console.log("Editing item:", mediaData);
}

  updatemajorList(newsData,team_id:any){
    console.log('re',team_id);

    this.mediaService.editMedia(newsData,team_id).subscribe((res)=>{
      console.log('res___1234',team_id);
     this.getAllMedia();
     this.toastrService.success('Form updated successfully!')
     this.cancel();
    //  this.clearInputFields(); //
    },
    (error) => {
      console.error('Error fetching investors: ', error);
    });
  }
  // editData(mediaData: any, el:any) {
  //   console.log(mediaData)
  //   // el.scrollIntoView({behaviour:'smooth'});
  //   this.id = mediaData.media_id
  //   this.updatedData = mediaData
  //   this.uploadMode=false;
  //   this.editMode = true; //change btn to update
  //   this.createMode = false;
  //   this.mediaForm.controls["mediaTitle"].setValue(mediaData.media_name);
  //   this.mediaForm.controls["status"].setValue(mediaData.media_status);
  //   this.media_id = mediaData.media_id;  
  //   this.mediaForm.controls["mediaLink"].setValue(mediaData.media_link); //static passing
  //   // this.mediaForm.controls["mediaImage"].setValue(mediaData.images[0].url);


  //   const url = mediaData.images[0].url;


  //   this.fileInputLabel = mediaData.images ? mediaData.images[0]?.path?.split('/')[1] : '';

   
  // }

  // updateForms() {
  //   if (this.mediaForm.valid) {
      

  //     if(this.uploadMode == false ){
  //       if (this.editMode) {
  //         this.editMode = true;         
  //         // this.currentData["news_status"] = this.status;
  //         this.data = {
            
  //           media_name: this.mediaForm.controls.mediaTitle.value,  
  //           media_link: this.mediaForm.controls.mediaLink.value,
  //           images: [
  //             {
  //               "file_id": this.updatedData.images[0].file_id,
  //               "path":this.updatedData.images[0].path,
  //               "url": this.updatedData.images[0].url
    
  //             }
  //           ]   
            
                
           
  //         };
         
  //         console.log("data....", this.data);
  //         this.mediaService.editMedia(this.data, this.id).subscribe(
  //           (res) => {
  //             this.mediaForm.reset();
  //             this.getAllMedia();
  //             this.editMode = false;
  //             this.createMode = true;
  //             this.fileInputLabel = 'Choose File';
  //             // this.imageUrl='';
  //             this.toastrService.success("Media Updated Successfully!");
  //           },
  //           (error) => {
  //             this.inValidMessage = true;
  //             this.toastrService.warning("Unable to update Media!");
  //           }
  //         ); /////put api function call ends here!
  //       }
  //     }
            
  //     else if(this.uploadMode ==true){
  //       if (this.mediaForm.valid) {
  //         this.createMode=false;
  //         this.currentData = this.mediaForm.value;
  //         // this.currentData["news_status"] = this.status;
  //         const formData = new FormData();
  //         formData.append("file", this.mediaForm.get("mediaImage").value);
  //         this.http.post
  //         <any>
  //         (this.baseUrl + "files?intent=create-edition", formData,{
  //         headers :headers, })
  //         .subscribe((response)=>{
  //         console.log("image response", response.results[0].url);
  //         if (response.statusCode === 200) {
  //         this.uploadFileInput.nativeElement.value = "";
  //         this.fileInputLabel = undefined;
  //         }
          
  //         if(this.imageUrl != null){
  //           this.data = {
  //             media_name: this.mediaForm.controls.mediaTitle.value,  
  //             media_link: this.mediaForm.controls.mediaLink.value,
  //             images: [
  //               {
  //                 "file_id": response.results[0].file_id,
  //                 "path":response.results[0].path,
  //                 "url": response.results[0].url
      
  //               }
  //             ]   
              
                  
             
  //           };
  //         }
         
  //         console.log("data....", this.data);
  //         this.mediaService.editMedia(this.data, this.id).subscribe(
  //         (res) => {
  //         this.mediaForm.reset();
  //         this.getAllMedia();
  //         this.createMode = true;
  //         this.fileInputLabel = 'Choose File';
  //         // this.imageUrl='';
  //         this.uploadMode=false;
  //         this.editMode=false;
  //         this.toastrService.success("Media Updated Successfully!");
  //         },
  //         (error) => {
  //         this.inValidMessage = true;
  //         this.toastrService.warning("Unable to update Media!");
  //         }
  //         );
  //         })
        
  //         /////put api function call ends here!
  //         }
  //     }
      
     
  //   }
  // }
  // updateForms() {
  //   if (this.mediaForm.valid) {
  //     console.log("Upload Mode:", this.uploadMode);
  //     console.log("Edit Mode:", this.editMode);
      
  //     if (!this.uploadMode && this.editMode) {
  //       this.data = {
  //         media_name: this.mediaForm.controls.mediaTitle.value,  
  //         media_link: this.mediaForm.controls.mediaLink.value,
  //         images: [
  //           {
  //             "file_id": this.updatedData.images[0].file_id,
  //             "path": this.updatedData.images[0].path,
  //             "url": this.updatedData.images[0].url
  //           }
  //         ]   
  //       };
        
  //       console.log("Updating Media with data:", this.data);
  
  //       this.mediaService.editMedia(this.data, this.id).subscribe(
  //         (res) => {
  //           console.log("Update Success:", res);
  //           this.mediaForm.reset();
  //           this.getAllMedia();
  //           this.editMode = false;
  //           this.createMode = true;
  //           this.fileInputLabel = 'Choose File';
  //           this.toastrService.success("Media Updated Successfully!");
  //         },
  //         (error) => {
  //           console.log("Update Error:", error);
  //           this.inValidMessage = true;
  //           this.toastrService.warning("Unable to update Media!");
  //         }
  //       );
  //     }
  //   }
  // }
  
  preview(item: any) {
    console.log("item", item);
    this.modaldata.mediaName = item.media_name;
    this.modaldata.link = item.media_link;    
    this.modaldata.image = item.images[0].url;
  }

  // deleteMediaData(id) {
  //   this.dialogService
  //     .open(ShowcaseDialogComponent, {
  //       context: {
  //         message: "Are you sure you want to delete this Media?",
          
  //       },
  //     })
  //     .onClose.subscribe((confirmed) => {
  //       if (confirmed) {
  //         console.log("deletedata:", id);
  //         this.mediaService.deleteMedia(id).subscribe(
  //           (data) => {
  //             this.getAllMedia();
  //             this.toastrService.success("Media Deleted Successfully!");
  //             this.fileInputLabel = 'Choose File';
  //           },
  //           (error) => {
  //             this.inValidMessage = true;
  //             this.toastrService.warning("Unable to Delete Media!");
  //           }
  //         );
  //       }
  //     });
  // }
  deleteMediaData(id:any){
  
    this.mediaService.deleteMedia(id).subscribe(()=>{
this.getAllMedia();   
this.toastrService.success('Media deleted successfully!')


    },
    (error) => {
      this.inValidMessage = true;
      this.toastrService.warning("Unable to Delete Media!");
    } )
    }


   // truncate text
   truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {       
      this.readMore = false;
      return text;
     
    } else {
      this.readMore = true;
      return text.substring(0, maxLength) + '...';
    }
  }
   // truncate text

  cancel() {
    this.mediaForm.reset();
    this.editMode = false;
    this.fileInputLabel = ''; // Reset file input label
    // this.UploadFileInput = false;

    this.ismediaFormSubmited = false;
    this.resetForm(); // Reset form and clear file input labels
    this.getAllMedia();
    console.log('canceledit');
  }
  resetForm() {
   
    this.fileInputLabel = ''; // Reset file input label
   // Reset document input label
    this.editedItem = null; // Clear edited data
    this.uploadingFile = false;

    // this.uploadFileInput.nativeElement.value = '';

  }

  urlValidator(regexPattern?: string | RegExp): ValidatorFn {
    const pattern =
      regexPattern ||
      /^(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?$/;

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        // If the field is empty, don't consider it invalid
        return null;
      }

      // Convert pattern to RegExp if it's a string
      const regExp = new RegExp(pattern);

      // Test the control value against the pattern
      const isValid = regExp.test(control.value);

      // If the value is not valid, return an error object
      return isValid ? null : { url: { value: control.value } };
    };
  }
  public loadScript(url: string) {

  const body = <HTMLDivElement> document.body;
   
  const script = document.createElement('script');
   
  script.innerHTML = '';
   
   script.src = url;
   
   script.async = false;
   
   script.defer = true;
   
  body.appendChild(script);
   
     }
     async filtersItem() {
    
      this.p = 1;
      var dateFilter = [];
      let startDate = this.mediaFilterForm.get('start_date').value;
      let endDate = this.mediaFilterForm.get('end_date').value;
      if(startDate && endDate){
        dateFilter.push({ "start_date": startDate, "end_date": endDate });
      }
     
    this.myArray = await this.setFilters(this.selectedStatus,dateFilter);
      console.log('myarray',this.myArray)
      console.log("Filters Applied:", this.myArray);
    
      let requestData = {
        query:this.searchText,
        filters: this.myArray,
        search_entity: "MEDIA",
        page: 0,
        page_size: 10,
      };
    
      this.mediaService.filterPress(requestData).subscribe((res: any) => {
        console.log("API Response:", res);
      
        this.filteredDataTable = res.results;
        // Update status counts
        this.totalItems=+res.total_search_results_count;  
        var totalPages = Math.ceil(this.totalItems/10);
        this.totalpages = totalPages; 
      });
      this.filterEnable = true;
    }
    
    async setFilters(...filters: any[][]) {
      //const filterNames = ["state", "city", "domain", "schemes", "status"];
    
      // Map through filterArray and update values based on corresponding position in filters array
      this.filterArray = this.filterArray.map((filter, index) => ({
          ...filter,
          value: filters[index] || [] // Assign values if present, else keep empty
      }));
      console.log('filterArray ----',this.filterArray);
      return this.filterArray;
    }
    resetFilter() {
      // this.selectedStatus = []; // Reset selected filters in dropdown
      this.mediaFilterForm.get('end_date')?.setValue(null)
      this.mediaFilterForm.get('start_date')?.setValue(null)
    
      this.myArray = []; // Reset filter array
      this.filterEnable = false; // Disable filter applied flag if needed
      this.p = 1; // Reset pagination to first page
    
      // Call existing method to load initial data
      setTimeout(() => {
        this.searchText = ''; // ✅ Ensure UI updates properly
      });
      this.getAllMedia();

    
    }
     exportAsXLSX(data):void {
      this.excelService.exportAsExcelFile(data, 'Media','Media_data');
    }

    //  downloadlabList(){ 
    //   this.exportData(this.dataTable);
     
    // }

    isDownloading:boolean;
    async listAllMediaDownload(): Promise<any[]> {
      return new Promise((resolve, reject) => {
        const request = {
          "query":this.searchText,
          filters: this.myArray,
          search_entity: "MEDIA",
          page: 0,
          page_size: 10,
        };
    
        this.mediaService.filterPress(request).subscribe(
          (res: any) => {
            resolve(res.results);
          },
          (err) => {
            reject(err);
          }
        );
      });
    }
    
   async downloadlabList(){
      this.isDownloading = true;
  
    try {
      const mediaData = await this.listAllMediaDownload();
      this.exportData(mediaData);
    } catch (err) {
      console.error('Download failed', err);
    }
  
    this.isDownloading = false;
    };
   


  exportData(data2: any[]) {    
    const labData = data2.map((d,index) => ({      
      // "Sl no": d.media_id? d.media_id :'-',
      'Sl No': index + 1,
      "Name": d.media_name? d.media_name : '-',
      "Created at":d.created_at?new Date(d.created_at):'-',
      "Link": d.media_link ? d.media_link : '-',
      "Updated at" : d.updated_at? new Date(d.updated_at):'-',
      
      "Image" : d.images[0].url ? d.images[0].url : '-',
      "Status" : d.media_status ? d.media_status : '-',    
    }));
    this.exportAsXLSX(labData)
    // this.generateExcelFile(data, "myData.xlsx");
  }


  paginate_startup(pageno) {
    this.pageno = pageno;
  
    console.log('pageno----',this.pageno);
    this.selectedindex = pageno-1;
    console.log('selectedindex-----',this.selectedindex)
    console.log('myarray ----',this.myArray);
    let data={
      query:this.searchText,
      "filters": this.myArray,
      "search_entity": "MEDIA",
      "page": pageno- 1,
      "page_size": 10,
        
      }
     
  
    
    console.log('data_payload',data);
  
    this.mediaService.filterPress(data).subscribe((res)=>{
      // this.filterdata = res.results;
      this.filteredDataTable = res.results;
      // this.dataTable = res.results;
      console.log('data----');
      console.log(data);
     
  
    })
  }
  
  getSerialNo(i:number) {
    return i+(this.selectedindex*10);
  }
  

}
